import Api from '@/api/Api'

export default {
  baseUrl: 'account_update',

  /**
   * count
   *
   * @returns {Promise}
   */
  count () {
    return Api.baseFetch(`${this.baseUrl}/count`)
  },

  /**
   * getCsvUrl
   *
   * @returns {string}
   */
  getCsvUrl () {
    return `${Api.baseUrl}/${this.baseUrl}/csv`
  },

  /**
   * setCsv
   *
   * @param {File} file
   * @returns {Promise}
   */
  setCsv (file) {
    const form = new FormData()
    form.append('File', file)

    return fetch(`${Api.baseUrl}/${this.baseUrl}/csv`, {
      method: 'POST',
      body: form,
    })
  },

  /**
   * performUpdate
   *
   * @returns {Promise}
   */
  performUpdate () {
    return Api.baseFetch(`${this.baseUrl}/perform_update`, {
      method: 'POST',
    })
  },

  /**
   * delete
   *
   * @returns {Promise}
   */
  delete () {
    return Api.baseFetch(this.baseUrl, {
      method: 'DELETE',
    })
  },
}
