<template>
  <div class="payout-overview--wrap">
    <data-table
      :columns="columns"
      :items="dividendPayouts"
      :is-loading="isLoading"
      :default-options="{ sortBy: ['resolutionDate'], sortDesc: [true] }"
    >
      <template #[`item.active`]="{ item }">
        <v-checkbox
          v-model="item.active"
          disabled
          class="mt-1 mb-2"
          hide-details
        />
      </template>

      <template #[`item.documentsGenerated`]="{ item }">
        <v-checkbox
          v-model="item.documentsGenerated"
          disabled
          class="mt-1 mb-2"
          hide-details
        />
      </template>

      <template #[`item.dividend`]="{ item }">
        {{ item.dividend | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.resolutionDate`]="{ item }">
        {{ item.resolutionDate | readableIsoDate(true) }}
      </template>

      <template #[`item.paymentDate`]="{ item }">
        {{ item.paymentDate | readableIsoDate }}
      </template>

      <template #[`item.paymentPeriodBegin`]="{ item }">
        {{ item.paymentPeriodBegin | readableIsoDate }}
      </template>

      <template #[`item.paymentPeriodEnd`]="{ item }">
        {{ item.paymentPeriodEnd | readableIsoDate }}
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-archive"
          icon-color="grey darken-1"
          :tooltip="$t('dividendPayout.downloadArchive')"
          :disabled="!item.documentsGenerated"
          :href="getArchiveDownloadLink(item.dividendPayoutId)"
        />
        <table-button
          icon="mdi-export"
          icon-color="grey darken-1"
          :tooltip="$t('dividendPayout.export')"
          :href="getExportDownloadLink(item.dividendPayoutId)"
        />
        <table-button
          icon="mdi-file-pdf-box"
          icon-color="grey darken-1"
          :tooltip="$t('dividendPayout.pdfPreview')"
          @click="payoutIdToGetPdfs = item.dividendPayoutId"
        />
        <table-button
          icon="mdi-cog"
          icon-color="grey darken-1"
          :tooltip="$t('common.edit')"
          :disabled="item.active"
          @click="payoutToEdit = item"
        />
        <table-button
          icon="mdi-clipboard-alert-outline"
          icon-color="grey darken-1"
          :tooltip="$t('dividendPayout.notices')"
          @click="payoutIdToGetNotices = item.dividendPayoutId"
        />
        <table-button
          icon="mdi-send-check"
          icon-color="grey darken-1"
          :tooltip="$t('dividendPayout.activate')"
          :disabled="item.active"
          @click="payoutToActivate = item"
        />
      </template>
    </data-table>

    <payout-pdf-dialog
      :payout-id="payoutIdToGetPdfs"
      @close="payoutIdToGetPdfs = null"
    />

    <payout-notices-dialog
      :payout-id="payoutIdToGetNotices"
      @close="payoutIdToGetNotices = null"
    />

    <edit-payout-dialog
      :payout-to-edit="payoutToEdit"
      @close="payoutToEdit = null"
      @updated="$emit('updated')"
    />

    <confirm-dialog
      :headline="$t('dividendPayout.activate')"
      :info-text="$t('dividendPayout.activateInfo')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="payoutToActivate !== null"
      @cancel="payoutToActivate = null"
      @ok="activate(payoutToActivate)"
    />
  </div>
</template>

<script>
import DividendPayoutApi from '@/api/DividendPayout.js'

import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import EditPayoutDialog from './EditPayoutDialog.vue'
import PayoutNoticesDialog from './PayoutNoticesDialog.vue'
import TableButton from '@/components/TableButton'
import PayoutPdfDialog from './PayoutPdfDialog.vue'

export default {
  name: 'payout-overview',

  components: {
    ConfirmDialog,
    DataTable,
    EditPayoutDialog,
    PayoutNoticesDialog,
    TableButton,
    PayoutPdfDialog,
  },

  props: {
    dividendPayouts: {
      type: Array,
      default: () => ([]),
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      payoutIdToGetNotices: null,
      payoutIdToGetPdfs: null,
      payoutToActivate: null,
      payoutToEdit: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('dividendPayout.active'), value: 'active', width: 80 },
        { text: this.$t('dividendPayout.documentsGenerated'), value: 'documentsGenerated' },
        { text: this.$t('dividendPayout.dividend'), value: 'dividend' },
        { text: this.$t('dividendPayout.resolutionDate'), value: 'resolutionDate' },
        { text: this.$t('dividendPayout.paymentDate'), value: 'paymentDate' },
        { text: this.$t('dividendPayout.paymentPeriodBegin'), value: 'paymentPeriodBegin' },
        { text: this.$t('dividendPayout.paymentPeriodEnd'), value: 'paymentPeriodEnd' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right', width: '230px' },
      ]
    },
  },

  methods: {
    /**
     * activate
     *
     * @param {Object} payout
     * @returns {Promise}
     */
    async activate (payout) {
      const res = await DividendPayoutApi.activate(payout.dividendPayoutId)

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('dividendPayout.activated') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.$emit('updated')
      this.payoutToActivate = null
    },

    /**
     * getArchiveDownloadLink
     *
     * @param {number} dividendPayoutId
     * @returns {string}
     */
    getArchiveDownloadLink (dividendPayoutId) {
      return DividendPayoutApi.getArchiveDownloadLink(dividendPayoutId)
    },

    /**
     * getExportDownloadLink
     *
     * @param {number} dividendPayoutId
     * @returns {string}
     */
    getExportDownloadLink (dividendPayoutId) {
      return DividendPayoutApi.getExportDownloadLink(dividendPayoutId)
    },
  },
}
</script>
