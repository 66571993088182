<template>
  <div class="date-config--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.dateConfig') }}
    </h1>

    <date-table with-admin-actions />
  </div>
</template>

<script>
import DateTable from '@/views/Dates/DateTable'

export default {
  name: 'date-config',

  components: {
    DateTable,
  },
}
</script>
