import { endOfDay } from 'date-fns'
import Api from '@/api/Api'

export default {
  baseUrl: 'share',

  /**
   * getAvailableShares
   *
   * @returns {Promise}
   */
  getAvailableShares () {
    return Api.baseFetch(`${this.baseUrl}/me/available`)
  },

  /**
   * getShareholders
   *
   * @param {Date} dateToUse
   * @returns {Promise}
   */
  getShareholders (dateToUse) {
    return Api.baseFetch(
      `${this.baseUrl}/all_shareholders/${endOfDay(dateToUse).toISOString()}`
    )
  },

  /**
   * getShareholderAgeDistribution
   *
   * @param {Date} dateToUse
   * @returns {Promise}
   */
  getShareholderAgeDistribution (dateToUse) {
    return Api.baseFetch(
      `${this.baseUrl}/all_shareholders_age/${endOfDay(dateToUse).toISOString()}`
    )
  },

  /**
   * getShareholderStats
   *
   * @param {Date} dateToUse
   * @returns {Promise}
   */
  getShareholderStats (dateToUse) {
    return Api.baseFetch(
      `${this.baseUrl}/all_shareholders_stats/${endOfDay(dateToUse).toISOString()}`
    )
  },

  /**
   * getSharesOf
   *
   * @param {number|string} accountId
   * @param {boolean} withPrevious
   * @returns {Promise}
   */
  getSharesOf (accountId, withPrevious = false) {
    return Api.baseFetch(`${this.baseUrl}/${accountId}${withPrevious ? '/all' : ''}`)
  },

  /**
   * getHistory
   *
   * @param {number} certificate
   * @returns {Promise}
   */
  getHistory (certificate) {
    return Api.baseFetch(`${this.baseUrl}/history/${certificate}`)
  },

  /**
   * getUserShares
   *
   * @returns {Promise}
   */
  getUserShares () {
    return Api.baseFetch(`${this.baseUrl}/me`)
  },

  /**
   * csvExportUrl
   *
   * @returns {string}
   */
  csvExportUrl () {
    const params = new URLSearchParams({ mime_type: 'text/csv' })
    return `${Api.baseUrl}/${this.baseUrl}/all_shareholders/export?${params}`
  }
}
