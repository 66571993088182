var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configuration--wrap"},[_c('h1',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.configuration'))+" ")]),_c('data-table',{attrs:{"columns":_vm.columns,"items":_vm.configs,"is-loading":_vm.isLoading,"show-search":false,"title":_vm.$t('configuration.allConfigurations'),"default-options":{ sortBy: ['validFrom'], sortDesc: [true] }},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('table-button',{attrs:{"icon":"mdi-playlist-plus","dark":"","tooltip":_vm.$t('common.newEntry'),"is-primary":""},on:{"click":_vm.showCreateDialog}})]},proxy:true},{key:"item.formulaPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.formulaPrice,_vm.$i18n.locale))+" ")]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.validFrom,true))+" ")]}},{key:"item.holdingReturnNumerator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(100 * item.holdingReturnNumerator / item.holdingReturnDenominator)+" % ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"disabled":_vm.configIsInPast(item),"icon":"mdi-pencil","icon-color":"primary","tooltip":_vm.configIsInPast(item) ? _vm.$t('configuration.pastNotAllowed') : _vm.$t('common.edit')},on:{"click":function($event){return _vm.showEditDialog(item)}}})]}}],null,true)}),_c('configuration-dialog',{attrs:{"is-visible":_vm.dialogVisible,"config-to-use":_vm.toEdit},on:{"close":_vm.onCloseDialog,"submit":_vm.onSubmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }