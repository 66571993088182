<template>
  <div class="csv-handling--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.csvHandling') }}
    </h1>

    <v-row dense>
      <v-col :cols="12" :lg="4">
        <v-card
          outlined
          height="200"
          :href="csvUserDownloadUrl"
          target="blank"
          class="d-flex flex-column"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.downloadUsers') }}
            <v-icon class="mt-6" size="46">
              mdi-tray-arrow-down
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="4">
        <input
          ref="file"
          type="file"
          accept=".csv"
          class="d-none"
          @change="uploadCsvFile"
        >

        <v-card
          :loading="isUploadingFile"
          outlined
          height="200"
          class="d-flex flex-column"
          @click="$refs.file.click()"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.uploadFile') }}
            <v-icon class="mt-6" size="46">
              mdi-tray-arrow-up
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="4">
        <v-card
          outlined
          height="200"
          :href="csvDownloadUrl"
          target="blank"
          class="d-flex flex-column"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.downloadFile') }}
            <v-icon class="mt-6" size="46">
              mdi-tray-arrow-down
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="4">
        <v-card
          :loading="updateInProgress"
          outlined
          height="200"
          class="d-flex flex-column"
          @click="performActions()"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.performActions') }} ({{ expectedChanges }})
            <v-icon class="mt-6" size="46">
              mdi-account-hard-hat
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="4">
        <v-card
          :loading="deletionInProgress"
          outlined
          height="200"
          class="d-flex flex-column"
          @click="deleteCurrentCsvFile()"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.deleteCsv') }}
            <v-icon class="mt-6" size="46">
              mdi-delete
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="4">
        <v-card
          :loading="isCreatingShares"
          outlined
          height="200"
          class="d-flex flex-column"
          @click="createBonusShares()"
        >
          <v-card-text class="flex-grow-1 d-flex flex-column align-center justify-center text-h6 text-center">
            {{ $t('csvHandling.createBonusShares') }}
            <v-icon class="mt-6" size="46">
              mdi-gift
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from '@/api/Api'
import AccountApi from '@/api/Account'
import AccountUpdateApi from '@/api/AccountUpdate'

export default {
  name: 'csv-handling',

  data () {
    return {
      deletionInProgress: false,
      expectedChanges: 0,
      isCreatingShares: false,
      isUploadingFile: false,
      updateInProgress: false,
    }
  },

  computed: {
    csvUserDownloadUrl () {
      return AccountApi.getCsvUrl()
    },

    csvDownloadUrl () {
      return AccountUpdateApi.getCsvUrl()
    },
  },

  mounted () {
    this.getExpectedChanges()
  },

  methods: {
    /**
     * getExpectedChanges
     *
     * @returns {Promise}
     */
    async getExpectedChanges () {
      const res = await AccountUpdateApi.count()
      res.ok && (this.expectedChanges = await res.json())
    },

    /**
     * uploadCsvFile
     *
     * @returns {Promise}
     */
    async uploadCsvFile () {
      this.isUploadingFile = true

      const res = await AccountUpdateApi.setCsv(this.$refs.file.files[0])
      await this.getExpectedChanges()

      this.isUploadingFile = false
      this.$refs.file.value = null

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('csvFile.uploadSuccess'), color: 'success' })
        : this.$store.commit('setSnackbar', {
          text: res.status === 400 ? res.statusText : this.$t('common.errorOccured'),
          color: 'error',
        })
    },

    /**
     * deleteCurrentCsvFile
     *
     * @returns {Promise}
     */
    async deleteCurrentCsvFile () {
      this.deletionInProgress = true
      const res = await AccountUpdateApi.delete()
      await this.getExpectedChanges()
      this.deletionInProgress = false

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('csvFile.csvDeletedSuccess'), color: 'success' })
        : this.showGenericError()
    },

    /**
     * performActions
     *
     * @returns {Promise}
     */
    async performActions () {
      this.updateInProgress = true
      const res = await AccountUpdateApi.performUpdate()
      await this.getExpectedChanges()
      this.updateInProgress = false

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('csvFile.actionsCompleted'), color: 'success' })
        : this.showGenericError()
    },

    /**
     * createBonusShares
     *
     * @returns {Promise}
     */
    async createBonusShares () {
      this.isCreatingShares = true
      const res = await Api.baseFetch('bonus_share/create', { method: 'POST' })
      this.isCreatingShares = false

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('csvFile.createdBonusShares'), color: 'success' })
        : this.showGenericError()
    },

    /**
     * showGenericError
     */
    showGenericError () {
      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    },
  },
}
</script>
