<template>
  <v-app v-if="translationsLoaded">
    <v-main>
      <app-header
        :sidebar-visible="sidebarVisible"
        @sidebar:toggle="onSidebarChange(!sidebarVisible)"
      />

      <v-container class="px-4 px-lg-15 py-10">
        <transition name="fade" mode="out-in">
          <router-view class="content" />
        </transition>
      </v-container>
    </v-main>

    <app-footer />

    <sidebar-menu
      :is-visible="sidebarVisible"
      @change="onSidebarChange"
    />
    <info-snackbar />
  </v-app>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'

import AppFooter from '@/views/AppFooter'
import AppHeader from '@/views/AppHeader'
import SidebarMenu from '@/views/SidebarMenu'
import InfoSnackbar from '@/components/InfoSnackbar'
import TranslationApi from '@/api/Translation'

export default {
  name: 'app',

  components: {
    AppFooter,
    AppHeader,
    SidebarMenu,
    InfoSnackbar,
  },

  data () {
    return {
      notificationInterval: null,
      sidebarVisible: true,
      translationsLoaded: false,
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  mounted () {
    this.loadTranslations()
    this.initNotifications()
  },

  destroyed () {
    this.notificationInterval !== null && clearInterval(this.notificationInterval)
  },

  methods: {
    /**
     * onSidebarChange
     *
     * @param {boolean} visibility
     * @returns {void}
     */
    onSidebarChange (visibility) {
      this.sidebarVisible = visibility
    },

    /**
     * Creates the interval to fetch new notifications.
     *
     * @returns {void}
     */
    initNotifications () {
      this.notificationInterval = setInterval(() =>
        this.user !== null && this.$store.dispatch('notification/get'), 1000 * 30
      )
    },

    /**
     * Loads available translations, uses those for our i18n-plugin.
     *
     * @returns {void}
     */
    async loadTranslations () {
      const res = await TranslationApi.get()

      if (res.ok) {
        const { data } = await res.json()
        Object.keys(data).forEach(key => this.$i18n.setLocaleMessage(key, data[key]))
        this.translationsLoaded = true
        document.title = this.$t('common.pageTitle')

        // refresh components so the width of the changed labels gets used
        window.dispatchEvent(new Event('resize'))
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@/styles/styles.scss';

  .fade-enter-active, .fade-leave-active {
    transition:
      transform 100ms cubic-bezier(1.0, 0.5, 0.8, 1.0),
      opacity 100ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .fade-enter {
    transform: scale(0.97);
    opacity: 0;
  }

  .fade-leave-to {
    opacity: 0;
  }
</style>
