import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#005EAB',
        secondary: colors.teal,
        accent: colors.deepPurple.accent3
      },
      dark: {
        primary: '#005EAB',
        secondary: colors.teal,
        accent: colors.deepPurple.accent3
      }
    },
  },
})
