<template>
  <div class="revenue-preview--wrap">
    <v-row v-if="splitInfo">
      <v-col :cols="12" :lg="6">
        <donut-base
          :series="[splitInfo.incomeUser, splitInfo.incomeHolding]"
          :height="420"
          :labels="[$t('sell.shareholders'), $t('sell.holding')]"
          :title="$t('sell.revenueOverview')"
          :subtitle="$t('sell.possibleRevenue')"
          :option-changes="optionChanges"
        />
      </v-col>

      <v-col :cols="12" :lg="6">
        <v-card class="fill-height">
          <v-card-title>
            {{ $t('sell.revenueDetails') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('sell.possibleRevenue') }}
          </v-card-subtitle>
          <v-card-text>
            <v-list class="mb-6">
              <template v-for="({ icon, label, data }, i) in listEntries">
                <v-list-item :key="i">
                  <v-list-item-icon>
                    <v-icon>{{ icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ data }}</v-list-item-title>
                    <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i < listEntries.length - 1" :key="`_${i}`" inset />
              </template>
            </v-list>

            <v-alert color="secondary" outlined icon="mdi-information" dark border="left">
              {{ $t('sell.revenuePreviewHint') }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from 'lodash'

import Api from '@/api/Api'
import DonutBase from '@/components/charts/DonutBase'

export default {
  name: 'revenue-preview',

  components: {
    DonutBase,
  },

  props: {
    amountToSell: {
      type: Number,
      default: 0,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    stockPrice: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      splitInfo: null,
      optionChanges: {
        dataLabels: {
          formatter: (val, opts) =>
            this.$options.filters.readablePrice(opts.w.config.series[opts.seriesIndex], this.$i18n.locale)
          ,
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                value: {
                  formatter: val => this.$options.filters.readablePrice(val, this.$i18n.locale)
                },
                total: {
                  formatter: ({ globals }) => this.$options.filters.readablePrice(
                    globals.seriesTotals.reduce((a, b) => a + b, 0),
                    this.$i18n.locale
                  )
                },
              },
            }
          }
        },
        tooltip: {
          y: {
            formatter: val => this.$options.filters.readablePrice(val, this.$i18n.locale)
          }
        },
      }
    }
  },

  computed: {
    listEntries () {
      const asPrice = this.$options.filters.readablePrice

      return [
        { label: this.$t('sell.holdingShares'), icon: 'mdi-domain', data: this.splitInfo.amountHolding },
        { label: this.$t('sell.shareholderShares'), icon: 'mdi-account-group', data: this.splitInfo.amountUser },
        { label: this.$t('sell.holdingRevenue'), icon: 'mdi-cash', data: asPrice(this.splitInfo.incomeHolding, this.$i18n.locale) },
        { label: this.$t('sell.shareholderRevenue'), icon: 'mdi-cash', data: asPrice(this.splitInfo.incomeUser, this.$i18n.locale) },
        { label: this.$t('sell.totalRevenue'), icon: 'mdi-cash-multiple', data: asPrice(this.splitInfo.incomeTotal, this.$i18n.locale) },
      ]
    },
  },

  watch: {
    amountToSell: { immediate: true, handler: 'loadSplitInfo' },
    stockPrice: { immediate: true, handler: 'loadSplitInfo' },
  },

  mounted () {
    this.loadSplitInfo()
  },

  methods: {
    /**
     * Load possible revenue-info - but debounced since triggering via fast
     * inputs is possible.
     *
     * @returns {void}
     */
    loadSplitInfo: debounce(async function () {
      const res = await Api.baseFetch(
        `stock/calculate/share_split/${Math.round(this.stockPrice)}/${this.amountToSell}`
      )

      res.ok && (this.splitInfo = await res.json())
    }, 150)
  },
}
</script>
