<template>
  <div class="info-tooltip--wrap d-inline-block">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div
          class="d-inline-block"
          v-bind="attrs"
          v-on="on"
        >
          <slot>
            <v-icon :color="iconColor">
              {{ icon }}
            </v-icon>
          </slot>
        </div>
      </template>

      <span>{{ text }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'info-tooltip',

  props: {
    text: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: 'mdi-information-outline'
    },

    iconColor: {
      type: String,
      default: 'primary'
    },
  }
}
</script>
