<template>
  <div v-if="pendingTransactions.length" class="pending-transactions--wrap mb-8">
    <data-table
      :columns="columns"
      :items="pendingTransactions"
      :default-options="{ sortBy: ['dateCreated'], sortDesc: [true] }"
      :is-loading="isLoading"
      :title="$t('transactions.pendingTransactions')"
    >
      <template #[`item.dateCreated`]="{ item }">
        {{ new Date(item.dateCreated) | readableDate(true) }}
      </template>

      <template #[`item.price`]="{ item }">
        {{ new Date(item.price) | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.formulaPrice`]="{ item }">
        {{ item.formulaPrice | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.buyerFullName`]="{ item }">
        <icon-buy-sell type="buy" class="mr-1" />
        {{ item.buyerFullName }}
      </template>

      <template #[`item.sellerFullName`]="{ item }">
        <icon-buy-sell type="sell" class="mr-1" />
        {{ item.sellerFullName }}
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import PendingTransactionApi from '@/api/PendingTransaction'

export default {
  name: 'pending-transactions',

  components: {
    DataTable,
    IconBuySell,
  },

  data () {
    return {
      pendingTransactions: [],
      isLoading: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('transactions.createdAt'), value: 'dateCreated', width: 160 },
        { text: this.$t('transactions.stockPrice'), value: 'price', align: 'right', width: 150 },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 100 },
        { text: this.$t('transactions.amountHolding'), value: 'amountHolding', align: 'right', width: 160 },
        { text: this.$t('transactions.priceHolding'), value: 'formulaPrice', align: 'right', width: 120 },
        { text: this.$t('transactions.seller'), value: 'sellerFullName' },
        { text: this.$t('transactions.buyer'), value: 'buyerFullName' },
      ]
    }
  },

  mounted () {
    this.getPendingTransactions()
  },

  methods: {
    /**
     * Loads the pending transactions related to the current user.
     *
     * @returns {void}
     */
    async getPendingTransactions () {
      this.isLoading = true

      const res = await PendingTransactionApi.getMy()

      if (res.ok) {
        const pendingTransactions = await res.json()
        this.pendingTransactions = pendingTransactions.map(transaction => ({
          ...transaction,
          amount: transaction.amountBuyer + transaction.amountHolding,
        }))
      }

      this.isLoading = false
    },
  },
}
</script>
