<template>
  <v-dialog
    :value="value"
    width="600"
    max-width="95%"
    persistent
    @input="visible => !visible && $emit('input', false)"
  >
    <v-form
      v-model="formIsValid"
      @submit.prevent="createPayout"
    >
      <v-card :loading="isLoading">
        <v-card-title>
          {{ $t('dividendPayout.create') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :value="payoutData.dividend / 100"
            outlined
            :label="$t('dividendPayout.dividend')"
            prepend-inner-icon="mdi-currency-eur"
            :rules="requiredRules"
            hide-details
            type="number"
            step="1"
            class="mb-4"
            @input="dividend => payoutData.dividend = dividend * 100"
          />
          <v-row>
            <v-col :cols="6">
              <date-text-input
                :value="payoutData.resolutionDate | isoDate"
                :label="$t('dividendPayout.resolutionDate')"
                :rules="requiredRules"
                class="mb-4"
                @input="isoDate => payoutData.resolutionDate = new Date(isoDate)"
              />
            </v-col>
            <v-col :cols="6">
              <div class="d-flex align-center">
                <time-text-input
                  :label="$t('common.time')"
                  :value="payoutData.resolutionDate"
                  @input="dateTime => payoutData.resolutionDate = dateTime"
                />

                <info-tooltip
                  :text="$t('dividendPayout.help.resolutionDate')"
                  class="ml-4"
                />
              </div>
            </v-col>
          </v-row>
          <date-text-input
            v-model="payoutData.paymentDate"
            :label="$t('dividendPayout.paymentDate')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentDate')" />
            </template>
          </date-text-input>
          <date-text-input
            v-model="payoutData.paymentPeriodBegin"
            :label="$t('dividendPayout.paymentPeriodBegin')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentPeriodBegin')" />
            </template>
          </date-text-input>
          <date-text-input
            v-model="payoutData.paymentPeriodEnd"
            :label="$t('dividendPayout.paymentPeriodEnd')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentPeriodEnd')" />
            </template>
          </date-text-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            @click="$emit('input', false)"
          >
            {{ $t('common.close') }}
          </v-btn>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            depressed
            :loading="isLoading"
            :disabled="!formIsValid"
          >
            {{ $t('dividendPayout.confirm_create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DividendPayoutApi from '@/api/DividendPayout.js'

import DateTextInput from '@/components/DateTextInput.vue'
import InfoTooltip from '@/components/InfoTooltip.vue'
import TimeTextInput from '@/components/TimeTextInput'

const defaultPayoutData = () => ({
  dividend: 0,
  resolutionDate: null,
  paymentDate: null,
  paymentPeriodBegin: null,
  paymentPeriodEnd: null,
})

export default {
  name: 'create-payout-dialog',

  components: {
    DateTextInput,
    InfoTooltip,
    TimeTextInput,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      formIsValid: null,
      isLoading: false,
      payoutData: defaultPayoutData(),
    }
  },

  computed: {
    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    },
  },

  watch: {
    value () {
      this.value && (this.payoutData = defaultPayoutData())
    },
  },

  methods: {
    /**
     * createPayout
     *
     * @returns {Promise}
     */
    async createPayout () {
      if (!this.formIsValid) {
        return
      }

      const res = await DividendPayoutApi.create(this.payoutData)

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('common.created') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.$emit('created')
    },
  },
}
</script>
