import Api from '@/api/Api'

export default {
  baseUrl: 'stock/offer',

  /**
   * getAll
   *
   * @param {boolean} noSessionUpdate If set, the session won't get extended
   * @returns {Promise}
   */
  getAll (noSessionUpdate = false) {
    return Api.baseFetch(this.baseUrl, {
      headers: { 'No-Session-Update': noSessionUpdate },
    })
  },

  /**
   * create
   *
   * @param {object} offer
   * @returns {Promise}
   */
  create (offer) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...offer,
        expires: offer.expires.toISOString()
      }),
    })
  },

  /**
   * update
   *
   * @param {object} offer
   * @returns {Promise}
   */
  update (offer) {
    return Api.baseFetch(`${this.baseUrl}/${offer.offerId}`, {
      method: 'PUT',
      body: JSON.stringify({
        ...offer,
        expires: offer.expires.toISOString()
      }),
    })
  },

  /**
   * delete
   *
   * @param {number|string} offerId
   * @param {string} secondFactorCode
   * @returns {Promise}
   */
  delete (offerId, secondFactorCode = '') {
    return Api.baseFetch(`${this.baseUrl}/${offerId}`, {
      method: 'DELETE',
      body: JSON.stringify({
        secondFactorCode
      })
    })
  },

  /**
   * accept
   *
   * @param {number} offerId
   * @param {number} amount
   * @param {string} secondFactorCode
   * @returns {Promise}
   */
  accept (offerId, amount = 0, secondFactorCode = '') {
    return Api.baseFetch(`offer/${offerId}/accept`, {
      method: 'POST',
      body: JSON.stringify({
        amount,
        secondFactorCode,
      })
    })
  },

  /**
   * Checks if the given api-response indicates a second-factor-error.
   *
   * @param {object} response
   * @returns {boolean}
   */
  isSecondFactorError (response) {
    return response.status === 400 && (
      response.statusText === 'no two factor authentication enabled' ||
      response.statusText === 'no two factor authentication code set' ||
      response.statusText === 'invalid two factor authentication code'
    )
  }
}
