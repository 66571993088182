<template>
  <v-navigation-drawer
    v-if="user"
    id="sidebar"
    :value="isVisible"
    floating
    app
    width="280"
    color="primary"
    dark
    @input="visibility => $emit('change', visibility)"
  >
    <div class="px-2 py-4">
      <router-link :to="{ name: 'Home' }" class="d-flex align-center">
        <v-avatar size="40" class="mr-4" color="white">
          <span class="logo-icon primary--text">M</span>
        </v-avatar>
        <div>
          <v-img
            :src="require('@/assets/gfx/mueller_bbm.svg')"
            alt="MBBM Logo"
            max-width="158"
            class="company-logo mb-1"
            contain
          />
          <div class="white--text">
            {{ $t('sidebar.portal') }}
          </div>
        </div>
      </router-link>
    </div>

    <v-divider />
    <user-menu />
    <v-divider />

    <icon-list
      :items="entries"
      class="mb-2 mt-4"
    />

    <icon-list
      :header="$t('sidebar.stocks')"
      :items="stockEntries"
      class="mb-2"
    />

    <icon-list
      :header="$t('sidebar.extras')"
      :items="extraEntries"
      class="mb-2"
    />
  </v-navigation-drawer>
</template>

<script>
import IconList from '@/components/IconList'
import UserMenu from '@/components/UserMenu'

export default {
  name: 'sidebar-menu',

  components: {
    IconList,
    UserMenu,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    entries () {
      return [
        { label: this.$t('sidebar.start'), route: 'Home', icon: 'mdi-view-dashboard-variant' },
        {
          label: this.$t('sidebar.information'),
          icon: 'mdi-information-outline',
          children: [
            { label: this.$t('sidebar.general'), route: 'GeneralInformation', icon: 'mdi-information-variant' },
            { label: this.$t('sidebar.reports'), route: 'Reports', icon: 'mdi-chart-box-outline' },
            { label: this.$t('sidebar.searchDocuments'), route: 'DocumentSearch', icon: 'mdi-file-find-outline' },
            { label: this.$t('sidebar.management'), route: 'Management', icon: 'mdi-head-cog-outline' },
          ],
        },
        { label: this.$t('sidebar.meetings'), route: 'Meetings', icon: 'mdi-account-multiple' },
        { label: this.$t('sidebar.dates'), route: 'Dates', icon: 'mdi-calendar' },
        { label: this.$t('sidebar.inbox'), route: 'UserMails', icon: 'mdi-card-account-mail' },
        { label: this.$t('sidebar.userDocuments'), route: 'UserDocuments', icon: 'mdi-file-document-outline' },
      ]
    },

    stockEntries () {
      return [
        { label: this.$t('sidebar.saleRequests'), route: 'SalesRequests', icon: 'mdi-store' },
        { label: this.$t('sidebar.myOffers'), route: 'UserOffers', icon: 'mdi-timer-outline' },
        { label: this.$t('sidebar.offerCreation'), route: 'OfferCreation', icon: 'mdi-offer' },
        { label: this.$t('sidebar.userTransactions'), route: 'UserTransactions', icon: 'mdi-bank-transfer' },
        { label: this.$t('sidebar.userStocks'), route: 'UserStocks', icon: 'mdi-account-cash' },
        { label: this.$t('sidebar.distribution'), route: 'StockDistribution', icon: 'mdi-chart-box' },
      ]
    },

    extraEntries () {
      return [
        { label: this.$t('sidebar.transactions'), route: 'Transactions', icon: 'mdi-bank-transfer' },
        { label: this.$t('sidebar.approval'), route: 'Approval', icon: 'mdi-check-decagram' },
        { label: this.$t('sidebar.users'), route: 'Users', icon: 'mdi-account' },
        { label: this.$t('sidebar.shareholders'), route: 'Shareholders', icon: 'mdi-account-group' },
        { label: this.$t('sidebar.phantoms'), route: 'Phantoms', icon: 'mdi-ghost-outline' },
        { label: this.$t('sidebar.cms'), route: 'CMS', icon: 'mdi-content-paste' },
        { label: this.$t('sidebar.translations'), route: 'Translations', icon: 'mdi-translate' },
        { label: this.$t('sidebar.emails'), route: 'Emails', icon: 'mdi-email' },
        { label: this.$t('sidebar.dateConfig'), route: 'DateConfig', icon: 'mdi-calendar' },
        { label: this.$t('sidebar.companyData'), route: 'CompanyData', icon: 'mdi-domain' },
        { label: this.$t('sidebar.configuration'), route: 'Configuration', icon: 'mdi-wrench' },
        { label: this.$t('sidebar.csvHandling'), route: 'CsvHandling', icon: 'mdi-swap-vertical' },
        { label: this.$t('sidebar.dividendPayout'), route: 'DividendPayout', icon: 'mdi-transfer' },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
  #sidebar {
    .logo-icon {
      font-weight: 700;
      font-size: 24px;
    }

    .company-logo {
      filter: invert(1);
    }

    a {
      text-decoration: none;
    }
  }
</style>
