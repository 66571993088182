import Api from '@/api/Api'

export default {
  baseUrl: 'appointment',

  /**
   * getAll
   *
   * @returns {Promise}
   */
  getAll () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * create
   *
   * @param {object} appointment
   * @param {string} appointment.date iso-date
   * @param {string} appointment.title
   * @param {string} appointment.text
   * @param {string} appointment.appointmentType
   * @returns {Promise}
   */
  create ({ date, title, text, appointmentType }) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({ date, title, text, appointmentType })
    })
  },

  /**
   * update
   *
   * @param {object} appointment
   * @param {number} appointment.appointmentId
   * @param {string} appointment.date iso-date
   * @param {string} appointment.title
   * @param {string} appointment.text
   * @param {string} appointment.appointmentType
   * @returns {Promise}
   */
  update ({ appointmentId, date, title, text, appointmentType }) {
    return Api.baseFetch(`${this.baseUrl}/${appointmentId}`, {
      method: 'PUT',
      body: JSON.stringify({ date, title, text, appointmentType })
    })
  },

  /**
   * delete
   *
   * @param {number} appointmentId
   * @returns {Promise}
   */
  delete (appointmentId) {
    return Api.baseFetch(`${this.baseUrl}/${appointmentId}`, {
      method: 'DELETE',
    })
  },

  /**
   * getTypes
   *
   * @returns {Promise}
   */
  getTypes () {
    return Api.baseFetch(`${this.baseUrl}/appointment_type`)
  },
}
