<template>
  <v-dialog
    :value="isVisible"
    width="700"
    max-width="90%"
    content-class="create-translation--dialog"
    @input="visibility => !visibility && $emit('cancel')"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="createEntry">
      <v-card>
        <v-card-title>
          {{ $t('translations.create') }}
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col :cols="6">
              <v-combobox
                v-model="area"
                :label="$t('translations.area')"
                :items="rootEntries"
                :rules="requiredRules"
                outlined
                hide-details
              />
            </v-col>
            <v-col :cols="6">
              <v-text-field
                v-model="key"
                :label="$t('translations.key')"
                :rules="requiredRules"
                outlined
                hide-details
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-for="language in languageKeys" :key="language" :cols="6">
              <v-text-field
                v-model="translation[language]"
                :label="language"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed @click="$emit('cancel')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn type="submit" depressed class="ml-auto">
            <v-icon color="primary" left>
              mdi-pencil
            </v-icon>
            {{ $t('common.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'create-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      area: null,
      formIsValid: false,
      key: '',
      translation: {},
    }
  },

  computed: {
    languageKeys () {
      return Object.keys(this.translations)
    },

    requiredRules () {
      return [(v => v !== undefined && v !== null && v !== '') || this.$t('common.requiredField')]
    },

    // unique list of root-entry-keys
    rootEntries () {
      const uniqueRootKeys = Object.keys(this.translations).reduce((rootEntries, language) => {
        Object.keys(this.translations[language]).forEach(key => rootEntries.add(key))
        return rootEntries
      }, new Set())

      return [...uniqueRootKeys].sort()
    },

    translations () {
      return this.$i18n.messages
    },
  },

  methods: {
    /**
     * Creates a new translation-entry (a nested object like the loaded
     * translations) based on the entered data.
     * That entry gets communicated upwards for further handling, then the
     * form gets reset.
     *
     * @returns {void}
     */
    createEntry () {
      this.$refs.form.validate()

      if (!this.formIsValid) {
        return
      }

      const path = `${this.area}.${this.key}`
      this.$emit('create:entry', { path, ...this.translation })

      this.area = null
      this.key = ''
      this.translation = {}
      this.$refs.form.resetValidation()
    },
  },
}
</script>
