<template>
  <div class="requests--wrap">
    <v-alert
      v-if="!isLoading && stockAmount === 0"
      prominent
      type="info"
      class="mb-10"
    >
      {{ $t('sell.missingStocks') }}
    </v-alert>

    <offer-table
      :type="offerType"
      :is-loading="isLoading"
      :offers="offers"
      :data-table-options="{
        itemsPerPage: 25,
        sortBy: ['price'],
        sortDesc: [true],
      }"
      @click:offer="offerToAccept => offer = offerToAccept"
    />

    <sell-dialog
      :offer="offer"
      :stock-amount="stockAmount"
      @close="offer = null"
      @sell="sell"
    />

    <data-reload-timer
      fixed
      :seconds="30"
      @request:reload="loadOffers(true)"
    />
  </div>
</template>

<script>
import DataReloadTimer from '@/components/DataReloadTimer'
import OfferApi from '@/api/Offer'
import OfferTable from './OfferTable'
import SellDialog from './SellDialog'
import ShareApi from '@/api/Share'

export default {
  name: 'requests-view',

  components: {
    DataReloadTimer,
    OfferTable,
    SellDialog,
  },

  data () {
    return {
      isLoading: false,
      offer: null,
      offers: [],
      offerType: 'buy',
      readTerms: false,
      sellAmount: 0,
      stockAmount: 0,
    }
  },

  computed: {
    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    /**
     * Loads data from the api required for this view.
     *
     * @returns {void}
     */
    async init () {
      this.isLoading = true

      await this.loadOffers()
      await this.getAvailableShares()

      this.isLoading = false
    },

    /**
     * Sell shares by accepting a request.
     *
     * @param {object} sellInfo
     * @param {object} sellInfo.offer Offer to accept
     * @param {number} sellInfo.amount Shares to sell
     * @param {number} sellInfo.secondFactorCode Entered second factor code
     */
    async sell ({ offer, amount, secondFactorCode }) {
      const res = await OfferApi.accept(offer.offerId, amount, secondFactorCode)
      this.offer = null

      if (!res.ok) {
        return this.$store.commit('setSnackbar', {
          text: OfferApi.isSecondFactorError(res) ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
          color: 'error',
        })
      }

      this.$store.commit('setSnackbar', { text: this.$t('salesRequests.acceptedOffer') })
      this.init()
    },

    /**
     * Loads the amount of shares a user is allowed to trade.
     *
     * @returns {void}
     */
    async getAvailableShares () {
      const res = await ShareApi.getAvailableShares()

      if (res.ok) {
        const { amount } = await res.json()
        this.stockAmount = amount
      }
    },

    /**
     * Loads all available offers.
     *
     * @param {boolean} isAutoReload If data is reloaded automatically, we don't want to extend the session
     * @returns {void}
     */
    async loadOffers (isAutoReload = false) {
      const res = await OfferApi.getAll(isAutoReload)
      res.ok && (this.offers = await res.json())
    }
  },
}
</script>
