<template>
  <div class="sale-requests--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.saleRequests') }}
    </h1>

    <div class="d-flex align-center flex-wrap mb-10">
      <v-tabs show-arrows color="grey darken-4" background-color="transparent" class="mb-2">
        <v-tab :to="{ name: 'Sales' }">
          <v-icon left>
            mdi-offer
          </v-icon>
          {{ $t('salesRequests.sales') }}
        </v-tab>

        <v-tab :to="{ name: 'Requests' }">
          <v-icon left>
            mdi-basket
          </v-icon>
          {{ $t('salesRequests.requests') }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <v-btn :to="{ name: transactionButtonTarget.name }" depressed color="secondary" class="mb-4">
        <v-icon left>
          {{ transactionButtonTarget.icon }}
        </v-icon>
        {{ transactionButtonTarget.label }}
      </v-btn>
    </div>

    <router-view />

    <pending-transactions class="mt-10" />
  </div>
</template>

<script>
import PendingTransactions from '@/views/Stocks/Transactions/PendingTransactions'

export default {
  name: 'sale-requests',

  components: {
    PendingTransactions,
  },

  computed: {
    /**
     * The button above the tab-content should lead the user to a route based
     * on the current tab.
     *
     * @returns {object} Name of the route, label of the button and an icon
     */
    transactionButtonTarget () {
      return this.$route.name === 'Sales'
        ? { name: 'CreateOffer', label: this.$t('sell.createOffer'), icon: 'mdi-bank-transfer-out' }
        : { name: 'CreateRequest', label: this.$t('sell.createRequest'), icon: 'mdi-bank-transfer-in' }
    },
  },
}
</script>

<style lang="scss">
  .sale-requests--wrap {
    .v-tabs {
      width: auto;

      .v-slide-group {
        display: inline-flex;
      }
    }
  }
</style>
