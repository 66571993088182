import Api from '@/api/Api'

export default {
  baseUrl: 'document',

  /**
   * list
   *
   * @returns {Promise}
   */
  list () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * get
   *
   * @returns {Promise}
   */
  get (documentId) {
    return Api.baseFetch(`${this.baseUrl}/${documentId}`)
  },

  /**
   * create
   *
   * @param {FormData} document
   * @returns {Promise}
   */
  create (document) {
    return fetch(`${Api.baseUrl}/${this.baseUrl}`, {
      method: 'POST',
      body: document,
    })
  },

  /**
   * update
   *
   * @param {FormData} document
   * @param {number} id
   * @returns {Promise}
   */
  update (document, id) {
    return fetch(`${Api.baseUrl}/${this.baseUrl}/${id}`, {
      method: 'PUT',
      body: document,
    })
  },

  /**
   * delete
   *
   * @param {number|string} documentId
   * @returns {Promise}
   */
  delete (documentId) {
    return Api.baseFetch(`${this.baseUrl}/${documentId}`, {
      method: 'DELETE',
    })
  },

  /**
   * getDocumentTypes
   *
   * @returns {Promise}
   */
  getDocumentTypes () {
    return Api.baseFetch(`${this.baseUrl}/document_type`)
  },

  /**
   * getDisplayAreas
   *
   * @returns {Promise}
   */
  getDisplayAreas () {
    return Api.baseFetch(`${this.baseUrl}/display_area`)
  },

  /**
   * Creates a download-link for the document with the given id.
   *
   * @param {number} documentId
   * @return {string}
   */
  getDownloadLink (documentId) {
    return `${Api.baseUrl}/${this.baseUrl}/${documentId}/file`
  }
}
