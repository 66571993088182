<template>
  <v-dialog
    :value="isVisible"
    scrollable
    :width="width"
    max-width="90%"
    content-class="recipient-dialog"
    @input="visibility => !visibility && $emit('cancel')"
  >
    <v-card>
      <v-card-text>
        <data-table
          flat
          :columns="columns"
          :items="recipients"
          :title="$t('emails.recipientList')"
          :default-options="{ sortBy: ['name'], sortDesc: [false] }"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn depressed class="ml-auto" @click="$emit('cancel')">
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTable from '@/components/DataTable'

export default {
  name: 'recipient-dialog',

  components: {
    DataTable
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    recipients: {
      type: Array,
      default: () => ([]),
    },

    width: {
      type: Number,
      default: 1100,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.name'), value: 'name' },
        { text: this.$t('common.mail'), value: 'email' },
      ]
    }
  },
}
</script>
