import Vue from 'vue'
import VueRouter from 'vue-router'

import PublicRoutes from './public'
import PrivateRoutes from './private'
import store from '@/store'
import { i18n } from '@/main.js'

Vue.use(VueRouter)

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return {
      selector: to.hash,
    }
  }

  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}

const routes = [...PublicRoutes, ...PrivateRoutes]
const router = new VueRouter({ routes, scrollBehavior })

/**
 * Protects routes by checking if the current user is logged in and has the
 * roles required for the target-route (defined at the route-definitions). If
 * that's not the case, the user isn't allowed to show the desired view, an
 * information gets shown.
 *
 * @param {object} to route
 * @param {object} from route
 * @param {function} next
 */
router.beforeEach((to, from, next) => {
  if (store.state.user.user && !store.getters.routeAllowedForUser(to)) {
    store.commit('setSnackbar', {
      text: i18n.t('common.notAllowed'),
      color: 'info',
    })
    return next(false)
  }

  next()
})

export default router
