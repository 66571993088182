<template>
  <div class="approval-view--wrap">
    <div class="mb-10 d-flex align-center">
      <h1 class="mb-0">
        {{ $t('sidebar.approval') }}
      </h1>

      <v-tooltip v-if="finalizeAllEnabled" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            depressed
            class="ml-auto"
            v-bind="attrs"
            v-on="on"
            @click="confirmFinalizeAll = true"
          >
            {{ $t('approval.finalizeAll') }}
          </v-btn>
        </template>
        <span>{{ $t('approval.finalizeAllHint') }}</span>
      </v-tooltip>
    </div>

    <data-table
      :columns="columns"
      :custom-page-size="25"
      :items="pendingTransactions"
      :page-sizes="[10, 25, 50, 100]"
      :default-options="{ sortBy: ['dateCreated'], sortDesc: [true] }"
      :title="$t('approval.acceptedOffers')"
      :is-loading="isLoading"
    >
      <template #[`item.price`]="{ item }">
        {{ item.price | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.dateCreated`]="{ item }">
        {{ item.dateCreated | readableIsoDate }}
      </template>

      <template #[`item.buyerFullName`]="{ item }">
        <router-link :to="{ name: 'UsersEditUser', params: { id: item.buyerId } }" class="text-decoration-none">
          <icon-buy-sell type="buy" />
          {{ item.buyerFullName }}
        </router-link>
      </template>

      <template #[`item.sellerFullName`]="{ item }">
        <router-link :to="{ name: 'UsersEditUser', params: { id: item.sellerId } }" class="text-decoration-none">
          <icon-buy-sell type="sell" />
          {{ item.sellerFullName }}
        </router-link>
      </template>

      <template #[`item.sellerTotalAmount`]="{ item }">
        <span :class="{ 'error--text': showTradeWarning(item) }">
          <v-icon v-if="showTradeWarning(item)" left small color="error">
            mdi-alert
          </v-icon>
          {{ item.sellerTotalAmount | localeNumber($i18n.locale) }}
        </span>
      </template>

      <template #[`item.amount`]="{ item }">
        <span :class="{ 'error--text': showTradeWarning(item) }">
          <v-icon v-if="showTradeWarning(item)" left small color="error">
            mdi-alert
          </v-icon>
          {{ item.amountBuyer + item.amountHolding | localeNumber($i18n.locale) }}
        </span>
      </template>

      <template #[`item.type`]="{ item }">
        <transaction-type-chip
          :transaction-type="item.type"
          compact
        />
      </template>

      <template #[`item.amountBuyer`]="{ item }">
        <span class="secondary--text">
          {{ item.amountBuyer | localeNumber($i18n.locale) }}
        </span>
      </template>

      <template #[`item.amountHolding`]="{ item }">
        <span class="accent--text">
          {{ item.amountHolding | localeNumber($i18n.locale) }}
        </span>
      </template>

      <template #[`item.check1Id`]="{ item }">
        <table-button
          :disabled="item.check1Id && item.check1Id !== user.accountId"
          :icon="item.check1Id ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
          :icon-color="item.check1Id ? 'success' : null"
          :tooltip="item.check1Id ? `${$t('approval.confirmedBy')} ${item.check1FullName}` : $t('approval.confirmTrade')"
          @click="confirmTrade(item)"
        />
      </template>

      <template #[`item.check2Id`]="{ item }">
        <table-button
          :disabled="item.check2Id && item.check2Id !== user.accountId"
          :icon="item.check2Id ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
          :icon-color="item.check2Id ? 'success' : null"
          :tooltip="item.check2Id ? `${$t('approval.approvedBy')} ${item.check2FullName}` : $t('approval.approveTrade')"
          @click="approveTrade(item)"
        />
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-cancel"
          icon-color="red"
          :tooltip="$t('approval.cancelTrade')"
          @click="tradeToCancel = item"
        />
        <table-button
          v-if="item.check1Id && item.check2Id"
          icon="mdi-checkbox-marked-circle-outline"
          icon-color="primary"
          :tooltip="$t('approval.finalize')"
          @click="tradeToSave = item"
        />
      </template>
    </data-table>

    <confirm-dialog
      :headline="$t('approval.cancelTrade')"
      :info-text="$t('approval.confirmCancel')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="tradeToCancel !== null"
      @cancel="tradeToCancel = null"
      @ok="cancelTrade"
    />

    <confirm-dialog
      :headline="$t('approval.save')"
      :info-text="$t('approval.confirmSave')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="tradeToSave !== null"
      @cancel="tradeToSave = null"
      @ok="finalizeTrade"
    />

    <confirm-dialog
      :headline="$t('approval.save')"
      :info-text="$t('approval.confirmFinalizeAll')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="confirmFinalizeAll"
      @cancel="confirmFinalizeAll = false"
      @ok="finalizeAll"
    />

    <data-reload-timer
      fixed
      :seconds="120"
      @request:reload="loadTransactions(true)"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import PendingTransactionApi from '@/api/PendingTransaction'
import TableButton from '@/components/TableButton'
import DataReloadTimer from '@/components/DataReloadTimer'
import TransactionTypeChip from '@/components/TransactionTypeChip'

export default {
  name: 'approval-view',

  components: {
    ConfirmDialog,
    DataReloadTimer,
    DataTable,
    IconBuySell,
    TableButton,
    TransactionTypeChip,
  },

  data () {
    return {
      confirmFinalizeAll: false,
      isLoading: false,
      pendingTransactions: [],
      tradeToCancel: null,
      tradeToSave: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.date'), value: 'dateCreated', width: 100 },
        { text: this.$t('common.type'), value: 'type', width: 30, sortable: false },
        { text: this.$t('transactions.buyer'), value: 'buyerFullName' },
        { text: this.$t('transactions.seller'), value: 'sellerFullName' },
        { text: this.$t('approval.sellerStocks'), value: 'sellerTotalAmount', align: 'right', sortable: false },
        { text: this.$t('approval.amountTotal'), value: 'amount', align: 'right' },
        { text: this.$t('approval.amountBuyer'), value: 'amountBuyer', align: 'right', sortable: false },
        { text: this.$t('approval.amountHolding'), value: 'amountHolding', align: 'right', sortable: false },
        { text: this.$t('transactions.stockPrice'), value: 'price', align: 'right' },
        { text: this.$t('transactions.confirmed'), value: 'check1Id', align: 'right', sortable: false },
        { text: this.$t('transactions.approved'), value: 'check2Id', align: 'right', sortable: false },
        { text: this.$t('common.actions'), value: 'actions', align: 'right', width: 120, sortable: false },
      ]
    },

    /**
     * Are there pending transactions that have been checked and approved?
     *
     * @returns {boolean}
     */
    finalizeAllEnabled () {
      return this.pendingTransactions
        .filter(({ check1Id, check2Id }) => check1Id !== null && check2Id !== null)
        .length > 0
    },

    minimumShares () {
      return this.$store.state.configuration.minimumShares
    },

    user () {
      return this.$store.state.user.user
    }
  },

  mounted () {
    this.loadTransactions()
  },

  methods: {
    /**
     * Loads all pending transactions.
     *
     * @param {boolean} isAutoReload If data is reloaded automatically, we don't want to extend the session
     * @returns {void}
     */
    async loadTransactions (isAutoReload = false) {
      this.isLoading = true

      const res = await PendingTransactionApi.getAll(isAutoReload)
      res.ok && (this.pendingTransactions = await res.json())

      this.isLoading = false
    },

    /**
     * Approves the given trade if it has been confirmed by another user.
     *
     * @param {object} trade
     * @returns {void}
     */
    async approveTrade (trade) {
      if (trade.check1Id === this.user.accountId) {
        return this.$store.commit('setSnackbar', { text: this.$t('approval.needsOtherUser'), color: 'error' })
      }

      if (trade.check1Id === null) {
        return this.$store.commit('setSnackbar', { text: this.$t('approval.needsConfirmation'), color: 'error' })
      }

      const res = await PendingTransactionApi.confirm(trade.id, 2, trade.check2Id ? 'clear' : 'set')
      this.afterTradeUpdate(res.ok)
    },

    /**
     * Cancels the selected trade.
     *
     * @returns {void}
     */
    async cancelTrade () {
      if (this.tradeToCancel) {
        const res = await PendingTransactionApi.delete(this.tradeToCancel.id)

        this.tradeToCancel = null
        this.$store.commit('setSnackbar', {
          text: res.ok ? this.$t('approval.canceled') : this.$t('common.errorOccured'),
          color: res.ok ? 'success' : 'error',
        })

        res.ok && this.loadTransactions()
      }
    },

    /**
     * Confirms the given trade or removes the current confirmation.
     *
     * @param {object} trade
     * @returns {void}
     */
    async confirmTrade (trade) {
      if (trade.check2Id && trade.check2Id === this.user.accountId) {
        return this.$store.commit('setSnackbar', { text: this.$t('approval.needsOtherUser'), color: 'error' })
      }

      const res = await PendingTransactionApi.confirm(trade.id, 1, trade.check1Id ? 'clear' : 'set')
      this.afterTradeUpdate(res.ok)
    },

    /**
     * Finalizes/accepts the trade if it was confirmed, approved.
     *
     * @returns {void}
     */
    async finalizeTrade () {
      const res = await PendingTransactionApi.accept(this.tradeToSave.id)
      this.tradeToSave = null
      this.afterTradeUpdate(res.ok)
    },

    /**
     * The stock-amount of the seller of a trade must not fall below a certain
     * value.
     *
     * @param {object} trade
     * @returns {boolean}
     */
    showTradeWarning (trade) {
      return trade.sellerTotalAmount - trade.amountBuyer - trade.amountHolding < this.minimumShares
    },

    /**
     * Shows a fitting message after updating a trade, reloads refreshed
     * pending-transactions.
     *
     * @param {boolean} wasSuccess
     * @return {void}
     */
    afterTradeUpdate (wasSuccess) {
      if (!wasSuccess) {
        return this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
      }

      // remove current data since it isn't valid anylonger after a change
      // until refreshed data was loaded
      this.pendingTransactions = []

      this.$store.commit('setSnackbar', { text: this.$t('approval.saved') })
      this.loadTransactions()
    },

    /**
     * Finalizes all trades that have been checked and approved.
     *
     * @returns {Promise}
     */
    async finalizeAll () {
      this.confirmFinalizeAll = false
      const res = await PendingTransactionApi.acceptAll()
      this.afterTradeUpdate(res.ok)
    },
  },
}
</script>
