import Api from '@/api/Api'

export default {
  baseUrl: 'transaction',

  /**
   * getAll
   *
   * @param {string} order
   * @param {string} sort
   * @returns {Promise}
   */
  getAll (order = 'DESC', sort = 'date') {
    const params = new URLSearchParams({
      order,
      sort
    })

    return Api.baseFetch(`${this.baseUrl}?${params}`)
  },

  /**
   * getMy
   *
   * @param {number} accountId
   * @param {string} order
   * @param {string} sort
   * @returns {Promise}
   */
  async getMy (accountId, order = 'ASC', sort = 'date') {
    const params = new URLSearchParams({
      order,
      sort
    })

    const res = await Api.baseFetch(`${this.baseUrl}/me?${params}`)

    if (!res.ok) {
      return []
    }

    /**
     * With the old api, the type was splitted to distinguish between purchases
     * and sales. That's not too easy for the new one, so we do it here.
     */
    return (await res.json()).map(transaction => {
      if (transaction.type === 'buy_sell') {
        transaction.type = transaction.buyerId === accountId ? 'buy' : 'sell'
      }

      return transaction
    })
  },

  /**
   * getTypes
   *
   * @returns {Promise}
   */
  getTypes () {
    return Api.baseFetch(`${this.baseUrl}/transaction_type`)
  },

  /**
   * csvExportUrl
   *
   * @param {string} dateFrom
   * @param {string} dateTo
   * @returns {string}
   */
  csvExportUrl (dateFrom = '', dateTo = '') {
    const from = dateFrom ? new Date(dateFrom) : new Date()
    const to = dateTo ? new Date(dateTo) : new Date()

    const params = new URLSearchParams({
      mime_type: 'text/csv',
      from: from.toISOString(),
      to: to.toISOString(),
    })

    return `${Api.baseUrl}/${this.baseUrl}/export?${params}`
  },

  /**
   * Creates the URL to the full export of all transactions.
   *
   * @returns {void}
   */
  fullCsvExportUrl () {
    return `${Api.baseUrl}/${this.baseUrl}/export_all`
  },

  /**
   * Fetches transactions without userdata so those can get used in statistics
   * available for all users.
   *
   * @returns {Promise}
   */
  getChartData () {
    return Api.baseFetch(`${this.baseUrl}/stats`)
  },

  /**
   * Creates a download-link for the files related to the transaction with the
   * given id.
   *
   * @param {number} transactionId
   * @return {string}
   */
  getDownloadLink (transactionId) {
    return `${Api.baseUrl}/${this.baseUrl}/${transactionId}/document`
  },
}
