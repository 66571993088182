<template>
  <div class="message-menu--wrap">
    <v-menu
      bottom
      offset-y
      max-width="90%"
      transition="slide-x-reverse-transition"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :value="unreadNotifications.length"
            color="accent"
            :content="`${unreadNotifications.length}`"
            offset-x="33"
            offset-y="25"
            bordered
          >
            <v-btn icon class="mr-2">
              <v-icon>mdi-bell-outline</v-icon>
            </v-btn>
          </v-badge>
        </div>
      </template>
      <v-card>
        <v-card-title class="pb-1">
          {{ $t('notifications.newNotifications') }}
        </v-card-title>
        <read-all-notifications-btn
          small
          class="mx-4 my-2"
        />
        <notification-list
          :notifications="unreadNotifications"
          @click:read="markAsRead"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn depressed :to="{ name: 'Notifications' }">
            {{ $t('notifications.showOverview') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import NotificationList from '@/components/NotificationList'
import ReadAllNotificationsBtn from '@/components/ReadAllNotificationsBtn.vue'

export default {
  name: 'notification-menu',

  components: {
    NotificationList,
    ReadAllNotificationsBtn,
  },

  computed: {
    unreadNotifications () {
      return this.$store.getters['notification/unreadNotifications']
    }
  },

  methods: {
    markAsRead (notification) {
      this.$store.dispatch('notification/markAsRead', notification.notificationId)
    },
  },
}
</script>
