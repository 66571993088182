<template>
  <v-dialog
    :value="isVisible"
    :width="900"
    max-width="90%"
    content-class="document-dialog"
    eager
    @input="visibility => !visibility && close()"
  >
    <div class="document-dialog--inner">
      <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
        <v-card flat>
          <v-card-title>
            {{ isEdit ? document.title : $t('common.newEntry') }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col :cols="12" :md="5">
                <v-text-field
                  v-model="documentConfig.title"
                  :label="$t('common.title')"
                  prepend-inner-icon="mdi-format-title"
                  outlined
                  hide-details
                  :rules="requiredRules"
                  class="mb-2"
                />
                <date-text-input
                  v-model="documentConfig.date"
                  :label="$t('common.date')"
                  :rules="requiredRules"
                  class="mb-2"
                />
                <v-autocomplete
                  v-model="documentConfig.companyId"
                  :items="companies"
                  item-text="shortName"
                  item-value="companyId"
                  :label="$t('common.company')"
                  :rules="requiredRules"
                  prepend-inner-icon="mdi-domain"
                  hide-details
                  outlined
                  class="mb-2"
                />
                <v-autocomplete
                  v-model="documentConfig.documentType"
                  :items="documentTypes"
                  :label="$t('common.type')"
                  :rules="requiredRules"
                  item-text="label"
                  item-value="value"
                  prepend-inner-icon="mdi-format-list-bulleted-type"
                  hide-details
                  outlined
                  class="mb-2"
                />
                <v-autocomplete
                  :value="isEdit ? documentConfig.language : chosenLanguages"
                  :items="languages"
                  :label="$t('document.language')"
                  :rules="[v => v.length > 0]"
                  :multiple="!isEdit"
                  item-value="key"
                  hide-details
                  outlined
                  small-chips
                  class="mb-2"
                  @input="choice => isEdit ? (documentConfig.language = choice) : (chosenLanguages = choice)"
                >
                  <template #item="{ item }">
                    <v-img :src="getLanguageIconByKey(item.key)" max-width="20" height="15" class="mr-2" />
                    {{ item.label }}
                  </template>
                  <template #selection="{ item }">
                    <v-chip small>
                      <v-img :src="getLanguageIconByKey(item.key)" max-width="20" height="15" class="mr-2" />
                      {{ item.label }}
                    </v-chip>
                  </template>
                </v-autocomplete>
                <v-file-input
                  v-model="documentConfig.file"
                  :label="$t('common.file')"
                  :rules="[v => !!v]"
                  accept="application/pdf"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  hide-details
                  outlined
                  class="mb-2"
                />
              </v-col>
              <v-col :cols="12" :md="7">
                <v-textarea
                  v-model="documentConfig.description"
                  outlined
                  hide-details
                  required
                  counter
                  minlength="30"
                  :label="$t('common.description')"
                  prepend-inner-icon="mdi-message-text"
                  class="mb-2"
                />
                <div class="d-flex flex-wrap">
                  <v-checkbox
                    v-for="area in displayAreas"
                    :key="area.value"
                    v-model="documentConfig.displayAreas"
                    :rules="[() => documentConfig.displayAreas.length > 0]"
                    :value="area.value"
                    hide-details
                    :label="area.label"
                    class="mr-6 mb-1"
                  />
                </div>
              </v-col>
            </v-row>

            <v-btn
              v-if="documentConfig.documentId"
              :href="getDownloadLink(documentConfig.documentId)"
              target="_blank"
              depressed
              color="secondary"
            >
              <v-icon left>
                mdi-file
              </v-icon>
              {{ $t('common.download') }}
            </v-btn>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn depressed @click="close">
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn type="submit" depressed>
              <v-icon left color="primary">
                mdi-pencil
              </v-icon>
              {{ document ? $t('common.update') : $t('common.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-dialog>
</template>

<script>
import CompanyApi from '@/api/Company'
import DocumentApi from '@/api/Documents'
import DateTextInput from '@/components/DateTextInput'
import LanguageSwitch from '@/mixins/LanguageSwitch'

const defaultDocumentConfig = () => ({
  title: '',
  description: '',
  date: null,
  documentType: null,
  companyId: null,
  displayAreas: [],
  file: null,
  language: null,
})

export default {
  name: 'document-dialog',

  components: {
    DateTextInput,
  },

  mixins: [
    LanguageSwitch,
  ],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    document: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      areas: [],
      documentConfig: defaultDocumentConfig(),
      formIsValid: false,
      chosenLanguages: [],
      companies: [],
      types: [],
    }
  },

  computed: {
    isEdit () {
      return this.document !== null
    },

    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },

    displayAreas () {
      return this.areas
        .map(area => ({ label: this.$t(`document.areas.${area}`), value: area }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },

    documentTypes () {
      return this.types
        .map(type => ({ label: this.$t(`document.type.${type}`), value: type }))
        .sort((a, b) => a.label.localeCompare(b.label))
    },
  },

  watch: {
    document () {
      this.documentConfig = this.document === null
        ? defaultDocumentConfig()
        : { ...this.document }

      this.$refs.form.resetValidation()
    },
  },

  mounted () {
    this.getCompanies()
    this.getDocumentTypes()
    this.getDisplayAreas()
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()

      if (res.ok) {
        const companies = await res.json()
        this.companies = companies.sort((a, b) => a.name.localeCompare(b.name))
      }
    },

    /**
     * Loads available document-types from the api.
     *
     * @returns {void}
     */
    async getDocumentTypes () {
      const res = await DocumentApi.getDocumentTypes()
      res.ok && (this.types = await res.json())
    },

    /**
     * Loads available display-areas from the api.
     *
     * @returns {void}
     */
    async getDisplayAreas () {
      const res = await DocumentApi.getDisplayAreas()
      res.ok && (this.areas = await res.json())
    },

    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },

    /**
     * Creates/updates documents based on the current mode. After that, the
     * dialog gets closed, a reload of the document-list gets requested.
     *
     * @returns {Promise}
     */
    async onSubmit () {
      this.$refs.form.validate()

      if (!this.formIsValid) {
        return
      }

      this.isEdit
        ? await this.updateDocument()
        : await this.createDocument()

      this.close()
      this.$emit('request-reload')
    },

    /**
     * Creates a new document for every chosen language.
     *
     * @returns {Promise}
     */
    async createDocument () {
      let hasError = false

      for (const language of this.chosenLanguages) {
        const formData = this.documentConfigAsForm()
        formData.set('language', language)
        const res = await DocumentApi.create(formData)

        if (!res.ok) {
          hasError = true
          continue
        }
      }

      hasError
        ? this.onError()
        : this.$store.commit('setSnackbar', { text: this.$t('common.created') })
    },

    /**
     * Updates the currently edited document.
     *
     * @returns {Promise}
     */
    async updateDocument () {
      const res = await DocumentApi.update(
        this.documentConfigAsForm(),
        this.documentConfig.documentId
      )

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
        : this.onError()
    },

    /**
     * Closes the dialog, resets the internal state.
     *
     * @returns {void}
     */
    close () {
      this.documentConfig = defaultDocumentConfig()
      this.chosenLanguages = []

      this.$refs.form.resetValidation()
      this.$emit('close')
    },

    /**
     * The API expects the document-configuration as a formdata-object.
     * Since vuetify creates multiple inputs with a one "name"-attribute for
     * autocompletes (one for the display-value and one for the actual value),
     * we have to build our own formdata.
     *
     * @returns {FormData}
     */
    documentConfigAsForm () {
      return Object.keys(this.documentConfig).reduce((formData, key) => {
        const value = this.documentConfig[key]
        Array.isArray(value)
          ? value.forEach(entry => formData.append(key, entry))
          : formData.append(key, value)

        return formData
      }, new FormData())
    },

    onError () {
      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    },
  }
}
</script>
