<template>
  <div class="extras--wrap">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'extras-index'
}
</script>
