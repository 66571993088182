<template>
  <div class="stock-sidebar--wrap">
    <v-navigation-drawer
      v-if="stockGroup"
      :value="stockGroup !== null"
      right
      app
      width="300"
      floating
      temporary
      class="elevation-3"
      @input="visibility => !visibility && $emit('close')"
    >
      <data-table
        :columns="columns"
        :items="sharesAsObjects"
        flat
        :custom-page-size="50"
        :page-sizes="[25, 50, 100]"
        :is-loading="isLoading"
        class="px-2"
      >
        <template #title>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn small icon @click="showHistory(item.number)">
            <v-icon small>
              mdi-history
            </v-icon>
          </v-btn>
        </template>
      </data-table>

      <v-dialog
        :value="historyToShow !== null"
        width="800"
        max-width="90%"
        @input="visibility => !visibility && (historyToShow = null)"
      >
        <div v-if="historyToShow">
          <data-table
            :items="historyToShow.items"
            :columns="historyColumns"
            :default-options="{ sortBy: ['boughtAt'], sortDesc: [true] }"
            :title="`${$t('stocks.number')} ${historyToShow.number}`"
          >
            <template #[`item.boughtAt`]="{ item }">
              {{ item.boughtAt | readableIsoDate }}
            </template>

            <template #[`item.purchasePrice`]="{ item }">
              {{ item.purchasePrice | readablePrice($i18n.locale) }}
            </template>

            <template #[`item.buyerFullName`]="{ item }">
              <icon-buy-sell type="buy" />
              {{ item.buyerFullName }}
            </template>

            <template #[`item.sellerFullName`]="{ item }">
              <icon-buy-sell type="sell" />
              {{ item.sellerFullName }}
            </template>
          </data-table>
        </div>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import ShareApi from '@/api/Share'

export default {
  name: 'stock-sidebar',

  components: {
    DataTable,
    IconBuySell,
  },

  props: {
    stockGroup: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      historyToShow: null,
      isLoading: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('stocks.number'), value: 'number' },
        { text: this.$t('stocks.history'), value: 'actions', align: 'right', sortable: false },
      ]
    },

    historyColumns () {
      return [
        { text: this.$t('transactions.seller'), value: 'sellerFullName' },
        { text: this.$t('transactions.buyer'), value: 'buyerFullName' },
        { text: this.$t('common.price'), value: 'purchasePrice', align: 'right' },
        { text: this.$t('common.date'), value: 'boughtAt', align: 'right' },
      ]
    },

    sharesAsObjects () {
      return this.stockGroup
        ? this.stockGroup.certificates.map(share => ({ number: share }))
        : []
    },
  },

  methods: {
    async showHistory (certificate) {
      this.isLoading = true
      const res = await ShareApi.getHistory(certificate)

      if (res.ok) {
        this.historyToShow = {
          items: await res.json(),
          number: certificate,
        }
      }

      this.isLoading = false
    },
  }
}
</script>

<style lang="scss">
  .stock-sidebar--wrap {
    .stock-table {
      .v-card__title {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
      }
    }
  }
</style>
