<template>
  <div class="transaction-overview--wrap">
    <average-price-volume-combi
      :formula-prices="formulaPrices"
      :is-loading="isLoading"
      :transactions="statisticTransactions"
      class="mb-4"
    />

    <div class="d-flex justify-end mb-4">
      <v-btn
        v-if="!transactionCreationDisabled"
        depressed
        color="secondary"
        dark
        @click="transactionDialogVisible = true"
      >
        <v-icon left>
          mdi-bank-plus
        </v-icon>
        {{ $t('transactions.newTransaction') }}
      </v-btn>

      <v-btn
        v-if="!transactionCreationDisabled"
        depressed
        color="secondary"
        dark
        class="ml-2"
        @click="bonusTransactionDialogVisible = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t('transactions.newBonusTransaction') }}
      </v-btn>

      <transaction-download class="ml-2" />
    </div>

    <transaction-table
      :is-loading="isLoading"
      :transactions="transactions"
      :page-size="50"
      :preselected-user-id="preselectedUser"
      :title="$t('transactions.head')"
      with-actions
    />

    <create-transaction-dialog
      :is-visible="transactionDialogVisible"
      @close="transactionDialogVisible = false"
      @request:reaload="init"
    />

    <create-bonus-dialog
      :is-visible="bonusTransactionDialogVisible"
      @close="bonusTransactionDialogVisible = false"
      @request:reload="init"
    />
  </div>
</template>

<script>
import AveragePriceVolumeCombi from '@/components/charts/AveragePriceVolumeCombi'
import ConfigurationApi from '@/api/Configuration'
import CreateBonusDialog from '@/views/Stocks/Transactions/CreateBonusDialog'
import CreateTransactionDialog from '@/views/Stocks/Transactions/CreateTransactionDialog'
import TransactionApi from '@/api/Transactions'
import TransactionDownload from '@/components/TransactionDownload'
import TransactionTable from '@/components/TransactionTable'

import roles from '@/roles'

export default {
  name: 'transaction-overview',

  components: {
    AveragePriceVolumeCombi,
    CreateBonusDialog,
    CreateTransactionDialog,
    TransactionDownload,
    TransactionTable,
  },

  data () {
    return {
      bonusTransactionDialogVisible: false,
      formulaPrices: [],
      isLoading: false,
      preselectedUser: null,
      statisticTransactions: [],
      transactions: [],
      transactionDialogVisible: false,
    }
  },

  computed: {
    transactionCreationDisabled () {
      return this.$store.state.user.user.roles.includes(roles.organe)
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    /**
     * Loads all transactions for the statistic (without user-information,
     * transactions of the holding).
     *
     * @returns {Promise}
     */
    async getStatisticTransactions () {
      const res = await TransactionApi.getChartData()
      res.ok && (this.statisticTransactions = await res.json())
    },

    /**
     * Loads all transactions.
     *
     * @returns {Promise}
     */
    async getTransactions () {
      const res = await TransactionApi.getAll()
      res.ok && (this.transactions = await res.json())
    },

    /**
     * Loads configurations, extracts formula-prices with their date from those
     * so we can use that dataset within statistics.
     *
     * @returns {Promise}
     */
    async getFormulaPrices () {
      const res = await ConfigurationApi.list()

      if (res.ok) {
        const configs = await res.json()
        this.formulaPrices = configs.map(({ formulaPrice, validFrom }) => ({ date: validFrom, formulaPrice }))
      }
    },

    /**
     * Loads datasets required by this view.
     *
     * @returns {Promise}
     */
    async init () {
      if (this.$route.params.filter) {
        this.preselectedUser = +this.$route.params.filter
      }

      this.isLoading = true
      await this.getTransactions()
      await this.getFormulaPrices()
      await this.getStatisticTransactions()
      this.isLoading = false
    },
  },
}
</script>
