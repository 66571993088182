<template>
  <div class="dates--wrap">
    <h1 class="mb-1">
      {{ $t('sidebar.dates') }}
    </h1>

    <h2 class="text-h6 mb-6">
      {{ $t('calendar.dateOverview') }}
    </h2>

    <month-calendar class="mb-10" />
    <date-table />
  </div>
</template>

<script>
import DateTable from './DateTable'
import MonthCalendar from '@/components/MonthCalendar'

export default {
  name: 'dates-view',

  components: {
    DateTable,
    MonthCalendar,
  },
}
</script>
