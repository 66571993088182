<template>
  <v-dialog
    :value="newsEntry !== null"
    width="990"
    max-width="90%"
    content-class="news-dialog"
    @input="visible => !visible && $emit('close')"
  >
    <v-card v-if="newsEntry">
      <v-card-title>
        {{ newsEntry.title }}
      </v-card-title>

      <v-card-subtitle v-if="newsEntry.subTitle">
        {{ newsEntry.subTitle }}
      </v-card-subtitle>

      <v-card-text>
        <div class="message mb-8" v-text="newsEntry.description" />
        <img v-if="getImageUrl(newsEntry)" :src="getImageUrl(newsEntry)" :alt="newsEntry.title" class="img-fluid">

        <v-row>
          <v-col>
            <news-links :link-list="newsEntry.links" />
          </v-col>
          <v-col>
            <news-documents :document-ids="newsEntry.documentIds" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="mt-auto">
        <v-chip small label color="grey lighten-4">
          <v-icon small class="mr-1" v-text="'mdi-calendar'" />
          {{ newsEntry.date | readableIsoDate }}
        </v-chip>

        <v-spacer />

        <v-btn depressed @click="$emit('close')">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NewsApi from '@/api/News'
import NewsDocuments from '@/components/NewsDocuments'
import NewsLinks from '@/components/NewsLinks'

export default {
  name: 'news-dialog',

  components: {
    NewsDocuments,
    NewsLinks,
  },

  props: {
    newsEntry: {
      type: Object,
      default: null,
    },
  },

  methods: {
    /**
     * Builds the url for an image-request for the given news-entry if it has
     * an image.
     *
     * @param {object} newsEntry
     * @returns {string|null}
     */
    getImageUrl (newsEntry) {
      return NewsApi.getImageUrl(newsEntry)
    },
  },
}
</script>
