<template>
  <div class="templates--wrap">
    <data-table
      :columns="columns"
      :items="templates"
      :title="$t('emails.availableTemplates')"
      :default-options="{ sortBy: ['messageTemplateId'], sortDesc: [true] }"
      :is-loading="isLoading"
    >
      <template #headerActions>
        <table-button
          icon="mdi-email-plus-outline"
          dark
          :tooltip="$t('emails.createTemplate')"
          is-primary
          @click="showCreateDialog = true"
        />
      </template>

      <template #[`item.lastSentAt`]="{ item }">
        <span v-if="item.lastSentAt">
          {{ item.lastSentAt | readableIsoDate(true) }}
        </span>
        <span v-else>-</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-eye"
          :tooltip="$t('emails.showTemplate')"
          @click="toShow = item"
        />

        <table-button
          icon="mdi-delete"
          icon-color="red"
          :tooltip="$t('emails.deleteTemplate')"
          @click="toRemove = item"
        />
      </template>
    </data-table>

    <confirm-dialog
      :headline="$t('emails.confirmTemplateDeletion')"
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeTemplate"
    />

    <template-dialog
      :is-visible="toShow !== null"
      :template="toShow"
      @cancel="toShow = null"
      @sent:template="onSentTemplate"
    />

    <create-template-dialog
      :is-visible="showCreateDialog"
      @cancel="showCreateDialog = false"
      @created:template="onCreatedTemplate"
    />
  </div>
</template>

<script>
import MessageApi from '@/api/Message'
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'
import TemplateDialog from './TemplateDialog'
import CreateTemplateDialog from './CreateTemplateDialog'

export default {
  name: 'email-templates',

  components: {
    ConfirmDialog,
    DataTable,
    TableButton,
    TemplateDialog,
    CreateTemplateDialog,
  },

  data () {
    return {
      isLoading: false,
      templates: [],
      toShow: null,
      toRemove: null,
      showCreateDialog: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('emails.sender'), value: 'senderName' },
        { text: this.$t('emails.subject'), value: 'subject' },
        { text: this.$t('emails.sentAt'), value: 'lastSentAt', align: 'right' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '110px', align: 'right' },
      ]
    }
  },

  mounted () {
    this.loadTemplates()
  },

  methods: {
    /**
     * Loads all available templates.
     *
     * @returns {void}
     */
    async loadTemplates () {
      this.isLoading = true
      const res = await MessageApi.getTemplates()
      res.ok && (this.templates = await res.json())
      this.isLoading = false
    },

    /**
     * Shows a confirmation, reloads templates after a template was created.
     *
     * @returns {void}
     */
    onCreatedTemplate () {
      this.showCreateDialog = false
      this.loadTemplates()
      this.$store.commit('setSnackbar', { text: this.$t('emails.templateCreated') })
    },

    async removeTemplate () {
      const res = await MessageApi.deleteTemplate(this.toRemove.messageTemplateId)

      if (res.ok) {
        this.toRemove = null
        this.loadTemplates()
        return this.$store.commit('setSnackbar', { text: this.$t('emails.templateDeleted') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    },

    onSentTemplate () {
      this.toShow = null
      this.loadTemplates()
      this.$store.commit('setSnackbar', { text: this.$t('emails.templateSent') })
    }
  }
}
</script>
