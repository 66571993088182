import Api from '@/api/Api'

export default {
  /**
   * login
   *
   * @param {object} options
   * @param {string} options.username
   * @param {string} options.password
   *
   * @returns {Promise}
   */
  login ({ email = '', password = '' }) {
    return Api.baseFetch('login', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    })
  },

  /**
   * logout
   *
   * @returns {Promise}
   */
  logout () {
    return Api.baseFetch('logout', {
      method: 'POST',
    })
  },

  /**
   * getAgreements
   *
   * @returns {Promise}
   */
  getAgreements () {
    return Api.baseFetch('agreement')
  },

  /**
   * updateAgreements
   *
   * @param {array} agreements
   * @returns {Promise}
   */
  updateAgreements (agreements = []) {
    return Api.baseFetch('agreement', {
      method: 'PUT',
      body: JSON.stringify(agreements),
    })
  },

  /**
   * requestPasswordReset
   *
   * @param {string} email
   * @returns {Promise}
   */
  requestPasswordReset (email) {
    return Api.baseFetch('requestPasswordReset', {
      method: 'POST',
      body: JSON.stringify({ email }),
    })
  },

  /**
   * performPasswordReset
   *
   * @param {string} token
   * @param {string} password
   * @returns {Promise}
   */
  performPasswordReset (token, password) {
    return Api.baseFetch(`performPasswordReset/${token}`, {
      method: 'POST',
      body: JSON.stringify({ password }),
    })
  },
}
