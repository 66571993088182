<template>
  <v-form
    ref="form"
    v-model="formIsValid"
    @submit.prevent="changePassword"
  >
    <v-card
      :flat="flat"
      :color="flat ? 'transparent' : ''"
      width="950"
      max-width="100%"
    >
      <v-card-title :class="{ 'px-0': flat, 'pt-0': flat }">
        {{ $t('formPasswordChange.heading') }}
      </v-card-title>

      <v-card-subtitle :class="{ 'px-0': flat }">
        {{ $t('formPasswordChange.hint') }}
      </v-card-subtitle>

      <v-card-text :class="{ 'px-0': flat }" class="my-4">
        <v-row>
          <v-col :cols="12" :lg="6">
            <v-text-field
              v-model="oldPassword"
              prepend-inner-icon="mdi-lock"
              hide-details="auto"
              outlined
              :label="$t('formPasswordChange.oldPassword')"
              class="mb-2"
              name="oldPassword"
              type="password"
              :rules="requiredRules"
            />
            <v-text-field
              v-model="password"
              prepend-inner-icon="mdi-lock"
              hide-details="auto"
              outlined
              :label="$t('formPasswordChange.newPassword')"
              class="mb-2"
              name="newPassword"
              type="password"
              autocomplete="new-password"
              :rules="requiredRules"
              :error="password.length > 0 && !passwordIsValid"
            />
            <v-text-field
              v-if="passwordIsValid"
              v-model="passwordConfirmation"
              prepend-inner-icon="mdi-lock"
              hide-details="auto"
              outlined
              :label="$t('formPasswordChange.newPasswordRepeat')"
              required
              class="mb-2"
              name="newPasswordRepeat"
              type="password"
              autocomplete="new-password"
              :rules="requiredRules"
              :error="password !== passwordConfirmation"
            />
          </v-col>
          <v-col :cols="12" :lg="6">
            <password-strength
              :password="password"
              class="mt-n2"
              @check:password="({ isValid }) => passwordIsValid = isValid"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions :class="{ 'px-0': flat }">
        <v-btn
          type="submit"
          depressed
          class="ml-auto"
          :disabled="!formIsValid"
        >
          <v-icon left color="primary">
            mdi-pencil
          </v-icon>
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import AccountApi from '@/api/Account'
import PasswordStrength from './PasswordStrength'

export default {
  name: 'reset-password-form',

  components: {
    PasswordStrength,
  },

  props: {
    flat: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      formIsValid: true,
      password: '',
      passwordConfirmation: '',
      passwordIsValid: false,
      oldPassword: '',
    }
  },

  computed: {
    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    }
  },

  methods: {
    /**
     * changePassword
     *
     * @returns {void}
     */
    async changePassword () {
      this.$refs.form.validate()

      if (this.password !== this.passwordConfirmation) {
        return this.$store.commit('setSnackbar', {
          text: this.$t('formPasswordChange.wrongConfirmation'),
          color: 'error'
        })
      }

      if (!this.formIsValid) {
        return
      }

      const res = await AccountApi.changePassword(this.oldPassword, this.password)

      if (res.ok) {
        this.$emit('change:success')
        return this.$store.commit('setSnackbar', { text: this.$t('formPasswordChange.passwordChanged') })
      }

      this.$store.commit('setSnackbar', { text: res.statusText, color: 'error' })
      this.$emit('change:failure')
    },
  },
}
</script>
