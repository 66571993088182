<template>
  <div class="stock-amount--wrap">
    <sparkline-base
      :labels="labels"
      :series="series"
      :height="height"
      :title="currentAmount | localeNumber($i18n.locale)"
      :option-changes="options"
      :subtitle="$t('common.stocks')"
      :is-loading="isLoading"
    >
      <template #fallback>
        <v-card-text class="py-6 mb-auto grey lighten-4">
          {{ $t('sell.noStocks') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="primary" small :to="{ name: 'SalesRequests' }" class="text-decoration-none">
            {{ $t('sidebar.saleRequests') }}
          </v-btn>
        </v-card-actions>
      </template>
    </sparkline-base>
  </div>
</template>

<script>
import SparklineBase from './SparklineBase'
import TransactionFormatter from '@/services/transaction-formatter'

import { format } from 'date-fns'

export default {
  name: 'stock-amount',

  components: {
    SparklineBase,
  },

  props: {
    height: {
      type: [Number, String],
      default: 'auto',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    transactions: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      options: {
        chart: {
          parentHeightOffset: 0,
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: { width: 1, curve: 'stepline' },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: (val, timestamp) => format(timestamp, 'dd.MM.yyyy'),
          },
          tickAmount: 4,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 3,
        },
      },
    }
  },

  computed: {
    monthlyTransactions () {
      return TransactionFormatter.getMonthlyTransactions(this.transactions)
    },

    currentAmount () {
      if (this.stocksByMonth.length === 0) {
        return 0
      }

      const lastEntry = this.stocksByMonth[this.stocksByMonth.length - 1]
      const month = Object.keys(lastEntry).pop()
      return lastEntry[month]
    },

    /**
     * Amount of stocks per month/year.
     *
     * @returns {array}
     */
    stocksByMonth () {
      return this.labels.reduce((entries, month, i) => {
        const transactions = this.monthlyTransactions[month] || []
        const monthlyAmount = transactions.reduce((amount, t) => {
          if (['buy', 'initial', 'new_issue', 'bonus', 'extra'].includes(t.type)) return amount + t.amount
          if (t.type === 'sell') return amount - t.amount
          if (t.type === 'split_out') return t.amount
          return amount
        }, i === 0 ? 0 : Object.values(entries[i - 1]).pop())

        return [...entries, { [month]: monthlyAmount }]
      }, [])
    },

    /**
     * Labels for the series-entries: Month/year-combination.
     *
     * @returns {array}
     */
    labels () {
      return Object.keys(this.monthlyTransactions)
    },

    /**
     * List of values to visualize: Amount of stocks per month.
     *
     * @returns {array}
     */
    series () {
      return [{
        name: this.$t('common.stocks'),
        data: this.stocksByMonth.map(o => Object.values(o).pop())
      }]
    }
  },
}
</script>
