<template>
  <div class="news-panel--wrap">
    <v-expansion-panels>
      <v-expansion-panel v-for="entry in news" :key="entry.newsId">
        <v-expansion-panel-header>
          <div>
            <div class="text-subtitle-1 font-weight-bold">
              {{ entry.title }}
            </div>
            <div v-if="entry.subTitle" class="line-clamp text-subtitle-1 text--secondary">
              {{ entry.subTitle }}
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-chip small label color="grey lighten-4" class="mx-6">
              <v-icon small class="mr-1" v-text="'mdi-calendar'" />
              {{ entry.date | readableIsoDate }}
            </v-chip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="message mb-8" v-text="entry.description" />
          <img v-if="getImageUrl(entry)" :src="getImageUrl(entry)" :alt="entry.title" class="img-fluid">

          <v-row>
            <v-col>
              <news-links :link-list="entry.links" />
            </v-col>
            <v-col>
              <news-documents :document-ids="entry.documentIds" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import NewsApi from '@/api/News'
import NewsDocuments from '@/components/NewsDocuments'
import NewsLinks from '@/components/NewsLinks'

export default {
  name: 'news-panel',

  components: {
    NewsDocuments,
    NewsLinks,
  },

  props: {
    news: {
      type: Array,
      default: () => ([])
    }
  },

  methods: {
    /**
     * Builds the url for an image-request for the given news-entry if it has
     * an image.
     *
     * @param {object} newsEntry
     * @returns {string|null}
     */
    getImageUrl (newsEntry) {
      return NewsApi.getImageUrl(newsEntry)
    },
  },
}
</script>

<style lang="scss">
  .news-panel--wrap {
    .line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .message {
      white-space: pre-wrap;
    }
  }
</style>
