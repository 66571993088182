<template>
  <div class="datatable--wrap">
    <v-card :flat="flat">
      <v-card-title
        v-if="!hideHeader"
        :class="{ 'px-0': flat }"
        class="py-3 justify-space-between"
      >
        <div v-if="hasTitle" class="flex my-1">
          {{ title }}
          <slot name="title" />
        </div>

        <div
          class="d-flex align-center"
          :class="{
            'flex': !hasTitle,
            'justify-end': hasTitle,
          }"
        >
          <v-text-field
            v-if="showSearch"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            :label="$t(searchPlaceholder)"
            outlined
            dense
            clearable
            hide-details
            class="table-search pa-0 my-0"
            :class="{ 'mr-auto': !hasTitle }"
          />

          <div v-if="$slots.headerActions" class="d-flex align-center justify-end ml-4">
            <slot name="headerActions" />
          </div>
        </div>
      </v-card-title>

      <v-divider v-if="!hideHeader" />

      <v-data-table
        dense
        :loading="isLoading"
        :headers="columns"
        :items="items"
        :items-per-page="pageSize"
        :options="defaultOptions"
        :search="search"
        :custom-filter="searchItems"
        :hide-default-footer="hideFooter"
        :class="{ 'pb-2': hideFooter }"
        :footer-props="{
          'items-per-page-options': pageSizes
        }"
        :mobile-breakpoint="960"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @click:row="(e, item) => $emit('click:row', e, item)"
        @dblclick:row="(e, item) => $emit('dblclick:row', e, item)"
        @update:sort-by="applySortFallback"
        @update:sort-desc="applySortFallback"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="data">
          <slot v-if="data && data.item" :name="slot" :item="data.item" />
          <slot v-else :name="slot" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { isValid, parseISO } from 'date-fns'

export default {
  name: 'data-table',

  props: {
    columns: {
      type: Array,
      default: () => ([]),
    },

    customPageSize: {
      type: Number,
      default: null,
    },

    defaultOptions: {
      type: Object,
      default: null,
    },

    fallbackSorting: {
      type: String,
      default: null,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => ([]),
    },

    pageSizes: {
      type: Array,
      default: () => ([5, 10, 25, 50, -1]),
    },

    searchPlaceholder: {
      type: String,
      default: 'common.search',
    },

    showSearch: {
      type: Boolean,
      default: true,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      search: '',
      sortBy: [],
      sortDesc: [],
    }
  },

  computed: {
    hasTitle () {
      return this.title || this.$slots.title
    },
  },

  watch: {
    customPageSize () {
      this.useCustomPageSize()
    }
  },

  mounted () {
    this.sortBy = this.defaultOptions?.sortBy || []
    this.sortDesc = this.defaultOptions?.sortDesc || []

    this.useCustomPageSize()
    this.applySortFallback()
  },

  methods: {
    /**
     * Custom search which can handle transformed dates, too: Datasets use iso-
     * strings (yyyy-mm-dd), while those get displayed in a readable way. Users
     * want to search for the readable variant.
     *
     * @param {any} value
     * @param {any} search
     * @returns {boolean}
     */
    searchItems (value, search) {
      if (isValid(parseISO(value))) {
        value = this.$options.filters.readableIsoDate(value, value.length > 10)
      }

      if (value === undefined || value === null || search === null || typeof value === 'boolean') {
        return false
      }

      return value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    },

    setPage (page) {
      this.currentPage = page
    },

    setPageSize (pageSize) {
      this.pageSize = pageSize
      this.currentPage = 1
    },

    useCustomPageSize () {
      this.pageSize = this.customPageSize ? this.customPageSize : this.pageSizes[0].value
    },

    /**
     * If a fallback-column for sorting is defined (secondary column to use
     * when the primary column has multiple equal values), we want to add that
     * to our sort-array and apply the direction of the primary column.
     *
     * @returns {void}
     */
    applySortFallback () {
      // no fallback set - nothing to do
      if (!this.fallbackSorting) {
        return
      }

      // add defined fallback as secondary sorting-column
      if (!this.sortBy.includes(this.fallbackSorting)) {
        this.sortBy = [...this.sortBy, this.fallbackSorting]
      }

      // primary sorting was removed, only added fallback stays -> clear it
      if (this.sortBy.length === 1 && this.sortBy.includes(this.fallbackSorting)) {
        this.sortBy = []
      }

      // apply direction of primary sorting to secondary one
      this.sortDesc = this.sortBy.length && this.sortDesc.length
        ? [this.sortDesc[0], this.sortDesc[0]]
        : []
    }
  },
}
</script>

<style lang="scss">
  .datatable--wrap {
    .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th {
      height: 38px;
    }

    .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
      min-height: 32px;
    }

    .v-card {
      .v-card__title {
        .v-input {
          font-weight: 400;
        }
      }
    }

    .v-data-table__wrapper {
      .v-data-table__empty-wrapper {
        td {
          padding: 24px;
        }
      }
    }

    .table-search {
      flex: 0 0 200px;
    }
  }
</style>
