<template>
  <div class="report-information--wrap">
    <h1 class="mb-6">
      {{ $t('information.reports') }}
    </h1>

    <document-table
      :search="{ displayAreas: ['report'] }"
      :title="$t('information.reportsHint')"
    />
  </div>
</template>

<script>
import DocumentTable from '@/components/DocumentTable'

export default {
  name: 'report-information',

  components: {
    DocumentTable,
  },
}
</script>
