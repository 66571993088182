var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stock-sidebar--wrap"},[(_vm.stockGroup)?_c('v-navigation-drawer',{staticClass:"elevation-3",attrs:{"value":_vm.stockGroup !== null,"right":"","app":"","width":"300","floating":"","temporary":""},on:{"input":function (visibility) { return !visibility && _vm.$emit('close'); }}},[_c('data-table',{staticClass:"px-2",attrs:{"columns":_vm.columns,"items":_vm.sharesAsObjects,"flat":"","custom-page-size":50,"page-sizes":[25, 50, 100],"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.showHistory(item.number)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-history ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"value":_vm.historyToShow !== null,"width":"800","max-width":"90%"},on:{"input":function (visibility) { return !visibility && (_vm.historyToShow = null); }}},[(_vm.historyToShow)?_c('div',[_c('data-table',{attrs:{"items":_vm.historyToShow.items,"columns":_vm.historyColumns,"default-options":{ sortBy: ['boughtAt'], sortDesc: [true] },"title":((_vm.$t('stocks.number')) + " " + (_vm.historyToShow.number))},scopedSlots:_vm._u([{key:"item.boughtAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.boughtAt))+" ")]}},{key:"item.purchasePrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.purchasePrice,_vm.$i18n.locale))+" ")]}},{key:"item.buyerFullName",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{attrs:{"type":"buy"}}),_vm._v(" "+_vm._s(item.buyerFullName)+" ")]}},{key:"item.sellerFullName",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{attrs:{"type":"sell"}}),_vm._v(" "+_vm._s(item.sellerFullName)+" ")]}}],null,true)})],1):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }