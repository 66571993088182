<template>
  <div class="current-offers--wrap">
    <v-row>
      <v-col :cols="12" :lg="6">
        <v-card color="primary" dark flat>
          <v-card-title>
            {{ $t('currentOffers.currentRequests') }}
            <v-spacer />
            <data-reload-timer
              color="white"
              :seconds="60"
              small
              hide-text
              @request:reload="loadOffers(true)"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="text-subtitle-1">
            <template v-if="buyData">
              {{ $t('currentOffers.summary', { shares: buyData.shares, min: (buyData.min), max: (buyData.max) }) }}
            </template>
            <template v-else>
              {{ $t('currentOffers.noRequests') }}
            </template>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn plain :to="{ name: 'Requests' }">
              {{ $t('currentOffers.goToRequests') }}
              <v-icon right>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col :cols="12" :lg="6">
        <v-card color="primary" dark flat>
          <v-card-title>
            {{ $t('currentOffers.currentSales') }}
            <v-spacer />
            <data-reload-timer
              color="white"
              :seconds="60"
              small
              hide-text
              @request:reload="loadOffers(true)"
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="text-subtitle-1">
            <template v-if="sellData">
              {{ $t('currentOffers.summary', { shares: sellData.shares, min: (sellData.min), max: (sellData.max) }) }}
            </template>
            <template v-else>
              {{ $t('currentOffers.noSales') }}
            </template>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn plain :to="{ name: 'Sales' }">
              {{ $t('currentOffers.goToSales') }}
              <v-icon right>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DataReloadTimer from '@/components/DataReloadTimer'
import OfferApi from '@/api/Offer'

export default {
  name: 'current-offers',

  components: {
    DataReloadTimer,
  },

  data () {
    return {
      offers: [],
    }
  },

  computed: {
    buyData () {
      return this.getSummaryByType('buy')
    },

    sellData () {
      return this.getSummaryByType('sell')
    },
  },

  mounted () {
    this.loadOffers()
  },

  methods: {
    /**
     * Loads all available offers, stores entries which aren't expired already
     * and still have an amount of shares for further usage.
     *
     * @param {boolean} isAutoReload If data is reloaded automatically, we don't want to extend the session
     * @returns {void}
     */
    async loadOffers (isAutoReload = false) {
      const res = await OfferApi.getAll(isAutoReload)

      if (res.ok) {
        const offers = await res.json()
        this.offers = offers.filter(({ amount, expires }) =>
          amount > 0 && new Date(expires).getTime() > new Date().getTime()
        )
      }
    },

    /**
     * Creates a summary for the offers with the given type containing the min-
     * and max-price and the total amount of shares.
     *
     * @param {string} type buy/sell
     * @returns {object|null} null = there aren't any offers for the given type
     */
    getSummaryByType (type) {
      const offers = this.offers.filter(({ offerType }) => offerType === type)

      if (offers.length === 0) {
        return null
      }

      const max = offers.reduce((max, { price }) => price > max ? price : max, offers[0].price)
      const min = offers.reduce((min, { price }) => price < min ? price : min, offers[0].price)
      const shares = offers.reduce((total, { amount }) => total + amount, 0)

      return {
        max: this.$options.filters.readablePrice(max, this.$i18n.locale),
        min: this.$options.filters.readablePrice(min, this.$i18n.locale),
        shares: this.$options.filters.localeNumber(shares, this.$i18n.locale),
      }
    }
  }
}
</script>
