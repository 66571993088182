export default {
  state: {
    user: null,
    impersonated: false,
  },

  getters: {
    /**
     * Creates a function which checks if the user has atleast one of the given
     * roles.
     *
     * @param {object} state
     * @returns {Function}
     */
    userHasRole: state => (roles = []) => {
      return state.user && roles.some(role => state.user.roles.includes(role))
    },

    /**
     * Checks if the user is allowed to see the given route based on the roles.
     * The route is allowed if it hasn't any required roles or one of those is
     * one of the user-roles.
     *
     * @param {object} route
     * @returns {boolean}
     */
    routeAllowedForUser: state => route => {
      const allowed = route.matched.reduce((allowed, record) => allowed.concat(record.meta.allowedRoles || []), [])
      return state.user && (!allowed.length || allowed.some(allowedRole => state.user.roles.includes(allowedRole)))
    }
  },

  mutations: {
    setUser (state, user) {
      state.user = user
    },

    logout (state) {
      state.user = null
    },

    setImpersonated (state, isImpersonated) {
      state.impersonated = isImpersonated
    },
  },
}
