import AccountApi from '@/api/Account'

export default {
  computed: {
    languages () {
      return [
        { label: this.$t('languages.german'), key: 'de-DE', icon: require('@/assets/gfx/flag_de.svg') },
        { label: this.$t('languages.english'), key: 'en-GB', icon: require('@/assets/gfx/flag_uk.svg') },
      ]
    },

    currentUser () {
      return this.$store.state.user.user
    }
  },

  methods: {
    /**
     * Returns the icon related to the language with the given key.
     *
     * @param {string} languageKey
     * @returns {string|undefined}
     */
    getLanguageIconByKey (languageKey) {
      return this.languages.find(({ key }) => key === languageKey)?.icon
    },

    /**
     * Sets the language to use. The selection can be saved if required.
     *
     * @param {string} languageKey
     * @param {boolean} persist
     * @returns {void}
     */
    setLanguage (languageKey, persist = true) {
      const shortLanguageKey = languageKey.slice(0, 2)
      this.$i18n.locale = languageKey

      // vuetify uses short language-codes, but our backend the long variant
      this.$vuetify.lang.current = shortLanguageKey

      // refresh components so the width of the changed labels gets used
      window.dispatchEvent(new Event('resize'))

      document.title = this.$t('common.pageTitle')
      document.documentElement.lang = shortLanguageKey

      if (this.currentUser && persist) {
        const user = { ...this.currentUser, language: languageKey }
        AccountApi.updateProfile(user)
        this.$store.commit('setUser', user)
      }
    }
  },
}
