<template>
  <v-dialog
    :value="offer !== null"
    :width="900"
    max-width="90%"
    @input="visible => !visible && $emit('close')"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card v-if="offer">
        <v-card-title>
          {{ $t('salesRequests.acceptOffer') }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :value="offer.fullName || $t('sell.anonymousUser')"
            disabled
            readonly
            :label="$t('sell.buyer')"
          />
          <v-text-field
            :value="offer.price | readablePrice($i18n.locale)"
            disabled
            readonly
            :label="$t('salesRequests.pricePerShare')"
          />

          <div class="text-subtitle-1 mb-4">
            {{ $t('salesRequests.sellAmountHead') }}
          </div>

          <v-row class="mb-4">
            <v-col>
              <v-text-field
                v-model.number="amount"
                :max="maxAvailable"
                :disabled="stockAmount === 0"
                hide-details
                outlined
                type="number"
                :rules="requiredRules"
                :label="$t('salesRequests.amount')"
              />
            </v-col>
            <v-col class="d-flex align-center">
              <v-slider
                v-model.number="amount"
                thumb-label
                hide-details
                :max="maxAvailable"
                :disabled="stockAmount === 0"
              />
            </v-col>
          </v-row>

          <revenue-preview
            v-if="amount"
            :amount-to-sell="amount"
            :stock-price="offer.price"
            flat
          />

          <v-switch v-model="readTerms" :rules="requiredRules" :disabled="stockAmount === 0" inset>
            <template #label>
              <accepted-terms-label />
            </template>
          </v-switch>

          <v-text-field
            v-model="secondFactorCode"
            prepend-inner-icon="mdi-key"
            :label="$t('salesRequests.twoFactorInfo')"
            hide-details
            outlined
            class="pa-0 my-2"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn depressed @click="$emit('close')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon left color="primary">
              mdi-cart
            </v-icon>
            {{ $t('salesRequests.sellNow') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AcceptedTermsLabel from '@/components/AcceptedTermsLabel'
import RevenuePreview from '@/views/Stocks/UserOffers/RevenuePreview'

export default {
  name: 'sell-dialog',

  components: {
    AcceptedTermsLabel,
    RevenuePreview,
  },

  props: {
    offer: {
      type: Object,
      default: null,
    },

    stockAmount: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      amount: 0,
      formIsValid: true,
      readTerms: false,
      secondFactorCode: '',
    }
  },

  computed: {
    /**
     * Available shares of the current offer.
     *
     * @returns {number}
     */
    maxAvailable () {
      return this.stockAmount <= this.offer.effectiveAmount ? this.stockAmount : this.offer.effectiveAmount
    },

    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },
  },

  methods: {
    /**
     * Communicates the request to accept an offer to the handling parent-
     * component (if the entered data ist valid), resets the internal state.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('sell', {
          offer: this.offer,
          amount: this.amount,
          secondFactorCode: this.secondFactorCode,
        })
        this.$emit('close')
        this.amount = 0
        this.readTerms = false
        this.secondFactorCode = ''
        this.$refs.form.resetValidation()
      }
    }
  },
}
</script>
