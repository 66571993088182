import Api from '@/api/Api'

export default {
  baseUrl: 'company',

  /**
   * getAll
   *
   * @returns {Promise}
   */
  getAll () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * create
   *
   * @param {object} company
   * @returns {Promise}
   */
  create (company) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify(company),
    })
  },

  /**
   * update
   *
   * @param {object} company
   * @returns {Promise}
   */
  update (company) {
    return Api.baseFetch(`${this.baseUrl}/${company.companyId}`, {
      method: 'PUT',
      body: JSON.stringify(company),
    })
  },

  /**
   * delete
   *
   * @param {number|string} companyId
   * @returns {Promise}
   */
  delete (companyId) {
    return Api.baseFetch(`${this.baseUrl}/${companyId}`, {
      method: 'DELETE',
    })
  },

  /**
   * createConfiguration
   *
   * @param {number|string} companyId
   * @param {object} config
   * @returns {Promise}
   */
  createConfiguration (companyId, config) {
    return Api.baseFetch(`${this.baseUrl}/${companyId}/configuration`, {
      method: 'POST',
      body: JSON.stringify({
        ...config,
        validFrom: config.validFrom.toISOString()
      }),
    })
  },

  /**
   * getConfigurationList
   *
   * @param {number|string} companyId
   * @returns {Promise}
   */
  getConfigurationList (companyId) {
    return Api.baseFetch(`${this.baseUrl}/${companyId}/configuration/list`)
  },

  /**
   * updateConfiguration
   *
   * @param {number|string} companyId
   * @param {object} config
   * @returns {Promise}
   */
  updateConfiguration (companyId, config) {
    return Api.baseFetch(`${this.baseUrl}/${companyId}/configuration/${config.companyConfigurationId}`, {
      method: 'PUT',
      body: JSON.stringify({
        ...config,
        validFrom: config.validFrom.toISOString()
      }),
    })
  },
}
