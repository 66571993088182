<template>
  <div class="date-table--wrap">
    <data-table
      :columns="columns"
      :items="dates"
      :default-options="{ sortBy: ['date'], sortDesc: [true] }"
    >
      <template v-if="withAdminActions" #headerActions>
        <table-button
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('dates.createDate')"
          is-primary
          @click="showDialog = true"
        />
      </template>

      <template #[`item.date`]="{ item }">
        {{ item.date | readableIsoDate(true) }}
      </template>

      <template #[`item.appointmentType`]="{ item }">
        <v-chip small outlined label :color="getEventColor(item.appointmentType)" dark>
          {{ $t(`calendar.type.${item.appointmentType}`) }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <div v-if="withAdminActions">
          <table-button
            icon="mdi-delete"
            icon-color="red"
            :tooltip="$t('common.delete')"
            @click="toRemove = item"
          />
          <table-button
            icon="mdi-pencil"
            icon-color="primary"
            :tooltip="$t('common.edit')"
            @click="editDateEntry(item)"
          />
        </div>

        <table-button
          v-else
          icon="mdi-eye"
          icon-color="primary"
          :tooltip="$t('common.more')"
          @click="eventToShow = item"
        />
      </template>
    </data-table>

    <confirm-dialog
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeEntry"
    />

    <event-dialog :event="eventToShow" @close="eventToShow = null" />

    <date-dialog
      v-if="withAdminActions"
      :is-visible="showDialog"
      :to-edit="toEdit"
      @close="onCloseDialog"
      @request:reload="loadDates"
    />
  </div>
</template>

<script>
import AppointmentApi from '@/api/Appointment'
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import DateDialog from './DateDialog'
import EventDialog from '@/components/EventDialog'
import TableButton from '@/components/TableButton'
import { getEventColor } from '@/services/event-colors'

export default {
  name: 'date-table',

  components: {
    ConfirmDialog,
    DataTable,
    DateDialog,
    EventDialog,
    TableButton,
  },

  props: {
    withAdminActions: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      dates: [],
      eventToShow: null,
      showDialog: false,
      toEdit: null,
      toRemove: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.date'), value: 'date' },
        { text: this.$t('dates.title'), value: 'title' },
        { text: this.$t('dates.type'), value: 'appointmentType' },
        { text: this.$t('dates.text'), value: 'text' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right' },
      ]
    }
  },

  mounted () {
    this.loadDates()
  },

  methods: {
    /**
     * Shows the dialog to edit the given calendar-entry.
     *
     * @param {object} entry
     * @returns {void}
     */
    editDateEntry (entry) {
      this.toEdit = entry
      this.showDialog = true
    },

    /**
     * Loads all available dates/appointments.
     *
     * @returns {void}
     */
    async loadDates () {
      const res = await AppointmentApi.getAll()
      res.ok && (this.dates = await res.json())
    },

    /**
     * Closes the dialog to edit an entry.
     *
     * @returns {void}
     */
    onCloseDialog () {
      this.showDialog = false
      this.toEdit = null
    },

    /**
     * Tells the api to remove the confirmed date-entry, reloads the dataset
     * afterwards.
     *
     * @returns {void}
     */
    async removeEntry () {
      if (this.toRemove) {
        const res = await AppointmentApi.delete(this.toRemove.appointmentId)
        res.ok
          ? this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
          : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

        this.toRemove = null
        this.loadDates()
      }
    },

    /**
     * Returns the color to use for the given event or a fallback.
     *
     * @param {string} appointmentType
     * @returns {string}
     */
    getEventColor (appointmentType) {
      return getEventColor(appointmentType)
    },
  },
}
</script>
