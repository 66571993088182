<template>
  <v-form
    ref="form"
    v-model="formIsValid"
    @submit.prevent="resetPassword"
  >
    <v-card width="800" max-width="90%">
      <v-card-title :class="{ 'px-0': flat }">
        {{ $t('login.resetPassword') }}
      </v-card-title>

      <v-card-subtitle :class="{ 'px-0': flat }">
        {{ $t('formPasswordChange.hint') }}
      </v-card-subtitle>

      <v-card-text :class="{ 'px-0': flat }" class="my-4">
        <v-row>
          <v-col :cols="12" :lg="6">
            <v-text-field
              v-model="password"
              prepend-inner-icon="mdi-lock"
              hide-details="auto"
              outlined
              :label="$t('formPasswordChange.newPassword')"
              class="mb-2"
              name="newPassword"
              type="password"
              autocomplete="new-password"
              :rules="requiredRules"
              :error="password.length > 0 && !passwordIsValid"
            />
            <v-text-field
              v-if="passwordIsValid"
              v-model="passwordConfirmation"
              prepend-inner-icon="mdi-lock"
              hide-details="auto"
              outlined
              :label="$t('formPasswordChange.newPasswordRepeat')"
              required
              class="mb-2"
              name="newPasswordRepeat"
              type="password"
              autocomplete="new-password"
              :rules="requiredRules"
              :error="password !== passwordConfirmation"
            />
          </v-col>
          <v-col :cols="12" :lg="6">
            <password-strength
              :password="password"
              class="mt-n2"
              @check:password="({ isValid }) => passwordIsValid = isValid"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions :class="{ 'px-0': flat }">
        <v-btn
          type="submit"
          depressed
          class="ml-auto"
          :disabled="!formIsValid"
        >
          <v-icon left color="primary">
            mdi-pencil
          </v-icon>
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import AuthApi from '@/api/Auth'
import PasswordStrength from './PasswordStrength'

export default {
  name: 'reset-password-form',

  components: {
    PasswordStrength,
  },

  props: {
    // remove x-paddings from card-elements
    flat: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      formIsValid: true,
      password: '',
      passwordConfirmation: '',
      passwordIsValid: false,
    }
  },

  computed: {
    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    },

    // users open the page with an url containing a reset-token
    token () {
      return this.$route.params.token ? this.$route.params.token : null
    },
  },

  methods: {
    /**
     * Sets the new password if the form is valid, informs the parent component
     * for further handling.
     *
     * @returns {void}
     */
    async resetPassword () {
      this.$refs.form.validate()

      if (this.password !== this.passwordConfirmation) {
        return this.$store.commit('setSnackbar', {
          text: this.$t('formPasswordChange.wrongConfirmation'),
          color: 'error'
        })
      }

      if (!this.formIsValid) {
        return
      }

      const res = await AuthApi.performPasswordReset(this.token, this.password)

      if (res.ok) {
        this.$emit('change:success')
        return this.$store.commit('setSnackbar', { text: 'formPasswordChange.passwordChanged' })
      }

      this.$store.commit('setSnackbar', { text: res.statusText, color: 'error' })
      this.$emit('change:failure')
    },
  },
}
</script>
