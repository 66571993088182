<template>
  <div class="users-list--wrap">
    <h1 class="mb-6">
      {{ $t('usersListUsers.heading') }}
    </h1>

    <data-table
      :columns="listShareholdersHeaders"
      :custom-page-size="25"
      :items="listShareholders"
      :page-sizes="[10, 25, 50, 100]"
      :default-options="{ sortBy: ['combinedName'], sortDesc: [false] }"
      :is-loading="isLoading"
      class="mb-8"
    >
      <template #headerActions>
        <table-button
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('usersAddUser.addUser')"
          is-primary
          class="ml-2"
          @click="$router.push({ name: 'UsersAddUser' })"
        />
      </template>

      <template #[`item.companyId`]="{ item }">
        {{ companyNameById(item.companyId) }}
      </template>

      <template #[`item.status`]="{ item }">
        {{ $t(`status.${item.status}`) }}
      </template>

      <template #[`item.combinedName`]="{ item }">
        <router-link
          :to="{ name: 'UsersEditUser', params: { id: item.accountId } }"
          class="text-decoration-none"
        >
          {{ item.combinedName }}
        </router-link>
      </template>

      <template #[`item.isActive`]="{ item }">
        <v-checkbox :input-value="item.isActive" inset disabled hide-details class="ma-0 pa-0" />
      </template>

      <template #[`item.roles`]="{ item }">
        {{ formattedRoles(item.roles) }}
      </template>

      <template #[`item.blocked`]="{ item }">
        <v-checkbox :input-value="item.blocked" inset disabled hide-details class="ma-0 pa-0" />
      </template>

      <template #[`item.actions`]="{ item }">
        <delete-user-button
          :id-to-delete="item.accountId"
          table-variant
          @deleted:user="init"
        />

        <table-button
          icon="mdi-pencil"
          icon-color="primary"
          :tooltip="$t('usersEditUser.heading')"
          :to="{ name: 'UsersEditUser', params: { id: item.accountId } }"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'
import CompanyApi from '@/api/Company'
import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'
import DeleteUserButton from '@/components/DeleteUserButton'

export default {
  name: 'users-list',

  components: {
    DataTable,
    DeleteUserButton,
    TableButton,
  },

  data () {
    return {
      listShareholders: [],
      companies: [],
      isLoading: false,
    }
  },

  computed: {
    listShareholdersHeaders () {
      return [
        { text: this.$t('forms.personId'), value: 'personId', align: 'right', width: 110 },
        { text: this.$t('users.name'), value: 'combinedName' },
        { text: this.$t('users.company'), value: 'companyId', sortable: false },
        { text: this.$t('users.status'), value: 'status' },
        { text: this.$t('users.active'), value: 'isActive' },
        { text: this.$t('users.role'), value: 'roles', sortable: false, width: 180 },
        { text: this.$t('common.actions'), value: 'actions', align: 'right', sortable: false },
      ]
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    /**
     * Loads required data for this view.
     *
     * @returns {void}
     */
    async init () {
      this.isLoading = true

      await this.getCompanies()
      await this.getAccounts()

      this.isLoading = false
    },

    /**
     * Formats the given array of roles to a readable list.
     *
     * @param {array} roles
     * @returns {string}
     */
    formattedRoles (roles) {
      if (!roles || !roles.length) {
        return '-'
      }

      return roles.map(role => this.$t(`roles.${role}`)).join(', ')
    },

    /**
     * Loads all accounts from the api, combines their names so those can get
     * displayed easy in one column.
     *
     * @returns {void}
     */
    async getAccounts () {
      const res = await AccountApi.listAll()

      if (res.ok) {
        const accounts = await res.json()
        this.listShareholders = accounts.map(account => ({
          ...account,
          combinedName: `${account.lastName} ${account.firstName}`
        }))
      }
    },

    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()
      res.ok && (this.companies = await res.json())
    },

    /**
     * Returns the name of the company with the given id.
     *
     * @param {number} id
     * @returns {string}
     */
    companyNameById (id) {
      const company = this.companies.find(company => company.companyId === id)
      return company ? company.name : '-'
    }
  }
}
</script>
