<template>
  <v-dialog
    :value="isVisible"
    scrollable
    width="800"
    max-width="90%"
    content-class="create-template-dialog"
    @input="visibility => !visibility && $emit('cancel')"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="createTemplate">
      <v-card :loading="isLoading">
        <v-card-title>
          {{ $t('emails.createTemplate') }}
        </v-card-title>

        <v-card-text class="py-2">
          <v-autocomplete
            v-model="template.senderId"
            :label="$t('emails.sender')"
            prepend-inner-icon="mdi-account-box"
            :items="users"
            item-value="accountId"
            item-text="combinedName"
            :rules="requiredRules"
            outlined
            hide-details
            class="mb-4"
          />

          <v-text-field
            v-model="template.subject"
            :label="$t('emails.subject')"
            prepend-inner-icon="mdi-format-title"
            :rules="requiredRules"
            outlined
            hide-details
            class="mb-4"
          />

          <v-textarea
            v-model="template.content"
            :label="$t('emails.message')"
            prepend-inner-icon="mdi-android-messages"
            outlined
            :rules="requiredRules"
            hide-details
            class="mb-4"
          />

          <v-autocomplete
            v-model="template.documentId"
            :label="$t('emails.attachment')"
            prepend-inner-icon="mdi-attachment"
            :items="documents"
            item-value="documentId"
            item-text="title"
            outlined
            hide-details
            class="mb-4"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="$emit('cancel')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>

          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon left color="primary">
              mdi-pencil
            </v-icon>
            {{ $t('common.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AccountApi from '@/api/Account'
import DocumentApi from '@/api/Documents'
import MessageApi from '@/api/Message'

const defaultTemplate = () => ({
  senderId: null,
  subject: null,
  content: null,
  documentId: null,
})

export default {
  name: 'create-template-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      documents: [],
      formIsValid: true,
      users: [],
      template: defaultTemplate(),
      isLoading: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },

    user () {
      return this.$store.state.user.user
    },
  },

  async mounted () {
    this.isLoading = true

    await this.getAccounts()
    await this.getDocuments()

    this.isLoading = false
  },

  methods: {
    /**
     * Creates a new message-template (if the entered data is valid), resets
     * the internal state afterwards.
     *
     * @returns {void}
     */
    async createTemplate () {
      this.$refs.form.validate()

      if (!this.formIsValid) {
        return
      }

      const res = await MessageApi.addTemplate(this.template)

      if (res.ok) {
        this.$emit('created:template')
        this.template = defaultTemplate
        this.template.senderId = this.user.accountId
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Loads all accounts from the api, combines their names for the usage as
     * labels of our select-options.
     *
     * @returns {void}
     */
    async getAccounts () {
      const res = await AccountApi.listAll()

      if (res.ok) {
        const accounts = await res.json()
        this.users = accounts.map(account => ({
          ...account,
          combinedName: `${account.lastName} ${account.firstName}`
        }))

        this.template.senderId = this.user.accountId
      }
    },

    /**
     * Loads all documents from the api, sorts those.
     *
     * @returns {void}
     */
    async getDocuments () {
      const res = await DocumentApi.list()

      if (res.ok) {
        this.documents = (await res.json()).sort((a, b) => b.date.localeCompare(a.date)) // dates are ISO-strings
      }
    },
  },
}
</script>
