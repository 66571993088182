<template>
  <div class="imprint--wrap running-text--wrap">
    <h1 class="mb-6">
      {{ $t('imprint.heading') }}
    </h1>
    <imprint-de v-if="currentLanguage === 'de-DE'" />
    <imprint-en v-if="currentLanguage === 'en-GB'" />
  </div>
</template>

<script>
import ImprintDe from '@/content/de/Imprint'
import ImprintEn from '@/content/en/Imprint'

export default {
  name: 'imprint-view',

  components: {
    ImprintDe,
    ImprintEn,
  },

  computed: {
    currentLanguage () {
      return this.$i18n.locale
    }
  }
}
</script>
