<template>
  <div class="shareholders--wrap">
    <h1 class="mb-6">
      {{ $t('shareholders.shareholders') }}
    </h1>

    <data-table
      :columns="columns"
      :custom-page-size="25"
      :items="shareholders"
      :page-sizes="[10, 25, 50, 100, -1]"
      :default-options="{ sortBy: ['combinedName'], sortDesc: [false] }"
      :is-loading="isLoading"
      class="mb-8"
    >
      <template #headerActions>
        <date-text-input
          :value="dateToUse | isoDate"
          dense
          :clearable="false"
          :label="$t('common.date')"
          input-class="pa-0 ml-4"
          @input="isoDate => dateToUse = new Date(isoDate)"
        />

        <table-button
          :to="{ name: 'StockDistribution' }"
          :tooltip="$t('sidebar.distribution')"
          is-primary
          icon="mdi-chart-box"
          dark
          class="ml-4"
        />

        <table-button
          v-if="allowCsvExport"
          :href="csvExportUrl"
          target="_blank"
          :tooltip="$t('common.exportCsv')"
          is-primary
          icon="mdi-download"
          dark
          class="ml-4"
        />
      </template>

      <template #[`item.combinedName`]="{ item }">
        <router-link
          :to="{ name: 'Transactions', params: { filter: item.accountId } }"
          class="text-decoration-none"
        >
          {{ item.combinedName }}
        </router-link>
      </template>

      <template #[`item.shares`]="{ item }">
        {{ item.shares | localeNumber($i18n.locale) }}
      </template>

      <template #[`item.sharesPct`]="{ item }">
        {{ item.sharesPct | localeNumber($i18n.locale, true) }}&nbsp;%
      </template>
    </data-table>
  </div>
</template>

<script>
import roles from '@/roles'

import DataTable from '@/components/DataTable'
import DateTextInput from '@/components/DateTextInput'
import ShareApi from '@/api/Share'
import TableButton from '@/components/TableButton'

export default {
  name: 'shareholders-view',

  components: {
    DataTable,
    DateTextInput,
    TableButton,
  },

  data () {
    return {
      shareholders: [],
      isLoading: false,
      dateToUse: new Date()
    }
  },

  computed: {
    allowCsvExport () {
      return this.$store.getters.userHasRole([roles.editor, roles.superadmin])
    },

    columns () {
      return [
        { text: this.$t('users.name'), value: 'combinedName', width: 280 },
        { text: this.$t('users.company'), value: 'companyName', width: 380 },
        { text: this.$t('shareholders.shareAmount'), value: 'shares', align: 'right' },
        { text: this.$t('shareholders.sharesPct'), value: 'sharesPct', align: 'right' },
      ]
    },

    csvExportUrl () {
      return ShareApi.csvExportUrl()
    },
  },

  watch: {
    dateToUse () {
      this.getShareholders()
    }
  },

  mounted () {
    this.getShareholders()
  },

  methods: {
    /**
     * Loads all shareholders from the api.
     *
     * @returns {void}
     */
    async getShareholders () {
      this.isLoading = true

      const res = await ShareApi.getShareholders(this.dateToUse)

      if (res.ok) {
        const shareholders = await res.json()
        this.shareholders = shareholders.map(shareholder => ({
          ...shareholder,
          combinedName: `${shareholder.lastName}, ${shareholder.firstName}`
        }))
      }

      this.isLoading = false
    },
  },
}
</script>
