<template>
  <div class="sell-stocks--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.trading') }}
    </h1>

    <v-tabs show-arrows color="grey darken-4" background-color="transparent" class="mb-6">
      <v-tab :to="{ name: 'CreateOffer' }">
        <v-icon left>
          mdi-bank-transfer-out
        </v-icon>
        {{ $t('sell.createOffer') }}
      </v-tab>

      <v-tab :to="{ name: 'CreateRequest' }">
        <v-icon left>
          mdi-bank-transfer-in
        </v-icon>
        {{ $t('sell.createRequest') }}
      </v-tab>
    </v-tabs>

    <router-view />

    <stock-amount
      height="150"
      :transactions="transactions"
      :is-loading="isLoading"
      class="mt-10"
    />
  </div>
</template>

<script>
import StockAmount from '@/components/charts/StockAmount'
import TransactionApi from '@/api/Transactions'

export default {
  name: 'trading-index',

  components: {
    StockAmount,
  },

  data () {
    return {
      isLoading: false,
      transactions: [],
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  mounted () {
    this.loadTransactions()
    this.$store.dispatch('getConfig')
  },

  methods: {
    async loadTransactions () {
      this.isLoading = true
      this.transactions = await TransactionApi.getMy(this.user.accountId)
      this.isLoading = false
    }
  }
}
</script>
