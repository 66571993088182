<template>
  <div class="contact--wrap">
    <h1 class="mb-6">
      {{ $t('information.searchDocuments') }}
    </h1>

    <div class="mb-9">
      <v-btn
        color="secondary"
        class="mr-2"
        depressed
        @click="dialogVisible = true"
      >
        <v-icon left>
          mdi-filter
        </v-icon>
        {{ $t('document.filter') }}
      </v-btn>

      <v-btn
        v-if="filterActive"
        depressed
        @click="clearFilter"
      >
        <v-icon left>
          mdi-filter-off
        </v-icon>
        {{ $t('document.filterReset') }}
      </v-btn>
    </div>

    <document-table
      :search="search"
      :title="$t('document.allDocuments')"
    />

    <v-bottom-sheet
      :value="dialogVisible"
      hide-overlay
      inset
      @input="visibility => dialogVisible = visibility"
    >
      <document-search-form
        @close="dialogVisible = false"
        @search="onSearch"
      />
    </v-bottom-sheet>
  </div>
</template>

<script>
import DocumentTable from '@/components/DocumentTable'
import DocumentSearchForm from './DocumentSearchForm'

export default {
  name: 'document-search',

  components: {
    DocumentSearchForm,
    DocumentTable,
  },

  data () {
    return {
      dialogVisible: false,
      filterActive: false,
      search: null,
    }
  },

  methods: {
    /**
     * onSearch
     *
     * @param {object} param
     * @param {object} param.search
     * @param {boolean} param.isCustomized
     * @returns {void}
     */
    onSearch ({ search, isCustomized }) {
      this.search = search
      this.filterActive = isCustomized
    },

    clearFilter () {
      this.search = null
      this.filterActive = false
    }
  }
}
</script>
