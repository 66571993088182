import Api from '@/api/Api'

export default {
  baseUrl: 'notification',

  /**
   * getAll
   *
   * @param {object} params
   * @param {string} params.after
   * @param {string} params.before
   * @param {string} params.topic
   * @returns {Promise}
   */
  getAll ({ after = '', before = '', topic = '' }) {
    const params = new URLSearchParams({
      CreatedAfter: after,
      CreatedBefore: before,
      NotificationTopic: topic
    })

    return Api.baseFetch(`${this.baseUrl}?${params}`)
  },

  /**
   * getTopics
   *
   * @returns {Promise}
   */
  getTopics () {
    return Api.baseFetch(`${this.baseUrl}/topics`)
  },

  /**
   * markAsRead
   *
   * @param {number|string} notificationId
   * @returns {Promise}
   */
  markAsRead (notificationId) {
    return Api.baseFetch(`${this.baseUrl}/${notificationId}/mark_as_read`, {
      method: 'POST',
    })
  },

  /**
   * markAllAsRead
   *
   * @returns {Promise}
   */
  markAllAsRead () {
    return Api.baseFetch(`${this.baseUrl}/mark_all_as_read`, {
      method: 'POST',
    })
  },
}
