<template>
  <v-dialog
    :value="isVisible"
    :width="700"
    max-width="90%"
    @input="visibility => !visibility && close()"
  >
    <v-form
      ref="form"
      v-model="formIsValid"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <v-card-title>
          {{ configToEdit ? $t('configuration.edit') : $t('configuration.create') }}
        </v-card-title>

        <v-card-text>
          <v-row dense class="mb-4">
            <v-col :cols="12" :md="4">
              <v-text-field
                :value="configuration.holdingPeriodYears"
                :label="$t('companyData.configuration.holdingPeriodYears')"
                type="number"
                outlined
                hide-details
                class="mb-2"
                step="1"
                :rules="requiredRules"
                @input="years => configuration.holdingPeriodYears = parseInt(years)"
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <date-text-input
                :value="configuration.validFrom | isoDate"
                :label="$t('companyData.configuration.validFrom')"
                :rules="requiredRules"
                :clearable="false"
                @input="isoDate => configuration.validFrom = new Date(isoDate)"
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <time-text-input
                :value="configuration.validFrom"
                :label="$t('companyData.configuration.validFrom')"
                @input="onTimeChange"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed class="ml-auto" @click="close">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>

          <v-btn depressed class="ml-2" type="submit">
            <v-icon left color="primary">
              mdi-pencil
            </v-icon>
            {{ configToEdit ? $t('common.save') : $t('common.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DateTextInput from '@/components/DateTextInput'
import TimeTextInput from '@/components/TimeTextInput'

const defaultConfiguration = () => ({
  holdingPeriodYears: 0,
  validFrom: new Date(),
})

export default {
  name: 'configuration-dialog',

  components: {
    DateTextInput,
    TimeTextInput,
  },

  props: {
    // show the dialog
    isVisible: {
      type: Boolean,
      default: false,
    },

    // use an existing dataset for an update
    configToEdit: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      configuration: defaultConfiguration(),
      formIsValid: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },
  },

  watch: {
    configToEdit () {
      this.useConfig()
    }
  },

  mounted () {
    this.useConfig()
  },

  methods: {
    /**
     * Tells the parent component that the dialog can get closed, resets the
     * form-validation.
     *
     * @returns {undefined}
     */
    close () {
      this.$emit('close')
      this.$refs.form.resetValidation()
    },

    /**
     * Communicates the current configuration to the parent component if the
     * form is valid.
     *
     * @returns {undefined}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('submit', this.configuration)
        this.configuration = defaultConfiguration()
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Uses the config given as a prop or the default for new datasets.
     *
     * @returns {undefined}
     */
    useConfig () {
      this.configuration = this.configToEdit
        ? { ...this.configToEdit, validFrom: new Date(this.configToEdit.validFrom) }
        : defaultConfiguration()
    },

    /**
     * onTimeChange
     *
     * @param {string} date
     * @returns {undefined}
     */
    onTimeChange (date) {
      this.configuration = { ...this.configuration, validFrom: date }
    },
  }
}
</script>
