<template>
  <form
    class="login-form"
    @submit.prevent="resetPasswordMode ? resetPassword() : login()"
  >
    <v-card width="500" max-width="90%" class="mx-auto">
      <v-card-title>
        <v-avatar color="primary" size="35" class="mr-2">
          <span class="white--text">M</span>
        </v-avatar>
        <v-img
          :src="require('@/assets/gfx/mueller_bbm.svg')"
          alt="MBBM Logo"
          max-width="170"
          class="company-logo mb-1"
          contain
        />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <h4 v-if="!resetPasswordMode" class="text-subtitle-2 mb-4">
          {{ $t('login.heading') }}
        </h4>
        <v-text-field
          v-model.trim="username"
          prepend-inner-icon="mdi-face-man"
          hide-details
          outlined
          :label="$t('login.name')"
          class="mb-2"
          name="email"
          persistent-placeholder
          required
          autocomplete="username"
        />
        <v-text-field
          v-if="!resetPasswordMode"
          v-model="password"
          prepend-inner-icon="mdi-lock"
          hide-details
          outlined
          :label="$t('login.password')"
          persistent-placeholder
          required
          name="password"
          type="password"
          autocomplete="current-password"
        />

        <v-alert
          v-model="showPasswordInfo"
          color="success"
          icon="mdi-information"
          prominent
          dark
          dense
          class="mt-4 mb-0"
          dismissible
        >
          {{ $t('login.passwordResetInfo') }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="px-4">
        <v-btn
          v-if="resetPasswordMode"
          text
          @click="resetPasswordMode = false"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('common.back') }}
        </v-btn>
        <v-btn
          v-else
          text
          @click="resetPasswordMode = true"
        >
          <v-icon left>
            mdi-account-question
          </v-icon>
          {{ $t('login.forgotPassword') }}
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="resetPasswordMode"
          :loading="loading"
          type="submit"
          depressed
          light
        >
          <v-icon left color="primary">
            mdi-lock-reset
          </v-icon>
          {{ $t('login.resetPassword') }}
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          type="submit"
          depressed
          light
        >
          <v-icon left color="primary">
            mdi-login
          </v-icon>
          {{ $t('login.login') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'login-form',

  data () {
    return {
      username: '',
      password: '',
      loading: false,
      resetPasswordMode: false,
      showPasswordInfo: false,
    }
  },

  methods: {
    /**
     * Performs a login with the currently entered data.
     *
     * @returns {void}
     */
    async login () {
      this.loading = true
      const res = await AuthApi.login({ email: this.username, password: this.password })
      this.loading = false

      if (!res.ok) {
        return this.$emit('login:failed', res.statusText)
      }

      this.$emit('login:completed')
    },

    /**
     * Requests the api to send an reset-link for the entered user.
     * After that, the login-mode gets activated again with an additional hint.
     *
     * @returns {void}
     */
    async resetPassword () {
      const res = await AuthApi.requestPasswordReset(this.username)

      if (res.ok) {
        this.resetPasswordMode = false
        this.showPasswordInfo = true
        this.$store.commit('setSnackbar', { text: this.$t('login.passwordReset') })
      }
    },
  }
}
</script>
