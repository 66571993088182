<template>
  <div class="notification-list--wrap">
    <v-list v-if="notifications.length" dense rounded>
      <transition-group name="flip-list">
        <template v-for="(notification, i) in notifications">
          <div :key="notification.notificationId">
            <v-list-item
              class="py-1"
              :class="{ read: notification.hasBeenRead }"
            >
              <v-list-item-avatar :color="notification.notificationTopic | notificationColor">
                <v-icon color="white">
                  {{ notification.notificationTopic | notificationIcon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`notifications.topics.${notification.notificationTopic}`) }}</v-list-item-title>
                <v-list-item-subtitle class="message">
                  {{ notification.subject[$i18n.locale] }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text class="mb-1">
                  {{ notification.createdAt | readableIsoDate(true, ' HH:mm') }}
                </v-list-item-action-text>
                <div class="d-flex">
                  <table-button
                    v-if="$options.filters.notificationRoute(notification.notificationTopic)"
                    icon="mdi-eye"
                    :tooltip="$t('common.more')"
                    :to="notification.notificationTopic | notificationRoute"
                  />
                  <table-button
                    v-if="!notification.hasBeenRead"
                    icon="mdi-checkbox-marked-circle-outline"
                    :tooltip="$t('notifications.markAsRead')"
                    @click="$emit('click:read', notification)"
                  />
                </div>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="i < notifications.length - 1"
              inset
            />
          </div>
        </template>
      </transition-group>
    </v-list>

    <v-alert v-else color="secondary" icon="mdi-email-outline" :class="{ 'ma-4': !fullWidthAlert }" dark>
      {{ $t('notifications.noNotifications') }}
    </v-alert>
  </div>
</template>

<script>
import TableButton from './TableButton.vue'

import notificationTypes from '@/assets/data/notification-types'

export default {
  name: 'notification-list',

  components: {
    TableButton,
  },

  filters: {
    notificationColor (notificationType) {
      return notificationTypes.find(({ type }) => type === notificationType)?.color
    },

    notificationIcon (notificationType) {
      return notificationTypes.find(({ type }) => type === notificationType)?.icon
    },

    notificationRoute (notificationType) {
      const routeName = notificationTypes.find(({ type }) => type === notificationType)?.to
      return routeName ? { name: routeName } : null
    },
  },

  props: {
    fullWidthAlert: {
      type: Boolean,
      default: false,
    },

    notifications: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="scss">
  .notification-list--wrap {
    .v-list {
      .v-list-item {
        &.read {
          opacity: 0.75;
        }
      }
    }

    .flip-list-move, .v-list-item, .flip-list-enter-active, .flip-list-leave-active {
      transition: transform 300ms cubic-bezier(0, 0, .2, 1);
    }

    .flip-list-enter, .flip-list-leave-to {
      opacity: 0;
      // transform: translateY(30px);
    }

    .flip-list-leave-active {
      position: absolute;
    }

    .message {
      white-space: pre-line;
    }
  }
</style>
