<template>
  <v-card flat>
    <v-card-title>
      {{ $t('document.filter') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="12" :lg="4">
          <v-text-field
            v-model="search.title"
            :label="$t('common.name')"
            prepend-inner-icon="mdi-format-title"
            hide-details
            outlined
            clearable
            class="mb-2"
          />
          <v-text-field
            v-model="search.description"
            outlined
            class="mb-2"
            hide-details
            clearable
            :label="$t('common.description')"
            prepend-inner-icon="mdi-message-text"
          />
          <v-autocomplete
            v-model.number="search.companyId"
            :items="companies"
            item-text="shortName"
            item-value="companyId"
            :label="$t('common.company')"
            prepend-inner-icon="mdi-domain"
            hide-details
            clearable
            outlined
            class="mb-2"
          />
        </v-col>
        <v-col :cols="12" :lg="4">
          <v-row>
            <v-col>
              <v-select
                v-model.number="search.year"
                :items="years"
                item-text="label"
                item-value="value"
                :label="$t('common.year')"
                prepend-inner-icon="mdi-calendar-search"
                class="mb-2"
                clearable
                hide-details
                outlined
              />
            </v-col>
            <v-col>
              <v-select
                v-model.number="search.quarter"
                :items="quarters"
                :label="$t('common.quarter')"
                prepend-inner-icon="mdi-calendar-search"
                class="mb-2"
                hide-details
                outlined
                clearable
              />
            </v-col>
          </v-row>

          <date-text-input
            v-model="search.date"
            :label="$t('common.uploadDate')"
            clearable
            class="mb-2"
          />

          <v-autocomplete
            v-model="search.documentType"
            :items="types"
            item-text="label"
            item-value="value"
            :label="$t('common.type')"
            prepend-inner-icon="mdi-format-list-bulleted-type"
            hide-details
            clearable
            outlined
            class="mb-2"
          />
        </v-col>
        <v-col :cols="12" :lg="4">
          <v-checkbox
            v-for="area in areas"
            :key="area"
            v-model="search.displayAreas"
            :value="area"
            color="secondary"
            hide-details
            :label="$t(`document.areas.${area}`)"
            class="my-1 mr-4"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer />
      <v-btn
        v-if="searchIsCustomized"
        depressed
        class="mr-2"
        @click="reset"
      >
        <v-icon left>
          mdi-filter-off
        </v-icon>
        {{ $t('document.filterReset') }}
      </v-btn>
      <v-btn color="secondary" depressed @click="$emit('close')">
        <v-icon left>
          mdi-close
        </v-icon>
        {{ $t('common.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CompanyApi from '@/api/Company'
import DateTextInput from '@/components/DateTextInput'
import DocumentApi from '@/api/Documents'

const defaultSearch = () => ({
  title: '',
  description: '',
  date: null,
  documentType: null,
  companyId: null,
  displayAreas: [],
  year: null,
  quarter: null,
})

export default {
  name: 'document-search-form',

  components: {
    DateTextInput,
  },

  data () {
    return {
      areas: [],
      search: defaultSearch(),
      formIsValid: false,
      companies: [],
      types: [],
      years: Array.from(Array(15).keys()).map(v => v + 2008).reverse(),
      quarters: [1, 2, 3, 4],
    }
  },

  computed: {
    searchIsCustomized () {
      return JSON.stringify(this.search) !== JSON.stringify(defaultSearch())
    },
  },

  watch: {
    search: {
      handler () {
        this.$emit('search', {
          search: this.search,
          isCustomized: this.searchIsCustomized,
        })
      },
      deep: true,
    }
  },

  mounted () {
    this.getCompanies()
    this.getDocumentTypes()
    this.getDisplayAreas()
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()
      if (res.ok) {
        const companies = await res.json()
        this.companies = companies.sort((a, b) => a.shortName.localeCompare(b.shortName))
      }
    },

    /**
     * Loads available document-types from the api.
     *
     * @returns {void}
     */
    async getDocumentTypes () {
      const res = await DocumentApi.getDocumentTypes()
      if (res.ok) {
        const types = await res.json()
        this.types = types.sort()
      }
    },

    /**
     * Loads available display-areas from the api.
     *
     * @returns {void}
     */
    async getDisplayAreas () {
      const res = await DocumentApi.getDisplayAreas()
      res.ok && (this.areas = await res.json())
    },

    reset () {
      this.search = defaultSearch()
      this.$emit('reset')
    }
  },
}
</script>
