import Api from '@/api/Api'

export default {
  baseUrl: 'news',

  /**
   * getAll
   *
   * @returns {Promise}
   */
  getAll () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * create
   *
   * @param {object} news
   * @param {string} news.date
   * @param {string} news.description
   * @param {array} news.documentIds
   * @param {array} news.links
   * @param {string} news.subTitle
   * @param {string} news.title
   * @returns {Promise}
   */
  create ({ date, description, documentIds = [], links = [], subTitle, title }) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({
        date,
        description,
        documentIds,
        links,
        subTitle,
        title,
      })
    })
  },

  /**
   * update
   *
   * @param {object} news
   * @param {number} news.newsId
   * @param {string} news.date
   * @param {string} news.description
   * @param {array} news.documentIds
   * @param {array} news.links
   * @param {string} news.subTitle
   * @param {string} news.title
   * @returns {Promise}
   */
  update ({ newsId, date, description, documentIds = [], links = [], subTitle, title }) {
    return Api.baseFetch(`${this.baseUrl}/${newsId}`, {
      method: 'PUT',
      body: JSON.stringify({
        date,
        description,
        documentIds,
        links,
        subTitle,
        title,
      })
    })
  },

  /**
   * delete
   *
   * @param {number} newsId
   * @returns {Promise}
   */
  delete (newsId) {
    return Api.baseFetch(`${this.baseUrl}/${newsId}`, {
      method: 'DELETE',
    })
  },

  /**
   * addImage
   *
   * @param {number} newsId
   * @param {File} file
   * @returns {Promise}
   */
  addImage (newsId, file) {
    return Api.baseFetch(`${this.baseUrl}/${newsId}/image`, {
      method: 'POST',
      body: file,
    })
  },

  /**
   * removeImage
   *
   * @param {number} newsId
   * @param {number} imageId
   * @returns {Promise}
   */
  removeImage (newsId, imageId) {
    return Api.baseFetch(`${this.baseUrl}/${newsId}/image/${imageId}`, {
      method: 'DELETE',
    })
  },

  /**
   * Builds the url for an image-request for the given news-entry if it has
   * an image.
   *
   * @param {object} newsEntry
   * @returns {string|null}
   */
  getImageUrl (newsEntry) {
    return newsEntry.newsImages.length
      ? `${Api.baseUrl}/${this.baseUrl}/${newsEntry.newsId}/image/${newsEntry.newsImages[0]}`
      : null
  }
}
