import Api from '@/api/Api'

export default {
  baseUrl: 'account',

  /**
   * me
   *
   * @returns {Promise}
   */
  me () {
    return Api.baseFetch(`${this.baseUrl}/me`)
  },

  /**
   * updateProfile
   *
   * @param {object} userdata
   * @returns {Promise}
   */
  updateProfile (userdata) {
    return Api.baseFetch(`${this.baseUrl}/me`, {
      method: 'PUT',
      body: JSON.stringify(userdata),
    })
  },

  /**
   * changeEmail
   *
   * @param {string} email
   * @returns {Promise}
   */
  changeEmail (email) {
    return Api.baseFetch(`${this.baseUrl}/me/change_email`, {
      method: 'PATCH',
      body: JSON.stringify({ email }),
    })
  },

  /**
   * changePassword
   *
   * @param {string} oldPassword
   * @param {string} newPassword
   * @returns {Promise}
   */
  changePassword (oldPassword, newPassword) {
    return Api.baseFetch(`${this.baseUrl}/me/change_password`, {
      method: 'PATCH',
      body: JSON.stringify({ oldPassword, newPassword }),
    })
  },

  /**
   * listAll
   *
   * @returns {Promise}
   */
  listAll () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * get
   *
   * @param {number|string} accountId
   * @returns {Promise}
   */
  get (accountId) {
    return Api.baseFetch(`${this.baseUrl}/${accountId}`)
  },

  /**
   * update
   *
   * @param {number|string} accountId
   * @param {object} dataset
   * @returns {Promise}
   */
  update (accountId, dataset) {
    return Api.baseFetch(`${this.baseUrl}/${accountId}`, {
      method: 'PUT',
      body: JSON.stringify(dataset),
    })
  },

  /**
   * create
   *
   * @param {object} user
   * @returns {Promise}
   */
  create (user) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify(user),
    })
  },

  /**
   * impersonate
   *
   * @param {number|string} userId
   * @returns {Promise}
   */
  impersonate (userId) {
    return Api.baseFetch(`${this.baseUrl}/${userId}/impersonate`, {
      method: 'POST',
    })
  },

  /**
   * deimpersonate
   *
   * @returns {Promise}
   */
  deimpersonate () {
    return Api.baseFetch(`${this.baseUrl}/deimpersonate`, {
      method: 'POST',
    })
  },

  /**
   * getStatus
   *
   * @returns {Promise}
   */
  getStatus () {
    return Api.baseFetch(`${this.baseUrl}/account_status`)
  },

  /**
   * getRoles
   *
   * @returns {Promise}
   */
  getRoles () {
    return Api.baseFetch(`${this.baseUrl}/role`)
  },

  /**
   * delete
   *
   * @param {number|string} userId
   * @returns {Promise}
   */
  delete (userId) {
    return Api.baseFetch(`${this.baseUrl}/${userId}`, {
      method: 'DELETE',
    })
  },

  /**
   * Allows editors/admins to reset the 2FA-Code of the user with the given id.
   *
   * @param {number|string} userId
   * @param {string} secondFactorCode
   * @returns {Promise}
   */
  reset2fa (userId, secondFactorCode) {
    return Api.baseFetch(`${this.baseUrl}/${userId}/reset-2fa`, {
      method: 'POST',
      body: JSON.stringify({
        secondFactorCode
      })
    })
  },

  /**
   * getCsvUrl
   *
   * @returns {string}
   */
  getCsvUrl () {
    return `${Api.baseUrl}/${this.baseUrl}/csv`
  },

  /**
   * getDocuments
   *
   * @returns {Promise}
   */
  getDocuments () {
    return Api.baseFetch(`${this.baseUrl}/document`)
  },

  /**
   * getTransactionDocuments
   *
   * @returns {Promise}
   */
  getTransactionDocuments () {
    return Api.baseFetch(`${this.baseUrl}/transaction_document`)
  },

  /**
   * getDocumentDownloadLink
   *
   * @returns {string}
   */
  getDocumentDownloadLink (accountDocumentId) {
    return `${Api.baseUrl}/${this.baseUrl}/document/${accountDocumentId}`
  },
}
