<template>
  <div class="missing-translations--wrap">
    <v-alert type="info" max-width="600" class="mb-10">
      {{ $t('translations.missingInfo') }}
    </v-alert>

    <v-list color="transparent">
      <template v-for="(key, i) in missingKeys">
        <v-list-item :key="i">
          <v-list-item-content>
            {{ key }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn text @click="toCreate = key">
              <v-icon left>
                mdi-pencil
              </v-icon>
              {{ $t('translations.translate') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="i < missingKeys.length - 1" :key="`div_${i}`" />
      </template>
    </v-list>

    <v-alert v-if="!isLoading && !missingKeys.length" type="success" max-width="600">
      {{ $t('translations.noMissingKeys') }}
    </v-alert>

    <v-dialog
      :value="toCreate !== null"
      :width="700"
      max-width="90%"
      @input="visibility => !visibility && (toCreate = null)"
    >
      <v-form v-if="toCreate !== null" @submit.prevent="createTranslation">
        <v-card>
          <v-card-title>
            {{ toCreate }}
          </v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col v-for="language in languageKeys" :key="language" :cols="6">
                <v-text-field
                  v-model="translation[language]"
                  :label="language"
                  outlined
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" depressed color="primary">
              {{ $t('common.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { get, set } from 'lodash'
import TranslationApi from '@/api/Translation'

export default {
  name: 'missing-translations',

  data () {
    return {
      isLoading: false,
      keys: [],
      toCreate: null,
      translation: {},
    }
  },

  computed: {
    languageKeys () {
      return Object.keys(this.translations)
    },

    translations () {
      return this.$i18n.messages
    },

    // keys found within the frontend which don't have an entry within the
    // translation-object
    missingKeys () {
      return this.keys.filter(key =>
        !this.languageKeys.reduce((hasTranslation, language) =>
          hasTranslation || (get(this.translations[language], key) !== undefined)
        , false)
      )
    }
  },

  async mounted () {
    this.isLoading = true
    await this.getTranslationKeys()
    this.isLoading = false
  },

  methods: {
    /**
     * Loads the file containing all translations-keys found within the views.
     *
     * @returns {void}
     */
    async getTranslationKeys () {
      const res = await fetch('/translations.json')
      res.ok && (this.keys = await res.json())
    },

    /**
     * Creates a new translation-entry within the current json-structure,
     * tells our api to persist that.
     *
     * @returns {void}
     */
    async createTranslation () {
      const translations = JSON.parse(JSON.stringify(this.translations)) // don't manipulate object-ref

      Object.keys(this.translation).forEach(key => {
        this.translation[key] && set(translations, `${key}.${this.toCreate}`, this.translation[key])
      })

      await TranslationApi.set(translations)
      this.onTranslationCreated()
    },

    /**
     * Reloads the current translations, injects those into the i18n-plugin.
     *
     * @returns {void}
     */
    async onTranslationCreated () {
      this.toCreate = null
      this.translation = {}

      const res = await TranslationApi.get()

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured') })
      }

      const { data } = await res.json()
      Object.keys(data).forEach(key => this.$i18n.setLocaleMessage(key, data[key]))

      // refresh components so the width of the changed labels gets used
      window.dispatchEvent(new Event('resize'))
      this.$store.commit('setSnackbar', { text: this.$t('common.created') })
    }
  },
}
</script>
