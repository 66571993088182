<template>
  <v-card class="company-distribution-bar--wrap">
    <v-card-title>
      {{ $t('shareholders.stocksPerCompany') }}
      <v-spacer />
      <grouping-inputs v-model="grouping" />
    </v-card-title>

    <v-skeleton-loader
      v-if="loading"
      :height="height"
      type="image"
      tile
    />

    <apex-chart
      v-if="companyAmounts.length && !loading"
      type="bar"
      width="100%"
      :height="500"
      :options="options"
      :series="series"
    />
  </v-card>
</template>

<script>
import chartColors from '@/components/charts/chart-colors'
import GroupingInputs from '@/components/charts/GroupingInputs'
import ShareholderFormatter from '@/services/shareholder-formatter'

export default {
  name: 'company-distribution-bar',

  components: {
    GroupingInputs,
  },

  props: {
    // amount of stocks per company
    companyAmounts: {
      type: Array,
      default: () => ([])
    },

    // height of the chart
    height: {
      type: Number,
      default: 500,
    },

    // show a loading-indicator
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      grouping: {
        active: true,
        limit: 0.05,
      },
    }
  },

  computed: {
    options () {
      return {
        chart: {
          stacked: true,
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '14px',
            colors: ['#000']
          },
          formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale),
        },
        grid: {
          borderColor: '#cfcfcf',
        },
        legend: {
          show: false
        },
        colors: chartColors,
        tooltip: {
          y: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale),
          },
        },
        xaxis: {
          categories: this.groupedAmounts.toShow
            .map(entry => entry.company)
            .concat(this.grouping.active ? this.$t('common.others') : []),
          labels: {
            trim: true,
            rotate: 0,
            hideOverlappingLabels: false,
          }
        },
      }
    },

    /**
     * Users are able to group datasets by a custom limit, so companies below a
     * share are shown as one dataset ('others').
     *
     * @returns {object}
     */
    groupedAmounts () {
      return ShareholderFormatter.groupCompanyAmounts(
        this.companyAmounts,
        this.grouping.active,
        this.grouping.limit
      )
    },

    /**
     * Datasets to visualize: Amount of shares per company + grouped datasets.
     *
     * @see groupedAmounts
     * @returns {array}
     */
    series () {
      return [
        {
          name: this.$t('shareholders.shareAmount'),
          data: this.groupedAmounts.toShow
            .map(entry => entry.stockAmount)
            .concat(this.grouping.active
              ? this.groupedAmounts.toHide.reduce((total, entry) => total + entry.stockAmount, 0)
              : []
            )
        },
      ]
    },
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style lang="scss" scoped>
  .v-card {
    overflow: hidden;
  }
</style>
