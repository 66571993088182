<template>
  <div class="read-all-notifications-btn--wrap">
    <v-btn
      elevation="0"
      color="primary"
      :small="small"
      @click="$store.dispatch('notification/markAllAsRead')"
    >
      {{ $t('notifications.markAllAsRead') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'read-all-notifications-btn',

  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
