export default {
  minimumLimit: 0.01,

  /**
   * Groups shareholders by the value of a key (e.g 'accountStatus' or
   * 'companyName').
   *
   * @param {array} shareholders
   * @returns {array}
   */
  groupShareholders (shareholders, key) {
    return shareholders.reduce((groups, shareholder) => {
      groups[shareholder[key]] = groups[shareholder[key]]
        ? [...groups[shareholder[key]], shareholder]
        : groups[shareholder[key]] = [shareholder]

      return groups
    }, {})
  },

  /**
   * Users are able to group datasets by a custom limit, so companies below a
   * share are shown as one dataset ('others').
   *
   * @param {array} companyAmounts amount of stocks per company
   * @param {boolean} groupingActive should datasets get grouped?
   * @param {number} groupingActive group data under share (1 = 100%)
   * @returns {object}
   */
  groupCompanyAmounts (companyAmounts, groupingActive, limit) {
    const restrictedLimit = limit < this.minimumLimit ? this.minimumLimit : limit

    return groupingActive
      ? {
          toShow: companyAmounts.filter(amountEntry => amountEntry.percentage >= restrictedLimit),
          toHide: companyAmounts.filter(amountEntry => amountEntry.percentage <= restrictedLimit)
        }
      : {
          toShow: companyAmounts,
          toHide: []
        }
  },

  /**
   * Amount of stocks by company based on the given shareholders.
   *
   * @param {array} shareholders
   * @returns {array}
   */
  getCompanyAmounts (shareholders = []) {
    const byCompany = this.groupShareholders(shareholders, 'companyShortName')
    const totalStockAmount = shareholders.reduce((amount, shareholder) => amount + shareholder.shares, 0)

    return Object.keys(byCompany).map(company => {
      const stockAmount = byCompany[company].reduce((amount, shareholder) => amount + shareholder.shares, 0)
      return {
        company,
        companyName: shareholders.find(({ companyShortName }) => company === companyShortName)?.companyName || '-',
        percentage: stockAmount / totalStockAmount,
        stockAmount,
      }
    }).sort((a, b) => a.company.localeCompare(b.company))
  },
}
