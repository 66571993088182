var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"backdrop",attrs:{"elevate-on-scroll":"","elevation":1,"height":80}},[(_vm.user)?_c('v-btn',{staticClass:"ml-0 mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('sidebar:toggle')}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),(!_vm.sidebarVisible)?_c('v-sheet',{staticClass:"mx-2 px-2 pt-2 pb-1",attrs:{"color":"primary","rounded":""}},[_c('img',{staticClass:"logo-mobile",attrs:{"src":require('@/assets/gfx/mueller_bbm.svg'),"alt":"MBBM Logo","contain":""}}),_c('span',{staticClass:"text-caption white--text"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.portal'))+" ")])]):_vm._e(),(_vm.user)?_c('div',{staticClass:"d-flex align-center ml-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('notification-menu')],1)]}}],null,false,1713428836)},[_c('span',[_vm._v(_vm._s(_vm.$t('topnav.notifications')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('contact-dialog')],1)]}}],null,false,2651403474)},[_c('span',[_vm._v(_vm._s(_vm.$t('topnav.contact')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('language-menu',{staticClass:"mx-2"})],1)]}}],null,false,2092702071)},[_c('span',[_vm._v(_vm._s(_vm.$t('topnav.switchLanguage')))])]),(_vm.impersonated)?_c('impersonate-hint',{staticClass:"ml-2"}):_vm._e(),_c('div',{staticClass:"d-none d-md-flex ml-2",style:({ maxWidth: '270px' })},[_c('user-menu')],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }