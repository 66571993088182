<template>
  <div class="management-information--wrap">
    <h1 class="mb-1">
      {{ $t('information.management') }}
    </h1>

    <h2 class="text-h6 mb-6">
      {{ $t('information.managementSubtitle') }}
    </h2>

    <v-list color="transparent">
      <template v-for="(entry, i) in entries">
        <v-list-item :key="i">
          <v-list-item-avatar size="70">
            <v-img :src="entry.image" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2 mb-1">
              {{ entry.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t(`management.positions.${entry.position}`) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="i < entries.length - 1" :key="`d_${i}`" inset />
      </template>
    </v-list>
  </div>
</template>

<script>
import ManagementEntries from '@/assets/data/management'

export default {
  name: 'management-information',

  data () {
    return {
      entries: ManagementEntries,
    }
  }
}
</script>
