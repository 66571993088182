<template>
  <div class="meetings--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.meetings') }}
    </h1>

    <v-tabs show-arrows color="grey darken-4" background-color="transparent" class="mb-10">
      <v-tab :to="{ name: 'AnnualMeetings' }">
        <v-icon left>
          mdi-domain
        </v-icon>
        {{ $t('meetings.annualMeetings') }}
      </v-tab>

      <v-tab :to="{ name: 'InformationMeetings' }">
        <v-icon left>
          mdi-information
        </v-icon>
        {{ $t('meetings.informationMeetings') }}
      </v-tab>
    </v-tabs>

    <div class="meetings--content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'meetings-index',
}
</script>
