<template>
  <v-dialog
    v-if="user"
    v-model="dialogVisible"
    width="800"
    max-width="90%"
  >
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-email-plus-outline</v-icon>
      </v-btn>
    </template>

    <v-form ref="form" v-model="formIsValid" @submit.prevent="sendContactForm">
      <v-card>
        <v-card-title>
          {{ $t('contact.header') }}
        </v-card-title>

        <v-card-subtitle>
          {{ $t('contact.subheader') }}
        </v-card-subtitle>

        <v-card-text>
          <v-text-field
            :value="`${user.lastName}, ${user.firstName}`"
            :label="$t('common.name')"
            prepend-inner-icon="mdi-account"
            disabled
            outlined
            required
            hide-details
            class="mb-4"
          />

          <v-text-field
            :value="user.email"
            :label="$t('common.mail')"
            prepend-inner-icon="mdi-at"
            disabled
            outlined
            required
          />

          <v-select
            v-model="subject"
            :items="subjects"
            :label="$t('contact.subject')"
            :rules="requiredRules"
            prepend-inner-icon="mdi-format-title"
            outlined
            hide-details
            class="mb-4"
          />

          <v-textarea
            v-model="messageText"
            outlined
            required
            counter
            minlength="30"
            :rules="requiredRules"
            :label="$t('contact.body')"
            prepend-inner-icon="mdi-message-text"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn depressed @click="dialogVisible = false">
            <v-icon left>
              mdi-close
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>
          <v-spacer />
          <v-btn type="submit" color="primary" depressed>
            <v-icon left>
              mdi-email-send-outline
            </v-icon>
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Api from '@/api/Api'

export default {
  name: 'contact-dialog',

  data () {
    return {
      subject: null,
      messageText: '',
      formIsValid: false,
      dialogVisible: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },

    subjects () {
      return [
        {
          value: 'WebProblem',
          text: this.$t('contact.subject.webProblem'),
        },
        {
          value: 'WebLogin',
          text: this.$t('contact.subject.webLogin'),
        },
        {
          value: 'ProfileUpdate',
          text: this.$t('contact.subject.profileUpdate'),
        },
        {
          value: 'ShareTransErr',
          text: this.$t('contact.subject.shareTransErr'),
        },
        {
          value: 'Other',
          text: this.$t('contact.subject.other'),
        }
      ]
    },

    user () {
      return this.$store.state.user.user
    }
  },

  watch: {
    dialogVisible () {
      // reset form when closing the dialog
      !this.dialogVisible && this.reset()
    },
  },

  methods: {
    /**
     * Sends the entered contact-request (the sender is automatically detected
     * by our api). If that's successful, the internal state gets reset.
     *
     * @returns {void}
     */
    async sendContactForm () {
      this.$refs.form.validate()

      if (!this.formIsValid) {
        return
      }

      const res = await Api.baseFetch('contactForm', {
        method: 'POST',
        body: JSON.stringify({ subject: this.subject, messageText: this.messageText })
      })

      if (res.ok) {
        this.reset()
        this.dialogVisible = false
        return this.$store.commit('setSnackbar', { text: this.$t('contact.messageSent') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured') })
    },

    /**
     * Resets the internal state, the form-validation.
     *
     * @returns {void}
     */
    reset () {
      this.subject = null
      this.messageText = ''
      this.$refs.form.resetValidation()
    },
  },
}
</script>
