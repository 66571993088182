import Api from '@/api/Api'

export default {
  baseUrl: '2fa',

  /**
   * enable
   *
   * @returns {Promise}
   */
  enable () {
    return Api.baseFetch(`${this.baseUrl}/enable`, {
      method: 'POST',
    })
  },

  /**
   * disable
   *
   * @param {string} code
   * @returns {Promise}
   */
  disable (code = '') {
    return Api.baseFetch(`${this.baseUrl}/disable`, {
      method: 'POST',
      body: JSON.stringify({ code }),
    })
  },

  /**
   * confirmSecret
   *
   * @param {string} secret
   * @param {string} code
   * @returns {Promise}
   */
  confirmSecret (secret, code) {
    return Api.baseFetch(`${this.baseUrl}/enable/confirm`, {
      method: 'POST',
      body: JSON.stringify({ secret, code }),
    })
  },

  /**
   * authenticate
   *
   * @param {string} code
   * @returns {Promise}
   */
  authenticate (code) {
    return Api.baseFetch(`${this.baseUrl}/authenticate`, {
      method: 'POST',
      body: JSON.stringify({ code }),
    })
  },
}
