<template>
  <div class="user-profile--wrap">
    <h1 class="mb-6">
      {{ user.lastName }}, {{ user.firstName }}
    </h1>

    <v-row align="stretch" class="mb-6">
      <v-col cols="12" lg="4">
        <stock-amount :height="150" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
      <v-col cols="12" lg="4">
        <revenue-history :height="150" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
      <v-col cols="12" lg="4">
        <transaction-history :height="150" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
    </v-row>

    <v-tabs
      v-model="activeTab"
      show-arrows
      color="grey darken-4"
      background-color="transparent"
      class="d-inline-block mb-8"
    >
      <v-tab>
        <v-icon left>
          mdi-pencil
        </v-icon>
        {{ $t('userNav.editProfile') }}
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-lock
        </v-icon>
        {{ $t('userNav.changePassword') }}
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-two-factor-authentication
        </v-icon>
        {{ $t('login.twoFactorAuth') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" class="transparent">
      <v-tab-item>
        <user-form :user-to-edit="user" @submit="updateProfile" />
      </v-tab-item>
      <v-tab-item>
        <change-password-form flat />
      </v-tab-item>
      <v-tab-item>
        <two-factor-auth />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'
import ChangePasswordForm from '@/components/ChangePasswordForm'
import LanguageSwitch from '@/mixins/LanguageSwitch'
import RevenueHistory from '@/components/charts/RevenueHistory'
import StockAmount from '@/components/charts/StockAmount'
import TransactionApi from '@/api/Transactions'
import TransactionHistory from '@/components/charts/TransactionHistory'
import TwoFactorAuth from './TwoFactorAuth'
import UserForm from '@/components/UserForm'

export default {
  name: 'user-profile',

  components: {
    ChangePasswordForm,
    RevenueHistory,
    StockAmount,
    TransactionHistory,
    TwoFactorAuth,
    UserForm,
  },

  mixins: [
    LanguageSwitch,
  ],

  data () {
    return {
      activeTab: null,
      isLoading: false,
      transactions: [],
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  async mounted () {
    this.loadUserTransactions()
  },

  methods: {
    /**
     * Loads the transactions of the current user.
     *
     * @returns {void}
     */
    async loadUserTransactions () {
      this.isLoading = true
      this.transactions = await TransactionApi.getMy(this.user.accountId)
      this.isLoading = false
    },

    /**
     * Updates the current user.
     *
     * @returns {void}
     */
    async updateProfile (user) {
      const res = await AccountApi.updateProfile(user)
      res.ok && this.$store.commit('setSnackbar', { text: this.$t('common.updated') })

      const userRes = await AccountApi.me()

      if (userRes.ok) {
        const updatedUser = await userRes.json()
        this.setLanguage(updatedUser.language, false)
        this.$store.commit('setUser', updatedUser)
      }
    },
  }
}
</script>
