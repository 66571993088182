import NotificationApi from '@/api/Notification'

export default {
  namespaced: true,

  state: {
    notifications: []
  },

  getters: {
    unreadNotifications: state => {
      return state.notifications.filter(notification => !notification.hasBeenRead)
    },
  },

  mutations: {
    setNotifications (state, notifications = []) {
      state.notifications = notifications.sort((a, b) => b.createdAt.localeCompare(a.createdAt)) // dates are ISO-strings
    },
  },

  actions: {
    async get ({ commit }, params = {}) {
      const res = await NotificationApi.getAll(params)
      res.ok && commit('setNotifications', await res.json())
    },

    async markAsRead ({ dispatch }, notificationId) {
      const res = await NotificationApi.markAsRead(notificationId)
      res.ok && dispatch('get')
    },

    async markAllAsRead ({ dispatch }) {
      const res = await NotificationApi.markAllAsRead()
      res.ok && dispatch('get')
    },
  },
}
