<template>
  <div class="offer-table--wrap">
    <data-table
      :columns="columns"
      :is-loading="isLoading"
      :items="validOffersWithFormulaPrice"
      :title="$t('sell.allOffers')"
      :default-options="dataTableOptions"
    >
      <template #[`item.expires`]="{ item }">
        {{ item.expires | readableIsoDate(true) }}
      </template>

      <template #[`item.fullName`]="{ item }">
        <icon-buy-sell :type="type" />
        {{ item.fullName || $t('sell.anonymousUser') }}
      </template>

      <template #[`item.price`]="{ item }">
        {{ item.price | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.formulaPrice`]="{ item }">
        {{ item.formulaPrice | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.holdingAmount`]="{ item }">
        {{ type === 'buy' ? item.effectiveAmount - item.amount : item.amount - item.effectiveAmount }}
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-cart"
          icon-color="primary"
          :disabled="isUserOffer(item)"
          :tooltip="isUserOffer(item) ? $t('salesRequests.ownOffer') : $t('salesRequests.acceptOffer')"
          @click="$emit('click:offer', item)"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import TableButton from '@/components/TableButton'

export default {
  name: 'offer-table',

  components: {
    DataTable,
    IconBuySell,
    TableButton,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    offers: {
      type: Array,
      default: () => ([])
    },

    type: {
      type: String,
      default: 'buy',
      validator: type => ['buy', 'sell'].includes(type)
    },

    dataTableOptions: {
      type: Object,
      default: null,
    },
  },

  computed: {
    columns () {
      const isBuy = this.type === 'buy'
      const sellOnlyColumns = isBuy
        ? []
        : [
            { text: this.$t('sell.holdingAmount'), value: 'holdingAmount', align: 'right', width: 180 },
            { text: this.$t('sell.formulaPrice'), value: 'formulaPrice', align: 'right', width: 180 },
          ]

      return [
        { text: this.$t('sell.activeUntil'), value: 'expires', width: 180 },
        { text: isBuy ? this.$t('sell.buyer') : this.$t('sell.seller'), value: 'fullName', width: 300 },
        { text: this.$t('sell.remaining'), value: isBuy ? 'amount' : 'effectiveAmount', align: 'right', width: 180 },
        { text: this.$t('common.price'), value: 'price', align: 'right', width: 180 },
        ...sellOnlyColumns,
        { text: this.$t('common.actions'), value: 'actions', width: 100, sortable: false, align: 'right' },
      ]
    },

    formulaPrice () {
      return this.$store.state.configuration.formulaPrice
    },

    user () {
      return this.$store.state.user.user
    },

    // we only want to use active offers with the correct type, availabe shares
    // and want to display the current formula price
    validOffersWithFormulaPrice () {
      return this.offers
        .filter(({ offerType, effectiveAmount, expires }) =>
          offerType === this.type && effectiveAmount > 0 && new Date(expires) > new Date()
        )
        .map(offer => ({ ...offer, formulaPrice: this.formulaPrice }))
    },
  },

  methods: {
    /**
     * Checks if the given offer was created by the current user.
     *
     * @param {object} offer
     * @returns {boolean}
     */
    isUserOffer (offer) {
      return offer.accountId === this.user.accountId
    }
  }
}
</script>
