<template>
  <div class="password-strength--wrap">
    <v-list dense color="transparent" class="pa-0 mb-4">
      <v-list-item v-for="(rule, i) in rules" :key="i">
        <v-list-item-icon>
          <v-icon :color="rule.check(password) ? 'success' : 'grey darken-3'">
            {{ rule.check(password) ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <span :class="{ 'font-weight-bold': rule.required }">
            {{ rule.message }}
          </span>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-progress-linear
      :value="strength * 100"
      :color="colorForStrength"
      rounded
    />
  </div>
</template>

<script>
export default {
  name: 'password-strength',

  props: {
    password: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      minimumOptionalRules: 3,
    }
  },

  computed: {
    allRequiredRulesFit () {
      return !this.rules.filter(rule => rule.required && !rule.check(this.password)).length
    },

    enoughOptionalRulesFit () {
      return this.rules.filter(rule => !rule.required && rule.check(this.password)).length >= this.minimumOptionalRules
    },

    rules () {
      return [
        { message: this.$t('login.ruleMinChars'), check: v => (v && v.length >= 8), required: true },
        { message: this.$t('login.ruleNumber'), check: v => /[\p{N}]+/gu.test(v) },
        { message: this.$t('login.ruleUppercase'), check: v => /[\p{Lu}]+/gu.test(v) },
        { message: this.$t('login.ruleLowercase'), check: v => /[\p{Ll}]+/gu.test(v) },
        { message: this.$t('login.ruleSpecialChar', { chars: '!@#$%' }), check: v => /([!@#$%])/.test(v) },
      ]
    },

    strength () {
      const matches = this.rules.filter(rule => rule.check(this.password)).length
      return matches / this.rules.length
    },

    colorForStrength () {
      if (!this.allRequiredRulesFit) {
        return 'error'
      }

      if (this.strength > 0.8) return 'green'
      if (this.strength > 0.6) return 'light-green'
      if (this.strength > 0.4) return 'orange'
      if (this.strength > 0.2) return 'deep-orange'

      return 'error'
    },
  },

  watch: {
    password () {
      this.$emit('check:password', {
        isValid: this.allRequiredRulesFit && this.enoughOptionalRulesFit,
        strength: this.strength,
      })
    },
  },
}
</script>
