import Api from '@/api/Api'

export default {
  baseUrl: 'pending_transaction',

  /**
   * getAll
   *
   * @param {boolean} noSessionUpdate If set, the session won't get extended
   * @returns {Promise}
   */
  getAll (noSessionUpdate = false) {
    return Api.baseFetch(this.baseUrl, {
      headers: { 'No-Session-Update': noSessionUpdate },
    })
  },

  /**
   * getMy
   *
   * @returns {Promise}
   */
  getMy () {
    return Api.baseFetch(`${this.baseUrl}/me`)
  },

  /**
   * confirm
   *
   * @param {number} transactionId
   * @param {number} check 1/2
   * @param {string} type set/clear
   * @returns {Promise}
   */
  confirm (transactionId, check, type) {
    return Api.baseFetch(`${this.baseUrl}/${transactionId}/check${check}/${type}`, {
      method: 'POST',
    })
  },

  /**
   * create
   *
   * @param {object} transaction
   * @param {number} transaction.amount
   * @param {number} transaction.buyerAccountId
   * @param {number} transaction.price
   * @param {number} transaction.sellerAccountId
   * @returns {Promise}
   */
  create ({ amount, buyerAccountId, price, sellerAccountId }) {
    return Api.baseFetch(`${this.baseUrl}/create`, {
      method: 'POST',
      body: JSON.stringify({
        amount,
        buyerAccountId,
        price,
        sellerAccountId,
      })
    })
  },

  /**
   * createBonusExtra
   *
   * @param {object} transaction
   * @param {number} transaction.amount
   * @param {number} transaction.buyerAccountId
   * @param {string} transaction.transactionType
   * @returns {Promise}
   */
  createBonusExtra ({ amount, buyerAccountId, transactionType }) {
    return Api.baseFetch(`${this.baseUrl}/create_bonus_extra`, {
      method: 'POST',
      body: JSON.stringify({
        amount,
        buyerAccountId,
        transactionType,
      })
    })
  },

  /**
   * delete
   *
   * @param {number|string} transactionId
   * @returns {Promise}
   */
  delete (transactionId) {
    return Api.baseFetch(`${this.baseUrl}/${transactionId}`, {
      method: 'DELETE',
    })
  },

  /**
   * accept
   *
   * @param {number} transactionId
   * @param {number} amount
   * @returns {Promise}
   */
  accept (transactionId) {
    return Api.baseFetch(`${this.baseUrl}/${transactionId}/accept`, {
      method: 'POST',
    })
  },

  /**
   * acceptAll
   *
   * @returns {Promise}
   */
  acceptAll () {
    return Api.baseFetch(`${this.baseUrl}/accept`, {
      method: 'POST',
    })
  },
}
