<template>
  <div class="information-meetings--wrap">
    <meeting-hint class="mb-5" width="760" />

    <document-table
      :search="{ displayAreas: ['info_meeting'] }"
      :title="$t('meetings.filesToDownload')"
    />
  </div>
</template>

<script>
import DocumentTable from '@/components/DocumentTable'
import MeetingHint from '@/views/Meetings/MeetingHint'

export default {
  name: 'information-meetings',

  components: {
    DocumentTable,
    MeetingHint,
  },
}
</script>
