<template>
  <div class="user-transactions--wrap">
    <h1 class="mb-6">
      {{ $t('transactions.myTransactions') }}
    </h1>

    <v-row v-if="!hideNonTransactionAreas" class="mb-4">
      <v-col :cols="12" :lg="4">
        <transaction-history :height="200" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
      <v-col :cols="12" :lg="4">
        <revenue-history :height="200" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
      <v-col :cols="12" :lg="4">
        <stock-amount :height="200" :transactions="transactions" :is-loading="isLoading" />
      </v-col>
    </v-row>

    <v-alert
      v-if="!isLoading && !transactions.length && !hideNonTransactionAreas"
      color="secondary"
      dark
      class="d-inline-block mb-10"
      icon="mdi-information-outline"
    >
      <div class="d-flex align-center flex-wrap">
        <div>
          {{ $t('transactions.noTransactions') }}
        </div>
        <v-divider vertical class="mx-4" />
        <div>
          <v-btn small depressed class="mr-2" light :to="{ name: 'SalesRequests' }">
            {{ $t('sidebar.saleRequests') }}
          </v-btn>
          <v-btn small depressed light :to="{ name: 'OfferCreation' }">
            {{ $t('sidebar.trading') }}
          </v-btn>
        </div>
      </div>
    </v-alert>

    <pending-transactions v-if="!hideNonTransactionAreas" />

    <transaction-table
      :title="$t('transactions.completedTransactions')"
      :transactions="transactions"
      compact
      class="mb-10"
    />
  </div>
</template>

<script>
import roles from '@/roles'

import PendingTransactions from './PendingTransactions'
import RevenueHistory from '@/components/charts/RevenueHistory'
import StockAmount from '@/components/charts/StockAmount'
import TransactionApi from '@/api/Transactions'
import TransactionHistory from '@/components/charts/TransactionHistory'
import TransactionTable from '@/components/TransactionTable'

export default {
  name: 'user-transactions',

  components: {
    PendingTransactions,
    RevenueHistory,
    StockAmount,
    TransactionHistory,
    TransactionTable,
  },

  data () {
    return {
      isLoading: false,
      transactions: [],
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    hideNonTransactionAreas () {
      return this.$store.getters.userHasRole([roles.ehemaliger])
    },
  },

  async mounted () {
    this.isLoading = true
    this.transactions = await TransactionApi.getMy(this.user.accountId)
    this.isLoading = false
  },
}
</script>
