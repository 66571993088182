<template>
  <div class="message-table--wrap">
    <data-table
      :columns="columns"
      :items="messages"
      :title="title"
      :default-options="{ sortBy: ['receivedAt'], sortDesc: [true] }"
      :is-loading="isLoading"
      :custom-page-size="25"
      class="mb-8"
    >
      <template #[`item.subject`]="{ item }">
        <a
          href="#"
          class="text-decoration-none"
          :class="{ 'font-weight-medium': !item.hasBeenRead }"
          @click.prevent="$emit('show:message', item)"
        >
          {{ item.subject }}
        </a>
      </template>

      <template #[`item.receivedAt`]="{ item }">
        {{ item.receivedAt | readableIsoDate(true) }}
      </template>

      <template #[`item.hasBeenRead`]="{ item }">
        <icon-mail-unread :read="item.hasBeenRead" />
      </template>

      <template #[`item.documentId`]="{ item }">
        <div v-if="item.document">
          <a
            :href="getDownloadLink(item.document.documentId)"
            target="_blank"
            class="text-decoration-none"
          >
            {{ item.document.title }}
          </a>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <slot name="actions" :item="item" />
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import DocumentApi from '@/api/Documents'
import IconMailUnread from '@/components/Icons/IconMailUnread'

export default {
  name: 'message-table',

  components: {
    DataTable,
    IconMailUnread,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    messages: {
      type: Array,
      default: () => ([])
    },

    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    columns () {
      return [
        { text: this.$t('emails.wasRead'), value: 'hasBeenRead', align: 'center', width: 100 },
        { text: this.$t('emails.subject'), value: 'subject' },
        { text: this.$t('emails.sentAt'), value: 'receivedAt', width: 180 },
        { text: this.$t('emails.attachments'), value: 'documentId', align: 'right' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '140px', align: 'right' },
      ]
    },
  },

  methods: {
    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },
  }
}
</script>
