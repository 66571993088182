<template>
  <div class="table-button--wrap ml-1">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div class="d-inline-block" v-on="on">
          <v-btn
            :class="isPrimary ? color : ''"
            :small="!isPrimary"
            :dark="dark"
            :disabled="disabled"
            icon
            :href="href || null"
            :target="target"
            :to="to || null"
            v-bind="attrs"
            @click.stop="$emit('click')"
          >
            <v-icon v-if="icon" :small="!isPrimary" :color="iconColor">
              {{ icon }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'table-button',

  props: {
    color: {
      type: String,
      default: 'secondary',
    },

    dark: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: '',
      required: true,
    },

    iconColor: {
      type: String,
      default: null,
    },

    isPrimary: {
      type: Boolean,
      default: false,
    },

    target: {
      type: String,
      default: null,
    },

    to: {
      type: Object,
      default: null,
    },

    tooltip: {
      type: String,
      default: '',
      required: true,
    }
  },
}
</script>

<style lang="scss">
  .table-button--wrap {
    display: inline-flex;
  }
</style>
