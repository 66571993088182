<template>
  <v-menu offset-y transition="scale-transition" nudge-bottom="10">
    <template #activator="{ on, attrs }">
      <v-btn class="body-2 text-capitalize ml-2" text v-bind="attrs" v-on="on">
        <v-avatar size="20px">
          <v-img :src="activeLanguage.icon" />
        </v-avatar>
        <span class="d-none d-md-inline-block ml-2">
          {{ activeLanguage.label }}
        </span>
      </v-btn>
    </template>
    <v-list dense class="navLanguage">
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        :class="{ 'active': activeLanguage.key === language.key }"
        @click="setLanguage(language.key)"
      >
        <v-avatar size="20px" class="mr-2">
          <v-img :src="language.icon" />
        </v-avatar>
        <v-list-item-title class="body-2 font-weight-regular">
          {{ language.label }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import LanguageSwitch from '@/mixins/LanguageSwitch'

export default {
  name: 'language-menu',

  mixins: [
    LanguageSwitch,
  ],

  computed: {
    activeLanguage () {
      return this.languages.find(language => language.key === this.$i18n.locale)
    }
  },
}
</script>

<style lang="scss">
  .navLanguage {

    .v-list-item {
      filter: grayscale(88%);
      transition: all 256ms ease-in-out;
      // use gpu
      will-change: transform;

      &.active,
      &:hover {
        filter: grayscale(0%)
      }
    }
  }
</style>
