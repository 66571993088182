var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-overview--wrap"},[_c('data-table',{attrs:{"columns":_vm.columns,"items":_vm.documents,"default-options":{ sortBy: ['date'], sortDesc: [true] },"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('table-button',{attrs:{"icon":"mdi-playlist-plus","dark":"","tooltip":_vm.$t('common.newEntry'),"is-primary":""},on:{"click":_vm.showCreateDialog}})]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.date))+" ")]}},{key:"item.companyId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyLabel(item.companyId))+" ")]}},{key:"item.documentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("document.type." + (item.documentType))))+" ")]}},{key:"item.displayAreas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDisplayAreas(item.displayAreas))+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-download","tooltip":_vm.$t('common.download'),"href":_vm.getDownloadLink(item.documentId),"target":"_blank"}})]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.getLanguageIconByKey(item.language),"max-width":"20","height":"15"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-pencil","icon-color":"primary","tooltip":_vm.$t('common.edit')},on:{"click":function($event){return _vm.showEditDialog(item)}}})]}}],null,true)}),_c('document-dialog',{attrs:{"is-visible":_vm.dialogVisible,"document":_vm.toEdit},on:{"close":_vm.onClose,"request-reload":_vm.getDocuments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }