<template>
  <div class="phantoms-list--wrap">
    <data-table
      :columns="columns"
      :custom-page-size="25"
      :items="filteredTransactions"
      :title="`${$t('phantoms.total')}: ${sumStockAmount}`"
      :default-options="{ sortBy: ['datePerformed'], sortDesc: [true] }"
      class="tablePhantomTransactions"
    >
      <template #headerActions>
        <table-button
          v-if="accountId"
          icon="mdi-filter-variant-minus"
          color="amber"
          :tooltip="$t('common.filter.reset')"
          is-primary
          :to="{ name: 'PhantomsTransactions' }"
        />
        <table-button
          v-if="withActions"
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('phantoms.transactions.newEntry')"
          is-primary
          @click="showDialog = true"
        />
      </template>

      <template #[`item.datePerformed`]="{ item }">
        {{ item.datePerformed | readableIsoDate }}
      </template>

      <template #[`item.type`]="{ item }">
        <transaction-type-chip :transaction-type="item.amount > 0 ? 'buy' : 'sell'" />
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount | localeNumber($i18n.locale) }}
      </template>

      <template #[`item.fullName`]="{ item }">
        <router-link :to="{ name: 'PhantomsTransactions', params: { id: item.accountId } }" class="text-decoration-none">
          {{ item.fullName }}
        </router-link>
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-trash-can-outline"
          :tooltip="$t('common.delete')"
          small
          icon-color="red"
          @click="toRemove = item"
        />
        <table-button
          icon="mdi-pencil"
          :tooltip="$t('common.edit')"
          small
          icon-color="primary"
          @click="editTransactionEntry(item)"
        />
      </template>
    </data-table>

    <transaction-dialog
      v-if="withActions"
      :is-visible="showDialog"
      :to-edit="toEdit"
      @cancel="onCloseDialog"
      @submit="onSubmitTransaction"
    />

    <confirm-dialog
      :headline="$t('common.confirmDeletion')"
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeEntry"
    />
  </div>
</template>

<script>
import roles from '@/roles'

import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import PhantomApi from '@/api/Phantom'
import TableButton from '@/components/TableButton'
import TransactionDialog from '@/views/Stocks/Phantoms/TransactionDialog'
import TransactionTypeChip from '@/components/TransactionTypeChip'

export default {
  name: 'phantoms-transactions',

  components: {
    ConfirmDialog,
    DataTable,
    TableButton,
    TransactionDialog,
    TransactionTypeChip,
  },

  data () {
    return {
      phantomTransactions: [],
      showDialog: false,
      toEdit: null,
      toRemove: null,
    }
  },

  computed: {
    columns () {
      const columns = [
        { text: this.$t('common.date'), value: 'datePerformed', width: 120 },
        { text: this.$t('common.type'), value: 'type', sortable: false, width: 120 },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 100 },
        { text: this.$t('common.owner'), value: 'fullName' },
      ]

      return this.withActions
        ? [...columns,
            { text: this.$t('common.actions'), value: 'actions', align: 'right', sortable: false },
          ]
        : columns
    },

    sumStockAmount () {
      const amount = this.filteredTransactions.reduce((sum, entry) => sum + entry.amount, 0)
      return this.$options.filters.localeNumber(amount, this.$i18n.locale)
    },

    accountId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    filteredTransactions () {
      return this.accountId
        ? this.phantomTransactions.filter(transaction => transaction.accountId === this.accountId)
        : this.phantomTransactions
    },

    // adds the possibility to add/delete/edit phantom-transactions
    withActions () {
      return this.$store.getters.userHasRole([roles.editor, roles.superadmin])
    },
  },

  mounted () {
    this.getPhantomTransactions()
  },

  methods: {
    /**
     * Loads all phantom-transactions.
     *
     * @param {object} transaction
     * @returns {void}
     */
    async getPhantomTransactions () {
      const res = await PhantomApi.getTransactions()
      res.ok && (this.phantomTransactions = await res.json())
    },

    /**
     * Creates/updates the given transaction-dataset.
     *
     * @param {object} transaction
     * @returns {void}
     */
    async onSubmitTransaction (transaction) {
      const isEdit = this.toEdit !== null
      const res = isEdit
        ? await PhantomApi.updateTransaction(transaction)
        : await PhantomApi.addTransaction(transaction)

      res.ok
        ? this.$store.commit('setSnackbar', { text: isEdit ? this.$t('common.updated') : this.$t('common.created') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.getPhantomTransactions()
      this.showDialog = false
      this.toEdit = null
    },

    /**
     * Opens the edit-dialog, sets the dataset to use.
     *
     * @param {object} entry Dataset to edit
     * @returns {void}
     */
    editTransactionEntry (entry) {
      this.toEdit = entry
      this.showDialog = true
    },

    /**
     * Closes the edit-dialog, resets the dataset to use.
     *
     * @returns {void}
     */
    onCloseDialog () {
      this.showDialog = false
      this.toEdit = null
    },

    /**
     * Removes the transaction marked by clicking the trash-icon.
     *
     * @param {object} transaction
     * @returns {void}
     */
    async removeEntry () {
      const res = await PhantomApi.deleteTransaction(this.toRemove.id)

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.getPhantomTransactions()
      this.toRemove = null
    },
  }
}
</script>
