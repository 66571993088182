<template>
  <v-card class="company-distribution-tree--wrap">
    <v-card-title>
      {{ $t('shareholders.stocksPerCompany') }}
      <v-spacer />
      <grouping-inputs v-model="grouping" />
    </v-card-title>

    <v-skeleton-loader
      v-if="loading"
      :height="height"
      type="image"
      tile
    />
    <div
      v-if="companyAmounts.length && !loading"
      class="chart-offset-compensation"
    >
      <apex-chart
        type="treemap"
        width="100%"
        :height="height"
        :options="options"
        :series="series"
      />
    </div>
  </v-card>
</template>

<script>
import chartColors from '@/components/charts/chart-colors'
import GroupingInputs from '@/components/charts/GroupingInputs'
import ShareholderFormatter from '@/services/shareholder-formatter'

export default {
  name: 'company-distribution-tree',

  components: {
    GroupingInputs,
  },

  props: {
    // amount of stocks per company
    companyAmounts: {
      type: Array,
      default: () => ([])
    },

    // height of the chart
    height: {
      type: Number,
      default: 480,
    },

    // show a loading-indicator
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      grouping: {
        active: true,
        limit: 0.05,
      },

      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: chartColors,
        plotOptions: {
          treemap: {
            distributed: true,
            useFillColorAsStroke: true,
            enableShades: false
          }
        },
        tooltip: {
          y: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale)
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          show: false,
        },
      },
    }
  },

  computed: {
    /**
     * Users are able to group datasets by a custom limit, so companies below a
     * share are shown as one dataset ('others').
     *
     * @returns {object}
     */
    groupedAmounts () {
      return ShareholderFormatter.groupCompanyAmounts(
        this.companyAmounts,
        this.grouping.active,
        this.grouping.limit
      )
    },

    /**
     * Datasets to visualize: Amount of shares per company with their names and
     * an additional entry for grouped datasets.
     *
     * @see groupedAmounts
     * @returns {array}
     */
    series () {
      return [
        {
          data: this.groupedAmounts.toShow
            .map(entry => ({ x: entry.company, y: entry.stockAmount }))
            .filter(dataset => dataset.y)
            .concat(this.grouping.active
              ? {
                  x: this.$t('common.others'),
                  y: this.groupedAmounts.toHide.reduce((total, entry) => total + entry.stockAmount, 0)
                }
              : [])
        }
      ]
    },
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style lang="scss" scoped>
  .v-card {
    overflow: hidden;

    .v-card__title {
      position: relative;
      z-index: 1;
    }
  }

  // the tree-chart has a wrong padding (see #1043), we want to use the full width/height
  .chart-offset-compensation {
    margin: -20px -25px -15px 0;
  }
</style>
