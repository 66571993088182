<template>
  <div class="translations--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.translations') }}
    </h1>

    <v-tabs show-arrows color="grey darken-4" background-color="transparent" class="mb-10">
      <v-tab :to="{ name: 'TranslationOverview' }">
        <v-icon left>
          mdi-translate
        </v-icon>
        {{ $t('translations.translated') }}
      </v-tab>

      <v-tab :to="{ name: 'MissingTranslations' }">
        <v-icon left>
          mdi-folder-outline
        </v-icon>
        {{ $t('translations.notTranslated') }}
      </v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'translations-index',
}
</script>
