<template>
  <div class="stock-distribution--wrap">
    <div class="mb-6 d-flex align-center flex-wrap">
      <h1>{{ $t('shareholders.shareDistribution') }}</h1>

      <v-spacer />

      <date-text-input
        :value="dateToUse | isoDate"
        :clearable="false"
        :label="$t('common.date')"
        dense
        input-class="pa-0 my-2"
        @input="isoDate => dateToUse = new Date(isoDate)"
      />
    </div>

    <v-alert
      v-if="!shareholders.length && !isLoading"
      icon="mdi-information-outline"
      color="secondary"
      dark
      border="left"
    >
      {{ $t('shareholders.noData') }}
    </v-alert>

    <div v-else>
      <v-row>
        <v-col :cols="12" :lg="8">
          <company-distribution-tree :company-amounts="companyAmounts" :loading="isLoading" />
        </v-col>

        <v-col :cols="12" :lg="4">
          <user-status-pie :shareholders="shareholders" :loading="isLoading" />
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" :lg="8">
          <company-distribution-bar :company-amounts="companyAmounts" :loading="isLoading" />
        </v-col>

        <v-col :cols="12" :lg="4">
          <company-distribution-polar :company-amounts="companyAmounts" :loading="isLoading" />
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12">
          <data-table
            :columns="columns"
            :items="groupedCompanyAmounts"
            :is-loading="isLoading"
            :default-options="{ sortBy: ['stockAmount'], sortDesc: [true] }"
          >
            <template #[`item.percentage`]="{ item }">
              {{ (item.percentage * 100) | localeNumber($i18n.locale, true) }}&nbsp;%
            </template>

            <template #[`item.stockAmount`]="{ item }">
              {{ item.stockAmount | localeNumber($i18n.locale) }}
            </template>
          </data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12">
          <age-distribution-bar />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AgeDistributionBar from '@/components/charts/AgeDistributionBar.vue'
import CompanyDistributionBar from '@/components/charts/CompanyDistributionBar'
import CompanyDistributionPolar from '@/components/charts/CompanyDistributionPolar'
import CompanyDistributionTree from '@/components/charts/CompanyDistributionTree'
import DataTable from '@/components/DataTable'
import DateTextInput from '@/components/DateTextInput'
import ShareApi from '@/api/Share'
import ShareholderFormatter from '@/services/shareholder-formatter'
import UserStatusPie from '@/components/charts/UserStatusPie'

export default {
  name: 'stock-distribution',

  components: {
    AgeDistributionBar,
    CompanyDistributionBar,
    CompanyDistributionPolar,
    CompanyDistributionTree,
    DataTable,
    DateTextInput,
    UserStatusPie,
  },

  data () {
    return {
      shareholders: [],
      isLoading: false,
      dateToUse: new Date(),
    }
  },

  computed: {
    /**
     * Amount of stocks by company.
     *
     * @returns {array}
     */
    companyAmounts () {
      return ShareholderFormatter.getCompanyAmounts(this.shareholders)
    },

    /**
     * Amount of stocks by company, but summarises entries under 1% share.
     *
     * @returns {array}
     */
    groupedCompanyAmounts () {
      const grouped = ShareholderFormatter.groupCompanyAmounts(this.companyAmounts, true, 0.01)

      return [
        ...grouped.toShow,
        {
          companyName: this.$t('common.others'),
          percentage: grouped.toHide.reduce((total, entry) => total + entry.percentage, 0),
          stockAmount: grouped.toHide.reduce((total, entry) => total + entry.stockAmount, 0),
        }
      ]
    },

    /**
     * Columns of the datatable with translatable labels.
     *
     * @returns {array}
     */
    columns () {
      return [
        { text: this.$t('common.company'), value: 'companyName' },
        { text: this.$t('common.amount'), value: 'stockAmount' },
        { text: this.$t('common.share'), value: 'percentage' },
      ]
    }
  },

  watch: {
    // shareholders are always related to a specific date
    dateToUse () {
      this.getShareholders()
    }
  },

  mounted () {
    this.getShareholders()
  },

  methods: {
    /**
     * Loads all shareholders from the api.
     *
     * @returns {void}
     */
    async getShareholders () {
      this.isLoading = true
      const res = await ShareApi.getShareholderStats(this.dateToUse)
      res.ok && (this.shareholders = await res.json())
      this.isLoading = false
    },
  },
}
</script>
