<template>
  <div class="news--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.news') }}
    </h1>

    <v-switch v-model="showList" inset :label="$t('news.listView')" class="mb-4" />

    <div v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="secondary" />
    </div>

    <div v-else>
      <news-panel v-if="showList" :news="news" />
      <news-grid v-else :news="news" />
    </div>
  </div>
</template>

<script>
import NewsApi from '@/api/News'
import NewsPanel from './NewsPanel.vue'
import NewsGrid from './NewsGrid.vue'

export default {
  name: 'news-view',

  components: {
    NewsPanel,
    NewsGrid
  },

  data () {
    return {
      news: [],
      isLoading: false,
      showList: false,
    }
  },

  mounted () {
    this.getNews()
  },

  methods: {
    /**
     * Loads available news from the api.
     *
     * @returns {void}
     */
    async getNews () {
      this.isLoading = true
      const res = await NewsApi.getAll()
      res.ok && (this.news = await res.json())
      this.news.sort((a, b) => b.date.localeCompare(a.date)) // dates are ISO-strings
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  .news-dialog,
  .news--wrap {
    .news-card {
      .v-card__title {
        word-break: break-word;
      }
    }

    .image-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        max-width: 100%;
      }

      > div {
        max-width: fit-content;
      }
    }

    .message {
      white-space: pre-wrap;
    }
  }
</style>
