import Imprint from '@/views/Meta/Imprint'
import Login from '@/views/Login/Login'
import ResetPassword from '@/views/Login/ResetPassword'

export default [
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/login/:target?',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },
]
