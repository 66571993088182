<template>
  <div class="offer-summary--wrap">
    <div>
      {{ $t(isRequest ? 'sell.requestSummaryStart' : 'sell.summaryStart', { amount: amount }) }}

      <span :class="{ 'red--text font-weight-bold': price < formulaPrice }">{{ price | readablePrice($i18n.locale) }}</span><span>{{ isRequest ? $t('sell.requestSummaryEnd') : $t('sell.summaryEnd') }}</span>

      <div v-if="price < formulaPrice" class="red--text mb-4">
        <v-icon color="red" small>
          mdi-alert
        </v-icon>

        {{ $t('sell.belowFormulaPrice') }} ({{ formulaPrice | readablePrice($i18n.locale) }})
      </div>
    </div>

    {{ $t('sell.confirmOffer') }}
  </div>
</template>

<script>
export default {
  name: 'offer-summary',

  props: {
    amount: {
      type: Number,
      default: 0,
    },

    price: {
      type: Number,
      default: 0,
    },

    isRequest: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    formulaPrice () {
      return this.$store.state.configuration.formulaPrice
    },
  },
}
</script>
