var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offer-table--wrap"},[_c('data-table',{attrs:{"columns":_vm.columns,"is-loading":_vm.isLoading,"items":_vm.validOffersWithFormulaPrice,"title":_vm.$t('sell.allOffers'),"default-options":_vm.dataTableOptions},scopedSlots:_vm._u([{key:"item.expires",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.expires,true))+" ")]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{attrs:{"type":_vm.type}}),_vm._v(" "+_vm._s(item.fullName || _vm.$t('sell.anonymousUser'))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.price,_vm.$i18n.locale))+" ")]}},{key:"item.formulaPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.formulaPrice,_vm.$i18n.locale))+" ")]}},{key:"item.holdingAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.type === 'buy' ? item.effectiveAmount - item.amount : item.amount - item.effectiveAmount)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-cart","icon-color":"primary","disabled":_vm.isUserOffer(item),"tooltip":_vm.isUserOffer(item) ? _vm.$t('salesRequests.ownOffer') : _vm.$t('salesRequests.acceptOffer')},on:{"click":function($event){return _vm.$emit('click:offer', item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }