<template>
  <div class="phantoms--wrap">
    <h1 class="mb-6">
      {{ $t('phantoms.heading') }}
    </h1>

    <v-tabs show-arrows color="grey darken-4" background-color="transparent" class="mb-10">
      <v-tab :to="{ name: 'PhantomsList' }">
        <v-icon class="text--disabled">
          mdi-ghost-outline
        </v-icon>
        <v-icon
          left
          small
        >
          mdi-playlist-check
        </v-icon>
        {{ $t('phantoms.nav.list') }}
      </v-tab>

      <v-tab :to="{ name: 'PhantomsTransactions' }">
        <v-icon class="text--disabled">
          mdi-ghost-outline
        </v-icon>
        <v-icon
          left
          small
        >
          mdi-cash
        </v-icon>
        {{ $t('phantoms.nav.transactions') }}
      </v-tab>
    </v-tabs>

    <div class="phantoms--content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'phantoms-index',
}
</script>
