<template>
  <div class="formula-price-info--wrap">
    <v-alert
      v-if="config"
      class="mb-0"
      color="secondary"
      dark
      icon="mdi-cash"
      outlined
    >
      <div class="font-weight-medium">
        {{ $t('common.currentFormulaPrice') }}:

        <v-chip small color="secondary" class="ml-2">
          {{ config.formulaPrice | readablePrice($i18n.locale) }}
        </v-chip>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'formula-price-info',

  computed: {
    config () {
      return this.$store.state.configuration
    },
  }
}
</script>
