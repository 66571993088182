<template>
  <v-snackbar
    app
    right
    bottom
    :timeout="6500"
    :color="snackbar.color"
    :value="snackbar.isVisible"
    :min-width="400"
    @input="visible => $store.commit('setSnackbarVisibility', visible)"
  >
    <div class="d-flex align-center">
      <v-icon left>
        mdi-information
      </v-icon>
      <div class="py-3 text-subtitle-1">
        {{ snackbar.text }}
      </div>
    </div>

    <template #action="{ attrs }">
      <v-divider vertical class="mx-3" />
      <v-btn
        icon
        v-bind="attrs"
        class="mr-2"
        @click="$store.commit('setSnackbarVisibility', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'info-snackbar',

  computed: {
    snackbar () {
      return this.$store.state.snackbar
    },
  },
}
</script>
