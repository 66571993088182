<template>
  <div class="user-mails--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.inbox') }}
    </h1>

    <div class="d-flex mb-4">
      <v-switch
        v-model="showRead"
        inset
        :label="$t('emails.showRead')"
        class="py-0 my-0 mr-8"
      />

      <v-switch
        v-model="showRemoved"
        inset
        :label="$t('emails.showRemoved')"
        class="py-0 my-0 mr-8"
      />
    </div>

    <message-table
      :messages="filteredMessages"
      :title="$t('emails.messages')"
      :is-loading="isLoading"
      class="mb-8"
      @show:message="message => messageToShow = message"
    >
      <template #actions="{ item }">
        <table-button
          icon="mdi-email-open-outline"
          :tooltip="$t('emails.showEmail')"
          @click="messageToShow = item"
        />

        <table-button
          icon="mdi-delete"
          icon-color="red"
          :tooltip="$t('emails.removeEmail')"
          @click="removeMessage(item)"
        />
      </template>
    </message-table>

    <message-table
      v-if="showRemoved"
      :messages="removedMessages"
      :title="$t('emails.removedEmails')"
      :is-loading="isLoading"
      class="mb-8"
      @show:message="message => messageToShow = item"
    >
      <template #actions="{ item }">
        <table-button
          icon="mdi-email-open-outline"
          :tooltip="$t('emails.showEmail')"
          @click="messageToShow = item"
        />

        <table-button
          icon="mdi-delete-restore"
          :tooltip="$t('emails.restoreEmail')"
          @click="restoreMessage(item)"
        />
      </template>
    </message-table>

    <v-dialog
      :value="messageToShow !== null"
      :width="800"
      max-width="90%"
      content-class="message-dialog"
      @input="visible => !visible && onCloseDialog()"
    >
      <v-card v-if="messageToShow">
        <v-card-title class="d-flex align-center">
          {{ messageToShow.subject }}

          <v-chip small class="ml-auto" color="secondary">
            <v-icon small left>
              mdi-calendar
            </v-icon>
            {{ messageToShow.receivedAt | readableIsoDate(true) }}
          </v-chip>
        </v-card-title>

        <v-card-subtitle>
          {{ messageToShow.senderName }}
        </v-card-subtitle>

        <v-card-text>
          <div class="message" v-text="messageToShow.content" />

          <div v-if="messageToShow.document" class="mt-4">
            <div class="text-h6 mb-1">
              {{ $t('emails.attachments') }}
            </div>
            <a
              :href="getDownloadLink(messageToShow.document.documentId)"
              target="_blank"
              class="text-decoration-none"
            >
              {{ messageToShow.document.title }}
            </a>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="onCloseDialog">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DocumentApi from '@/api/Documents'
import MessageApi from '@/api/Message'
import TableButton from '@/components/TableButton'
import MessageTable from './MessageTable'

export default {
  name: 'user-mails',

  components: {
    MessageTable,
    TableButton,
  },

  data () {
    return {
      documents: [],
      isLoading: false,
      messages: [],
      messageToShow: null,
      showRead: true,
      showRemoved: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('emails.wasRead'), value: 'hasBeenRead', align: 'center', width: 100 },
        { text: this.$t('emails.subject'), value: 'subject' },
        { text: this.$t('emails.sentAt'), value: 'receivedAt', width: 180 },
        { text: this.$t('emails.attachments'), value: 'attachments', align: 'right' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '140px', align: 'right' },
      ]
    },

    filteredMessages () {
      return this.messages.filter(({ deleted, hasBeenRead }) =>
        !deleted && (this.showRead ? true : !hasBeenRead)
      )
    },

    removedMessages () {
      return this.messages.filter(({ deleted }) => deleted)
    }
  },

  async mounted () {
    // the order is important - messages need documents
    await this.loadDocuments()
    await this.loadMessages()
  },

  methods: {
    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },

    /**
     * Loads all documents from the api so we have the full datasets available
     * for displaying the name, if the document has a file.
     *
     * @returns {void}
     */
    async loadDocuments () {
      this.isLoading = true

      const res = await DocumentApi.list()
      res.ok && (this.documents = await res.json())

      this.isLoading = false
    },

    /**
     * Loads messages for the current user, combines those with full datasets
     * of documents.
     *
     * @returns {void}
     */
    async loadMessages () {
      this.isLoading = true

      const res = await MessageApi.getInbox()

      if (res.ok) {
        const messages = await res.json()
        this.messages = messages.map(message => ({
          ...message,
          document: this.documents.find(({ documentId }) => documentId === message.documentId)
        }))
      }

      this.isLoading = false
    },

    /**
     * Marks the currently shown message as read, closes the dialog.
     *
     * @returns {void}
     */
    async onCloseDialog () {
      await MessageApi.markAsRead(this.messageToShow.sentMessageId)
      await this.loadMessages()
      this.messageToShow = null
    },

    /**
     * Removes the given message, reloads all messages.
     *
     * @param {object} message
     * @returns {void}
     */
    async removeMessage (message) {
      const res = await MessageApi.removeMessage(message.sentMessageId)
      this.$store.commit('setSnackbar', {
        text: res.ok ? this.$t('common.deleted') : this.$t('common.errorOccured'),
        color: res.ok ? 'success' : 'error',
      })

      this.loadMessages()
    },

    /**
     * Restores the given message, reloads all messages.
     *
     * @param {object} message
     * @returns {void}
     */
    async restoreMessage (message) {
      await MessageApi.restoreMessage(message.sentMessageId)
      this.loadMessages()
    },
  }
}
</script>

<style lang="scss">
  .message-dialog {
    .message {
      white-space: pre-wrap;
    }
  }
</style>
