var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phantoms-list--wrap"},[_c('data-table',{staticClass:"tablePhantomTransactions",attrs:{"columns":_vm.columns,"custom-page-size":25,"items":_vm.filteredTransactions,"title":((_vm.$t('phantoms.total')) + ": " + _vm.sumStockAmount),"default-options":{ sortBy: ['datePerformed'], sortDesc: [true] }},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [(_vm.accountId)?_c('table-button',{attrs:{"icon":"mdi-filter-variant-minus","color":"amber","tooltip":_vm.$t('common.filter.reset'),"is-primary":"","to":{ name: 'PhantomsTransactions' }}}):_vm._e(),(_vm.withActions)?_c('table-button',{attrs:{"icon":"mdi-playlist-plus","dark":"","tooltip":_vm.$t('phantoms.transactions.newEntry'),"is-primary":""},on:{"click":function($event){_vm.showDialog = true}}}):_vm._e()]},proxy:true},{key:"item.datePerformed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.datePerformed))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('transaction-type-chip',{attrs:{"transaction-type":item.amount > 0 ? 'buy' : 'sell'}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amount,_vm.$i18n.locale))+" ")]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'PhantomsTransactions', params: { id: item.accountId } }}},[_vm._v(" "+_vm._s(item.fullName)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-trash-can-outline","tooltip":_vm.$t('common.delete'),"small":"","icon-color":"red"},on:{"click":function($event){_vm.toRemove = item}}}),_c('table-button',{attrs:{"icon":"mdi-pencil","tooltip":_vm.$t('common.edit'),"small":"","icon-color":"primary"},on:{"click":function($event){return _vm.editTransactionEntry(item)}}})]}}],null,true)}),(_vm.withActions)?_c('transaction-dialog',{attrs:{"is-visible":_vm.showDialog,"to-edit":_vm.toEdit},on:{"cancel":_vm.onCloseDialog,"submit":_vm.onSubmitTransaction}}):_vm._e(),_c('confirm-dialog',{attrs:{"headline":_vm.$t('common.confirmDeletion'),"is-visible":_vm.toRemove !== null},on:{"cancel":function($event){_vm.toRemove = null},"ok":_vm.removeEntry}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }