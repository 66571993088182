import router from '@/router'
import store from '@/store'

export default {
  baseUrl: '/api/v1',
  baseHeader: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },

  /**
   * Request-wrap for global interceptions.
   *
   * @param {string} url
   * @param {object} options
   * @param {string} options.method
   * @param {any} options.body
   * @param {object} options.headers Additional headers (can override the default)
   * @returns {Promise}
   */
  async baseFetch (url = '', { method = 'GET', body, headers = {} } = {}) {
    const res = await fetch(`${this.baseUrl}/${url}`, {
      headers: {
        ...this.baseHeader,
        ...headers,
      },
      method,
      body,
      credentials: 'include',
    })

    if (
      router.currentRoute.name !== 'Login' &&
      (res.status === 401 || (res.status === 403 && res.headers.get('x-actual-status-code') === '401'))
    ) {
      store.commit('logout')
      return router.replace({ name: 'Login' })
    }

    return res
  },

  /**
   * Creates an url to the agreement-file with the given id, language.
   *
   * @param {number} id
   * @param {string} locale
   * @returns {string}
   */
  getAgreementUrl (id, locale) {
    return `${this.baseUrl}/agreement/${id}/file/${locale}`
  }
}
