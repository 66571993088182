export default [
  {
    id: 1,
    type: 'transaction',
    color: 'primary',
    icon: 'mdi-bank-transfer',
    to: 'UserTransactions',
  },
  {
    id: 2,
    type: 'message',
    color: 'secondary',
    icon: 'mdi-email',
    to: 'UserMails',
  },
  {
    id: 3,
    type: 'news',
    color: 'accent',
    icon: 'mdi-newspaper-variant',
    to: 'News',
  },
  {
    id: 4,
    type: 'pending_transaction',
    color: 'amber darken-2',
    icon: 'mdi-bank-off',
  },
  {
    id: 5,
    type: 'offer',
    color: 'light-green',
    icon: 'mdi-tag',
    to: 'SalesRequests',
  },
]
