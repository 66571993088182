<template>
  <div class="two-factor-confirmation--wrap">
    <form @submit.prevent="sendConfirmationCode">
      <v-card width="500" max-width="90%" class="mx-auto">
        <v-card-title>
          {{ $t('login.twoFactorAuth') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('login.pleaseEnterCode') }}
        </v-card-subtitle>

        <v-divider />

        <v-card-text>
          <v-text-field
            v-model="code"
            :label="$t('login.twoFactorCode')"
            outlined
            hide-details
            autofocus
            required
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn depressed type="submit">
            <v-icon left color="primary">
              mdi-send
            </v-icon>
            {{ $t('common.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import TwoFactorAuthApi from '@/api/TwoFactorAuth'

export default {
  name: 'two-factor-confirmation',

  data () {
    return {
      code: '',
    }
  },

  methods: {
    /**
     * Sends the confirmation-code the the api to check it.
     *
     * @returns {void}
     */
    async sendConfirmationCode () {
      const res = await TwoFactorAuthApi.authenticate(this.code)
      this.$emit(res.ok ? 'code:valid' : 'code:invalid')
    }
  }
}
</script>
