<template>
  <div class="company-data--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.companyData') }}
    </h1>

    <data-table
      :columns="columns"
      :items="companies"
      :is-loading="isLoading"
      :default-options="{ sortBy: ['shortName'], sortDesc: [false] }"
    >
      <template #headerActions>
        <table-button
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('common.newEntry')"
          is-primary
          @click="showCreateDialog"
        />
      </template>

      <template #[`item.emailDomain`]="{ item }">
        {{ item.emailDomain || '-' }}
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-cog"
          icon-color="grey darken-1"
          :tooltip="$t('companyData.configuration.title')"
          @click="companyConfigToShow = item"
        />

        <table-button
          icon="mdi-pencil"
          icon-color="primary"
          :tooltip="$t('common.edit')"
          @click="showEditDialog(item)"
        />

        <table-button
          icon="mdi-delete"
          icon-color="red"
          :tooltip="$t('common.delete')"
          @click="toRemove = item"
        />
      </template>
    </data-table>

    <confirm-dialog
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeEntry"
    />

    <company-dialog
      :is-visible="dialogVisible"
      :company="toEdit"
      @close="onCloseDialog"
      @submit="onSubmit"
    />

    <company-config-dialog
      :company="companyConfigToShow"
      @close="companyConfigToShow = null"
    />
  </div>
</template>

<script>
import CompanyApi from '@/api/Company'
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'
import CompanyDialog from './CompanyDialog'
import CompanyConfigDialog from './CompanyConfig/ListDialog.vue'

export default {
  name: 'company-data',

  components: {
    TableButton,
    ConfirmDialog,
    DataTable,
    CompanyDialog,
    CompanyConfigDialog,
  },

  data () {
    return {
      companies: [],
      toEdit: null,
      toRemove: null,
      dialogVisible: false,
      isLoading: false,
      companyConfigToShow: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('companyData.shortName'), value: 'shortName' },
        { text: this.$t('companyData.fullName'), value: 'name' },
        { text: this.$t('companyData.mailDomain'), value: 'emailDomain' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right', width: '140px' },
      ]
    },
  },

  async mounted () {
    await this.getCompanies()
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      this.isLoading = true
      const res = await CompanyApi.getAll()
      res.ok && (this.companies = await res.json())
      this.isLoading = false
    },

    /**
     * Creates/updates the given company.
     *
     * @param {object} company
     * @returns {void}
     */
    async onSubmit (company) {
      const isEdit = this.toEdit !== null

      const res = isEdit
        ? await CompanyApi.update(company)
        : await CompanyApi.create(company)

      if (res.ok) {
        this.getCompanies()
        this.dialogVisible = false
        return this.$store.commit('setSnackbar', {
          text: isEdit ? this.$t('common.updated') : this.$t('common.created')
        })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    },

    /**
     * Removes the company that was marked by clicking the delete-button,
     * closes the confirm-dialog.
     *
     * @returns {void}
     */
    async removeEntry () {
      const res = await CompanyApi.delete(this.toRemove.companyId)

      if (res.ok) {
        this.getCompanies()
        this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
      } else {
        this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
      }

      this.toRemove = null
    },

    /**
     * Opens an empty company-dialog to create a new dataset.
     *
     * @returns {void}
     */
    showCreateDialog () {
      this.toEdit = null
      this.dialogVisible = true
    },

    /**
     * Opens a dialog to edit an existing company.
     *
     * @param {object} company
     * @returns {void}
     */
    showEditDialog (company) {
      this.toEdit = company
      this.dialogVisible = true
    },

    /**
     * Closes the create/edit-dialog, resets related state.
     *
     * @returns {void}
     */
    onCloseDialog () {
      this.toEdit = null
      this.dialogVisible = false
    },
  }
}
</script>
