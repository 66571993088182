<template>
  <div class="accepted-terms-label--wrap">
    {{ $t('sell.acceptedTerms') }}

    <v-tooltip v-if="conditionsUrl" bottom>
      <template #activator="{ on, attrs }">
        <div class="d-inline-block" v-on="on">
          <v-btn
            :href="conditionsUrl"
            target="_blank"
            small
            icon
            outlined
            color="primary"
            class="text-decoration-none ml-2"
            v-bind="attrs"
            @click.stop
          >
            <v-icon small color="primary">
              mdi-download
            </v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ $t('common.download') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Api from '@/api/Api'
import AuthApi from '@/api/Auth'

export default {
  name: 'accepted-terms-label',

  data () {
    return {
      conditions: null,
    }
  },

  computed: {
    conditionsUrl () {
      return this.conditions
        ? Api.getAgreementUrl(this.conditions.agreementId, this.$i18n.locale)
        : null
    }
  },

  mounted () {
    this.getConditionsOfParticipation()
  },

  methods: {
    /**
     * Loads all agreements, tries to find the conditions of participation.
     * Searching by a label is crap, but necessary because the API doesn't
     * "know" which agreement has what content.
     *
     * @returns {void}
     */
    async getConditionsOfParticipation () {
      const res = await AuthApi.getAgreements()

      if (res.ok) {
        const agreements = await res.json()
        this.conditions = agreements.find(agreement => agreement.key === 'conditions')
      }
    },
  },
}
</script>
