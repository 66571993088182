<template>
  <div class="translation-keys--wrap">
    <data-table
      :columns="columns"
      :items="filteredEntries"
      :custom-page-size="50"
      :page-sizes="[25, 50, 100]"
      :title="filterPath || $t('translations.allTranslations')"
      :is-loading="isLoading"
    >
      <template #headerActions>
        <slot name="actions" />
      </template>

      <template #item="{ item }">
        <tr>
          <td class="text-center">
            <v-icon :color="item.completed ? 'success' : 'error'">
              {{ item.completed ? 'mdi-check' : 'mdi-alert-circle' }}
            </v-icon>
          </td>

          <td>{{ item.path }}</td>

          <template v-for="language in languageKeys">
            <td :key="`col_${language}`">
              <form @submit.prevent="updateTranslation(item)">
                <v-text-field
                  :value="item[language]"
                  hide-details
                  :error="item[language] === undefined"
                  :placeholder="`${item.path} - ${language}`"
                  class="pt-1"
                  @input="value => onTranslationChange(item.path, language, value)"
                />
              </form>
            </td>
          </template>

          <td class="text-right">
            <table-button
              icon="mdi-delete"
              icon-color="red"
              :tooltip="$t('common.delete')"
              @click="$emit('remove:entry', item)"
            />

            <table-button
              icon="mdi-translate"
              icon-color="primary"
              :tooltip="$t('common.update')"
              @click="updateTranslation(item)"
            />
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { get } from 'lodash'

import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'

export default {
  name: 'translation-table',

  components: {
    DataTable,
    TableButton,
  },

  props: {
    // object-path ('a.b.c') that should act as a filter
    filterPath: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      changes: {},
      isLoading: false,
      keys: [],
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('translations.completed'), value: 'completed', align: 'center', width: 120 },
        { text: this.$t('translations.path'), value: 'path' },
        ...this.languageKeys.map(language => ({ text: language, value: language })),
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right' },
      ]
    },

    // loaded translations
    translations () {
      return this.$i18n.messages
    },

    // first level of our translation are language-keys
    languageKeys () {
      return Object.keys(this.translations)
    },

    // translation-object transformed to a list of translation-entries -> [{ path, [language]: translation }]
    translationEntries () {
      return this.languageKeys
        .reduce((items, language) => [...items, this.getKeys(this.translations[language])], []) // get keys per language
        .flat(Infinity) // flatten those
        .filter((path, i, paths) => paths.indexOf(path) === i) // remove duplicates
        .sort() // sort before transformation
        .map(this.addTranslationsToPath) // transformation to translation-entry-objects
    },

    // translation-entries with a path matching our search
    filteredEntries () {
      return this.translationEntries.filter(entry =>
        this.filterPath ? entry.path.indexOf(this.filterPath) === 0 : true
      )
    }
  },

  methods: {
    /**
     * Combines the given path with the related translations.
     *
     * @param {string} path
     * @returns {object}
     */
    addTranslationsToPath (path) {
      const keyEntries = this.languageKeys.reduce((entries, language) => {
        const translation = get(this.translations[language], path)
        return translation ? { ...entries, [language]: translation } : entries
      }, {})

      return {
        path,
        ...keyEntries,
        completed: Object.keys(keyEntries).length === this.languageKeys.length
      }
    },

    /**
     * Checks which keys are present within the given object.
     *
     * @param {object} o
     * @param {string} path
     * @returns {array|string}
     */
    getKeys (o, path = '') {
      return !o || typeof o !== 'object'
        ? path
        : Object.keys(o).map(key =>
          this.getKeys(o[key], path ? [path, key].join('.') : key)
        )
    },

    /**
     * We don't want to manipulate the entries via v-model directly (searching
     * for an item and editing it could result in an empty view), so we store
     * the changes until the user wants to save those by using the button.
     *
     * @param {string} path object-path of the changed entry
     * @param {string} language key of the edited language
     * @param {string} value updated translation
     * @returns {void}
     */
    onTranslationChange (path, language, value) {
      this.changes[path] = { ...this.changes[path], [language]: value }
    },

    /**
     * Applies saved changes to the given translation-entry (see
     * "onTranslationChange"), communicates that to the parent component to
     * persist the update.
     *
     * @param {object} entry
     * @see onTranslationChange
     */
    updateTranslation (entry) {
      const updatedEntry = { ...entry, ...this.changes[entry.path] }
      this.$emit('update:entry', updatedEntry)
    },
  }
}
</script>

<style lang="scss">
  .translation-keys--wrap {
    .v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            tr > th {
              height: 41px;
            }
          }

          tbody {
            tr {
              td {
                height: auto;

                .v-input {
                  &.error--text {
                    background-color: rgba(255, 0, 0, 0.12);
                    border-radius: 4px 4px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
