<template>
  <div class="change-password--wrap">
    <h1 class="mb-6">
      {{ $t('userEditProfile.heading') }}
    </h1>

    <change-password-form />
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/ChangePasswordForm'

export default {
  name: 'change-password',

  components: {
    ChangePasswordForm
  },
}
</script>
