<template>
  <div class="news-documents--wrap">
    <div v-if="documentsToShow.length" class="documents--wrap mt-4">
      <div class="text-h6 mb-1">
        {{ $t('news.documents') }}
      </div>
      <div v-for="document in documentsToShow" :key="document.documentId">
        <a
          :href="getDownloadLink(document.documentId)"
          target="_blank"
          class="text-decoration-none d-flex align-center"
        >
          <table-button
            icon="mdi-file-download"
            icon-color="primary"
            :tooltip="$t('common.download')"
            class="mr-2"
          />

          {{ document.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DocumentApi from '@/api/Documents'
import TableButton from '@/components/TableButton'

export default {
  name: 'news-documents',

  components: {
    TableButton
  },

  props: {
    documentIds: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      documents: []
    }
  },

  computed: {
    documentsToShow () {
      return this.documents.filter(({ documentId }) => this.documentIds.includes(documentId))
    }
  },

  mounted () {
    this.loadDocuments()
  },

  methods: {
    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },

    /**
     * Loads all documents from the api so we have the full datasets available
     * for displaying the name, if the document has a file.
     *
     * @returns {void}
     */
    async loadDocuments () {
      const res = await DocumentApi.list()
      res.ok && (this.documents = await res.json())
    },
  }
}
</script>
