<template>
  <v-card
    flat
    color="secondary"
    class="d-inline-block"
    dark
    :width="width"
    max-width="100%"
  >
    <v-card-title>
      <v-icon left>
        mdi-information
      </v-icon>
      {{ $t('common.hint') }}
    </v-card-title>
    <v-card-text>
      <span>
        {{ $t('meetings.hint') }}
        <router-link :to="{ name: 'Reports' }" class="white--text">
          {{ $t('sidebar.information') }}
        </router-link>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'meeting-hint',

  props: {
    width: {
      type: [Number, String],
      default: 'auto'
    }
  }
}
</script>
