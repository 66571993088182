import { parseISO } from 'date-fns'
import DateHelper from '@/services/date-helper'

export const isoDate = date =>
  date ? DateHelper.getIsoDate(date) : null

export const readableDate = (date, full = false) =>
  date ? DateHelper.readableDate(date, full) : null

export const readableIsoDate = (date, full = false, timeFormat) =>
  date ? DateHelper.readableDate(parseISO(date), full, timeFormat) : null
