<template>
  <div class="news-grid--wrap">
    <v-row>
      <v-col v-for="entry in news" :key="entry.newsId" :cols="12" :sm="6" :lg="4">
        <v-card class="news-card fill-height d-flex flex-column">
          <v-img
            v-if="entry.newsImages.length"
            :src="getImageUrl(entry)"
            :alt="entry.title"
            :height="imageHeight"
            class="flex-grow-0"
          />

          <v-sheet
            v-else
            :height="imageHeight"
            color="grey lighten-3 d-flex align-center justify-center"
          >
            <v-img
              :src="require('@/assets/gfx/mueller_bbm.svg')"
              alt="Müller-BBM"
              max-width="200"
              class="company-logo mb-1"
              contain
            />
          </v-sheet>

          <v-card-title>
            {{ entry.title }}
          </v-card-title>

          <v-card-subtitle v-if="entry.subTitle">
            {{ entry.subTitle }}
          </v-card-subtitle>

          <v-card-actions class="mt-auto">
            <v-chip label color="grey lighten-4">
              <v-icon small class="mr-1" v-text="'mdi-calendar'" />
              {{ entry.date | readableIsoDate }}
            </v-chip>

            <v-spacer />

            <v-btn color="secondary" depressed @click="activeEntry = entry">
              {{ $t('common.more') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <news-dialog
      :news-entry="activeEntry"
      @close="activeEntry = null"
    />
  </div>
</template>

<script>
import NewsApi from '@/api/News'
import NewsDialog from './NewsDialog.vue'

export default {
  name: 'news-grid',

  components: {
    NewsDialog,
  },

  props: {
    news: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      activeEntry: null,
      imageHeight: 200,
    }
  },

  methods: {
    /**
     * Builds the url for an image-request for the given news-entry if it has
     * an image.
     *
     * @param {object} newsEntry
     * @returns {string|null}
     */
    getImageUrl (newsEntry) {
      return NewsApi.getImageUrl(newsEntry)
    },
  },
}
</script>

<style lang="scss">
  .news-panel--wrap {
    .line-clamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .message {
      white-space: pre-wrap;
    }
  }
</style>
