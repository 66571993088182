import { get } from 'lodash'

/**
 * Checks which translations are at the given object-path, combines those
 * to single entries with some meta-information to display a status.
 *
 * Background: Translations are stored within a single object whose first
 * level contains language-keys. In the view we want to display a list of
 * translations-keys along with their translations for all languages.
 *
 * @param {string} path
 * @returns {array}
 */
export const getEntriesForPath = (translations, path) => {
  const entries = Object.keys(translations).reduce((entries, lang) => {
    // entry for the current language at the given path
    const languageEntry = get(translations[lang], path)
    if (!languageEntry) return entries

    // create new entries for translations-keys with their translation or
    // extend the existing entry already holding other translations.
    Object.keys(languageEntry).forEach(key => {
      const entryForKey = entries.find(entry => entry.key === key)
      const value = languageEntry[key]

      if (typeof value !== 'object') {
        entryForKey === undefined
          ? entries.push({ [lang]: value, path: `${path}.${key}`, key })
          : (entryForKey[lang] = value)
      }
    })

    return entries
  }, [])

  // add some meta-information to entries to show warnings,.. within the view
  return entries.map(entry => ({
    ...entry,
    completed: entryIsTranslated(entry, translations),
  }))
}

/**
 * Checks if the given entry has translations for all languages.
 *
 * @param {object} entry
 * @returns {boolean}
 */
function entryIsTranslated (entry, translations) {
  return Object.keys(translations).reduce((translated, lang) => {
    return translated && entry[lang] !== undefined && entry[lang] !== ''
  }, true)
}
