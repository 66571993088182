<template>
  <v-dialog
    :value="payoutData !== null"
    width="600"
    max-width="95%"
    persistent
    @input="visible => !visible && $emit('close')"
  >
    <v-card v-if="payoutData !== null" :loading="isLoading">
      <v-card-title>
        {{ $t('dividendPayout.edit') }}
      </v-card-title>
      <v-card-text class="pa-4">
        <v-form
          v-model="formIsValid"
          @submit.prevent="updatePayout"
        >
          <v-text-field
            :value="payoutData.dividend / 100"
            outlined
            :label="$t('dividendPayout.dividend')"
            prepend-inner-icon="mdi-currency-eur"
            :rules="requiredRules"
            hide-details
            type="number"
            step="1"
            class="mb-4"
            @input="dividend => payoutData.dividend = dividend * 100"
          />
          <v-row>
            <v-col :cols="6">
              <date-text-input
                :value="new Date(payoutData.resolutionDate) | isoDate"
                :label="$t('dividendPayout.resolutionDate')"
                :rules="requiredRules"
                class="mb-4"
                @input="isoDate => payoutData.resolutionDate = new Date(isoDate)"
              />
            </v-col>
            <v-col :cols="6">
              <div class="d-flex align-center">
                <time-text-input
                  :label="$t('common.time')"
                  :value="new Date(payoutData.resolutionDate)"
                  @input="dateTime => payoutData.resolutionDate = dateTime"
                />

                <info-tooltip
                  :text="$t('dividendPayout.help.resolutionDate')"
                  class="ml-4"
                />
              </div>
            </v-col>
          </v-row>
          <date-text-input
            v-model="payoutData.paymentDate"
            :label="$t('dividendPayout.paymentDate')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentDate')" />
            </template>
          </date-text-input>
          <date-text-input
            v-model="payoutData.paymentPeriodBegin"
            :label="$t('dividendPayout.paymentPeriodBegin')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentPeriodBegin')" />
            </template>
          </date-text-input>
          <date-text-input
            v-model="payoutData.paymentPeriodEnd"
            :label="$t('dividendPayout.paymentPeriodEnd')"
            :rules="requiredRules"
            class="mb-4"
          >
            <template #append>
              <info-tooltip :text="$t('dividendPayout.help.paymentPeriodEnd')" />
            </template>
          </date-text-input>

          <div class="d-flex">
            <v-btn
              type="submit"
              color="primary"
              depressed
              :loading="isLoading"
              :disabled="!formIsValid"
              class="ml-auto"
            >
              {{ $t('dividendPayout.confirm_edit') }}
            </v-btn>
          </div>
        </v-form>

        <div class="text-h6 my-4">
          {{ $t('dividendPayout.documents') }}
        </div>

        <v-file-input
          v-model="churchTaxFile"
          :label="$t('dividendPayout.churchTaxFile')"
          accept=".csv"
          prepend-icon=""
          prepend-inner-icon="mdi-file"
          append-outer-icon="mdi-upload"
          hide-details
          outlined
          class="mb-2"
          @click:append-outer="uploadFile()"
        />
        <v-file-input
          v-model="dioceseFile"
          :label="$t('dividendPayout.dioceseFile')"
          accept=".csv"
          prepend-icon=""
          prepend-inner-icon="mdi-file"
          append-outer-icon="mdi-upload"
          hide-details
          outlined
          class="mb-2"
          @click:append-outer="uploadFile(true)"
        />
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer />
        <v-btn
          depressed
          :loading="isLoading"
          @click="$emit('close')"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DividendPayoutApi from '@/api/DividendPayout.js'

import DateTextInput from '@/components/DateTextInput.vue'
import InfoTooltip from '@/components/InfoTooltip.vue'
import TimeTextInput from '@/components/TimeTextInput'

export default {
  name: 'edit-payout-dialog',

  components: {
    DateTextInput,
    InfoTooltip,
    TimeTextInput,
  },

  props: {
    payoutToEdit: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      formIsValid: null,
      isLoading: false,
      payoutData: null,
      churchTaxFile: null,
      dioceseFile: null,
    }
  },

  computed: {
    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    },
  },

  watch: {
    payoutToEdit () {
      this.payoutData = this.payoutToEdit
        ? { ...this.payoutToEdit }
        : null

      this.churchTaxFile = null
      this.dioceseFile = null
    },
  },

  methods: {
    /**
     * updatePayout
     *
     * @returns {Promise}
     */
    async updatePayout () {
      if (!this.formIsValid) {
        return
      }

      const res = await DividendPayoutApi.update(this.payoutData)

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.$emit('close')
      this.$emit('updated')
    },

    /**
     * uploadFile
     *
     * @returns {Promise}
     */
    async uploadFile (isDiocese = false) {
      const fileToUpload = isDiocese ? this.dioceseFile : this.churchTaxFile

      if (!fileToUpload || !this.payoutData?.dividendPayoutId) {
        return
      }

      this.isLoading = true

      const res = isDiocese
        ? await DividendPayoutApi.setDioceseFile(this.payoutData.dividendPayoutId, fileToUpload)
        : await DividendPayoutApi.setChurchTaxFile(this.payoutData.dividendPayoutId, fileToUpload)

      res.ok
        ? this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
        : this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })

      this.isLoading = false
    },
  },
}
</script>
