<template>
  <div class="imprint-content--wrap">
    <v-card>
      <v-card-title>
        Müller-BBM AG
      </v-card-title>
      <v-card-text class="body-1">
        <p>
          Müller-BBM AG<br>
          Helmut-A.-Müller-Straße 1&nbsp;-&nbsp;5<br>
          82152 Planegg/München
        </p>
        <p>
          Executive Board: Dr.-Ing. Peter Romanow (Chairman), Joachim Bittner, Dr. rer. nat. Rolf Schirmacher <br>
          Chairman of the Supervisory Board: Prof. Dr.-Ing. Gerhard Müller
        </p>
        <p>
          German Commercial Register No.: HRB 177273<br>
          VAT No.: DE129421781
        </p>
        <p>
          Public Liability Insurance<br>
          Zurich Versicherung AG, 53287 Bonn<br>
          Territorial validity: Europe
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'imprint-en',
}

</script>
