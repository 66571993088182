<template>
  <div class="notification-overview--wrap">
    <div class="d-flex flex-wrap align-center mb-6">
      <h1 class="mr-auto">
        {{ $t('notifications.notifications') }}
      </h1>

      <v-switch
        v-model="showAlreadyRead"
        inset
        :label="$t('notifications.showAlreadyRead')"
        class="mr-4"
      />

      <read-all-notifications-btn />
    </div>

    <filters
      v-model="filters"
      class="mb-4"
    />

    <v-card>
      <notification-list
        :notifications="filteredNotifications"
        full-width-alert
        @click:read="markAsRead"
      />
    </v-card>
  </div>
</template>

<script>
import Filters from './Filters'
import NotificationList from '@/components/NotificationList'
import ReadAllNotificationsBtn from '@/components/ReadAllNotificationsBtn.vue'

export default {
  name: 'notification-overview',

  components: {
    Filters,
    NotificationList,
    ReadAllNotificationsBtn,
  },

  data () {
    return {
      showAlreadyRead: false,
      toRemove: null,
      filters: [],
    }
  },

  computed: {
    notifications () {
      return this.$store.state.notification.notifications
    },

    filteredNotifications () {
      return (this.showAlreadyRead ? this.notifications : this.unreadNotifications).filter(notification =>
        !this.filters.length || this.filters.find(filter => filter.type === notification.notificationTopic)
      )
    },

    unreadNotifications () {
      return this.$store.getters['notification/unreadNotifications']
    }
  },

  methods: {
    markAsRead (notification) {
      this.$store.dispatch('notification/markAsRead', notification.notificationId)
    },
  }
}
</script>
