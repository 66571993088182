<template>
  <v-dialog
    :value="isVisible"
    max-width="450"
    @input="visibility => !visibility && onCancel()"
  >
    <v-form
      ref="form"
      v-model="formIsValid"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <v-card-title>
          {{ toEdit === null ? $t('phantoms.transactions.newEntry') : $t('phantoms.transactions.editEntry') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model.number="phantomTransaction.accountId"
                :items="phantoms"
                item-text="combinedName"
                item-value="accountId"
                :label="$t('common.owner')"
                :rules="requiredRules"
                outlined
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <date-text-input
                :value="new Date(phantomTransaction.datePerformed) | isoDate"
                :rules="requiredRules"
                :label="$t('common.date')"
                @input="setDatePerformed"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.number="phantomTransaction.amount"
                hide-details
                outlined
                :label="$t('common.amount')"
                :rules="requiredRules"
                type="number"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn
            depressed
            @click="onCancel"
          >
            <v-icon
              color="red"
              left
            >
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            depressed
            type="submit"
            class="ml-auto"
            :disabled="!formIsValid"
          >
            <v-icon
              color="primary"
              left
            >
              mdi-pencil
            </v-icon>
            {{ toEdit === null ? $t('common.create') : $t('common.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AccountApi from '@/api/Account'
import DateTextInput from '@/components/DateTextInput'

const defaultPhantomTransaction = () => ({
  accountId: null,
  amount: 0,
  datePerformed: new Date(),
})

export default {
  name: 'transaction-dialog',

  components: {
    DateTextInput,
  },

  props: {
    // show the dialog
    isVisible: {
      type: Boolean,
      default: false,
    },

    // existing transaction to edit
    toEdit: {
      type: Object,
      default: null,
    }
  },

  data () {
    return {
      menu: false,
      formIsValid: false,
      phantomTransaction: defaultPhantomTransaction(),
      phantoms: [],
      requiredRules: [v => v !== undefined && v !== null && v !== ''],
    }
  },

  watch: {
    toEdit () {
      this.setTransaction()
    }
  },

  async mounted () {
    await this.getPhantoms()
    this.setTransaction()
  },

  methods: {
    /**
     * Bridge for setting the date (picker uses date-iso, server full-iso
     * without timezones).
     *
     * @param {string} isoDate Simple iso-date without time
     * @returns {string} Full iso-date without timezone
     */
    setDatePerformed (isoDate) {
      this.phantomTransaction = { ...this.phantomTransaction, datePerformed: new Date(isoDate).toISOString() }
    },

    /**
     * Loads all accounts from the api, removes non-phantoms and combines their
     * names.
     *
     * @returns {void}
     */
    async getPhantoms () {
      const res = await AccountApi.listAll()

      if (res.ok) {
        const users = await res.json()
        this.phantoms = users
          .filter(user => user.roles.includes('phantom'))
          .map(user => ({ ...user, combinedName: `${user.lastName} ${user.firstName}` }))
      }
    },

    /**
     * Use values of the given phantom-transaction instead of the defaults.
     *
     * @returns {void}
     */
    setTransaction () {
      this.toEdit && (this.phantomTransaction = { ...this.toEdit })
    },

    /**
     * Informs the parent component that the user wants to cancel the action,
     * resets values to defaults.
     *
     * @returns {void}
     */
    onCancel () {
      this.reset()
      this.$emit('cancel')
    },

    /**
     * Checks the form, communicates the dataset to the parent component if
     * everything is fine.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('submit', this.phantomTransaction)
        this.reset()
      }
    },

    /**
     * Resets values to defaults, as well as the validation.
     *
     * @returns {void}
     */
    reset () {
      this.phantomTransaction = defaultPhantomTransaction()
      this.$refs.form.resetValidation()
    },
  },
}
</script>
