<template>
  <v-list
    v-if="someRouteAllowed"
    :key="user ? user.accountId : null"
    dense
  >
    <v-subheader v-if="header" class="text-uppercase">
      {{ header }}
    </v-subheader>

    <template v-for="(item, i) in items">
      <v-list-group
        v-if="item.children && item.children.length"
        :key="i"
        :prepend-icon="item.icon"
        no-action
        color="white"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="(child, x) in item.children"
          :key="`${i}_${x}`"
          v-if-route-allowed
          :to="child.route ? { name: child.route } : undefined"
          :exact="item.route === 'Home'"
          color="white"
        >
          <v-list-item-title>
            {{ $t(child.label) }}
          </v-list-item-title>
          <v-list-item-icon v-if="child.icon">
            <v-icon style="opacity: 0.80;">
              {{ child.icon }}
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-list-item
        v-else
        :key="i"
        v-if-route-allowed
        :exact="item.route === 'Home'"
        :to="item.route ? { name: item.route } : undefined"
      >
        <v-list-item-icon v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'icon-list',

  props: {
    header: {
      type: String,
      default: null,
    },

    /**
     * item-props: "icon", "label", "route"
     */
    items: {
      type: Array,
      default: () => ([]),
    },
  },

  computed: {
    someRouteAllowed () {
      return this.items.reduce((someAllowed, { route }) => {
        const { resolved } = this.$router.resolve({ name: route })
        return someAllowed || this.$store.getters.routeAllowedForUser(resolved)
      }, false)
    },

    user () {
      return this.$store.state.user.user
    },
  },
}
</script>
