export default {
  editor: 'editor',
  ehemaliger: 'ehemaliger',
  exporter: 'exporter',
  guest: 'guest',
  management: 'vorstand',
  organe: 'organe',
  phantom: 'phantom',
  sellout: 'abverkauf',
  superadmin: 'superadmin',
  user: 'aktionaer',
}
