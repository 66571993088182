<template>
  <v-card class="sparkline-base--wrap fill-height">
    <v-card-title>
      {{ title }}
    </v-card-title>

    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>

    <v-skeleton-loader
      v-if="isLoading"
      :height="height"
      type="image"
    />

    <apex-chart
      v-else-if="hasChartData"
      :type="isLine ? 'line' : 'area'"
      width="100%"
      :height="height"
      :options="{...options, labels }"
      :series="series"
      class="mt-auto"
    />

    <slot v-else name="fallback" />
  </v-card>
</template>

<script>
import { merge } from 'lodash'

const defaultOptions = () => ({
  chart: {
    stacked: false,
    sparkline: {
      enabled: true
    },
  },
  colors: ['#009688', '#2196F3'],
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  xaxis: {
    type: 'datetime',
  },
  fill: {
    opacity: 0.75,
    type: 'solid'
  },
  tooltip: {
    x: {
      format: 'MM.yyyy'
    },
    followCursor: true,
    fixed: {
      enabled: false,
      position: 'topLeft',
      offsetX: 16,
    },
  },
})

export default {
  name: 'transaction-volume',

  props: {
    height: {
      type: [Number, String],
      default: 'auto',
    },

    labels: {
      type: Array,
      default: () => ([]),
    },

    optionChanges: {
      type: Object,
      default: () => ({}),
    },

    series: {
      type: Array,
      default: () => ([]),
    },

    title: {
      type: [Number, String],
      default: '',
    },

    subtitle: {
      type: [Number, String],
      default: '',
    },

    isLine: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // has the chart already loaded data to show?
    hasChartData () {
      return this.isLoading || !this.series.length
        ? false
        : this.series.some(({ data }) => data && data.length)
    },

    options () {
      return merge(defaultOptions(), this.optionChanges)
    },
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style lang="scss">
  .sparkline-base--wrap {
    &.v-card {
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .apexcharts-legend {
        &.apexcharts-align-left {
          .apexcharts-legend-series {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
  }
</style>
