import Api from '@/api/Api'

export default {
  baseUrl: 'translation/translation',

  /**
   * get
   *
   * @returns {Promise}
   */
  get () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * set
   *
   * @param {object} translations
   * @returns {Promise}
   */
  set (translations) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({ data: translations }),
    })
  },
}
