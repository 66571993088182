<template>
  <v-dialog
    :value="payoutId !== null"
    width="600"
    max-width="95%"
    @input="visible => !visible && $emit('close')"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        {{ $t('dividendPayout.notices') }}
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-sheet
          v-if="!isLoading && notices.length === 0"
          color="success"
          class="white--text pa-4 my-2"
        >
          {{ $t('dividendPayout.no_notices') }}
        </v-sheet>

        <v-list v-if="notices.length > 0" dense>
          <template v-for="([name, info, type], i) in notices">
            <v-list-item :key="i">
              <v-list-item-icon class="align-self-center">
                <v-icon :color="type === 'warning' ? 'warning' : 'error'">
                  {{ type === 'warning' ? 'mdi-alert' : 'mdi-alert-circle' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t(`dividendPayout.notices.${info}`) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="i < notices.length - 1"
              :key="`div_${i}`"
            />
          </template>
        </v-list>
      </v-card-text>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          depressed
          @click="$emit('close')"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DividendPayoutApi from '@/api/DividendPayout.js'

export default {
  name: 'payout-notices-dialog',

  props: {
    payoutId: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      isLoading: false,
      notices: [],
    }
  },

  watch: {
    payoutId () {
      this.payoutId
        ? this.getNotices()
        : this.notices = []
    },
  },

  methods: {
    /**
     * getNotices
     *
     * @returns {Promise}
     */
    async getNotices () {
      if (!this.payoutId) {
        return
      }

      this.isLoading = true
      const res = await DividendPayoutApi.get(this.payoutId)

      this.isLoading = false
      this.notices = res.ok
        ? (await res.json())?.notices || []
        : []
    },
  },
}
</script>
