var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-table--wrap"},[_c('data-table',{attrs:{"columns":_vm.columns,"default-options":{ sortBy: ['date'], sortDesc: [true] },"custom-page-size":25,"items":_vm.filteredDocuments,"hide-footer":_vm.dashboardMode,"show-search":_vm.showSearch,"is-loading":_vm.isLoading,"title":_vm.title},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_vm._t("headerActions")]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.date))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.getDownloadLink(item.documentId),"target":"_blank"}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.companyId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyLabel(item.companyId))+" ")]}},{key:"item.displayAreas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReadableDisplayAreas(item.displayAreas) || '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-file-download","icon-color":"primary","tooltip":_vm.$t('common.download'),"href":_vm.getDownloadLink(item.documentId),"target":"_blank"}}),_c('table-button',{attrs:{"icon":"mdi-eye","tooltip":_vm.$t('common.show')},on:{"click":function($event){_vm.documentToShow = item}}})]}}],null,true)}),_c('v-dialog',{attrs:{"value":_vm.documentToShow !== null,"width":700,"max-width":"90%"},on:{"input":function (visible) { return !visible && (_vm.documentToShow = null); }}},[(_vm.documentToShow)?_c('v-card',[_c('v-card-title',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.documentToShow.title)+" ")]),_c('v-card-subtitle',[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-calendar ")]),_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(_vm.documentToShow.date))+" ")],1),_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.$t(("document.type." + (_vm.documentToShow.documentType))))+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.documentToShow.description)+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"href":_vm.getDownloadLink(_vm.documentToShow.documentId),"target":"_blank","depressed":""}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v(" mdi-file-download ")]),_vm._v(" "+_vm._s(_vm.$t('common.download'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.documentToShow = null}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }