<template>
  <v-card class="donut-base--wrap fill-height" :flat="flat">
    <v-card-title v-if="title !== ''">
      {{ title }}
    </v-card-title>

    <v-card-subtitle v-if="subtitle !== ''">
      {{ subtitle }}
    </v-card-subtitle>

    <v-skeleton-loader
      v-if="loading"
      :height="height"
      type="image"
      tile
    />

    <apex-chart
      v-if="series.length && !loading"
      type="donut"
      width="100%"
      :height="height"
      :options="options"
      :series="series"
      class="mb-5"
    />
  </v-card>
</template>

<script>
import { merge } from 'lodash'
import chartColors from '@/components/charts/chart-colors'

export default {
  name: 'donut-base',

  props: {
    flat: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [Number, String],
      default: 'auto',
    },

    labels: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    optionChanges: {
      type: Object,
      default: () => ({}),
    },

    series: {
      type: Array,
      default: () => ([]),
    },

    title: {
      type: [Number, String],
      default: '',
    },

    subtitle: {
      type: [Number, String],
      default: '',
    },
  },

  computed: {
    options () {
      return merge(this.getDefaultOptions(), this.optionChanges, { labels: this.labels })
    },
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
  },

  methods: {
    getDefaultOptions () {
      return {
        colors: chartColors,
        legend: {
          position: 'bottom',
          markers: {
            radius: 2,
          }
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          background: {
            enabled: true,
            fill: 1,
            foreColor: '#000',
          },
          dropShadow: {
            enabled: false,
          }
        },

        tooltip: {
          y: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale)
          },
        },

        plotOptions: {
          pie: {
            donut: {
              size: '55%',
              labels: {
                show: true,
                total: {
                  show: true,
                  formatter: w => {
                    const total = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)

                    return this.$options.filters.localeNumber(total, this.$i18n.locale)
                  },
                },
                value: {
                  formatter: val => {
                    return this.$options.filters.localeNumber(+val, this.$i18n.locale)
                  }
                },
              }
            }
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-card {
    overflow: hidden;
  }
</style>
