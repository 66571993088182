<template>
  <div class="imprint-content--wrap">
    <v-card>
      <v-card-title>
        Müller-BBM AG
      </v-card-title>
      <v-card-text class="body-1">
        <p>
          Helmut-A.-Müller-Straße 1&nbsp;-&nbsp;5<br>
          82152 Planegg/München
        </p>
        <p>
          Vorstand: Dr.-Ing. Peter Romanow (Vorsitzender), Joachim Bittner, Dr. rer. nat. Rolf Schirmacher <br>
          Vorsitzender des Aufsichtsrats: Prof. Dr.-Ing. Gerhard Müller
        </p>
        <p>
          Handelsregister Nr. HRB 177273<br>
          Ust ID-Nr. DE129421781
        </p>
        <p>
          Berufshaftpflichtversicherung<br>
          Zurich Versicherung AG, 53287 Bonn<br>
          Räumlicher Geltungsbereich: Europa
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'imprint-de',
}

</script>
