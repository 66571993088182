<template>
  <div class="user-form--wrap">
    <v-form
      ref="form"
      v-model="formIsValid"
      @submit.prevent="onSubmit"
    >
      <v-card class="mb-10" outlined>
        <v-card-title>
          <v-icon left>
            mdi-account
          </v-icon>
          {{ $t('users.person') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="user.salutationAddress"
                dense
                :label="$t('forms.salutation')"
                required
                hide-details="auto"
                outlined
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="user.academicTitle"
                dense
                :label="$t('forms.titleAcademic')"
                hide-details="auto"
                outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.nobilityTitle"
                dense
                :label="$t('forms.titleNobility')"
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.firstName"
                dense
                :label="`${$t('forms.firstName')}\xa0*`"
                required
                hide-details="auto"
                outlined
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.lastName"
                dense
                :label="`${$t('forms.lastName')}\xa0*`"
                required
                hide-details="auto"
                outlined
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.salutationSignature"
                dense
                :label="$t('forms.signature')"
                required
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.email"
                dense
                type="email"
                :label="`${$t('forms.emailAddress')}\xa0*`"
                hide-details="auto"
                outlined
                :rules="requiredRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="3">
              <date-text-input
                v-model="user.birthdate"
                birthday-mode
                :label="`${$t('forms.dateOfBirth')}\xa0*`"
                input-class="pa-0"
                dense
                :disabled="!isAdmin"
                :readonly="!isAdmin"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                v-model="user.language"
                :label="`${$t('forms.language')}\xa0*`"
                hide-details="auto"
                outlined
                dense
                :items="languages"
                item-text="label"
                item-value="key"
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="user.notificationTopics"
                :items="notificationTopics"
                :label="$t('forms.notificationTopics')"
                hide-details="auto"
                outlined
                dense
                multiple
                small-chips
              >
                <template #item="{ item }">
                  {{ $t(`notifications.topics.${item}`) }}
                </template>
                <template #selection="{ item }">
                  <v-chip small>
                    {{ $t(`notifications.topics.${item}`) }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-10" outlined>
        <v-card-title>
          <v-icon left>
            mdi-card-account-mail
          </v-icon>
          {{ $t('users.reachability') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="user.country"
                :items="countries"
                item-text="country"
                item-value="value"
                :label="`${$t('common.country')}\xa0*`"
                hide-details="auto"
                outlined
                dense
                :rules="requiredRules"
              >
                <template #item="{ item }">
                  {{ $t(`countries.${item}`) }}
                </template>
                <template #selection="{ item }">
                  {{ $t(`countries.${item}`) }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.address1"
                dense
                :label="`${$t('forms.postalAddress1')}\xa0*`"
                :placeholder="$t('forms.postalAddress1_placeholder')"
                required
                hide-details="auto"
                outlined
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.address2"
                dense
                :label="`${$t('forms.postalAddress2')}\xa0*`"
                :placeholder="$t('forms.postalAddress2_placeholder')"
                required
                hide-details="auto"
                outlined
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.address3"
                dense
                :label="$t('forms.postalAddress3')"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.address4"
                dense
                :label="$t('forms.postalAddress4')"
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-10" outlined>
        <v-card-title>
          <v-icon left>
            mdi-bank
          </v-icon>
          {{ $t('users.bankData') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="user.iban"
                dense
                :label="$t('forms.iban')"
                :placeholder="$t('forms.iban_placeholder')"
                :rules="[checkIban]"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="user.bic"
                dense
                :label="$t('forms.bic')"
                :placeholder="$t('forms.bic_placeholder')"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="user.bank"
                dense
                :label="$t('forms.creditInstitution')"
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="isAdmin" class="mb-10" outlined>
        <v-card-title>
          <v-icon left>
            mdi-cog
          </v-icon>
          {{ $t('users.misc') }}
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="user.taxId"
                dense
                :label="$t('forms.taxId')"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="user.personId"
                dense
                :label="$t('forms.personId')"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="user.companyId"
                :items="companies"
                item-text="name"
                item-value="companyId"
                :label="`${$t('common.company')}\xa0*`"
                hide-details="auto"
                outlined
                dense
                :rules="requiredRules"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="user.status"
                :items="status"
                :label="`${$t('forms.status')}\xa0*`"
                hide-details="auto"
                outlined
                dense
                :rules="requiredRules"
              >
                <template #item="{ item }">
                  {{ $t(`status.${item}`) }}
                </template>
                <template #selection="{ item }">
                  {{ $t(`status.${item}`) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                v-model="user.roles"
                :items="roles"
                :label="$t('forms.role')"
                hide-details="auto"
                outlined
                multiple
                dense
                small-chips
              >
                <template #item="{ item }">
                  {{ $t(`roles.${item}`) }}
                </template>
                <template #selection="{ item }">
                  <v-chip small>
                    {{ $t(`roles.${item}`) }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="mt-2 d-flex align-center flex-wrap">
              <v-switch
                v-model="user.isActive"
                :label="$t('forms.active')"
                hide-details="auto"
                inset
                class="pa-0 ml-2 mr-10 my-0"
              />
              <v-switch
                v-model="user.forcePasswordChange"
                :label="$t('forms.forcePasswordChange')"
                hide-details="auto"
                inset
                class="pa-0 ma-0"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="d-flex  ">
        <delete-user-button
          v-if="user.accountId"
          :id-to-delete="user.accountId"
          class="mr-2"
          @deleted:user="$router.push({ name: 'Users' })"
        />

        <v-spacer />

        <v-btn type="submit" color="primary" depressed :disabled="!formIsValid">
          {{ isEdit ? $t('common.update') : $t('common.create') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import * as ibantools from 'ibantools'
import roles from '@/roles'

import AccountApi from '@/api/Account'
import CompanyApi from '@/api/Company'
import CountryCodes from '@/assets/data/countries.js'
import DateTextInput from '@/components/DateTextInput'
import DeleteUserButton from '@/components/DeleteUserButton'
import LanguageSwitch from '@/mixins/LanguageSwitch'
import NotificationApi from '@/api/Notification'

export default {
  name: 'user-form',

  components: {
    DateTextInput,
    DeleteUserButton,
  },

  mixins: [
    LanguageSwitch,
  ],

  props: {
    userToEdit: {
      type: Object,
      default: null,
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      companies: [],
      countries: CountryCodes,
      formIsValid: false,
      roles: [],
      status: [],
      notificationTopics: [],

      user: {
        academicTitle: '',
        accountId: null,
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        bank: '',
        bic: '',
        birthdate: '',
        companyId: null,
        country: '',
        email: '',
        firstName: '',
        forcePasswordChange: false,
        iban: '',
        isActive: true,
        language: '',
        lastName: '',
        nobilityTitle: '',
        notificationTopics: [],
        personId: null,
        roles: [],
        salutationAddress: '',
        salutationSignature: '',
        status: '',
        taxId: '',
      },
    }
  },

  computed: {
    isEdit () {
      return this.userToEdit !== null
    },

    isAdmin () {
      return this.$store.getters.userHasRole([roles.superadmin, roles.editor])
    },

    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    },

    userRoles () {
      return this.user.roles
    },
  },

  watch: {
    userRoles (to, from) {
      if (to.includes(roles.user) && !from.includes(roles.user)) {
        this.setDefaultNotificationTopics()
      }
    },

    userToEdit () {
      this.setUser()
    }
  },

  async mounted () {
    await this.getCompanies()
    await this.getRoles()
    await this.getStatus()
    await this.getNotificationTopics()

    this.setUser()
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()

      if (res.ok) {
        const companies = await res.json()
        this.companies = companies.sort((a, b) => a.name.localeCompare(b.name))
      }
    },

    /**
     * Loads available roles.
     *
     * @returns {void}
     */
    async getRoles () {
      const res = await AccountApi.getRoles()
      res.ok && (this.roles = await res.json())
    },

    /**
     * Loads available account-status.
     *
     * @returns {void}
     */
    async getStatus () {
      const res = await AccountApi.getStatus()
      res.ok && (this.status = await res.json())
    },

    /**
     * Loads available notification-topics.
     *
     * @returns {void}
     */
    async getNotificationTopics () {
      const res = await NotificationApi.getTopics()
      res.ok && (this.notificationTopics = await res.json())
    },

    /**
     * Emits the current user-dataset to the parent component if the form is
     * valid.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('submit', this.user)
        this.$refs.form.resetValidation()
      }
    },

    /**
     * If a specific role is assigned to an user, some of the possible
     * notification-topics should get preselected.
     *
     * @returns {undefined}
     */
    setDefaultNotificationTopics () {
      this.notificationTopics
        .filter(topic => topic !== 'offer')
        .forEach(topic =>
          !this.user.notificationTopics.includes(topic) && this.user.notificationTopics.push(topic)
        )
    },

    /**
     * Uses the user-dataset given as a property instead of the current one.
     *
     * @returns {void}
     */
    setUser () {
      this.isEdit && (this.user = { ...this.userToEdit })
    },

    checkIban (iban) {
      return iban && iban.length ? ibantools.isValidIBAN(iban) : true
    }
  }
}
</script>
