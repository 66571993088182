<template>
  <v-sheet
    v-if="transactionType"
    height="20"
    rounded
    class="transaction-type-indicator d-inline-flex align-center"
  >
    <v-chip class="light-background" :color=" color" label />

    <div class="chip-content">
      <v-tooltip v-if="compact" bottom>
        <template #activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            :color="color"
            size="20"
            tile
            v-on="on"
          >
            <v-icon color="white" size="15">
              {{ icon }}
            </v-icon>
          </v-avatar>
        </template>
        <span>
          {{ label }}
        </span>
      </v-tooltip>

      <template v-else>
        <v-avatar v-if="icon" size="20" :color="color" tile class="mr-1">
          <v-icon color="white" size="15">
            {{ icon }}
          </v-icon>
        </v-avatar>

        <span class="text-caption mr-2">
          {{ label }}
        </span>
      </template>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'transaction-type-chip',

  props: {
    compact: {
      type: Boolean,
      default: false,
    },

    transactionType: {
      type: String,
      default: ''
    },
  },

  computed: {
    type () {
      return this.visualizations[this.transactionType]
    },

    color () {
      return this.type ? this.type.color : null
    },

    icon () {
      return this.type ? this.type.icon : null
    },

    label () {
      return this.type ? this.type.label : this.transactionType
    },

    visualizations () {
      return {
        initial: {
          icon: 'mdi-clock-check-outline',
          color: 'grey',
          label: this.$t('transactions.initial')
        },
        buy: {
          icon: 'mdi-bank-transfer-in',
          color: 'light-green',
          label: this.$t('transactions.buy'),
        },
        sell: {
          icon: 'mdi-bank-transfer-out',
          color: 'red lighten-1',
          label: this.$t('transactions.sell'),
        },
        buy_sell: {
          icon: 'mdi-bank-transfer',
          color: 'blue-grey lighten-2',
          label: this.$t('transactions.buySell'),
        },
        NEW: {
          icon: 'mdi-autorenew',
          color: 'blue accent-3',
          label: this.$t('transactions.new'),
        },
        new_issue: {
          icon: 'mdi-alert-circle-outline',
          color: 'deep-orange accent-3',
          label: this.$t('transactions.newIssue'),
        },
        SPLIT: {
          icon: 'mdi-call-split',
          color: 'grey darken-3',
          label: this.$t('transactions.split'),
        },
        split_in: {
          icon: 'mdi-call-split',
          color: 'grey darken-3',
          label: this.$t('transactions.splitIn'),
        },
        split_out: {
          icon: 'mdi-call-split',
          color: 'grey darken-3',
          label: this.$t('transactions.splitOut'),
        },
        cancelled: {
          icon: 'mdi-file-cancel',
          color: 'deep-purple accent-3',
          label: this.$t('transactions.canceled'),
        },
        bonus: {
          icon: 'mdi-calendar-plus',
          color: 'indigo',
          label: this.$t('transactions.bonus'),
        },
        extra: {
          icon: 'mdi-plus',
          color: 'indigo accent-4',
          label: this.$t('transactions.extra'),
        },
      }
    }
  },
}
</script>

<style lang="scss">
  .transaction-type-indicator {
    position: relative;
    overflow: hidden;
    z-index: 1;

    .light-background {
      opacity: 0.15;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    .chip-content {
      position: relative;
      z-index: 1;
    }
  }
</style>
