<template>
  <div class="month-calendar--wrap">
    <v-card class="fill-height">
      <v-card-title>
        {{ $t('calendar.calendar') }}

        <v-spacer />

        <div class="d-flex flex-wrap align-center justify-end">
          <v-icon small left>
            mdi-calendar
          </v-icon>
          <span class="text-caption">
            {{ start | readableIsoDate }} - {{ end | readableIsoDate }}
          </span>

          <v-btn small icon class="ml-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn small icon class="ml-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-calendar
          ref="calendar"
          v-model="calendar"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          type="month"
          :events="dates"
          :event-color="getEventColor"
          event-name="title"
          event-overlap-mode="stack"
          @click:event="({ event }) => eventToShow = event"
          @change="onCalendarChange"
        />
      </v-card-text>
    </v-card>

    <event-dialog :event="eventToShow" @close="eventToShow = null" />
  </div>
</template>

<script>
import AppointmentApi from '@/api/Appointment'
import EventDialog from '@/components/EventDialog'
import { getEventColor } from '@/services/event-colors'

export default {
  name: 'month-calendar',

  components: {
    EventDialog,
  },

  data () {
    return {
      end: null,
      start: null,
      calendar: null,
      dates: [],
      eventToShow: null,
    }
  },

  mounted () {
    this.loadDates()
  },

  methods: {
    /**
     * Loads all available dates, formats those so they can get used by the
     * calendar-plugin (needs real dates, start+end).
     *
     * @returns {void}
     */
    async loadDates () {
      const res = await AppointmentApi.getAll()

      if (res.ok) {
        const dates = await res.json()

        this.dates = dates.map(date => ({
          ...date,
          start: new Date(date.date),
          end: new Date(date.date),
        }))
      }
    },

    /**
     * Returns the color to use for the given event or a fallback.
     *
     * @param {object} event
     * @returns {string}
     */
    getEventColor (event) {
      return getEventColor(event.appointmentType)
    },

    /**
     * When users switch the month to show, we have to keep our state up to
     * date manually.
     *
     * @param {object} event
     * @param {object} event.start
     * @param {object} event.end
     * @returns {void}
     */
    onCalendarChange ({ start, end }) {
      this.start = start.date
      this.end = end.date
    },
  },
}
</script>
