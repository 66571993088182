<template>
  <v-dialog
    :value="company !== null"
    :width="900"
    max-width="90%"
    @input="visibility => !visibility && $emit('close')"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        {{ $t('companyData.configuration.title') }}
      </v-card-title>

      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>
                {{ $t('companyData.configuration.validFrom') }}
              </th>
              <th>
                {{ $t('companyData.configuration.holdingPeriodYears') }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="config in configurations"
              :key="config.companyConfigurationId"
            >
              <td>
                {{ config.validFrom | readableIsoDate(true) }}
              </td>
              <td>
                {{ config.holdingPeriodYears }}
              </td>
              <td class="text-right">
                <table-button
                  :disabled="configIsInPast(config)"
                  icon="mdi-pencil"
                  icon-color="primary"
                  :tooltip="configIsInPast(config) ? $t('configuration.pastNotAllowed') : $t('common.edit')"
                  @click="showEditConfigDialog(config)"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          elevation="0"
          @click="showConfigDialog = true"
        >
          {{ $t('companyData.configuration.newConfiguration') }}
        </v-btn>

        <v-btn depressed class="ml-auto" @click="$emit('close')">
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <create-update-dialog
      :is-visible="showConfigDialog"
      :config-to-edit="configToEdit"
      @close="onCloseCreateUpdateDialog()"
      @submit="config => createOrUpdateConfiguration(config)"
    />
  </v-dialog>
</template>

<script>
import CompanyApi from '@/api/Company'
import TableButton from '@/components/TableButton'
import CreateUpdateDialog from './CreateUpdateDialog.vue'

export default {
  name: 'company-dialog',

  components: {
    CreateUpdateDialog,
    TableButton,
  },

  props: {
    company: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      configToEdit: null,
      configurations: [],
      formIsValid: false,
      showConfigDialog: false,
      isLoading: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },
  },

  watch: {
    company () {
      this.company !== null && this.loadCompanyConfigurations()
    }
  },

  methods: {
    /**
     * createOrUpdateConfiguration
     *
     * @param {object} config
     * @returns {void}
     */
    async createOrUpdateConfiguration (config) {
      this.isLoading = true

      const res = this.configToEdit
        ? await CompanyApi.updateConfiguration(this.company.companyId, config)
        : await CompanyApi.createConfiguration(this.company.companyId, config)

      if (res.ok) {
        this.onCloseCreateUpdateDialog()
        await this.loadCompanyConfigurations()
      }

      this.isLoading = false
    },

    /**
     * Loads the configurations of the current company.
     *
     * @returns {Promise}
     */
    async loadCompanyConfigurations () {
      const res = await CompanyApi.getConfigurationList(this.company.companyId)
      res.ok && (this.configurations = await res.json())
    },

    /**
     * Checks if the given configuration is in the past.
     *
     * @param {object} config
     * @returns {boolean}
     */
    configIsInPast (config) {
      return new Date(config.validFrom) < new Date()
    },

    /**
     * onCloseCreateUpdateDialog
     *
     * @returns {undefined}
     */
    onCloseCreateUpdateDialog () {
      this.showConfigDialog = false
      this.configToEdit = null
    },

    /**
     * showEditConfigDialog
     *
     * @param {object} config
     * @returns {undefined}
     */
    showEditConfigDialog (config) {
      this.showConfigDialog = true
      this.configToEdit = config
    },
  },
}
</script>
