import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import ifRouteAllowed from './directives/ifRouteAllowed'

import { isoDate, readableDate, readableIsoDate } from './filters/dates'
import { localeNumber, readablePrice } from './filters/numbers'

Vue.filter('isoDate', isoDate)
Vue.filter('localeNumber', localeNumber)
Vue.filter('readableDate', readableDate)
Vue.filter('readableIsoDate', readableIsoDate)
Vue.filter('readablePrice', readablePrice)

Vue.directive('ifRouteAllowed', ifRouteAllowed)

Vue.use(VueI18n)
Vue.use(VueApexCharts)
Vue.component('apex-chart', VueApexCharts)

// translations will get loaded on startup
export const i18n = new VueI18n({ locale: 'de-DE', messages: {} })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
