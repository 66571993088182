<template>
  <v-dialog
    :value="isVisible"
    :width="700"
    max-width="90%"
    @input="visibility => !visibility && close()"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ configToUse ? $t('configuration.edit') : $t('configuration.create') }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                :value="configuration.formulaPrice / 100"
                :label="$t('configuration.formulaPrice')"
                type="number"
                outlined
                hide-details
                class="mb-2"
                :rules="requiredRules"
                @input="formulaPrice => configuration.formulaPrice = formulaPrice * 100"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="configuration.minimumShares"
                :label="$t('configuration.minimumShares')"
                type="number"
                outlined
                hide-details
                class="mb-2"
                :rules="requiredRules"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="configuration.holdingReturnNumerator"
                :label="$t('configuration.holdingReturnNumerator')"
                type="number"
                outlined
                hide-details
                class="mb-2"
                :rules="requiredRules"
                append-icon="mdi-percent"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <date-text-input
                :value="configuration.validFrom | isoDate"
                :label="$t('configuration.validFrom')"
                :rules="requiredRules"
                @input="isoDate => configuration.validFrom = new Date(isoDate)"
              />
            </v-col>
            <v-col>
              <time-text-input
                :value="configuration.validFrom"
                :label="$t('configuration.validFrom')"
                @input="onTimeChange"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn depressed class="ml-auto" @click="close">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>

          <v-btn depressed class="ml-2" type="submit">
            <v-icon left color="primary">
              mdi-pencil
            </v-icon>
            {{ configToUse ? $t('common.save') : $t('common.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DateTextInput from '@/components/DateTextInput'
import TimeTextInput from '@/components/TimeTextInput'

const defaultConfig = () => ({
  formulaPrice: 0,
  holdingReturnDenominator: 100,
  holdingReturnNumerator: 0,
  minimumShares: 0,
  validFrom: new Date(),
})

export default {
  name: 'configuration-dialog',

  components: {
    DateTextInput,
    TimeTextInput,
  },

  props: {
    // show the dialog
    isVisible: {
      type: Boolean,
      default: false,
    },

    // use an existing dataset
    configToUse: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      configuration: defaultConfig(),
      formIsValid: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },
  },

  watch: {
    configToUse () {
      this.useConfig()
    }
  },

  mounted () {
    this.useConfig()
  },

  methods: {
    /**
     * Tells the parent component that the dialog can get closed, resets the
     * form-validation.
     *
     * @returns {void}
     */
    close () {
      this.$emit('close')
      this.$refs.form.resetValidation()
    },

    /**
     * Communicates the current configuration to the parent component if the
     * form is valid.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('submit', this.configuration)
        this.configuration = defaultConfig()
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Uses the config given as a prop or the default for new datasets.
     *
     * @returns {void}
     */
    useConfig () {
      this.configuration = this.configToUse
        ? { ...this.configToUse, validFrom: new Date(this.configToUse.validFrom) }
        : defaultConfig()
    },

    onTimeChange (date) {
      this.configuration = { ...this.configuration, validFrom: date }
    }
  }
}
</script>
