<template>
  <div class="cms--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.cms') }}
    </h1>

    <v-tabs color="grey darken-4" background-color="transparent" class="mb-10">
      <v-tab :to="{ name: 'CmsDocuments' }">
        <v-icon left>
          mdi-file-document-outline
        </v-icon>
        {{ $t('cms.documents') }}
      </v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'cms-index',
}
</script>
