<template>
  <div class="mails-sent--wrap">
    <data-table
      :columns="columns"
      :items="outbox"
      :title="$t('emails.archiveHeadline')"
      :default-options="{ sortBy: ['sentAt'], sortDesc: [true] }"
      :is-loading="isLoading"
    >
      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-email-open-outline"
          :tooltip="$t('emails.showEmail')"
          @click="mailToShow = item"
        />

        <table-button
          icon="mdi-account-multiple"
          :tooltip="$t('emails.showRecipientList')"
          @click="recipientsToShow = item.receiverListDto"
        />

        <table-button
          icon="mdi-delete"
          icon-color="red"
          :tooltip="$t('emails.deleteEmail')"
          @click="toRemove = item"
        />
      </template>

      <template #[`item.sentAt`]="{ item }">
        {{ item.sentAt | readableIsoDate(true) }}
      </template>

      <template #[`item.attachments`]="{ item }">
        {{ item.documentId ? 1 : 0 }}
      </template>
    </data-table>

    <recipient-dialog
      :is-visible="recipientsToShow.length > 0"
      :recipients="recipientsToShow"
      @cancel="recipientsToShow = []"
    />

    <confirm-dialog
      :headline="$t('emails.confirmEntryDeletion')"
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeEntry"
    />

    <v-dialog
      :value="mailToShow !== null"
      scrollable
      width="1100"
      max-width="90%"
      content-class="mail-dialog"
      @input="visibility => !visibility && (mailToShow = null)"
    >
      <v-card v-if="mailToShow">
        <v-card-title class="mb-2">
          {{ mailToShow.subject }}
          <v-chip small color="secondary" class="ml-auto">
            {{ mailToShow.sentAt | readableIsoDate(true) }}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <blockquote>
            {{ mailToShow.content }}
          </blockquote>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="recipientsToShow = mailToShow.receiverListDto">
            <v-icon left>
              mdi-account-multiple
            </v-icon>
            {{ $t('emails.showRecipientList') }}
          </v-btn>
          <v-spacer />
          <v-btn depressed @click="mailToShow = null">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import MessageApi from '@/api/Message'
import RecipientDialog from './RecipientDialog'
import TableButton from '@/components/TableButton'

export default {
  name: 'mails-sent',

  components: {
    ConfirmDialog,
    DataTable,
    RecipientDialog,
    TableButton,
  },

  data () {
    return {
      outbox: [],
      recipientsToShow: [],
      toRemove: null,
      mailToShow: null,
      isLoading: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('emails.subject'), value: 'subject' },
        { text: this.$t('emails.sentAt'), value: 'sentAt' },
        { text: this.$t('emails.attachments'), value: 'attachments' },
        { text: this.$t('common.amount'), value: 'amount', align: 'right' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, width: '140px', align: 'right' },
      ]
    }
  },

  mounted () {
    this.getOutbox()
  },

  methods: {
    /**
     * Loads already sent messages.
     *
     * @returns {void}
     */
    async getOutbox () {
      this.isLoading = true

      const res = await MessageApi.getOutbox()
      res.ok && (this.outbox = await res.json())

      this.isLoading = false
    },

    /**
     * Removes the confirmed entry.
     *
     * @returns {void}
     */
    async removeEntry () {
      const res = await MessageApi.removeFromOutbox(this.toRemove.messageId)

      this.$store.commit('setSnackbar', {
        text: res.ok ? this.$t('emails.entryDeleted') : this.$t('common.errorOccured'),
        color: res.ok ? 'success' : 'error',
      })

      this.toRemove = null
      this.getOutbox()
    },
  }
}
</script>

<style lang="scss">
  .mail-dialog {
    blockquote {
      white-space: pre-wrap;
    }
  }
</style>
