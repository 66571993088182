<template>
  <div
    v-if="currentUser.accountId !== idToDelete"
    class="delete-user-button--wrap d-inline-block"
  >
    <table-button
      v-if="tableVariant"
      :tooltip="$t('common.delete')"
      icon="mdi-delete"
      icon-color="red"
      dark
      @click="showDeleteConfirmation = true"
    />

    <v-btn
      v-else
      type="button"
      color="red"
      dark
      depressed
      @click="showDeleteConfirmation = true"
    >
      <v-icon left>
        mdi-delete
      </v-icon>
      {{ $t('common.delete') }}
    </v-btn>

    <confirm-dialog
      :is-visible="showDeleteConfirmation"
      @cancel="showDeleteConfirmation = false"
      @ok="deleteUser"
    />

    <confirm-dialog
      :headline="$t('users.deleteNotPossible')"
      info-text=""
      :cancel-text="$t('common.close')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="conflicts.length > 0"
      @cancel="conflicts = []"
      @ok="conflicts = []"
    >
      <v-list>
        <template v-for="(conflict, i) in conflicts">
          <v-list-item :key="i">
            <v-list-item-icon>
              <v-icon color="red">
                mdi-alert-circle-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`users.errors.${conflict}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < conflicts.length - 1" :key="`divider_${i}`" />
        </template>
      </v-list>
    </confirm-dialog>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'
import ConfirmDialog from '@/components/ConfirmDialog'
import TableButton from '@/components/TableButton'

export default {
  name: 'delete-user-button',

  components: {
    ConfirmDialog,
    TableButton,
  },

  props: {
    idToDelete: {
      type: Number,
      default: null,
      required: true,
    },

    tableVariant: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      conflicts: [],
      showDeleteConfirmation: false,
    }
  },

  computed: {
    currentUser () {
      return this.$store.state.user.user
    },
  },

  methods: {
    /**
     * Deletes the currently shown user. If there are any conflicts, a dialog
     * gets shown.
     *
     * @returns {void}
     */
    async deleteUser () {
      this.showDeleteConfirmation = false
      const res = await AccountApi.delete(this.idToDelete)

      if (res.ok) {
        this.$emit('deleted:user')
        return this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
      }

      const { conflicts } = await res.json()
      this.conflicts = conflicts
    },
  },
}
</script>
