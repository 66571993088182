<template>
  <v-dialog
    :value="isVisible"
    scrollable
    width="800"
    max-width="90%"
    content-class="template-dialog"
    @input="visibility => !visibility && $emit('cancel')"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card v-if="template" :loading="requestInProgress">
        <v-card-title>
          {{ $t('emails.template') }} {{ template.id }}
        </v-card-title>

        <v-card-text style="max-height: 75vh;">
          <v-row class="mb-4">
            <v-col :cols="6">
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account-box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('emails.sender') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ template.senderName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-format-title</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('emails.subject') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ template.subject }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('emails.sentAt') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span v-if="template.lastSentAt">
                        {{ template.lastSentAt | readableIsoDate(true) }}
                      </span>
                      <span v-else>-</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="document">
                  <v-list-item-icon>
                    <v-icon>mdi-attachment</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('emails.attachments') }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <a
                        :href="documentDownloadLink"
                        target="_blank"
                        class="text-decoration-none"
                      >
                        {{ document.title }}
                      </a>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col :cols="6">
              <v-select
                v-model="chosenRecipientType"
                :items="recipientTypes"
                item-value="label.de-DE"
                item-text="label"
                return-object
                :label="$t('emails.sendTemplateTo')"
                prepend-inner-icon="mdi-account-multiple"
                dense
                outlined
                hide-details
                class="my-5"
                :rules="[v => !!v || $t('common.requiredField')]"
              >
                <template #item="{ item }">
                  {{ item.label[$i18n.locale] }}
                </template>
                <template #selection="{ item }">
                  {{ item.label[$i18n.locale] }}
                </template>
              </v-select>

              <v-btn
                v-if="chosenRecipientType"
                depressed
                @click="showRecipientList = true"
              >
                <v-icon left>
                  mdi-account-multiple
                </v-icon>
                {{ $t('emails.showRecipientList') }}
              </v-btn>
            </v-col>
          </v-row>

          <h2 class="mb-2 text-h6">
            {{ $t('emails.message') }}
          </h2>

          <blockquote
            class="text--secondary"
            v-text="template.content"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn depressed @click="$emit('cancel')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>
          <v-btn
            depressed
            class="ml-2"
            type="submit"
            :disabled="!formIsValid || requestInProgress"
            :loading="requestInProgress"
          >
            <v-icon left color="primary">
              mdi-send
            </v-icon>
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <recipient-dialog
      :is-visible="showRecipientList"
      :recipients="chosenRecipientType ? chosenRecipientType.messageReceivers : []"
      :width="800"
      @cancel="showRecipientList = false"
    />
  </v-dialog>
</template>

<script>
import DocumentApi from '@/api/Documents'
import MessageApi from '@/api/Message'
import RecipientDialog from './RecipientDialog'

export default {
  name: 'template-dialog',

  components: {
    RecipientDialog,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    template: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      chosenRecipientType: null,
      document: null,
      formIsValid: true,
      recipientTypes: [],
      requestInProgress: false,
      showRecipientList: false,
    }
  },

  computed: {
    // builds the url for downloading the document with the given id.
    documentDownloadLink () {
      return this.document ? DocumentApi.getDownloadLink(this.document.documentId) : ''
    },
  },

  watch: {
    template () {
      this.onTemplateChange()
    }
  },

  mounted () {
    this.getReveivers()
  },

  methods: {
    /**
     * Loads available receiver-lists.
     *
     * @returns {void}
     */
    async getReveivers () {
      const res = await MessageApi.getReveivers()
      res.ok && (this.recipientTypes = await res.json())
    },

    async onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.requestInProgress = true

        const res = await MessageApi.sendTemplate(
          this.template.messageTemplateId,
          this.chosenRecipientType.messageReceivers.map(({ receiverId }) => receiverId)
        )

        this.requestInProgress = false

        if (!res.ok) {
          return this.$store.commit('setSnackbar', { text: this.$t('emails.templateNotSent'), color: 'error' })
        }

        this.$emit('sent:template')
        this.chosenRecipientType = null
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Loads the document of the current template.
     *
     * @returns {void}
     */
    async onTemplateChange () {
      this.chosenRecipientType = null
      this.document = null

      if (this.template && this.template.documentId !== null) {
        const res = await DocumentApi.get(this.template.documentId)
        res.ok && (this.document = await res.json())
      }
    },
  },
}
</script>

<style lang="scss">
  .template-dialog {
    blockquote {
      white-space: pre-wrap;
    }
  }
</style>
