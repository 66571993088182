<template>
  <div class="transaction-history--wrap">
    <sparkline-base
      :labels="labels"
      :series="series"
      :height="height"
      :option-changes="options"
      :title="$t('transactions.history')"
      :subtitle="$t('transactions.buySell')"
    >
      <template #fallback>
        <v-card-text class="py-6 mb-auto grey lighten-4">
          {{ $t('transactions.noTransactions') }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn depressed color="primary" small :to="{ name: 'SalesRequests' }" class="text-decoration-none">
            {{ $t('sidebar.saleRequests') }}
          </v-btn>
        </v-card-actions>
      </template>
    </sparkline-base>
  </div>
</template>

<script>
import SparklineBase from './SparklineBase'
import TransactionFormatter from '@/services/transaction-formatter'

import { format } from 'date-fns'

export default {
  name: 'transaction-history',

  components: {
    SparklineBase,
  },

  props: {
    height: {
      type: [Number, String],
      default: 'auto',
    },

    transactions: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      options: {
        chart: {
          parentHeightOffset: 0,
          sparkline: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#4CAF50', '#F44336'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        stroke: { width: 1, curve: 'stepline' },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: (val, timestamp) => format(timestamp, 'dd.MM.yyyy'),
          },
          tickAmount: 4,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 3,
        },
        tooltip: {
          y: {
            formatter: value => value ? this.$options.filters.localeNumber(value, this.$i18n.locale) : null
          },
        },
      },
    }
  },

  computed: {
    monthlySales () {
      return TransactionFormatter.getMonthlyTransactionSumByType(this.transactions, 'sell', 'amount')
    },

    monthlyPurchases () {
      return TransactionFormatter.getMonthlyTransactionSumByType(this.transactions, 'buy', 'amount')
    },

    series () {
      return [
        {
          name: this.$t('transactions.purchases'),
          data: this.monthlyPurchases.map(o => Object.values(o).pop()),
        },
        {
          name: this.$t('transactions.sales'),
          data: this.monthlySales.map(o => Object.values(o).pop()),
        },
      ]
    },

    labels () {
      return Object.keys(
        TransactionFormatter.getMonthlyTransactions(this.transactions)
      )
    },
  },
}
</script>
