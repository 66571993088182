<template>
  <div class="user--wrap">
    <div class="user--content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-index',
}
</script>
