<template>
  <v-dialog
    :value="offer !== null"
    :width="600"
    max-width="90%"
    class="buy-dialog"
    @input="visible => !visible && $emit('close')"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card v-if="offer">
        <v-card-title>
          {{ $t('salesRequests.acceptOffer') }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :value="offer.fullName || $t('sell.anonymousUser')"
            disabled
            readonly
            :label="$t('sell.seller')"
          />
          <v-text-field
            :value="offer.price | readablePrice($i18n.locale)"
            disabled
            readonly
            :label="$t('salesRequests.pricePerShare')"
          />

          <div class="text-subtitle-1 mb-4">
            {{ $t('salesRequests.buyAmountHead') }}
          </div>

          <v-row class="mb-4">
            <v-col>
              <v-text-field
                v-model="amount"
                :max="offer.effectiveAmount"
                hide-details
                outlined
                type="number"
                :rules="requiredRules"
                :label="$t('salesRequests.amount')"
              />
            </v-col>
            <v-col class="d-flex align-center">
              <v-slider
                v-model="amount"
                thumb-label
                hide-details
                :max="offer.effectiveAmount"
              />
            </v-col>
          </v-row>

          <div class="text-subtitle-1 mb-4 primary--text">
            <strong>
              {{ $t('salesRequests.expenses') }} {{ offer.price * amount | readablePrice($i18n.locale) }}
            </strong>
          </div>

          <v-switch v-model="readTerms" :rules="requiredRules" hide-details inset>
            <template #label>
              <accepted-terms-label />
            </template>
          </v-switch>

          <v-text-field
            v-model="secondFactorCode"
            prepend-inner-icon="mdi-key"
            :label="$t('salesRequests.twoFactorInfo')"
            hide-details
            outlined
            class="pa-0 my-6 mb-2"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn depressed @click="$emit('close')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon left color="primary">
              mdi-cart
            </v-icon>
            {{ $t('salesRequests.buyNow') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AcceptedTermsLabel from '@/components/AcceptedTermsLabel'

export default {
  name: 'buy-dialog',

  components: {
    AcceptedTermsLabel,
  },

  props: {
    offer: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      amount: 0,
      formIsValid: true,
      readTerms: false,
      secondFactorCode: '',
    }
  },

  computed: {
    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },
  },

  methods: {
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('buy', {
          offer: this.offer,
          amount: this.amount,
          secondFactorCode: this.secondFactorCode,
        })
        this.$emit('close')
        this.amount = 0
        this.readTerms = false
        this.secondFactorCode = ''
        this.$refs.form.resetValidation()
      }
    }
  },
}
</script>
