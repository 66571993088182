var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-tree--wrap"},[_c('div',{staticClass:"pa-4"},[_c('h3',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t('translations.areas'))+" ")])]),_c('v-divider'),_c('div',{staticClass:"px-4 py-2"},[_c('span',{staticClass:"legend error mr-2"}),_vm._v(" "+_vm._s(_vm.$t('translations.errorIndicator'))+" ")]),_c('v-divider'),_c('v-treeview',{ref:"tree",attrs:{"activatable":"","active-class":"","color":"","items":_vm.tree,"item-key":"path","dense":"","return-object":"","transition":"","hoverable":""},on:{"update:active":function (selection) { return _vm.$emit('input', selection.length ? selection[0].path : null); }},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
          'error--text': _vm.nodeHasError(item),
          'font-weight-bold': item.path === _vm.value
        }},[_vm._v(" "+_vm._s(item.key)+" "),(item.path === _vm.value)?_c('v-icon',{attrs:{"color":"amber","right":""}},[_vm._v(" mdi-filter-outline ")]):_vm._e()],1)]}},{key:"prepend",fn:function(ref){
        var item = ref.item;
        var open = ref.open;
return [(item.children.length)?_c('v-icon',{class:{ 'error--text': _vm.nodeHasError(item) }},[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',{class:{ 'error--text': _vm.nodeHasError(item) }},[_vm._v(" mdi-folder-outline ")])]}},{key:"append",fn:function(ref){
        var item = ref.item;
return [(!item.children.length && !item.entries.length)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove:node', item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }