<template>
  <div class="data-reload-timer--wrap d-none d-lg-block" :class="{ fixed }">
    <v-tooltip left>
      <template #activator="{ on, attrs }">
        <v-progress-circular
          :rotate="-90"
          :size="small ? 30 : 50"
          :width="small ? 4 : 7"
          :value="progress"
          :color="color"
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="!hideText" class="text-caption">
            {{ progress.toFixed(0) }}%
          </span>
        </v-progress-circular>
      </template>
      <span>
        {{ $t('common.reloadInfo', { seconds }) }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'data-reload-timer',

  props: {
    fixed: {
      type: Boolean,
      default: false,
    },

    hideText: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: 'primary',
    },

    seconds: {
      type: Number,
      default: 60,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      interval: null,
      progress: 0,
    }
  },

  mounted () {
    this.interval = setInterval(this.onTick, 1000)
  },

  destroyed () {
    this.interval !== null && clearInterval(this.interval)
  },

  methods: {
    onTick () {
      this.progress += 1 / this.seconds * 100

      if (this.progress >= 100) {
        this.progress = 0
        this.$emit('request:reload')
      }
    }
  },
}
</script>

<style lang="scss">
  .data-reload-timer--wrap {
    &.fixed {
      position: fixed;
      bottom: 80px;
      right: 30px;
      z-index: 5;
    }
  }
</style>
