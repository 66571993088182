<template>
  <v-card class="company-distribution-polar--wrap">
    <v-card-title>
      {{ $t('shareholders.stocksPerCompany') }}
    </v-card-title>

    <div>
      <v-skeleton-loader
        v-if="loading"
        :height="height"
        type="image"
        tile
      />

      <apex-chart
        v-if="companyAmounts.length && !loading"
        :type="isPolar ? 'polarArea' : 'pie'"
        width="100%"
        :height="height"
        :options="options"
        :series="series"
      />
    </div>

    <v-divider class="mt-4" />

    <v-card-actions>
      <v-switch v-model="isPolar" inset label="Polar" class="my-0 ml-2 py-0" hide-details />
      <v-spacer />
      <grouping-inputs v-model="grouping" />
    </v-card-actions>
  </v-card>
</template>

<script>
import chartColors from '@/components/charts/chart-colors'
import GroupingInputs from '@/components/charts/GroupingInputs'
import ShareholderFormatter from '@/services/shareholder-formatter'

export default {
  name: 'company-distribution-polar',

  components: {
    GroupingInputs,
  },

  props: {
    // amount of stocks per company
    companyAmounts: {
      type: Array,
      default: () => ([])
    },

    // height of the chart
    height: {
      type: Number,
      default: 450,
    },

    // show a loading-indicator
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isPolar: true,
      grouping: {
        active: true,
        limit: 0.05,
      }
    }
  },

  computed: {
    /**
     * Users are able to group datasets by a custom limit, so companies below a
     * share are shown as one dataset ('others').
     *
     * @returns {object}
     */
    groupedAmounts () {
      return ShareholderFormatter.groupCompanyAmounts(
        this.companyAmounts,
        this.grouping.active,
        this.grouping.limit
      )
    },

    /**
     * Datasets to visualize: Amount of shares per company + grouped datasets.
     *
     * @see groupedAmounts
     * @returns {array}
     */
    series () {
      return this.groupedAmounts.toShow
        .map(entry => entry.stockAmount)
        .concat(this.grouping.active
          ? this.groupedAmounts.toHide.reduce((total, entry) => total + entry.stockAmount, 0)
          : []
        )
    },

    /**
     * Labels to show: Names of the companies + one for grouped datasets.
     *
     * @see groupedAmounts
     * @returns {array}
     */
    labels () {
      return this.groupedAmounts.toShow
        .map(entry => entry.company)
        .concat(this.grouping.active ? this.$t('common.others') : [])
    },

    options () {
      return {
        legend: {
          height: 60,
          position: 'bottom',
          markers: {
            radius: 2,
          }
        },
        grid: {
          borderColor: '#111',
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
          polarArea: {
            rings: {
              strokeWidth: 1,
              strokeColor: '#cfcfcf',
            },
            spokes: {
              strokeWidth: 1,
              connectorColors: '#cfcfcf',
            }
          }
        },
        colors: chartColors,
        fill: {
          opacity: 0.9,
        },
        stroke: {
          show: false,
        },
        tooltip: {
          y: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale)
          },
        },
        labels: this.labels,
      }
    }
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
  }
}
</script>

<style lang="scss" scoped>
  .v-card {
    overflow: hidden;
  }
</style>
