<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-icon
        :color="read ? 'success' : ''"
        v-bind="attrs"
        v-on="on"
      >
        {{ read ? 'mdi-email-open-outline' : 'mdi-email' }}
      </v-icon>
    </template>
    <span>
      {{ read ? $t('emails.wasRead') : $t('emails.unread') }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'icon-mail-unread',

  props: {
    read: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
