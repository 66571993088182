import Api from '@/api/Api'

export default {
  baseUrl: 'phantom',

  /**
   * getTransactions
   *
   * @returns {Promise}
   */
  getTransactions () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * Creates a new phantom-transaction. The 'fullName'-field is required, but
   * will get set by the api.
   *
   * @param {object} fields
   * @param {string} fields.datePerformed iso-date
   * @param {number} fields.accountId id of the user
   * @param {number} fields.amount amount of shares
   * @returns {Promise}
   */
  addTransaction ({ datePerformed, accountId, amount }) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({ datePerformed, accountId, amount, fullName: '' })
    })
  },

  /**
   * Updates an existing new phantom-transaction. The 'fullName'-field is
   * required, but will get set by the api.
   *
   * @param {object} fields
   * @param {number|string} fields.id id of the phantom-transaction
   * @param {string} fields.datePerformed iso-date
   * @param {number} fields.accountId id of the user
   * @param {number} fields.amount amount of shares
   * @returns {Promise}
   */
  updateTransaction ({ id, datePerformed, accountId, amount }) {
    return Api.baseFetch(`${this.baseUrl}/${id}`, {
      method: 'PUT',
      body: JSON.stringify({ datePerformed, accountId, amount, fullName: '' })
    })
  },

  /**
   * Deletes the phantom-transaction with the given id.
   *
   * @param {number|string} id id of the phantom-transaction
   * @returns {Promise}
   */
  deleteTransaction (id) {
    return Api.baseFetch(`${this.baseUrl}/${id}`, {
      method: 'DELETE',
    })
  },
}
