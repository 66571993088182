<template>
  <v-menu v-if="user" offset-y min-width="270" max-width="90%">
    <template #activator="{ on, attrs }">
      <v-list class="pa-0" max-width="100%" color="transparent">
        <v-list-item class="pl-2" v-bind="attrs" v-on="on">
          <v-list-item-avatar class="mr-4">
            <v-icon size="40">
              mdi-account-circle
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{ user.firstName }} {{ user.lastName }}
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>mdi-chevron-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <v-sheet>
      <v-list dense>
        <v-list-item v-for="(entry, index) in entries" :key="index" :to="{ name: entry.to }">
          <v-list-item-icon>
            <v-icon>{{ entry.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ entry.label }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('userNav.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import AuthApi from '@/api/Auth'

export default {
  name: 'user-menu',

  computed: {
    entries () {
      return [
        { label: this.$t('userNav.profile'), icon: 'mdi-face-man', to: 'UserProfile' },
        { label: this.$t('userNav.changePassword'), icon: 'mdi-lock', to: 'ChangePassword' },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },

  methods: {
    async logout () {
      const res = await AuthApi.logout()

      if (res.ok) {
        this.$store.commit('logout')
        this.$store.commit('setSnackbar', { text: this.$t('login.logoutSuccess') })
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>
