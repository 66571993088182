<template>
  <v-dialog
    :value="payoutId !== null"
    width="600"
    max-width="95%"
    @input="visible => !visible && $emit('close')"
  >
    <v-card :loading="isLoading">
      <v-card-title>
        {{ $t('dividendPayout.pdfPreview') }}
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="mb-6">
          <v-autocomplete
            v-model="selectedAccountId"
            :items="accounts"
            item-value="accountId"
            item-text="combinedName"
            :label="$t('dividendPayout.pdfPreviewFor')"
            prepend-inner-icon="mdi-bank-transfer-in"
            outlined
            hide-details
            clearable
            class="mb-2"
          />

          <v-btn
            depressed
            color="primary"
            :disabled="!pdfPreviewLink"
            :href="pdfPreviewLink"
            target="_blank"
          >
            {{ $t('dividendPayout.showPdfPreview') }}
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          depressed
          @click="$emit('close')"
        >
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccountApi from '@/api/Account'
import DividendPayoutApi from '@/api/DividendPayout.js'

export default {
  name: 'payout-notices-dialog',

  props: {
    payoutId: {
      type: Number,
      default: null,
    },
  },

  data () {
    return {
      accounts: [],
      isLoading: false,
      selectedAccountId: null,
    }
  },

  computed: {
    pdfPreviewLink () {
      return this.payoutId && this.selectedAccountId
        ? DividendPayoutApi.getPdfPreviewLink(this.payoutId, this.selectedAccountId)
        : null
    },
  },

  mounted () {
    this.getAccounts()
  },

  methods: {
    /**
     * Loads all accounts from the api, combines their names and sorts those.
     *
     * @returns {Promise}
     */
    async getAccounts () {
      this.isLoading = true
      const res = await AccountApi.listAll()
      this.isLoading = false

      if (res.ok) {
        const accounts = await res.json()
        this.accounts = accounts
          .map(account => ({
            ...account,
            combinedName: `${account.lastName}, ${account.firstName}`
          }))
          .sort((a, b) => a.combinedName.localeCompare(b.combinedName))
      }
    },
  },
}
</script>
