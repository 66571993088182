<template>
  <div class="notification-filters--wrap">
    <v-select
      :value="value"
      :items="notificationTypes"
      :label="$t('notifications.filters')"
      item-value="id"
      hide-details
      clearable
      outlined
      multiple
      small-chips
      return-object
      @change="activeFilters => $emit('input', activeFilters)"
    >
      <template #item="{ item }">
        {{ $t(`notifications.topics.${item.type}`) }}
      </template>
      <template #selection="{ item }">
        <v-chip
          small
          :color="item.color"
          text-color="white"
          close
          @click:close="removeFilter(item)"
        >
          {{ $t(`notifications.topics.${item.type}`) }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import notificationTypes from '@/assets/data/notification-types'

export default {
  name: 'notification-filters',

  props: {
    value: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      notificationTypes,
    }
  },

  methods: {
    /**
     * Removes the given filter from the currently active ones and communicates
     * those to the parent component.
     *
     * @param {object} filter
     * @returns {undefined}
     */
    removeFilter (filter) {
      this.$emit('input', this.value.filter(selectedFilter => selectedFilter.id !== filter.id))
    }
  },
}
</script>
