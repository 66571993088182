<template>
  <div class="impersonate-hint--wrap">
    <v-alert
      dense
      icon="mdi-drama-masks"
      prominent
      color="pink accent-3"
      border="bottom"
      dark
      class="my-0"
    >
      {{ $t('users.impersonated') }}

      <v-btn
        small
        depressed
        class="ml-3"
        outlined
        @click="deimpersonate"
      >
        {{ $t('users.deimpersonate') }}
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'

export default {
  name: 'impersonate-hint',

  methods: {
    /**
     * Switch back to the own account after an impersonation.
     *
     * @returns {void}
     */
    async deimpersonate () {
      const res = await AccountApi.deimpersonate()

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: this.$t('users.deimpersonateError'), color: 'error' })
      }

      const profileRes = await AccountApi.me()

      if (profileRes.ok) {
        const profile = await profileRes.json()

        this.$store.commit('setUser', profile)
        this.$store.commit('setImpersonated', profileRes.headers.get('x-impersonated') === 'true')
        this.$store.commit('setSnackbar', { text: this.$t('users.deimpersonateSuccess') })
        this.$store.dispatch('notification/get')

        this.$route.name !== 'Users' && this.$router.push({ name: 'Users' })
      }
    },
  }
}
</script>
