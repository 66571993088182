<template>
  <div class="transaction-table--wrap">
    <data-table
      :columns="columns"
      :custom-page-size="25"
      fallback-sorting="id"
      :items="filteredTransactions"
      :page-sizes="[10, 25, 50, 100]"
      :default-options="{ sortBy: ['datePerformed'], sortDesc: [true] }"
      :title="title"
      :is-loading="isLoading"
    >
      <template v-if="withActions" #headerActions>
        <div class="d-flex flex-wrap align-center">
          <v-autocomplete
            v-model="filterUser"
            :items="users"
            item-text="name"
            item-value="id"
            dense
            outlined
            :label="$t('common.user')"
            class="pa-0"
            hide-details
            clearable
            prepend-inner-icon="mdi-account"
          />

          <date-text-input
            v-model="filterDate"
            dense
            :label="$t('transactions.transactionsSince')"
            input-class="pa-0 ml-4"
          />
        </div>
      </template>

      <template #[`item.type`]="{ item }">
        <transaction-type-chip :transaction-type="item.type" />
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount | localeNumber($i18n.locale) }}
      </template>

      <template #[`item.price`]="{ item }">
        {{ item.price | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.holdingPeriod`]="{ item }">
        {{ item.holdingPeriod | readableIsoDate(true) }}
      </template>

      <template #[`item.dateCreated`]="{ item }">
        {{ item.dateCreated | readableIsoDate(true) }}
      </template>

      <template #[`item.datePerformed`]="{ item }">
        <v-tooltip
          v-if="compact"
          left
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.datePerformed | readableIsoDate() }}
            </span>
          </template>
          <span>
            {{ item.datePerformed | readableIsoDate(true) }}
          </span>
        </v-tooltip>

        <span v-else>
          {{ item.datePerformed | readableIsoDate(true) }}
        </span>
      </template>

      <template #[`item.buyerFullName`]="{ item }">
        <div v-if="item.buyerId">
          <icon-buy-sell type="buy" />
          <a
            v-if="withActions"
            href="#"
            class="text-decoration-none"
            @click.prevent="filterUser = item.buyerId"
          >
            {{ item.buyerFullName }}
          </a>
          <span v-else>{{ item.buyerFullName }}</span>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <template #[`item.sellerFullName`]="{ item }">
        <div v-if="item.sellerId">
          <icon-buy-sell type="sell" />
          <a
            v-if="withActions"
            href="#"
            class="text-decoration-none"
            @click.prevent="filterUser = item.sellerId"
          >
            {{ item.sellerFullName }}
          </a>
          <span v-else>{{ item.sellerFullName }}</span>
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          v-if="item.hasDocument"
          icon="mdi-file-download"
          :tooltip="$t('common.download')"
          :href="getDownloadLink(item.id)"
          target="_blank"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import { parseISO } from 'date-fns'

import DataTable from '@/components/DataTable'
import DateTextInput from '@/components/DateTextInput'
import IconBuySell from '@/components/Icons/IconBuySell'
import TableButton from '@/components/TableButton'
import TransactionTypeChip from '@/components/TransactionTypeChip'

import TransactionApi from '@/api/Transactions.js'

export default {
  name: 'transaction-table',

  components: {
    DataTable,
    DateTextInput,
    IconBuySell,
    TableButton,
    TransactionTypeChip,
  },

  props: {
    // reduce amount of columns, data to show
    compact: {
      type: Boolean,
      default: false,
    },

    // visualize loading-state
    isLoading: {
      type: Boolean,
      default: false,
    },

    // transactions to show
    transactions: {
      type: Array,
      default: () => ([])
    },

    // initial filter by an user
    preselectedUserId: {
      type: Number,
      default: null,
    },

    // title above the table
    title: {
      type: String,
      default: null,
    },

    // add filters, actions above the table
    withActions: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      filterDate: null,
      filterUser: null,
    }
  },

  computed: {
    columns () {
      const columns = [
        {
          text: this.compact ? this.$t('common.date') : this.$t('transactions.performedAt'),
          value: 'datePerformed',
          width: this.compact ? 100 : 160,
        },
        { text: this.$t('transactions.holdingPeriod'), value: 'holdingPeriod', width: 160, sortable: false },
        { text: this.$t('common.type'), value: 'type', width: 115, class: 'px-0', cellClass: 'px-0' },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 100 },
        { text: this.$t('transactions.stockPrice'), value: 'price', align: 'right', width: 120 },
        { text: this.$t('transactions.seller'), value: 'sellerFullName' },
        { text: this.$t('transactions.buyer'), value: 'buyerFullName' },
        { text: '', value: 'actions', align: 'right' },
      ]

      if (!this.compact) {
        columns.unshift(
          { text: this.$t('transactions.createdAt'), value: 'dateCreated', width: 160 },
        )
      }

      return columns
    },

    /**
     * Users have the option to set a date- and user-filter.
     *
     * @returns {array}
     */
    filteredTransactions () {
      const byDate = this.filterDate
        ? this.transactions.filter(transaction => parseISO(transaction.datePerformed) >= parseISO(this.filterDate))
        : this.transactions

      return this.filterUser
        ? byDate.filter(transaction => transaction.buyerId === this.filterUser || transaction.sellerId === this.filterUser)
        : byDate
    },

    /**
     * Users that are present within the loaded transactions.
     *
     * @returns {array}
     */
    users () {
      return this.transactions
        .reduce((users, transaction) => {
          users.find(u => u.id === transaction.buyerId) === undefined &&
            users.push({ id: transaction.buyerId, name: transaction.buyerFullName })

          users.find(u => u.id === transaction.sellerId) === undefined &&
            users.push({ id: transaction.sellerId, name: transaction.sellerFullName })
          return users
        }, [])
        .sort()
    },
  },

  watch: {
    transactions (to, from) {
      if (from.length === 0 && to.length > 0 && this.preselectedUserId) {
        this.users.find(({ id }) => id === this.preselectedUserId) && (this.filterUser = this.preselectedUserId)
      }
    },
  },

  methods: {
    /**
     * A transaction may have a related pdf-file (or a zip when the current
     * user is an admin). The link to the file is a simple api-endpoint.
     *
     * @param {number} transactionId
     * @returns {string}
     */
    getDownloadLink (transactionId) {
      return TransactionApi.getDownloadLink(transactionId)
    },
  },
}
</script>
