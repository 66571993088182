<template>
  <div class="translation-overview--wrap">
    <v-row>
      <v-col :cols="12" :md="3">
        <v-sheet color="white" rounded elevation="2">
          <filter-tree
            v-model="filterPath"
            @remove:node="node => toRemove = node"
          />
        </v-sheet>
      </v-col>

      <v-col :cols="12" :md="9">
        <translation-table
          :filter-path="filterPath"
          @remove:entry="entry => toRemove = entry"
          @update:entry="setEntry"
        >
          <template #actions>
            <table-button
              v-if="filterPath"
              icon="mdi-filter-off"
              color="amber"
              dark
              :tooltip="$t('translations.showAll')"
              is-primary
              class="ml-2"
              @click="filterPath = null"
            />

            <table-button
              icon="mdi-translate"
              dark
              :tooltip="$t('translations.addNew')"
              is-primary
              class="ml-2"
              @click="createDialogVisible = true"
            />
          </template>
        </translation-table>
      </v-col>
    </v-row>

    <create-dialog
      :is-visible="createDialogVisible"
      @cancel="createDialogVisible = false"
      @create:entry="setEntry"
    />

    <confirm-dialog
      :is-visible="toRemove !== null"
      @cancel="toRemove = null"
      @ok="removeEntry"
    />
  </div>
</template>

<script>
import { omit, set } from 'lodash'

import ConfirmDialog from '@/components/ConfirmDialog'
import CreateDialog from './CreateDialog'
import FilterTree from './FilterTree'
import TableButton from '@/components/TableButton'
import TranslationApi from '@/api/Translation'
import TranslationTable from './TranslationTable'

export default {
  name: 'translation-overview',

  components: {
    ConfirmDialog,
    CreateDialog,
    FilterTree,
    TableButton,
    TranslationTable,
  },

  data () {
    return {
      createDialogVisible: false,
      filterPath: null,
      toRemove: null,
    }
  },

  computed: {
    // first level of translations are language-keys, deeper ones contain actual entries
    languageKeys () {
      return Object.keys(this.translations)
    },

    translations () {
      return this.$i18n.messages
    },
  },

  methods: {
    /**
     * Updates the actual translations with the values of the given, updated
     * translation-entry.
     *
     * @param {object} entry
     * @returns {void}
     */
    setEntry (entry) {
      // don't manipulate original object-ref
      const translations = JSON.parse(JSON.stringify(this.translations))

      this.languageKeys.forEach(language =>
        set(translations[language], entry.path, entry[language] || null)
      )

      this.updateTranslations(translations, () => {
        this.createDialogVisible = false
        this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
      })
    },

    /**
     * Removes the entry to delete from the actual translation-object, saves that.
     *
     * @returns {void}
     */
    removeEntry () {
      // don't manipulate original object-ref
      const translations = JSON.parse(JSON.stringify(this.translations))

      this.languageKeys.forEach(language => {
        translations[language] = omit(translations[language], this.toRemove.path)
      })

      this.updateTranslations(translations, () =>
        this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
      )

      this.toRemove.path === this.filterPath && (this.filterPath = null)
      this.toRemove = null
    },

    /**
     * Saves the given translation-object and reloads, sets the updated
     * translations.
     *
     * @param {object} translations
     * @param {function} afterUpdate
     */
    async updateTranslations (translations, afterUpdate = () => {}) {
      await TranslationApi.set(translations)
      const res = await TranslationApi.get()

      if (res.ok) {
        const { data } = await res.json()
        Object.keys(data).forEach(key => this.$i18n.setLocaleMessage(key, data[key]))
        afterUpdate()

        // refresh components so the width of the changed labels gets used
        window.dispatchEvent(new Event('resize'))
      }
    },
  }
}
</script>
