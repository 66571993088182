<template>
  <v-dialog persistent :value="isVisible" width="500">
    <v-card>
      <v-card-title>
        {{ headline !== null ? headline : $t('common.confirmDeletion') }}
      </v-card-title>

      <v-card-text>
        {{ infoText !== null ? infoText : $t('common.deletionHint') }}
        <slot />
      </v-card-text>

      <v-card-actions>
        <v-btn depressed @click="$emit('cancel')">
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ cancelText !== null ? cancelText : $t('common.cancel') }}
        </v-btn>
        <v-btn depressed class="ml-auto" :disabled="confirmed" @click="confirm">
          <v-icon :color="actionColor" left>
            {{ actionIcon }}
          </v-icon>
          {{ actionText !== null ? actionText : $t('common.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirm-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
      default: null,
    },

    infoText: {
      type: String,
      default: null,
    },

    cancelText: {
      type: String,
      default: null,
    },

    actionColor: {
      type: String,
      default: 'red',
    },

    actionText: {
      type: String,
      default: null,
    },

    actionIcon: {
      type: String,
      default: 'mdi-delete'
    },
  },

  data () {
    return {
      confirmed: false,
    }
  },

  watch: {
    isVisible () {
      this.confirmed = false
    },
  },

  methods: {
    confirm () {
      this.confirmed = true
      this.$emit('ok')
    }
  }
}
</script>
