<template>
  <div class="emails--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.emails') }}
    </h1>

    <v-tabs
      show-arrows
      color="grey darken-4"
      background-color="transparent"
      class="mb-10"
    >
      <v-tab :to="{ name: 'EmailTemplates' }">
        <v-icon left>
          mdi-clipboard-text
        </v-icon>
        {{ $t('emails.templates') }}
      </v-tab>

      <v-tab :to="{ name: 'MailsSent' }">
        <v-icon left>
          mdi-send
        </v-icon>
        {{ $t('emails.sentMails') }}
      </v-tab>
    </v-tabs>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'emails-index',
}
</script>
