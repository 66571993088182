<template>
  <v-dialog
    :value="event !== null"
    width="700"
    max-width="90%"
    @input="isVisible => !isVisible && $emit('close')"
  >
    <v-card v-if="event">
      <v-card-title class="mb-2">
        {{ event.title }}

        <v-spacer />

        <v-chip label :color="getEventColor(event)" dark>
          {{ $t(`calendar.type.${event.appointmentType}`) }}
        </v-chip>
      </v-card-title>

      <v-card-subtitle>
        <v-chip small outlined label>
          <v-icon small left>
            mdi-calendar
          </v-icon>
          {{ event.date | readableIsoDate(true) }}
        </v-chip>
      </v-card-subtitle>

      <v-card-text>
        {{ event.text }}
      </v-card-text>

      <v-divider />

      <v-card-actions class="px-6">
        <v-btn
          v-if="event.appointmentType === 'gen_meeting'"
          depressed
          :to="{ name: 'Meetings' }"
        >
          {{ $t('sidebar.meetings') }}
        </v-btn>
        <v-spacer />
        <v-btn depressed @click="$emit('close')">
          <v-icon color="red">
            mdi-close
          </v-icon>
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getEventColor } from '@/services/event-colors'

export default {
  name: 'event-dialog',

  props: {
    event: {
      type: Object,
      default: null,
    },
  },

  methods: {
    /**
     * Returns the color to use for the given event or a fallback.
     *
     * @param {object} event
     * @returns {string}
     */
    getEventColor (event) {
      return getEventColor(event.appointmentType)
    },
  },
}
</script>
