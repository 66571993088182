<template>
  <div class="user-stock-overview--wrap">
    <h1 class="mb-6">
      {{ $t('stocks.stocksOf') }} {{ user.lastName }}, {{ user.firstName }}
    </h1>

    <stock-amount
      v-if="transactions.length"
      height="150"
      :transactions="transactions"
      class="mb-6"
    />

    <user-stocks
      :loading="loadingShares"
      :shares="shares"
      compact
    />
  </div>
</template>

<script>
import ShareApi from '@/api/Share'
import StockAmount from '@/components/charts/StockAmount'
import TransactionApi from '@/api/Transactions'
import UserStocks from '@/components/UserStocks'

export default {
  name: 'user-stock-overview',

  components: {
    StockAmount,
    UserStocks,
  },

  data () {
    return {
      loadingShares: false,
      shares: [],
      transactions: [],
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },
  },

  async mounted () {
    this.getShares()
    this.transactions = await TransactionApi.getMy(this.user.accountId)
  },

  methods: {
    async getShares () {
      this.loadingShares = true
      const res = await ShareApi.getUserShares()
      this.loadingShares = false
      res.ok && (this.shares = await res.json())
    },
  },
}
</script>
