<template>
  <div class="user-offers--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.myOffers') }}
    </h1>

    <data-table
      :columns="columns"
      :items="offers"
      :title="$t('sell.myOffers')"
      :show-search="false"
      :default-options="{ sortBy: ['expires'], sortDesc: [false] }"
    >
      <template #headerActions>
        <v-btn :to="{ name: 'OfferCreation' }" depressed color="secondary">
          <v-icon left>
            mdi-offer
          </v-icon>
          {{ $t('sidebar.offerCreation') }}
        </v-btn>
      </template>
      <template #[`item.offerType`]="{ item }">
        <icon-buy-sell :type="item.offerType" />
        {{ $t(`sell.${item.offerType}`) }}
      </template>

      <template #[`item.expires`]="{ item }">
        {{ item.expires | readableIsoDate(true) }}
      </template>

      <template #[`item.price`]="{ item }">
        {{ item.price | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-cancel"
          icon-color="red"
          :tooltip="$t('sell.cancelOffer')"
          @click="toDelete = item.offerId"
        />
        <table-button
          :disabled="new Date(item.expires) < new Date()"
          icon="mdi-pencil"
          icon-color="primary"
          :tooltip="new Date(item.expires) < new Date() ? $t('sell.editPastNotAllowed') : $t('sell.editOffer')"
          @click="toEdit = item"
        />
      </template>
    </data-table>

    <pending-transactions class="mt-10" />

    <confirm-dialog
      :headline="$t('sell.cancelOffer')"
      :info-text="$t('sell.confirmCancel')"
      :is-visible="toDelete !== null"
      @cancel="toDelete = null"
      @ok="cancelOffer"
    >
      <v-form @submit.prevent="cancelOffer">
        <v-text-field
          v-model="secondFactorCode"
          prepend-inner-icon="mdi-key"
          :label="$t('salesRequests.twoFactorInfo')"
          hide-details
          outlined
          autofocus
          class="pa-0 mt-6 mb-2"
        />
      </v-form>
    </confirm-dialog>

    <v-dialog
      :value="toEdit !== null"
      :width="900"
      max-width="90%"
      @input="visibility => !visibility && (toEdit = null)"
    >
      <v-sheet>
        <request-offer
          v-if="toEdit && toEdit.offerType === 'buy'"
          :to-edit="toEdit"
          @cancel="toEdit = null"
          @updated:offer="onOfferUpdate"
        />

        <sell-offer
          v-if="toEdit && toEdit.offerType === 'sell'"
          :to-edit="toEdit"
          class="pa-6"
          @updated:offer="onOfferUpdate"
        />
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import OfferApi from '@/api/Offer'
import PendingTransactions from '@/views/Stocks/Transactions/PendingTransactions'
import RequestOffer from '@/views/Stocks/UserOffers/Creation/RequestOffer'
import SellOffer from '@/views/Stocks/UserOffers/Creation/SellOffer'
import TableButton from '@/components/TableButton'

export default {
  name: 'user-offers',

  components: {
    ConfirmDialog,
    DataTable,
    IconBuySell,
    PendingTransactions,
    RequestOffer,
    SellOffer,
    TableButton,
  },

  data () {
    return {
      offers: [],
      secondFactorCode: '',
      showConfirmation: false,
      showEditDialog: false,
      toEdit: null,
      toDelete: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('sell.type'), value: 'offerType', width: 150 },
        { text: this.$t('sell.activeUntil'), value: 'expires', width: 150 },
        { text: this.$t('common.price'), value: 'price', align: 'right', width: 150 },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 100 },
        { text: this.$t('common.actions'), value: 'actions', width: 150, sortable: false, align: 'right' },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },

  mounted () {
    this.loadOffers()
  },

  methods: {
    /**
     * Loads all available offers, filters those since we only want to display
     * the entries of the current user.
     *
     * @returns {void}
     */
    async loadOffers () {
      const res = await OfferApi.getAll()

      if (res.ok) {
        const offers = await res.json()
        this.offers = offers.filter(({ accountId }) => accountId === this.user.accountId)
      }
    },

    /**
     * Cancels the confirmed offer.
     *
     * @returns {void}
     */
    async cancelOffer () {
      const res = await OfferApi.delete(this.toDelete, this.secondFactorCode)
      this.toDelete = null
      this.secondFactorCode = ''

      if (res.ok) {
        this.loadOffers()
        return this.$store.commit('setSnackbar', { text: this.$t('sell.offerCanceled') })
      }

      this.$store.commit('setSnackbar', {
        text: OfferApi.isSecondFactorError(res) ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
        color: 'error',
      })
    },

    onOfferUpdate () {
      this.toEdit = null
      this.loadOffers()
    },
  }
}
</script>
