<template>
  <div class="general-information--wrap">
    <h1 class="mb-6">
      {{ $t('information.general') }}
    </h1>

    <document-table
      :search="{ displayAreas: ['general'] }"
      :title="$t('information.generalHint')"
    />
  </div>
</template>

<script>
import DocumentTable from '@/components/DocumentTable'

export default {
  name: 'general-information',

  components: {
    DocumentTable,
  },
}
</script>
