import Api from '@/api/Api'

export default {
  baseUrl: 'message',

  /**
   * getInbox
   *
   * @param {boolean} withDeleted
   * @param {boolean} withAlreadyRead
   * @returns {Promise}
   */
  getInbox (withDeleted = true, withAlreadyRead = true) {
    return Api.baseFetch(
      `${this.baseUrl}/inbox?Deleted=${withDeleted}&AlreadyRead=${withAlreadyRead}`
    )
  },

  /**
   * removeMessage
   *
   * @param {number} messageId
   * @returns {Promise}
   */
  removeMessage (messageId) {
    return Api.baseFetch(`${this.baseUrl}/inbox/${messageId}`, {
      method: 'DELETE',
    })
  },

  /**
   * restoreMessage
   *
   * @param {number} messageId
   * @returns {Promise}
   */
  restoreMessage (messageId) {
    return Api.baseFetch(`${this.baseUrl}/inbox/${messageId}`, {
      method: 'POST',
    })
  },

  /**
   * markAsRead
   *
   * @param {number} messageId
   * @returns {Promise}
   */
  markAsRead (messageId) {
    return Api.baseFetch(`${this.baseUrl}/inbox/${messageId}`, {
      method: 'PUT',
    })
  },

  /**
   * getOutbox
   *
   * @returns {Promise}
   */
  getOutbox () {
    return Api.baseFetch(`${this.baseUrl}/outbox`)
  },

  /**
   * removeFromOutbox
   *
   * @param {number} messageId
   * @returns {Promise}
   */
  removeFromOutbox (messageId) {
    return Api.baseFetch(`${this.baseUrl}/outbox/${messageId}`, {
      method: 'DELETE',
    })
  },

  /**
   * getReveivers
   *
   * @returns {Promise}
   */
  getReveivers () {
    return Api.baseFetch(`${this.baseUrl}/receiver_list`)
  },

  /**
   * getTemplates
   *
   * @returns {Promise}
   */
  getTemplates () {
    return Api.baseFetch(`${this.baseUrl}/template`)
  },

  /**
   * addTemplate
   *
   * @param {object} fields
   * @param {number} fields.senderId
   * @param {string} fields.subject
   * @param {string} fields.content
   * @param {number} fields.documentId
   * @returns {Promise}
   */
  addTemplate ({ senderId, subject = '', content = '', documentId }) {
    return Api.baseFetch(`${this.baseUrl}/template`, {
      method: 'POST',
      body: JSON.stringify({ senderId, subject, content, documentId })
    })
  },

  /**
   * updateTemplate
   *
   * @param {object} fields
   * @param {number} fields.templateId
   * @param {number} fields.senderId
   * @param {string} fields.subject
   * @param {string} fields.content
   * @returns {Promise}
   */
  updateTemplate ({ templateId, senderId, subject = '', content = '' }) {
    return Api.baseFetch(`${this.baseUrl}/template/${templateId}`, {
      method: 'PUT',
      body: JSON.stringify({ senderId, subject, content })
    })
  },

  /**
   * deleteTemplate
   *
   * @param {number} templateId
   * @returns {Promise}
   */
  deleteTemplate (templateId) {
    return Api.baseFetch(`${this.baseUrl}/template/${templateId}`, {
      method: 'DELETE',
    })
  },

  /**
   * sendTemplate
   *
   * @param {number} templateId
   * @param {array} receiverIds
   * @returns {Promise}
   */
  sendTemplate (templateId, receiverIds = []) {
    return Api.baseFetch(`${this.baseUrl}/template/${templateId}`, {
      method: 'POST',
      body: JSON.stringify(receiverIds)
    })
  },
}
