import Api from '@/api/Api'

export default {
  baseUrl: 'dividend_payout',

  /**
   * activate
   *
   * @param {object} dividendPayoutId
   * @returns {Promise}
   */
  activate (dividendPayoutId) {
    return Api.baseFetch(`${this.baseUrl}/${dividendPayoutId}/activate`, {
      method: 'POST',
    })
  },

  /**
   * get
   *
   * @param {number} dividendPayoutId
   * @returns {Promise}
   */
  get (dividendPayoutId) {
    return Api.baseFetch(`${this.baseUrl}/${dividendPayoutId}`)
  },

  /**
   * getAll
   *
   * @returns {Promise}
   */
  getAll () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * create
   *
   * @param {object} payoutData
   * @returns {Promise}
   */
  create (payoutData) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify(payoutData),
    })
  },

  /**
   * update
   *
   * @param {object} payoutData
   * @returns {Promise}
   */
  update (payoutData) {
    // seperate fields that mustn't get updated by the client
    const {
      dividendPayoutId: id,
      active,
      documentsGenerated,
      notices,
      ...update
    } = payoutData

    return Api.baseFetch(`${this.baseUrl}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(update),
    })
  },

  /**
   * setChurchTaxFile
   *
   * @param {number} dividendPayoutId
   * @param {File} file
   * @returns {Promise}
   */
  setChurchTaxFile (dividendPayoutId, file) {
    const form = new FormData()
    form.append('File', file)

    return fetch(`${Api.baseUrl}/${this.baseUrl}/${dividendPayoutId}/church_tax`, {
      method: 'PUT',
      body: form,
    })
  },

  /**
   * setDioceseFile
   *
   * @param {number} dividendPayoutId
   * @param {File} file
   * @returns {Promise}
   */
  setDioceseFile (dividendPayoutId, file) {
    const form = new FormData()
    form.append('File', file)

    return fetch(`${Api.baseUrl}/${this.baseUrl}/${dividendPayoutId}/diocese`, {
      method: 'PUT',
      body: form,
    })
  },

  /**
   * getArchiveDownloadLink
   *
   * @param {number} dividendPayoutId
   * @returns {string}
   */
  getArchiveDownloadLink (dividendPayoutId) {
    return `${Api.baseUrl}/${this.baseUrl}/${dividendPayoutId}/all`
  },

  /**
   * getExportDownloadLink
   *
   * @param {number} dividendPayoutId
   * @returns {string}
   */
  getExportDownloadLink (dividendPayoutId) {
    return `${Api.baseUrl}/${this.baseUrl}/${dividendPayoutId}/export`
  },

  /**
   * getPdfPreviewLink
   *
   * @param {number} dividendPayoutId
   * @param {number} accountId
   * @returns {string}
   */
  getPdfPreviewLink (dividendPayoutId, accountId) {
    return `${Api.baseUrl}/${this.baseUrl}/${dividendPayoutId}/pdf/${accountId}`
  },
}
