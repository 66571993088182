<template>
  <div class="agreements--wrap">
    <v-card width="500" max-width="90%" class="mx-auto">
      <v-card-title>
        {{ $t('login.confirmAgreements') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('login.confirmAgreementsInfo') }}
      </v-card-subtitle>

      <v-divider />

      <v-card-text class="py-0">
        <v-list color="transparent">
          <template v-for="(agreement, i) in unacceptedAgreements">
            <v-list-item :key="i">
              <v-list-item-content>
                <v-list-item-title class="mb-1">
                  {{ $t(`agreements.${agreement.key}`) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-btn
                    :href="getAgreementFileUrl(agreement.agreementId)"
                    target="_blank"
                    x-small
                    depressed
                    color="secondary"
                    class="my-1"
                  >
                    <v-icon x-small class="mr-1">
                      mdi-download
                    </v-icon>
                    {{ $t('common.download') }}
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  :input-value="agreement.accepted"
                  @change="accepted => onAgreementChange(agreement, accepted)"
                />
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="i < unacceptedAgreements.length - 1" :key="`divider_${i}`" />
          </template>
        </v-list>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click="updateAgreements">
          <v-icon left color="primary">
            mdi-send
          </v-icon>
          {{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Api from '@/api/Api'
import AuthApi from '@/api/Auth'

export default {
  name: 'agreements-view',

  data () {
    return {
      agreements: [],
      agreementsAccepted: false,
    }
  },

  computed: {
    unacceptedAgreements () {
      return this.agreements.filter(({ acceptedAt }) => acceptedAt === null)
    },
  },

  mounted () {
    this.getAgreements()
  },

  methods: {
    /**
     * Loads all agreements for the current user.
     *
     * @returns {void}
     */
    async getAgreements () {
      const res = await AuthApi.getAgreements()
      res.ok && (this.agreements = await res.json())
    },

    /**
     * When the user confirms an agreement, a temporary confirmation-flag gets
     * set (we must only send new confirmations to the API).
     *
     * @param {object} agreement
     * @param {boolean} accepted
     * @returns {void}
     */
    onAgreementChange (agreement, accepted) {
      this.agreements = this.agreements.map(a => a.agreementId === agreement.agreementId
        ? { ...agreement, accepted }
        : a
      )
    },

    /**
     * Sends the checked agreements to the server so the confirmation gets
     * persisted.
     *
     * @returns {void}
     */
    async updateAgreements () {
      const res = await AuthApi.updateAgreements(
        this.agreements.filter(({ accepted }) => accepted)
      )

      if (res.ok) {
        this.getAgreements()
        this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
        this.$emit('updated')
      }
    },

    /**
     * Creates the URL to the file of the agreement with the given id.
     *
     * @param {number} agreementId
     * @returns {string}
     */
    getAgreementFileUrl (agreementId) {
      return Api.getAgreementUrl(agreementId, this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss">
  .agreements--wrap {
    .download-link {
      text-decoration: none;
    }
  }
</style>
