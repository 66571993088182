var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list--wrap"},[_c('h1',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.$t('usersListUsers.heading'))+" ")]),_c('data-table',{staticClass:"mb-8",attrs:{"columns":_vm.listShareholdersHeaders,"custom-page-size":25,"items":_vm.listShareholders,"page-sizes":[10, 25, 50, 100],"default-options":{ sortBy: ['combinedName'], sortDesc: [false] },"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('table-button',{staticClass:"ml-2",attrs:{"icon":"mdi-playlist-plus","dark":"","tooltip":_vm.$t('usersAddUser.addUser'),"is-primary":""},on:{"click":function($event){return _vm.$router.push({ name: 'UsersAddUser' })}}})]},proxy:true},{key:"item.companyId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.companyNameById(item.companyId))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("status." + (item.status))))+" ")]}},{key:"item.combinedName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'UsersEditUser', params: { id: item.accountId } }}},[_vm._v(" "+_vm._s(item.combinedName)+" ")])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":item.isActive,"inset":"","disabled":"","hide-details":""}})]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedRoles(item.roles))+" ")]}},{key:"item.blocked",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"input-value":item.blocked,"inset":"","disabled":"","hide-details":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-user-button',{attrs:{"id-to-delete":item.accountId,"table-variant":""},on:{"deleted:user":_vm.init}}),_c('table-button',{attrs:{"icon":"mdi-pencil","icon-color":"primary","tooltip":_vm.$t('usersEditUser.heading'),"to":{ name: 'UsersEditUser', params: { id: item.accountId } }}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }