<template>
  <div class="transaction-download--wrap">
    <v-btn
      depressed
      color="secondary"
      dark
      @click="dialogVisible = true"
    >
      <v-icon left>
        mdi-download
      </v-icon>
      {{ $t('common.exportCsv') }}
    </v-btn>

    <v-dialog v-model="dialogVisible" width="600" max-width="90%">
      <v-form
        ref="form"
        v-model="formIsValid"
        @submit.prevent="onSubmit"
      >
        <v-card>
          <v-card-title>
            {{ $t('common.exportCsv') }}
          </v-card-title>

          <v-card-text>
            <date-text-input
              v-model="dateFrom"
              dense
              :label="$t('transactions.transactionsSince')"
              :rules="requiredRules"
              :disabled="isFullExport"
              class="mb-4"
            />

            <date-text-input
              v-model="dateTo"
              dense
              :label="$t('transactions.transactionsUntil')"
              :rules="requiredRules"
              :disabled="isFullExport"
            />

            <v-checkbox
              v-model="isFullExport"
              :label="`${'transactions.fullExport'}`"
            />
          </v-card-text>

          <v-card-actions>
            <v-btn depressed @click="dialogVisible = false">
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('common.cancel') }}
            </v-btn>

            <v-btn
              type="submit"
              depressed
              color="secondary"
              class="ml-auto"
              :disabled="isFullExport ? false : !formIsValid"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              {{ $t('common.exportCsv') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import DateTextInput from '@/components/DateTextInput'
import TransactionApi from '@/api/Transactions'

export default {
  name: 'transaction-download',

  components: {
    DateTextInput,
  },

  data () {
    return {
      dateFrom: null,
      dateTo: null,
      dialogVisible: false,
      formIsValid: false,
      isFullExport: false,
    }
  },

  computed: {
    requiredRules () {
      return [
        v => !!v || this.$t('common.fieldIsRequired'),
      ]
    },
  },

  methods: {
    onSubmit () {
      if (this.isFullExport) {
        window.open(TransactionApi.fullCsvExportUrl(), '_blank')
        return
      }

      this.$refs.form.validate()

      if (this.formIsValid) {
        window.open(TransactionApi.csvExportUrl(this.dateFrom, this.dateTo), '_blank')
        this.$refs.form.resetValidation()
      }
    },
  },
}
</script>
