import store from '@/store'

export default {
  inserted: (el, _, vnode) => {
    const target = vnode.componentOptions?.propsData?.to

    if (!target) {
      return
    }

    const { resolved: routeForEntry } = vnode.context.$router.resolve(target)

    if (!routeForEntry || !store.getters.routeAllowedForUser(routeForEntry)) {
      el.remove()
    }
  },
}
