<template>
  <div class="filter-tree--wrap">
    <div class="pa-4">
      <h3 class="text-h6">
        {{ $t('translations.areas') }}
      </h3>
    </div>

    <v-divider />

    <div class="px-4 py-2">
      <span class="legend error mr-2" />
      {{ $t('translations.errorIndicator') }}
    </div>

    <v-divider />

    <v-treeview
      ref="tree"
      activatable
      active-class=""
      color=""
      :items="tree"
      item-key="path"
      dense
      return-object
      transition
      hoverable
      @update:active="selection => $emit('input', selection.length ? selection[0].path : null)"
    >
      <template #label="{ item }">
        <span
          :class="{
            'error--text': nodeHasError(item),
            'font-weight-bold': item.path === value
          }"
        >
          {{ item.key }}
          <v-icon v-if="item.path === value" color="amber" right>
            mdi-filter-outline
          </v-icon>
        </span>
      </template>

      <template #prepend="{ item, open }">
        <v-icon v-if="item.children.length" :class="{ 'error--text': nodeHasError(item) }">
          {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
        </v-icon>

        <v-icon v-else :class="{ 'error--text': nodeHasError(item) }">
          mdi-folder-outline
        </v-icon>
      </template>

      <template #append="{ item }">
        <v-btn
          v-if="!item.children.length && !item.entries.length"
          icon
          @click.stop="$emit('remove:node', item)"
        >
          <v-icon color="red">
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { merge } from 'lodash'
import { getEntriesForPath } from '@/services/translation-formatter'

export default {
  name: 'filter-tree',

  props: {
    value: {
      type: String,
      default: null,
    },
  },

  computed: {
    translations () {
      return this.$i18n.messages
    },

    tree () {
      return this.buildTranslationTree()
    },
  },

  watch: {
    value: 'fixTreeState',
  },

  methods: {
    /**
     * Our translations are stored as a nested object. We want to display a
     * sorted tree based on that, so we have to do some transformations.
     *
     * @returns {array}
     */
    buildTranslationTree () {
      // first level of translations are language-keys, deeper ones contain actual entries
      const rootEntries = Object.keys(this.translations).reduce((items, languageKey) =>
        merge({}, items, this.translations[languageKey])
      , {})

      return Object.keys(rootEntries)
        .map(path => ({
          key: path,
          path,
          entries: getEntriesForPath(this.translations, path),
          children: this.objectToTree(rootEntries[path], path)
        }))
        .sort((a, b) => a.path.localeCompare(b.path))
    },

    /**
     * Transforms the given object to an array with entries, children to build
     * a tree.
     *
     * @param {object} obj
     * @param {string?} parentId
     * @returns {array}
     */
    objectToTree (obj, parentId) {
      return Object.keys(obj).reduce((items, key) => {
        if (!(typeof obj[key] === 'object') || !obj[key]) return items
        const path = `${parentId}.${key}`

        return [
          ...items,
          {
            key,
            path,
            entries: getEntriesForPath(this.translations, path),
            children: this.objectToTree(obj[key], path),
          }
        ]
      }, [])
    },

    /**
     * nodeHasError
     *
     * @param {object} item
     * @returns {boolean}
     */
    nodeHasError (item) {
      if (!item.entries.every(entry => entry.completed)) {
        return true
      }

      return item.children.reduce((hasError, entry) => hasError || this.nodeHasError(entry), false)
    },

    /**
     * The tree has active bugs related to the state of active entries: Using
     * the intended 'active'-prop fires wrong events when getting changed, so
     * we handle the active-visualization by our own.
     * When the filter gets reset from outside (= the value-prop gets changed),
     * the tree has a wrong state which leads to a required doubleclick. So we
     * help the plugin with keeping the correct state.
     *
     * @param {string|null} to
     * @param {string|null} from
     * @returns {void}
     */
    fixTreeState (to, from) {
      to === null && this.$refs.tree.updateActive(from, false)
    },
  },
}
</script>

<style lang="scss">
  .filter-tree--wrap {
    .v-treeview--dense .v-treeview-node__root {
      min-height: 41px;
    }

    .v-treeview-node {
      cursor: pointer;
    }

    .legend {
      width: 10px;
      height: 10px;
      display: inline-block;
      border-radius: 50%;
    }
  }
</style>
