<template>
  <login-wrap>
    <reset-password-form
      @change:success="$router.push({ name: 'Login' })"
    />
  </login-wrap>
</template>

<script>
import LoginWrap from '@/components/LoginWrap'
import ResetPasswordForm from '@/components/ResetPasswordForm'

export default {
  name: 'reset-password',

  components: {
    LoginWrap,
    ResetPasswordForm,
  },
}
</script>
