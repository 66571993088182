var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"approval-view--wrap"},[_c('div',{staticClass:"mb-10 d-flex align-center"},[_c('h1',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('sidebar.approval'))+" ")]),(_vm.finalizeAllEnabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.confirmFinalizeAll = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('approval.finalizeAll'))+" ")])]}}],null,false,3117210558)},[_c('span',[_vm._v(_vm._s(_vm.$t('approval.finalizeAllHint')))])]):_vm._e()],1),_c('data-table',{attrs:{"columns":_vm.columns,"custom-page-size":25,"items":_vm.pendingTransactions,"page-sizes":[10, 25, 50, 100],"default-options":{ sortBy: ['dateCreated'], sortDesc: [true] },"title":_vm.$t('approval.acceptedOffers'),"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.price,_vm.$i18n.locale))+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.dateCreated))+" ")]}},{key:"item.buyerFullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'UsersEditUser', params: { id: item.buyerId } }}},[_c('icon-buy-sell',{attrs:{"type":"buy"}}),_vm._v(" "+_vm._s(item.buyerFullName)+" ")],1)]}},{key:"item.sellerFullName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'UsersEditUser', params: { id: item.sellerId } }}},[_c('icon-buy-sell',{attrs:{"type":"sell"}}),_vm._v(" "+_vm._s(item.sellerFullName)+" ")],1)]}},{key:"item.sellerTotalAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': _vm.showTradeWarning(item) }},[(_vm.showTradeWarning(item))?_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.sellerTotalAmount,_vm.$i18n.locale))+" ")],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': _vm.showTradeWarning(item) }},[(_vm.showTradeWarning(item))?_c('v-icon',{attrs:{"left":"","small":"","color":"error"}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amountBuyer + item.amountHolding,_vm.$i18n.locale))+" ")],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('transaction-type-chip',{attrs:{"transaction-type":item.type,"compact":""}})]}},{key:"item.amountBuyer",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amountBuyer,_vm.$i18n.locale))+" ")])]}},{key:"item.amountHolding",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"accent--text"},[_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amountHolding,_vm.$i18n.locale))+" ")])]}},{key:"item.check1Id",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"disabled":item.check1Id && item.check1Id !== _vm.user.accountId,"icon":item.check1Id ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline',"icon-color":item.check1Id ? 'success' : null,"tooltip":item.check1Id ? ((_vm.$t('approval.confirmedBy')) + " " + (item.check1FullName)) : _vm.$t('approval.confirmTrade')},on:{"click":function($event){return _vm.confirmTrade(item)}}})]}},{key:"item.check2Id",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"disabled":item.check2Id && item.check2Id !== _vm.user.accountId,"icon":item.check2Id ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline',"icon-color":item.check2Id ? 'success' : null,"tooltip":item.check2Id ? ((_vm.$t('approval.approvedBy')) + " " + (item.check2FullName)) : _vm.$t('approval.approveTrade')},on:{"click":function($event){return _vm.approveTrade(item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-cancel","icon-color":"red","tooltip":_vm.$t('approval.cancelTrade')},on:{"click":function($event){_vm.tradeToCancel = item}}}),(item.check1Id && item.check2Id)?_c('table-button',{attrs:{"icon":"mdi-checkbox-marked-circle-outline","icon-color":"primary","tooltip":_vm.$t('approval.finalize')},on:{"click":function($event){_vm.tradeToSave = item}}}):_vm._e()]}}],null,true)}),_c('confirm-dialog',{attrs:{"headline":_vm.$t('approval.cancelTrade'),"info-text":_vm.$t('approval.confirmCancel'),"action-text":_vm.$t('common.confirm'),"action-icon":"mdi-check","action-color":"primary","is-visible":_vm.tradeToCancel !== null},on:{"cancel":function($event){_vm.tradeToCancel = null},"ok":_vm.cancelTrade}}),_c('confirm-dialog',{attrs:{"headline":_vm.$t('approval.save'),"info-text":_vm.$t('approval.confirmSave'),"action-text":_vm.$t('common.confirm'),"action-icon":"mdi-check","action-color":"primary","is-visible":_vm.tradeToSave !== null},on:{"cancel":function($event){_vm.tradeToSave = null},"ok":_vm.finalizeTrade}}),_c('confirm-dialog',{attrs:{"headline":_vm.$t('approval.save'),"info-text":_vm.$t('approval.confirmFinalizeAll'),"action-text":_vm.$t('common.confirm'),"action-icon":"mdi-check","action-color":"primary","is-visible":_vm.confirmFinalizeAll},on:{"cancel":function($event){_vm.confirmFinalizeAll = false},"ok":_vm.finalizeAll}}),_c('data-reload-timer',{attrs:{"fixed":"","seconds":120},on:{"request:reload":function($event){return _vm.loadTransactions(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }