<template>
  <div class="user-add--wrap">
    <h1 class="mb-6">
      {{ $t('usersAddUser.heading') }}
    </h1>

    <user-form @submit="createUser" />
  </div>
</template>

<script>
import AccountApi from '@/api/Account'
import UserForm from '@/components/UserForm'

export default {
  name: 'add-user',

  components: {
    UserForm,
  },

  methods: {
    /**
     * Saves the given user-dataset as a new one. If that's successfull, the
     * user gets redirected to the overview.
     *
     * @param {object} user
     * @returns {void}
     */
    async createUser (user) {
      const res = await AccountApi.create(user)

      if (res.ok) {
        this.$router.push({ name: 'UsersList' })
        this.$store.commit('setSnackbar', { text: this.$t('common.created') })
        return
      }

      const { error } = await res.json()

      this.$store.commit('setSnackbar', {
        text: error ? this.$t(error) : this.$t('common.errorOccured'),
        color: 'error',
      })
    }
  },
}
</script>
