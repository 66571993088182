<template>
  <login-wrap>
    <div v-if="sessionChecked" class="login--inner">
      <login-form
        v-if="requiresLogin"
        @login:completed="checkSession"
        @login:failed="onLoginFailed"
      />

      <agreements-view
        v-if="requiresAgreement"
        @updated="checkSession"
      />

      <change-email-form
        v-if="requiresEmailChange"
        @change:success="checkSession"
        @change:failure="checkSession"
      />

      <change-password-form
        v-if="requiresPasswordChange"
        @change:success="checkSession"
        @change:failure="checkSession"
      />

      <two-factor-confirmation
        v-if="requiresSecondFactor"
        @code:valid="checkSession"
        @code:invalid="onSecondFactorFailed"
      />

      <two-factor-creation
        v-if="requiresSecondFactorCreation"
        @close="checkSession"
      />
    </div>

    <v-img
      v-else
      :src="require('@/assets/gfx/mueller_bbm.svg')"
      alt="MBBM Logo"
      max-width="170"
      class="company-logo mb-1"
      contain
    />
  </login-wrap>
</template>

<script>
import AccountApi from '@/api/Account'
import AgreementsView from './Agreements'
import LanguageSwitch from '@/mixins/LanguageSwitch'
import LoginForm from './LoginForm'
import LoginWrap from '@/components/LoginWrap'
import ChangeEmailForm from './ChangeEmailForm'
import ChangePasswordForm from '@/components/ChangePasswordForm'
import TwoFactorConfirmation from './TwoFactorConfirmation'
import TwoFactorCreation from './TwoFactorCreation.vue'

export default {
  name: 'login-view',

  components: {
    AgreementsView,
    LoginForm,
    LoginWrap,
    ChangeEmailForm,
    ChangePasswordForm,
    TwoFactorConfirmation,
    TwoFactorCreation,
  },

  mixins: [
    LanguageSwitch,
  ],

  data () {
    return {
      requiresLogin: true,
      requiresAgreement: false,
      requiresEmailChange: false,
      requiresPasswordChange: false,
      requiresSecondFactor: false,
      requiresSecondFactorCreation: false,
      sessionChecked: false,
    }
  },

  computed: {
    target () {
      return this.$route.params.target ? this.$route.params.target : null
    },
  },

  async mounted () {
    this.checkSession()
  },

  methods: {
    /**
     * The 'me'-call gives us information about the session-/login-status of
     * the current user. Based on that, we want to require credentials, an
     * confirmation of agreements or a password-change.
     * If the user has a valid session, we get, store the dataset of the user
     * and perform a redirect to the dashboard (or the desired target).
     *
     * @returns {void}
     */
    async checkSession () {
      const res = await AccountApi.me()

      // not logged-in
      if (res.status === 403) {
        this.requiresLogin = true
      }

      // additional authentication-steps are required
      if (res.status === 318) {
        const { sessionStatus } = await res.json()
        this.requiresLogin = false
        this.requiresAgreement = sessionStatus === 'requires_agreement'
        this.requiresEmailChange = sessionStatus === 'requires_new_email'
        this.requiresPasswordChange = sessionStatus === 'requires_password_change'
        this.requiresSecondFactor = sessionStatus === 'requires_second_factor'
        this.requiresSecondFactorCreation = sessionStatus === 'requires_creating_second_factor'
      }

      // user is logged-in
      if (res.status === 200) {
        const defaultTarget = 'Home'
        const user = await res.json()

        this.setLanguage(user.language)
        this.$store.commit('setUser', user)
        this.$store.commit('setImpersonated', res.headers.get('x-impersonated') === 'true')
        this.$store.commit('setSnackbar', { text: this.$t('login.loginSuccess') })
        this.$store.dispatch('getConfig')
        this.$store.dispatch('notification/get')

        this.$router
          .push(this.target ? { path: this.target } : { name: defaultTarget })
          .catch(() =>
            // redirect wasn't possible (eg because of missing role for that view) - lets try the default-view instead
            this.$router.push({ name: defaultTarget })
          )
      }

      this.sessionChecked = true
    },

    /**
     * onLoginFailed
     *
     * @param {string} reason
     * @returns {void}
     */
    onLoginFailed (reason) {
      this.checkSession()
      return this.$store.commit('setSnackbar', { text: reason, color: 'error' })
    },

    onSecondFactorFailed () {
      this.checkSession()

      return this.$store.commit('setSnackbar', {
        text: this.$t('login.twoFactorError'),
        color: 'error'
      })
    }
  }
}
</script>

<style lang="scss">
  .login--inner {
    min-width: 0;
  }
</style>
