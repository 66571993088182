<template>
  <v-icon
    :color="typeBuySellMapping[type].color"
    small
    left
  >
    {{ typeBuySellMapping[type].icon }}
  </v-icon>
</template>

<script>
const typeBuySellMapping = {
  buy: { color: 'green', icon: 'mdi-bank-transfer-in' },
  sell: { color: 'red', icon: 'mdi-bank-transfer-out' },
}

export default {
  name: 'icon-buy-sell',

  props: {
    type: {
      type: String,
      default: 'buy',
      validator: val => Object.keys(typeBuySellMapping).includes(val)
    },
  },

  data () {
    return {
      typeBuySellMapping,
    }
  },

}
</script>
