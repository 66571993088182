<template>
  <div class="phantoms-list--wrap">
    <data-table
      :columns="columns"
      :items="phantomTransactionsByUser"
      :title="`${$t('phantoms.total')}: ${totalStockAmount}`"
      :custom-page-size="25"
      :default-options="{ sortBy: ['fullName'], sortDesc: [false] }"
    >
      <template #[`item.fullName`]="{ item }">
        <router-link :to="{ name: 'PhantomsTransactions', params: { id: item.accountId } }" class="text-decoration-none">
          {{ item.fullName }}
        </router-link>
      </template>

      <template #[`item.firstTransaction`]="{ item }">
        {{ item.firstTransaction | readableIsoDate }}
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount | localeNumber($i18n.locale) }}
      </template>
    </data-table>
  </div>
</template>

<script>
import roles from '@/roles'

import DataTable from '@/components/DataTable'
import PhantomApi from '@/api/Phantom'

export default {
  name: 'phantoms-list',

  components: {
    DataTable,
  },

  data () {
    return {
      phantomTransactionsByUser: [],
    }
  },

  computed: {
    columns () {
      const columns = [
        { text: this.$t('common.owner'), value: 'fullName' },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 120 },
      ]

      if (this.showFirstPurchase) {
        columns.push({ text: this.$t('phantoms.firstPurchase'), value: 'firstTransaction', align: 'right', width: 160 })
      }

      return columns
    },

    // show an additional column for some roles
    showFirstPurchase () {
      return this.$store.getters.userHasRole([roles.editor, roles.superadmin])
    },

    // amount of all phantom-stocks of all phantom-users
    totalStockAmount () {
      const amount = this.phantomTransactionsByUser.reduce((sum, entry) => sum + entry.amount, 0)
      return this.$options.filters.localeNumber(amount, this.$i18n.locale)
    }
  },

  mounted () {
    this.getPhantomTransactions()
  },

  methods: {
    /**
     * Loads all phantom-transactions and groups those based on the user.
     * While doing that, the individual stock-sum gets calculated. Finally, the
     * result gets slightly modified so the view can handle it easily.
     *
     * @returns {void}
     */
    async getPhantomTransactions () {
      const res = await PhantomApi.getTransactions()

      if (res.ok) {
        const phantomTransactions = await res.json()
        const byUser = phantomTransactions.reduce((byUser, { accountId, amount, fullName, datePerformed }) => {
          byUser[accountId] = byUser[accountId]
            ? { ...byUser[accountId], amount: byUser[accountId].amount + amount }
            : { fullName, amount, firstTransaction: datePerformed }

          return byUser
        }, {})

        // object was fine for grouping, but our table wants a list
        this.phantomTransactionsByUser = Object.keys(byUser).map(key => ({ ...byUser[key], accountId: +key }))
      }
    },
  }
}
</script>
