<template>
  <div class="sales--wrap">
    <offer-table
      :type="offerType"
      :is-loading="isLoading"
      :offers="offers"
      :data-table-options="{
        itemsPerPage: 25,
        sortBy: ['price'],
        sortDesc: [false],
      }"
      @click:offer="offerToAccept => offer = offerToAccept"
    />

    <buy-dialog
      :offer="offer"
      @close="offer = null"
      @buy="buy"
    />

    <data-reload-timer
      fixed
      :seconds="30"
      @request:reload="loadOffers(true)"
    />
  </div>
</template>

<script>
import BuyDialog from './BuyDialog.vue'
import DataReloadTimer from '@/components/DataReloadTimer'
import OfferApi from '@/api/Offer'
import OfferTable from './OfferTable'

export default {
  name: 'sales-view',

  components: {
    BuyDialog,
    DataReloadTimer,
    OfferTable,
  },

  data () {
    return {
      isLoading: false,
      offer: null,
      offerType: 'sell',
      offers: [],
    }
  },

  mounted () {
    this.loadOffers()
  },

  methods: {
    /**
     * Buy shares by accepting an offer.
     *
     * @param {object} sellInfo
     * @param {object} sellInfo.offer Offer to accept
     * @param {number} sellInfo.amount Shares to sell
      @param {number} sellInfo.secondFactorCode Entered second factor code
     */
    async buy ({ offer, amount, secondFactorCode }) {
      const res = await OfferApi.accept(offer.offerId, amount, secondFactorCode)
      this.offer = null

      if (!res.ok) {
        return this.$store.commit('setSnackbar', {
          text: OfferApi.isSecondFactorError(res) ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
          color: 'error',
        })
      }

      this.$store.commit('setSnackbar', { text: this.$t('salesRequests.acceptedOffer') })
      this.loadOffers()
    },

    /**
     * Loads all available offers.
     *
     * @param {boolean} isAutoReload If data is reloaded automatically, we don't want to extend the session
     * @returns {void}
     */
    async loadOffers (isAutoReload = false) {
      this.isLoading = true
      const res = await OfferApi.getAll(isAutoReload)
      res.ok && (this.offers = await res.json())
      this.isLoading = false
    }
  },
}
</script>
