<template>
  <v-dialog
    :value="isVisible"
    width="700"
    max-width="90%"
    @input="visibility => !visibility && $emit('close')"
  >
    <form v-if="!confirmed" @submit.prevent="confirm2fa">
      <v-card :loading="isLoading">
        <v-card-title>
          {{ $t('login.confirmTwoFactor') }}
        </v-card-title>
        <v-card-text>
          <v-img :src="qrCode" max-width="300" class="mx-auto mb-5" />

          <v-text-field
            :value="secret"
            :label="$t('login.twoFactorSecret')"
            readonly
            disabled
            outlined
            hide-details
            class="mb-2"
          />
          <v-text-field
            v-model="code"
            :label="$t('login.twoFactorCode')"
            outlined
            hide-details
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="$emit('close')">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn depressed type="submit" :loading="isLoading">
            <v-icon left color="primary">
              mdi-check
            </v-icon>
            {{ $t('common.enable') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>

    <v-card v-if="confirmed && codePdfUrl">
      <v-card-title>{{ $t('login.downloadCodes') }}</v-card-title>
      <v-card-text>
        {{ $t('login.codeInfo') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn depressed @click="$emit('close')">
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('common.close') }}
        </v-btn>

        <v-btn depressed :href="codePdfUrl" download="codes.pdf">
          <v-icon left color="primary">
            mdi-download
          </v-icon>
          {{ $t('common.download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TwoFactorAuthApi from '@/api/TwoFactorAuth'

export default {
  name: 'two-factor-dialog',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    secret: {
      type: String,
      default: '',
    },

    qrCode: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      code: '',
      codePdfUrl: '',
      confirmed: false,
      isLoading: false,
    }
  },

  methods: {
    /**
     * Confirms the activation with the created secret, the entered code.
     * If that's successful, the response contains a pdf-file for the user to
     * download.
     *
     * @returns {void}
     */
    async confirm2fa () {
      this.isLoading = true
      const res = await TwoFactorAuthApi.confirmSecret(this.secret, this.code)
      this.isLoading = false

      if (res.ok) {
        this.confirmed = true
        this.codePdfUrl = window.URL.createObjectURL(await res.blob())
        this.$emit('confirmed')
        return this.$store.commit('setSnackbar', { text: this.$t('login.twoFactorEnabled') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('login.twoFactorNotEnabled'), color: 'error' })
    },
  },
}
</script>
