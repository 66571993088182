<template>
  <div class="dataconfidentiality--wrap running-text--wrap">
    <h1 class="mb-6">
      {{ $t('dataconfidentiality.heading') }}
    </h1>
    <content-de v-if="currentLanguage === 'de-DE'" />
    <content-en v-if="currentLanguage === 'en-GB'" />
  </div>
</template>

<script>
import ContentDe from '@/content/de/DataConfidentiality.vue'
import ContentEn from '@/content/en/DataConfidentiality.vue'

export default {
  name: 'data-confidentiality',

  components: {
    ContentDe,
    ContentEn,
  },

  computed: {
    currentLanguage () {
      return this.$i18n.locale
    }
  }
}
</script>
