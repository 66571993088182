<template>
  <div class="annual-meetings--wrap">
    <v-row class="mb-5">
      <v-col :cols="12" :lg="5">
        <meeting-hint class="fill-height" />
      </v-col>
      <v-col :cols="12" :lg="7">
        <v-card flat class="fill-height d-inline-block" color="secondary" dark outlined>
          <v-card-title>
            <v-icon left>
              mdi-information
            </v-icon>
            {{ $t('meetings.lastAnnualMeeting') }}
          </v-card-title>
          <v-card-text>
            {{ $t('meetings.lastAnnualMeetingInfo') }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <document-table
      :search="{ displayAreas: ['general_meeting'] }"
      :title="$t('meetings.filesToDownload')"
    />
  </div>
</template>

<script>
import DocumentTable from '@/components/DocumentTable'
import MeetingHint from '@/views/Meetings/MeetingHint'

export default {
  name: 'annual-meetings',

  components: {
    DocumentTable,
    MeetingHint,
  },
}
</script>
