<template>
  <v-card class="age-distribution-bar--wrap">
    <v-card-title>
      {{ $t('ageDistribution.title') }}
    </v-card-title>

    <v-skeleton-loader
      v-if="isLoading"
      :height="height"
      type="image"
      tile
    />

    <apex-chart
      v-if="dataset.length && !isLoading"
      type="bar"
      width="100%"
      :height="height"
      :options="options"
      :series="series"
    />
  </v-card>
</template>

<script>
import ShareApi from '@/api/Share'

export default {
  name: 'age-distribution-bar',

  props: {
    height: {
      type: Number,
      default: 500,
    },
  },

  data () {
    return {
      isLoading: false,
      dataset: []
    }
  },

  computed: {
    options () {
      return {
        chart: {
          toolbar: {
            show: false,
          }
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '14px',
            colors: ['#000']
          },
          formatter: value => `${this.$options.filters.localeNumber(value, this.$i18n.locale, true)} %`,
        },
        grid: {
          borderColor: '#cfcfcf',
        },
        colors: ['#005eab'],
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale),
          },
          custom: ({ dataPointIndex }) => {
            const { ageGroup, avgNumShares, numShares, people } = this.dataset[dataPointIndex]
            return `
              <div class="age-distribution-tooltip">
                <div class="apexcharts-tooltip-title pa-2">
                  ${this.$t('ageDistribution.ageGroup')}: ${ageGroup}
                </div>
                <div class="pa-2">
                  <div class="d-flex justify-space-between mb-1">
                    <span>
                      ${this.$t('ageDistribution.totalSharesPerGroup')}:
                    </span>
                    <span>
                      ${this.$options.filters.localeNumber(numShares, this.$i18n.locale)}
                    </span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <span>
                      ${this.$t('ageDistribution.avgSharesPerGroup')}:
                    </span>
                    <span>
                      ${this.$options.filters.localeNumber(avgNumShares, this.$i18n.locale, true)}
                    </span>
                  </div>
                  <div class="d-flex justify-space-between">
                    <span>
                      ${this.$t('ageDistribution.shareholderAmount')}:
                    </span>
                    <span>
                      ${this.$options.filters.localeNumber(people, this.$i18n.locale)}
                    </span>
                  </div>
                </div>
              </div>
            `
          }
        },
        xaxis: {
          categories: this.dataset.map(({ ageGroup }) => ageGroup),
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          title: {
            text: this.$t('ageDistribution.ageGroups')
          }
        },
        yaxis: {
          labels: {
            formatter: value => this.$options.filters.localeNumber(value, this.$i18n.locale, true),
          },
          title: {
            text: this.$t('ageDistribution.groupPercentage')
          }
        }
      }
    },

    /**
     * Datasets to visualize: Amount of shares per company + grouped datasets.
     *
     * @see groupedAmounts
     * @returns {array}
     */
    series () {
      return [
        {
          name: this.$t('shareholders.pctNumShares'),
          data: this.dataset.map(entry => entry.pctNumShares)
        },
      ]
    },
  },

  mounted () {
    // react to initial layout-changed (e.g. the sidebar)
    window.dispatchEvent(new Event('resize'))
    this.getAgeDistribution()
  },

  methods: {
    /**
     * Loads the dataset for our statistic. The api is able to respond with the
     * data for a specific date - but we just want the current one.
     *
     * @returns {Promise}
     */
    async getAgeDistribution () {
      this.isLoading = true
      const res = await ShareApi.getShareholderAgeDistribution(new Date())
      res.ok && (this.dataset = await res.json())
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  .age-distribution-bar--wrap {
    .v-card {
      overflow: hidden;
    }

    .age-distribution-tooltip {
      min-width: 320px;
    }
  }
</style>
