<template>
  <v-dialog
    :value="isVisible"
    max-width="450"
    @input="isVisible => !isVisible && onClose()"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card :loading="isLoading">
        <v-card-title>
          {{ toEdit === null ? $t('dates.newEntry') : $t('dates.editEntry') }}
        </v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="date.appointmentType"
            :items="dateTypes"
            :label="$t('dates.type')"
            :rules="requiredRules"
            outlined
            hide-details
            class="mb-4"
          >
            <template #item="{ item }">
              {{ $t(`calendar.type.${item}`) }}
            </template>
            <template #selection="{ item }">
              {{ $t(`calendar.type.${item}`) }}
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="date.title"
            :label="$t('date.title')"
            prepend-inner-icon="mdi-format-title"
            outlined
            hide-details
            class="mb-4"
          />

          <v-textarea
            v-model="date.text"
            outlined
            hide-details
            required
            counter
            minlength="30"
            :label="$t('dates.description')"
            prepend-inner-icon="mdi-message-text"
            class="mb-4"
          />

          <v-row>
            <v-col>
              <date-text-input
                :value="date.date | isoDate"
                :rules="requiredRules"
                :label="$t('common.date')"
                class="mb-4"
                @input="isoDate => date.date = new Date(isoDate)"
              />
            </v-col>
            <v-col>
              <time-text-input
                :label="$t('common.time')"
                :value="date.date"
                @input="dateTime => date.date = dateTime"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn depressed @click="onClose">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn depressed type="submit" :disabled="!formIsValid">
            <v-icon color="primary" left>
              mdi-pencil
            </v-icon>
            {{ toEdit === null ? $t('common.create') : $t('common.edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AppointmentApi from '@/api/Appointment'
import DateTextInput from '@/components/DateTextInput'
import TimeTextInput from '@/components/TimeTextInput'

const defaultDate = () => ({
  id: null,
  date: new Date(),
  title: '',
  text: '',
  appointmentType: null,
})

export default {
  name: 'date-dialog',

  components: {
    DateTextInput,
    TimeTextInput,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    toEdit: {
      type: Object,
      default: null,
    }
  },

  data () {
    return {
      date: defaultDate(),
      dateTypes: [],
      formIsValid: false,
      isLoading: false,
      requiredRules: [v => !!v],
    }
  },

  watch: {
    toEdit () {
      this.useDate()
    }
  },

  mounted () {
    this.useDate()
    this.loadTypes()
  },

  methods: {
    /**
     * Loads available appointment-types.
     *
     * @returns {void}
     */
    async loadTypes () {
      const res = await AppointmentApi.getTypes()
      res.ok && (this.dateTypes = await res.json())
    },

    /**
     * Tells the parent component that the dialog can get closed, resets the
     * form-validation.
     *
     * @returns {void}
     */
    onClose (e) {
      this.reset()
      this.$emit('close')
    },

    /**
     * Creates or updates the current date-entry if the entered data is valid.
     * If that's successful, a reload by the parent-component gets requested.
     *
     * @returns {void}
     */
    async onSubmit () {
      this.$refs.form.validate()
      if (!this.formIsValid) return

      this.isLoading = true
      const res = this.toEdit ? await AppointmentApi.update(this.date) : await AppointmentApi.create(this.date)
      this.isLoading = false

      if (res.ok) {
        this.onClose()
        this.$emit('request:reload')
      }
    },

    /**
     * Resets the internal state.
     *
     * @returns {void}
     */
    reset () {
      this.date = defaultDate()
      this.$refs.form.resetValidation()
    },

    /**
     * Uses the date-data given as a prop or the default for new datasets.
     *
     * @returns {void}
     */
    useDate () {
      this.date = this.toEdit
        ? { ...this.toEdit, date: new Date(this.toEdit.date) }
        : defaultDate()
    }
  },
}
</script>
