import { format, formatISO } from 'date-fns'

export default {
  /**
  * getIsoDate
  *
  * @param {Date} date
  * @returns {string}
  */
  getIsoDate (date) {
    return formatISO(date, { representation: 'date' })
  },

  /**
   * readableDate
   *
   * @param {Date} date
   * @param {boolean} full Includes the time of the date
   * @param {string} timeFormat Custom format for the time
   * @returns {string}
   */
  readableDate (date, full = false, timeFormat = ' HH:mm:ss') {
    return format(date, `dd.MM.yyyy${full ? timeFormat : ''}`)
  },
}
