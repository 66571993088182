<template>
  <div class="configuration--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.configuration') }}
    </h1>

    <data-table
      :columns="columns"
      :items="configs"
      :is-loading="isLoading"
      :show-search="false"
      :title="$t('configuration.allConfigurations')"
      :default-options="{ sortBy: ['validFrom'], sortDesc: [true] }"
    >
      <template #headerActions>
        <table-button
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('common.newEntry')"
          is-primary
          @click="showCreateDialog"
        />
      </template>

      <template #[`item.formulaPrice`]="{ item }">
        {{ item.formulaPrice | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.validFrom`]="{ item }">
        {{ item.validFrom | readableIsoDate(true) }}
      </template>

      <template #[`item.holdingReturnNumerator`]="{ item }">
        {{ 100 * item.holdingReturnNumerator / item.holdingReturnDenominator }}&nbsp;%
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          :disabled="configIsInPast(item)"
          icon="mdi-pencil"
          icon-color="primary"
          :tooltip="configIsInPast(item) ? $t('configuration.pastNotAllowed') : $t('common.edit')"
          @click="showEditDialog(item)"
        />
      </template>
    </data-table>

    <configuration-dialog
      :is-visible="dialogVisible"
      :config-to-use="toEdit"
      @close="onCloseDialog"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import ConfigurationApi from '@/api/Configuration'
import ConfigurationDialog from './ConfigurationDialog'
import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'

export default {
  name: 'configuration-overview',

  components: {
    ConfigurationDialog,
    DataTable,
    TableButton,
  },

  data () {
    return {
      configs: [],
      toEdit: null,
      toRemove: null,
      dialogVisible: false,
      isLoading: false,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('configuration.validFrom'), value: 'validFrom' },
        { text: this.$t('configuration.formulaPrice'), value: 'formulaPrice', align: 'right' },
        { text: this.$t('configuration.minimumShares'), value: 'minimumShares', align: 'right' },
        { text: this.$t('configuration.holdingAmount'), value: 'holdingReturnNumerator', align: 'right' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right', width: '110px' },
      ]
    },
  },

  async mounted () {
    await this.loadConfigs()
  },

  methods: {
    /**
     * Loads available configurations from the api.
     *
     * @returns {void}
     */
    async loadConfigs () {
      this.isLoading = true
      const res = await ConfigurationApi.list()
      res.ok && (this.configs = await res.json())
      this.isLoading = false
    },

    /**
     * Creates/updates the given configuration.
     *
     * @param {object} config
     * @returns {void}
     */
    async onSubmit (config) {
      const isEdit = this.toEdit !== null
      const res = isEdit
        ? await ConfigurationApi.update(config)
        : await ConfigurationApi.create(config)

      if (res.ok) {
        this.loadConfigs()
        this.onCloseDialog()
        return this.$store.commit('setSnackbar', {
          text: isEdit ? this.$t('common.updated') : this.$t('common.created')
        })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    },

    /**
     * Checks if the given configuration is in the past.
     *
     * @param {object} config
     * @returns {boolean}
     */
    configIsInPast (config) {
      return new Date(config.validFrom) < new Date()
    },

    /**
     * Opens an empty configuration-dialog to create a new dataset.
     *
     * @returns {void}
     */
    showCreateDialog () {
      this.toEdit = null
      this.dialogVisible = true
    },

    /**
     * Opens a dialog to edit an existing configuration.
     *
     * @param {object} configuration
     * @returns {void}
     */
    showEditDialog (configuration) {
      this.toEdit = configuration
      this.dialogVisible = true
    },

    /**
     * Closes the create/edit-dialog, resets related state.
     *
     * @returns {void}
     */
    onCloseDialog () {
      this.toEdit = null
      this.dialogVisible = false
    },
  }
}
</script>
