var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-stocks--wrap"},[_c('data-table',{staticClass:"stock-table",attrs:{"columns":_vm.columns,"items":_vm.tableData,"default-options":{ sortBy: ['transactionDto.dateCreated'], sortDesc: [true] },"title":_vm.$t('stocks.stocksPerTransaction'),"is-loading":_vm.loading},scopedSlots:_vm._u([{key:"item.transactionDto.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.transactionDto.dateCreated,true))+" ")]}},{key:"item.transactionDto.datePerformed",fn:function(ref){
var item = ref.item;
return [(_vm.compact)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.transactionDto.datePerformed))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.transactionDto.datePerformed,true))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.transactionDto.datePerformed,true))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amount,_vm.$i18n.locale))+" ")]}},{key:"item.ranges",fn:function(ref){
var item = ref.item;
return _vm._l((item.ranges),function(range,i){return _c('v-chip',{key:((item.transactionDto.id) + "_" + i),staticClass:"mr-1 my-1",attrs:{"small":"","label":"","color":"grey lighten-4"}},[_vm._v(" "+_vm._s(range[0])),(range[1])?_c('span',[_vm._v(" - "+_vm._s(range[1]))]):_vm._e()])})}},{key:"item.transactionDto.sellerFullName",fn:function(ref){
var item = ref.item;
return [(item.transactionDto.sellerFullName)?_c('span',[_c('icon-buy-sell',{attrs:{"type":"sell"}}),_vm._v(" "+_vm._s(item.transactionDto.sellerFullName)+" ")],1):_vm._e()]}},{key:"item.transactionDto.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.transactionDto.price,_vm.$i18n.locale))+" ")]}},{key:"item.transactionDto.type",fn:function(ref){
var item = ref.item;
return [_c('transaction-type-chip',{attrs:{"transaction-type":item.transactionDto.type}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-button',{attrs:{"icon":"mdi-format-list-bulleted","tooltip":_vm.$t('stocks.showStocks')},on:{"click":function($event){_vm.entryToShow = item}}})]}}],null,true)}),_c('stock-sidebar',{attrs:{"stock-group":_vm.entryToShow},on:{"close":function($event){_vm.entryToShow = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }