<template>
  <v-dialog
    :value="isVisible"
    :width="750"
    max-width="90%"
    content-class="create-bonus-dialog"
    eager
    @input="visibility => !visibility && $emit('close')"
  >
    <div class="create-bonus-dialog--inner">
      <v-form ref="form" v-model="formIsValid" @submit.prevent="showConfirmation">
        <v-card flat :loading="isLoading">
          <v-card-title>
            {{ $t('transactions.newBonusTransaction') }}
          </v-card-title>

          <v-card-text>
            <v-autocomplete
              v-model="transaction.buyerAccountId"
              :items="accounts"
              item-value="accountId"
              item-text="combinedName"
              :label="$t('transactions.receiver')"
              :rules="requiredRules"
              prepend-inner-icon="mdi-bank-transfer-in"
              outlined
              hide-details
              class="mb-4"
            >
              <template #item="{ item }">
                <icon-buy-sell type="buy" />
                {{ item.combinedName }}
              </template>
              <template #selection="{ item }">
                {{ item.combinedName }}
              </template>
            </v-autocomplete>

            <v-row>
              <v-col :cols="12" :md="6">
                <v-select
                  v-model="transaction.transactionType"
                  :items="allowedTransactionTypes"
                  :label="$t('common.type')"
                  :rules="requiredRules"
                  outlined
                  hide-details
                  class="mb-4"
                />
              </v-col>

              <v-col :cols="12" :md="6">
                <v-text-field
                  v-model.number="transaction.amount"
                  :min="1"
                  hide-details
                  outlined
                  step="1"
                  :rules="requiredRules"
                  :label="$t('common.amount')"
                  type="number"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn depressed @click="$emit('close')">
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              type="submit"
              depressed
              :disabled="!formIsValid"
              :loading="isLoading"
            >
              <v-icon left color="primary">
                mdi-pencil
              </v-icon>
              {{ $t('common.create') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>

    <confirm-dialog
      action-color="primary"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      :info-text="''"
      :is-visible="confirmationVisible"
      :headline="$t('transactions.confirmBonus')"
      @cancel="confirmationVisible = false"
      @ok="createTransaction"
    >
      <transaction-type-chip
        :transaction-type="transaction.transactionType"
        class="mb-4"
      />

      <v-list dense>
        <v-list-item>
          <v-list-item-icon>
            <icon-buy-sell type="buy" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ getAccountName(transaction.buyerAccountId) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('transactions.receiver') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-counter</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ transaction.amount }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('common.amount') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </confirm-dialog>
  </v-dialog>
</template>

<script>
import AccountApi from '@/api/Account'
import PendingTransactionApi from '@/api/PendingTransaction'

import ConfirmDialog from '@/components/ConfirmDialog'
import IconBuySell from '@/components/Icons/IconBuySell'
import TransactionTypeChip from '@/components/TransactionTypeChip'

const defaultBonusTransaction = () => ({
  amount: 0,
  buyerAccountId: null,
  transactionType: null,
})

export default {
  name: 'create-bonus-dialog',

  components: {
    ConfirmDialog,
    IconBuySell,
    TransactionTypeChip,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      confirmationVisible: false,
      transaction: defaultBonusTransaction(),
      formIsValid: false,
      accounts: [],
      isLoading: false,
    }
  },

  computed: {
    allowedTransactionTypes () {
      return [
        { value: 'bonus', text: this.$t('transactions.bonus') },
        { value: 'extra', text: this.$t('transactions.extra') },
      ]
    },

    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },
  },

  mounted () {
    this.getAccounts()
  },

  methods: {
    /**
     * Shows a confirmation-dialog if the entered data is valid.
     *
     * @returns {void}
     */
    showConfirmation () {
      this.$refs.form.validate()
      this.formIsValid && (this.confirmationVisible = true)
    },

    /**
     * Saves a new transaction if the entered data is valid.
     *
     * @returns {Promise}
     */
    async createTransaction () {
      this.confirmationVisible = false
      this.isLoading = true
      const res = await PendingTransactionApi.createBonusExtra(this.transaction)
      this.isLoading = false

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: res.statusText, color: 'error' })
      }

      this.$emit('close')
      this.$emit('request:reload')
      this.transaction = defaultBonusTransaction()
      this.$refs.form.resetValidation()
      this.$store.commit('setSnackbar', { text: this.$t('common.created') })
    },

    /**
     * Loads all accounts from the api, combines their names and sorts those.
     *
     * @returns {Promise}
     */
    async getAccounts () {
      const res = await AccountApi.listAll()

      if (res.ok) {
        const accounts = await res.json()
        this.accounts = accounts
          .map(account => ({
            ...account,
            combinedName: `${account.lastName}, ${account.firstName}`
          }))
          .sort((a, b) => a.combinedName.localeCompare(b.combinedName))
      }
    },

    /**
     * Returns the full name of the account with the given id.
     *
     * @param {number} accountId
     * @returns {string}
     */
    getAccountName (accountId) {
      const account = this.accounts.find(account => account.accountId === accountId)
      return account ? account.combinedName : '-'
    },
  }
}
</script>
