<template>
  <div class="dividend-payout--wrap">
    <div class="mb-6 d-flex flex-wrap">
      <h1 class="mr-auto">
        {{ $t('sidebar.dividendPayout') }}
      </h1>

      <v-btn
        depressed
        color="primary"
        @click="showCreateDialog = true"
      >
        {{ $t('dividendPayout.create') }}
      </v-btn>
    </div>

    <payout-overview
      :is-loading="isLoading"
      :dividend-payouts="dividendPayouts"
      @updated="getDividendPayouts()"
    />

    <create-payout-dialog
      v-model="showCreateDialog"
      @created="onPayoutCreated"
    />

    <data-reload-timer
      fixed
      :seconds="120"
      @request:reload="getDividendPayouts()"
    />
  </div>
</template>

<script>
import DividendPayoutApi from '@/api/DividendPayout.js'

import CreatePayoutDialog from './CreatePayoutDialog.vue'
import DataReloadTimer from '@/components/DataReloadTimer'
import PayoutOverview from './PayoutOverview.vue'

export default {
  name: 'dividend-payout',

  components: {
    CreatePayoutDialog,
    DataReloadTimer,
    PayoutOverview,
  },

  data () {
    return {
      isLoading: false,
      showCreateDialog: false,
      dividendPayouts: [],
    }
  },

  mounted () {
    this.getDividendPayouts()
  },

  methods: {
    /**
     * getDividendPayouts
     *
     * @returns {Promise}
     */
    async getDividendPayouts () {
      this.isLoading = true
      const res = await DividendPayoutApi.getAll()
      res.ok && (this.dividendPayouts = await res.json())
      this.isLoading = false
    },

    /**
     * onPayoutCreated
     *
     * @returns {undefined}
     */
    onPayoutCreated () {
      this.showCreateDialog = false
      this.getDividendPayouts()
    },
  },
}
</script>
