<template>
  <div class="price-input--wrap">
    <v-text-field
      :value="centPrice ? centPrice / 100 : null"
      prepend-inner-icon="mdi-currency-eur"
      type="number"
      :min="0"
      :max="maxPrice"
      step="0.01"
      :label="label"
      :rules="rules"
      hide-details
      outlined
      class="pa-0"
      placeholder="0,00"
      @input="onPriceChange"
    />
  </div>
</template>

<script>
export default {
  name: 'price-input',

  model: {
    prop: 'centPrice',
  },

  props: {
    centPrice: {
      type: Number,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      maxPrice: 1000000,
      rules: [
        v => !!v,
        v => v > 0,
        v => v <= this.maxPrice,
        v => {
          if (!v) return true
          const decimalSplit = v.toString().split('.')
          return !(decimalSplit.length > 1 && decimalSplit[1].length > 2)
        },
      ]
    }
  },

  methods: {
    onPriceChange (euroPrice) {
      let price = euroPrice ? parseFloat(euroPrice) : 0

      price < 0 && (price = 0)
      price > this.maxPrice && (price = this.maxPrice)

      // 0.14 * 100 -> 14.000000000000002
      this.$emit('input', Math.round(price * 100))
    }
  },
}
</script>
