<template>
  <div class="change-email-form--wrap">
    <v-form @submit.prevent="changeEmail">
      <v-card width="500" max-width="90%" class="mx-auto">
        <v-card-title>
          {{ $t('login.changeEmail') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('login.pleaseChangeEmail') }}
        </v-card-subtitle>

        <v-divider />

        <v-card-text>
          <v-text-field
            v-model.trim="newEmail"
            prepend-inner-icon="mdi-face-man"
            hide-details
            outlined
            :label="$t('login.name')"
            class="mb-2"
            name="email"
            type="email"
            persistent-placeholder
            required
            autocomplete="username"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            :loading="isLoading"
            depressed
            type="submit"
          >
            <v-icon left color="primary">
              mdi-send
            </v-icon>
            {{ $t('login.changeEmail') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import AccountApi from '@/api/Account'

export default {
  name: 'change-email-form',

  data () {
    return {
      isLoading: false,
      newEmail: '',
    }
  },

  methods: {
    /**
     * Changes the e-mail-address of the current user.
     *
     * @returns {Promise}
     */
    async changeEmail () {
      this.isLoading = true

      const res = await AccountApi.changeEmail(this.newEmail)

      if (res.ok) {
        this.$emit('change:success')
        this.$store.commit('setSnackbar', { text: this.$t('login.changeEmailSuccess'), color: 'success' })
      } else {
        this.$emit('change:failure')
        this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
      }

      this.isLoading = false
    },
  },
}
</script>
