<template>
  <div class="news-links--wrap">
    <div v-if="linkList.length" class="links--wrap mt-4">
      <div class="text-h6 mb-1">
        {{ $t('news.links') }}
      </div>
      <div v-for="({ label, link }, i) in linkList" :key="`link_${i}`">
        <a :href="link" target="_blank" class="text-decoration-none">
          {{ label }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'news-links',

  props: {
    // list of objects with a label + link
    linkList: {
      type: Array,
      default: () => ([])
    }
  },
}
</script>
