var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pendingTransactions.length)?_c('div',{staticClass:"pending-transactions--wrap mb-8"},[_c('data-table',{attrs:{"columns":_vm.columns,"items":_vm.pendingTransactions,"default-options":{ sortBy: ['dateCreated'], sortDesc: [true] },"is-loading":_vm.isLoading,"title":_vm.$t('transactions.pendingTransactions')},scopedSlots:_vm._u([{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(new Date(item.dateCreated),true))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(new Date(item.price),_vm.$i18n.locale))+" ")]}},{key:"item.formulaPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.formulaPrice,_vm.$i18n.locale))+" ")]}},{key:"item.buyerFullName",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{staticClass:"mr-1",attrs:{"type":"buy"}}),_vm._v(" "+_vm._s(item.buyerFullName)+" ")]}},{key:"item.sellerFullName",fn:function(ref){
var item = ref.item;
return [_c('icon-buy-sell',{staticClass:"mr-1",attrs:{"type":"sell"}}),_vm._v(" "+_vm._s(item.sellerFullName)+" ")]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }