import { format, subMonths } from 'date-fns'

const getLastMonth = dateString => format(subMonths(new Date(dateString), 1), 'yyyy-MM')

export default {
  /**
   * Groups the given transactions by their month-year-combination.
   * If there aren't multiple months to display we add the last one with an
   * empty value so we're able to visualize a difference.
   *
   * @param {array} transactions
   * @returns {object}
   */
  getMonthlyTransactions (transactions) {
    const byMonth = transactions.reduce((monthly, transaction) => {
      const group = transaction.datePerformed.substr(0, 7)
      monthly[group] = monthly[group]
        ? [...monthly[group], transaction]
        : monthly[group] = [transaction]

      return monthly
    }, {})

    const months = Object.keys(byMonth)

    if (months.length !== 1) {
      return byMonth
    }

    return { [getLastMonth(months[0])]: [], ...byMonth }
  },

  /**
   * Groups the given transactions with the wanted type by their month,
   * calculates the sum of the wanted field.
   * If there aren't multiple months to display we add the last one with an
   * empty value so we're able to visualize a difference.
   *
   * @param {array} transactions Transactions to group
   * @param {string} type Transaction-type to use
   * @param {string} toSum Field of a transaction to sum
   * @param {boolean} useAmount Should the sum get multiplied with the amount of the transaction?
   * @returns {array} Transactions grouped by month
   */
  getMonthlyTransactionSumByType (transactions, type, toSum, useAmount) {
    const grouped = this.getMonthlyTransactions(transactions)
    const byType = Object.keys(grouped).map(month => {
      const transactions = grouped[month]
      const sum = transactions.reduce((sum, t) => t.type === type
        ? sum + (toSum ? t[toSum] : 1) * (useAmount ? t.amount : 1)
        : sum, 0
      )

      return { [month]: sum }
    })

    if (byType.length !== 1) {
      return byType
    }

    return [
      { [getLastMonth(Object.keys(byType[0])[0])]: 0 },
      ...byType
    ]
  },
}
