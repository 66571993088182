<template>
  <div class="date-text-input--wrap">
    <v-menu
      v-model="pickerVisible"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="value | readableIsoDate"
          :label="label"
          :rules="rules"
          :dense="dense"
          :outlined="outlined"
          :disabled="disabled"
          hide-details
          prepend-inner-icon="mdi-calendar"
          readonly
          :class="inputClass"
          :clearable="clearable"
          v-bind="attrs"
          v-on="on"
          @click:clear="$emit('input', '')"
        >
          <template v-if="$slots.append" #append>
            <slot name="append" />
          </template>
        </v-text-field>
      </template>
      <v-sheet color="white">
        <v-date-picker
          :allowed-dates="allowedDates"
          :value="value || defaultDate"
          :max="birthdayMode ? new Date() : null | isoDate"
          @change="isoDate => $emit('input', isoDate)"
        />
        <v-divider />
        <div class="d-flex pa-2">
          <v-spacer />
          <v-btn depressed @click="pickerVisible = false">
            {{ $t('common.close') }}
          </v-btn>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import { formatISO, subYears } from 'date-fns'

export default {
  name: 'date-text-input',

  props: {
    allowedDates: {
      type: Function,
      default: () => true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    dense: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: '',
    },

    birthdayMode: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: true,
    },

    rules: {
      type: Array,
      default: () => ([]),
    },

    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      pickerVisible: false,
    }
  },

  computed: {
    defaultDate () {
      const date = this.birthdayMode ? subYears(new Date(), 18) : new Date()
      return formatISO(date, { representation: 'date' })
    },
  },
}
</script>
