var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-table--wrap"},[_c('data-table',{staticClass:"mb-8",attrs:{"columns":_vm.columns,"items":_vm.messages,"title":_vm.title,"default-options":{ sortBy: ['receivedAt'], sortDesc: [true] },"is-loading":_vm.isLoading,"custom-page-size":25},scopedSlots:_vm._u([{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-none",class:{ 'font-weight-medium': !item.hasBeenRead },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('show:message', item)}}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}},{key:"item.receivedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.receivedAt,true))+" ")]}},{key:"item.hasBeenRead",fn:function(ref){
var item = ref.item;
return [_c('icon-mail-unread',{attrs:{"read":item.hasBeenRead}})]}},{key:"item.documentId",fn:function(ref){
var item = ref.item;
return [(item.document)?_c('div',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":_vm.getDownloadLink(item.document.documentId),"target":"_blank"}},[_vm._v(" "+_vm._s(item.document.title)+" ")])]):_c('div',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("actions",null,{"item":item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }