import colors from 'vuetify/lib/util/colors'

export default [
  colors.lightBlue.base,
  colors.indigo.darken4,
  colors.teal.accent3,
  colors.deepPurple.accent3,
  colors.indigo.accent4,
  colors.lightGreen.base,
  colors.amber.darken1,
  colors.deepOrange.accent3,
  colors.pink.darken2,
  colors.red.lighten1,
  colors.purple.base,
  colors.cyan.base,
  colors.green.accent4,
  colors.blueGrey.darken2,
]
