<template>
  <div class="user-documents--wrap">
    <h1 class="mb-6">
      {{ $t('sidebar.userDocuments') }}
    </h1>

    <data-table
      :columns="columns"
      :items="combinedDocuments"
      :is-loading="isLoading"
      :default-options="{ sortBy: ['date'], sortDesc: [true] }"
    >
      <template #[`item.date`]="{ item }">
        {{ item.date | readableIsoDate }}
      </template>

      <template #[`item.name`]="{ item }">
        <transaction-type-chip
          v-if="item.isTransaction"
          :transaction-type="item.name"
        />

        <template v-else>
          {{ item.name }}
        </template>
      </template>

      <template #[`item.file`]="{ item }">
        <table-button
          icon="mdi-file-download"
          :tooltip="$t('common.download')"
          :href="item.link"
          target="_blank"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import AccountApi from '@/api/Account.js'
import TransactionApi from '@/api/Transactions.js'

import DataTable from '@/components/DataTable'
import TableButton from '@/components/TableButton'
import TransactionTypeChip from '@/components/TransactionTypeChip'

export default {
  name: 'user-documents',

  components: {
    DataTable,
    TableButton,
    TransactionTypeChip,
  },

  data () {
    return {
      documents: [],
      isLoading: false,
      transactionDocuments: [],
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.date'), value: 'date', width: 150 },
        { text: this.$t('common.file'), value: 'name' },
        { text: this.$t('common.download'), value: 'file', sortable: false, align: 'right', width: 150 },
      ]
    },

    /**
     * Regular documents and specialized transaction-documents have a different
     * datastructure and downloadlinks, but should get visualized in one table.
     *
     * @returns {array}
     */
    combinedDocuments () {
      const documents = this.documents.map(document => ({
        date: document.created,
        name: document.filename,
        link: AccountApi.getDocumentDownloadLink(document.accountDocumentId),
      }))

      const transactionDocuments = this.transactionDocuments.map(transaction => ({
        date: transaction.datePerformed,
        name: transaction.type,
        link: TransactionApi.getDownloadLink(transaction.id),
        isTransaction: true,
      }))

      return [...documents, ...transactionDocuments]
    },
  },

  mounted () {
    this.init()
  },

  methods: {
    async init () {
      this.isLoading = true

      const documentRes = await AccountApi.getDocuments()
      const transactionDocumentRes = await AccountApi.getTransactionDocuments()

      documentRes.ok && (this.documents = await documentRes.json())
      transactionDocumentRes.ok && (this.transactionDocuments = await transactionDocumentRes.json())

      this.isLoading = false
    },
  },
}
</script>
