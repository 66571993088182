<template>
  <div class="document-table--wrap">
    <data-table
      :columns="columns"
      :default-options="{ sortBy: ['date'], sortDesc: [true] }"
      :custom-page-size="25"
      :items="filteredDocuments"
      :hide-footer="dashboardMode"
      :show-search="showSearch"
      :is-loading="isLoading"
      :title="title"
    >
      <template #headerActions>
        <slot name="headerActions" />
      </template>

      <template #[`item.date`]="{ item }">
        {{ item.date | readableIsoDate }}
      </template>

      <template #[`item.title`]="{ item }">
        <a
          :href="getDownloadLink(item.documentId)"
          target="_blank"
          class="text-decoration-none"
        >
          {{ item.title }}
        </a>
      </template>

      <template #[`item.companyId`]="{ item }">
        {{ getCompanyLabel(item.companyId) }}
      </template>

      <template #[`item.displayAreas`]="{ item }">
        {{ getReadableDisplayAreas(item.displayAreas) || '-' }}
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-file-download"
          icon-color="primary"
          :tooltip="$t('common.download')"
          :href="getDownloadLink(item.documentId)"
          target="_blank"
        />
        <table-button
          icon="mdi-eye"
          :tooltip="$t('common.show')"
          @click="documentToShow = item"
        />
      </template>
    </data-table>

    <v-dialog
      :value="documentToShow !== null"
      :width="700"
      max-width="90%"
      @input="visible => !visible && (documentToShow = null)"
    >
      <v-card v-if="documentToShow">
        <v-card-title class="mb-4">
          {{ documentToShow.title }}
        </v-card-title>
        <v-card-subtitle>
          <v-chip color="secondary" class="mr-2">
            <v-icon small left>
              mdi-calendar
            </v-icon>
            {{ documentToShow.date | readableIsoDate }}
          </v-chip>
          <v-chip color="secondary">
            {{ $t(`document.type.${documentToShow.documentType}`) }}
          </v-chip>
        </v-card-subtitle>
        <v-card-text>
          {{ documentToShow.description }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            :href="getDownloadLink(documentToShow.documentId)"
            target="_blank"
            depressed
          >
            <v-icon left color="primary">
              mdi-file-download
            </v-icon>
            {{ $t('common.download') }}
          </v-btn>
          <v-spacer />
          <v-btn depressed @click="documentToShow = null">
            <v-icon left>
              mdi-close
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getQuarter, getYear, parseISO } from 'date-fns'

import CompanyApi from '@/api/Company'
import DataTable from '@/components/DataTable'
import DocumentApi from '@/api/Documents'
import TableButton from '@/components/TableButton'

export default {
  name: 'document-table',

  components: {
    DataTable,
    TableButton,
  },

  props: {
    // only 5 recent entries, no footer
    dashboardMode: {
      type: Boolean,
      default: false,
    },

    // search-object to filter documents
    search: {
      type: Object,
      default: null,
    },

    // show search-input above the table
    showSearch: {
      type: Boolean,
      default: true,
    },

    // title of the table
    title: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      companies: [],
      documents: [],
      documentToShow: null,
      isLoading: false,
      maxDescriptionLength: 30,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.date'), value: 'date', width: 100 },
        { text: this.$t('common.company'), value: 'companyId' },
        { text: this.$t('common.title'), value: 'title' },
        { text: this.$t('document.area'), value: 'displayAreas', sortable: false },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right', width: 100 },
      ]
    },

    /**
     * Filters the set of documents by the given search-object by comparing
     * it's values.
     *
     * @returns {array}
     */
    filteredDocuments () {
      const documentsByLanguage = this.documents.filter(({ language }) => language === this.$i18n.locale)

      if (this.search === null) {
        return documentsByLanguage
      }

      return documentsByLanguage.filter(document => {
        return Object.keys(this.search).reduce((allFiltersMatch, key) => {
          const filterValue = this.search[key]

          // search by the number of a year, but documents use iso-dates
          if (key === 'year' && filterValue) {
            return allFiltersMatch && +filterValue === getYear(parseISO(document.date))
          }

          // search by the number of a quarter, but documents use iso-dates
          if (key === 'quarter' && filterValue) {
            return allFiltersMatch && +filterValue === getQuarter(parseISO(document.date))
          }

          // some part of a string matches (case-insensitive)
          // handles full dates, too (since we're using iso-strings)
          if (typeof filterValue === 'string' && filterValue.length) {
            return allFiltersMatch && document[key].toLowerCase().includes(filterValue.toLowerCase())
          }

          // some array-element of the search must be present within the document-dataset
          if (Array.isArray(filterValue) && filterValue.length) {
            return allFiltersMatch && filterValue.some(element => document[key].includes(element))
          }

          // a number must match exactly
          if (typeof filterValue === 'number') {
            return allFiltersMatch && +document[key] === filterValue
          }

          return allFiltersMatch
        }, true)
      })
    }
  },

  async mounted () {
    this.isLoading = true

    await this.getCompanies()
    await this.getDocuments()

    this.isLoading = false
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()
      res.ok && (this.companies = await res.json())
    },

    /**
     * Loads all documents from the api.
     *
     * @returns {void}
     */
    async getDocuments () {
      const res = await DocumentApi.list()
      res.ok && (this.documents = await res.json())

      if (this.dashboardMode) {
        this.documents = this.documents
          .sort((a, b) => b.date.localeCompare(a.date)) // dates are ISO-strings
          .slice(0, 5)
      }
    },

    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },

    /**
     * Finds the label of the company with the given id.
     *
     * @param {number|string}
     * @returns {string}
     */
    getCompanyLabel (companyId) {
      const company = this.companies.find(company => company.companyId === companyId)
      return company ? company.shortName : '-'
    },

    /**
     * Formats, translates the given display-area-keys so those are readable by
     * the user.
     *
     * @param {array} displayAreas
     * @returns {string}
     */
    getReadableDisplayAreas (displayAreas = []) {
      return displayAreas
        .map(area => this.$t(`document.areas.${area}`))
        .join(', ')
    }
  },
}
</script>
