import Api from '@/api/Api'

export default {
  baseUrl: 'configuration',

  /**
   * get
   *
   * @returns {Promise}
   */
  get () {
    return Api.baseFetch(this.baseUrl)
  },

  /**
   * create
   *
   * @param {object}
   * @returns {Promise}
   */
  create (config) {
    return Api.baseFetch(this.baseUrl, {
      method: 'POST',
      body: JSON.stringify({
        ...config,
        validFrom: config.validFrom.toISOString()
      }),
    })
  },

  /**
   * update
   *
   * @param {object}
   * @returns {Promise}
   */
  update (config) {
    return Api.baseFetch(`${this.baseUrl}/${config.configurationId}`, {
      method: 'PUT',
      body: JSON.stringify({
        ...config,
        validFrom: config.validFrom.toISOString()
      }),
    })
  },

  /**
   * list
   *
   * @returns {Promise}
   */
  list () {
    return Api.baseFetch(`${this.baseUrl}/list`)
  },
}
