<template>
  <div class="dashboard--wrap">
    <div class="d-flex align-center flex-wrap mb-6">
      <h1 class="mb-0">
        {{ $t('sidebar.start') }}
      </h1>
      <v-spacer />

      <div class="d-flex my-2">
        <v-alert
          v-if="lastAveragePriceByFormulaPrice !== null"
          outlined
          color="secondary"
          class="mb-0"
        >
          {{ $t('averagePriceVolume.formulaPriceAveragePrice') }}
          <v-chip small color="secondary" class="ml-2">
            {{ lastAveragePriceByFormulaPrice | readablePrice($i18n.locale) }}
          </v-chip>
        </v-alert>

        <formula-price-info class="ml-2" />
      </div>
    </div>

    <div v-if="isLoading" class="loader d-flex justify-center align-center">
      <v-progress-circular indeterminate color="secondary" />
    </div>

    <template v-else>
      <current-offers class="mb-12" />
      <average-price-volume-combi
        class="mb-12"
        :formula-prices="formulaPrices"
        :transactions="chartTransactions"
        @calculated-averageprice="setLastAveragePrice"
      />

      <document-table
        dashboard-mode
        :title="$t('document.newDocuments')"
        :show-search="false"
        class="mb-12"
      >
        <template #headerActions>
          <v-btn small text :to="{ name: 'DocumentSearch' }">
            <v-icon left>
              mdi-file-find-outline
            </v-icon>
            {{ $t('sidebar.searchDocuments') }}
          </v-btn>
        </template>
      </document-table>

      <month-calendar class="mb-12" />

      <div class="d-flex align-center mb-4">
        <h2>{{ $t('sidebar.distribution') }}</h2>
        <v-spacer />
        <v-btn depressed color="secondary" :to="{ name: 'StockDistribution' }">
          {{ $t('common.more') }}
        </v-btn>
      </div>

      <v-row class="mb-12">
        <v-col :cols="12" :md="8">
          <company-distribution-tree :company-amounts="companyAmounts" :loading="isLoading" />
        </v-col>
        <v-col :cols="12" :md="4">
          <user-status-pie :height="470" :shareholders="shareholders" />
        </v-col>
      </v-row>

      <age-distribution-bar />
    </template>
  </div>
</template>

<script>
import AgeDistributionBar from '@/components/charts/AgeDistributionBar.vue'
import CompanyDistributionTree from '@/components/charts/CompanyDistributionTree'
import ConfigurationApi from '@/api/Configuration'
import CurrentOffers from '@/components/CurrentOffers'
import DocumentTable from '@/components/DocumentTable'
import FormulaPriceInfo from '@/components/FormulaPriceInfo'
import MonthCalendar from '@/components/MonthCalendar'
import ShareApi from '@/api/Share'
import ShareholderFormatter from '@/services/shareholder-formatter'
import TransactionApi from '@/api/Transactions'
import UserStatusPie from '@/components/charts/UserStatusPie'
import AveragePriceVolumeCombi from '@/components/charts/AveragePriceVolumeCombi'

export default {
  name: 'dashbaord-view',

  components: {
    AgeDistributionBar,
    AveragePriceVolumeCombi,
    CompanyDistributionTree,
    CurrentOffers,
    DocumentTable,
    FormulaPriceInfo,
    MonthCalendar,
    UserStatusPie,
  },

  data () {
    return {
      formulaPrices: [],
      isLoading: true,
      shareholders: [],
      chartTransactions: [],
      lastAveragePriceByFormulaPrice: null,
    }
  },

  computed: {
    /**
     * Amount of stocks by company.
     *
     * @returns {array}
     */
    companyAmounts () {
      return ShareholderFormatter.getCompanyAmounts(this.shareholders)
    },

    user () {
      return this.$store.state.user.user
    },
  },

  async mounted () {
    this.isLoading = true
    await this.getFormulaPrices()
    await this.getShareholders()
    await this.getTransactions()
    this.isLoading = false
  },

  methods: {
    /**
     * Loads all shareholders from the api.
     *
     * @returns {void}
     */
    async getShareholders () {
      const res = await ShareApi.getShareholderStats(new Date())
      res.ok && (this.shareholders = await res.json())
    },

    /**
     * Loads all transactions, but without user-information.
     *
     * @returns {void}
     */
    async getTransactions () {
      const res = await TransactionApi.getChartData()
      res.ok && (this.chartTransactions = await res.json())
    },

    /**
     * Loads configurations, extracts formula-prices with their date from those
     * so we can use that dataset within statistics.
     *
     * @returns {Promise}
     */
    async getFormulaPrices () {
      const res = await ConfigurationApi.list()

      if (res.ok) {
        const configs = await res.json()
        this.formulaPrices = configs.map(({ formulaPrice, validFrom }) => ({ date: validFrom, formulaPrice }))
      }
    },

    /**
     * Uses the last average-price (per formula-price-step/change) as the one
     * to display above the dashboard.
     *
     * @param {array} averages
     * @returns {undefined}
     */
    setLastAveragePrice (averages) {
      if (averages.length !== 0) {
        this.lastAveragePriceByFormulaPrice = averages[averages.length - 1].average
      }
    },
  },
}
</script>

<style lang="scss">
  .dashboard--wrap {
    .loader {
      height: 50vh;
    }
  }
</style>
