<template>
  <div v-if="userToEdit" class="user-edit--wrap">
    <h1 class="mb-6">
      {{ userToEdit.lastName }}, {{ userToEdit.firstName }}
    </h1>

    <div class="d-flex align-center mb-8">
      <v-tabs
        v-model="activeTab"
        show-arrows
        color="grey darken-4"
        background-color="transparent"
        class="d-inline-block"
      >
        <v-tab>
          <v-icon left>
            mdi-pencil
          </v-icon>
          {{ $t('userNav.editProfile') }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-format-list-bulleted
          </v-icon>
          {{ $t('users.shares') }}
        </v-tab>
      </v-tabs>

      <v-spacer />

      <v-btn
        v-if="showReset2faButton"
        depressed
        color="secondary"
        class="mx-2"
        @click="confirm2faReset = true"
      >
        {{ $t('users.reset2fa') }}
      </v-btn>

      <v-btn
        v-if="showImpersonateButton"
        depressed
        color="secondary"
        @click="impersonate"
      >
        <v-icon left>
          mdi-logout-variant
        </v-icon>
        {{ $t('users.impersonate') }}
      </v-btn>
    </div>

    <v-tabs-items v-model="activeTab" class="transparent">
      <v-tab-item>
        <user-form
          v-if="userToEdit"
          :user-to-edit="userToEdit"
          @submit="updateUser"
        />
      </v-tab-item>

      <v-tab-item>
        <v-switch
          v-model="showAllShares"
          :disabled="loadingShares"
          :label="$t('users.showAllShares')"
          inset
          class="ml-3"
          @change="getUserShares"
        />

        <user-stocks
          :loading="loadingShares"
          :shares="userShares"
          with-history
          class="pa-1"
        />
      </v-tab-item>
    </v-tabs-items>

    <confirm-dialog
      :headline="$t('users.reset2fa')"
      :info-text="$t('users.confirmReset2fa')"
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="confirm2faReset"
      @cancel="confirm2faReset = false"
      @ok="reset2fa"
    >
      <v-form @submit.prevent="saveOffer">
        <v-text-field
          v-model="secondFactorCode"
          prepend-inner-icon="mdi-key"
          :label="$t('salesRequests.twoFactorInfo')"
          hide-details
          outlined
          autofocus
          class="pa-0 mt-6 mb-2"
        />
      </v-form>
    </confirm-dialog>
  </div>
</template>

<script>
import roles from '@/roles'

import AccountApi from '@/api/Account'
import ShareApi from '@/api/Share'

import ConfirmDialog from '@/components/ConfirmDialog'
import UserForm from '@/components/UserForm'
import UserStocks from '@/components/UserStocks'

export default {
  name: 'edit-user',

  components: {
    ConfirmDialog,
    UserForm,
    UserStocks,
  },

  data () {
    return {
      activeTab: null,
      confirm2faReset: false,
      loadingShares: false,
      secondFactorCode: '',
      showAllShares: false,
      userShares: [],
      userToEdit: null,
    }
  },

  computed: {
    userId () {
      return this.$route.params.id ? +this.$route.params.id : null
    },

    user () {
      return this.$store.state.user.user
    },

    // impersonation is only allowed as superadmin while editing an user which isn't an admin
    showImpersonateButton () {
      return this.$store.getters.userHasRole([roles.superadmin]) &&
        this.userToEdit !== null &&
        !this.userToEdit.roles.includes(roles.superadmin)
    },

    // only editors&superadmins are allowed to perform a 2fa-reset
    showReset2faButton () {
      return this.$store.getters.userHasRole([roles.editor, roles.superadmin])
    },
  },

  async mounted () {
    await this.getUser()
    this.getUserShares()
  },

  methods: {
    /**
     * Loads the dataset of the user with the id given as route-parameter.
     *
     * @returns {void}
     */
    async getUser () {
      const res = await AccountApi.get(this.userId)
      res.ok && (this.userToEdit = await res.json())
    },

    /**
     * Loads shares of the currently edited user. There's a variant with active
     * shares and one which includes previous shares, too. We use the variant
     * based on the current user-input.
     *
     * @returns {void}
     */
    async getUserShares () {
      this.loadingShares = true
      const res = await ShareApi.getSharesOf(this.userId, this.showAllShares)
      res.ok && (this.userShares = await res.json())
      this.loadingShares = false
    },

    /**
     * impersonate
     *
     * @returns {void}
     */
    async impersonate () {
      const res = await AccountApi.impersonate(this.userId)

      if (!res.ok) {
        return this.$store.commit('setSnackbar', { text: this.$t('users.impersonateError'), color: 'error' })
      }

      const profileRes = await AccountApi.me()

      if (profileRes.ok) {
        const profile = await profileRes.json()

        this.$store.commit('setUser', profile)
        this.$store.commit('setImpersonated', profileRes.headers.get('x-impersonated') === 'true')
        this.$store.commit('setSnackbar', { text: this.$t('users.impersonateSuccess') })
        this.$store.dispatch('notification/get')

        this.$route.name !== 'UserProfile' && this.$router.push({ name: 'UserProfile' })
      }
    },

    /**
     * Resets the 2fa-authentication of the currently edited user after the
     * action got confirmed by the current editor/superadmin.
     *
     * @returns {Promise}
     */
    async reset2fa () {
      const res = await AccountApi.reset2fa(this.userToEdit.accountId, this.secondFactorCode)
      this.secondFactorCode = ''
      this.confirm2faReset = false

      if (!res.ok) {
        return this.$store.commit('setSnackbar', {
          text: res.status === 400 ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
          color: 'error'
        })
      }

      this.$store.commit('setSnackbar', { text: this.$t('users.reset2faSuccess'), color: 'success' })
    },

    /**
     * Updates the given user.
     *
     * @returns {void}
     */
    async updateUser (user) {
      const res = await AccountApi.update(user.accountId, user)

      if (res.ok) {
        this.getUser()
        return this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
    }
  },

}
</script>
