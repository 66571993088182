<template>
  <v-footer app inset color="grey lighten-5" dark>
    <div class="footerContent text-center flex-grow-1 body-2">
      <div class="grey--text">
        &copy; Copyright 2012-2022 &middot; Müller-BBM AG &middot; Helmut-A.-Müller-Straße 1&nbsp;-&nbsp;5 &middot; D-82152 Planegg/München
      </div>
      <div>
        <ul class="navFooter mb-0 pa-0 d-flex justify-center">
          <li
            v-for="(item,index) in navFooterItems"
            :key="index"
          >
            <router-link
              v-if="item.private ? user !== null : true"
              :to="{name: item.route}"
              exact
              class="mx-2 primary--text"
            >
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'app-footer',

  computed: {
    navFooterItems () {
      return [
        { label: this.$t('footer.nav.imprint'), route: 'Imprint' },
        { label: this.$t('footer.nav.dataConfidentiality'), route: 'DataConfidentiality', private: true },
      ]
    },

    user () {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
  .v-footer {
    backdrop-filter: blur(30px);
  }

  .footerContent {
    .navFooter {
      list-style: none;

      li {
        a {
          text-decoration: none;
        }
      }
    }
  }
</style>
