<template>
  <div class="user-stocks--wrap">
    <data-table
      :columns="columns"
      :items="tableData"
      :default-options="{ sortBy: ['transactionDto.dateCreated'], sortDesc: [true] }"
      :title="$t('stocks.stocksPerTransaction')"
      :is-loading="loading"
      class="stock-table"
    >
      <template #[`item.transactionDto.dateCreated`]="{ item }">
        {{ item.transactionDto.dateCreated | readableIsoDate(true) }}
      </template>

      <template #[`item.transactionDto.datePerformed`]="{ item }">
        <v-tooltip
          v-if="compact"
          left
        >
          <template #activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.transactionDto.datePerformed | readableIsoDate() }}
            </span>
          </template>
          <span>
            {{ item.transactionDto.datePerformed | readableIsoDate(true) }}
          </span>
        </v-tooltip>
        <span v-else>
          {{ item.transactionDto.datePerformed | readableIsoDate(true) }}
        </span>
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.amount | localeNumber($i18n.locale) }}
      </template>

      <template #[`item.ranges`]="{ item }">
        <v-chip
          v-for="(range, i) in item.ranges"
          :key="`${item.transactionDto.id}_${i}`"
          small
          label
          color="grey lighten-4"
          class="mr-1 my-1"
        >
          {{ range[0] }}<span v-if="range[1]">&nbsp;-&nbsp;{{ range[1] }}</span>
        </v-chip>
      </template>

      <template #[`item.transactionDto.sellerFullName`]="{ item }">
        <span v-if="item.transactionDto.sellerFullName">
          <icon-buy-sell type="sell" />
          {{ item.transactionDto.sellerFullName }}
        </span>
      </template>

      <template #[`item.transactionDto.price`]="{ item }">
        {{ item.transactionDto.price | readablePrice($i18n.locale) }}
      </template>

      <template #[`item.transactionDto.type`]="{ item }">
        <transaction-type-chip :transaction-type="item.transactionDto.type" />
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-format-list-bulleted"
          :tooltip="$t('stocks.showStocks')"
          @click="entryToShow = item"
        />
      </template>
    </data-table>

    <stock-sidebar
      :stock-group="entryToShow"
      @close="entryToShow = null"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import IconBuySell from '@/components/Icons/IconBuySell'
import StockSidebar from '@/components/StockSidebar'
import TableButton from '@/components/TableButton'
import TransactionTypeChip from '@/components/TransactionTypeChip'

export default {
  name: 'user-stocks',

  components: {
    DataTable,
    IconBuySell,
    StockSidebar,
    TableButton,
    TransactionTypeChip,
  },

  props: {
    compact: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    shares: {
      type: Array,
      default: () => ([])
    },

    withHistory: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      entryToShow: null,
    }
  },

  computed: {
    columns () {
      const columns = [
        {
          text: this.compact ? this.$t('common.date') : this.$t('stocks.transactionDate'),
          value: 'transactionDto.datePerformed',
          width: this.compact ? 100 : 180,
        },
        { text: this.$t('common.type'), value: 'transactionDto.type', width: 150 },
        { text: this.$t('common.amount'), value: 'amount', align: 'right', width: 100 },
        { text: this.$t('stocks.numbers'), value: 'ranges', sortable: false },
        { text: this.$t('common.price'), value: 'transactionDto.price', align: 'right', width: 100 },
        { text: this.$t('stocks.seller'), value: 'transactionDto.sellerFullName', width: 250 },
      ]

      if (this.withHistory) {
        columns.push({ text: this.$t('common.actions'), value: 'actions', width: 50, sortable: false, align: 'right' })
      }

      if (!this.compact) {
        columns.unshift(
          { text: this.$t('stocks.buyDate'), value: 'transactionDto.dateCreated', width: 160 },
        )
      }

      return columns
    },

    // transform original data so our table can handle sorting,..
    tableData () {
      return this.shares.map(({ certificates, ranges, transactionDto }) => ({
        amount: certificates.length,
        certificates,
        ranges,
        transactionDto,
      }))
    },

    user () {
      return this.$store.state.user.user
    },
  },
}
</script>

<style lang="scss">
  .user-stocks--wrap {
    .stock-table {
      td {
        vertical-align: text-top;
      }
    }
  }
</style>
