<template>
  <v-app-bar
    elevate-on-scroll
    :elevation="1"
    :height="80"
    class="backdrop"
  >
    <v-btn v-if="user" icon class="ml-0 mr-2" @click="$emit('sidebar:toggle')">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-sheet v-if="!sidebarVisible" color="primary" rounded class="mx-2 px-2 pt-2 pb-1">
      <img
        :src="require('@/assets/gfx/mueller_bbm.svg')"
        alt="MBBM Logo"
        class="logo-mobile"
        contain
      >
      <span class="text-caption white--text">
        {{ $t('sidebar.portal') }}
      </span>
    </v-sheet>

    <div v-if="user" class="d-flex align-center ml-auto">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <notification-menu />
          </span>
        </template>
        <span>{{ $t('topnav.notifications') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <contact-dialog />
          </span>
        </template>
        <span>{{ $t('topnav.contact') }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <language-menu class="mx-2" />
          </span>
        </template>
        <span>{{ $t('topnav.switchLanguage') }}</span>
      </v-tooltip>

      <impersonate-hint v-if="impersonated" class="ml-2" />

      <div class="d-none d-md-flex ml-2" :style="{ maxWidth: '270px' }">
        <user-menu />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import ContactDialog from '@/views/ContactDialog'
import ImpersonateHint from '@/components/ImpersonateHint'
import LanguageMenu from '@/components/LanguageMenu'
import NotificationMenu from '@/views/NotificationMenu'
import UserMenu from '@/components/UserMenu'

export default {
  name: 'app-header',

  components: {
    ContactDialog,
    ImpersonateHint,
    LanguageMenu,
    NotificationMenu,
    UserMenu,
  },

  props: {
    sidebarVisible: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    impersonated () {
      return this.$store.state.user.impersonated
    },
  },
}
</script>

<style lang="scss">
  .v-application {
    .v-application--wrap {
      .v-main {
        .v-app-bar {
          &.backdrop {
            position: sticky;
            top: 0;
            z-index: 5;
            background-color: rgba($color: #FBFBFB, $alpha: 0.97);
          }

          .logo-mobile {
            width: 140px;
            display: block;
            filter: invert(1);
          }
        }
      }
    }
  }

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .v-application {
      .v-application--wrap {
        .v-main {
          .v-app-bar {
            &.backdrop {
              background-color: rgba($color: #FBFBFB, $alpha: 0.66);
              backdrop-filter: blur(50px);
            }
          }
        }
      }
    }
  }
</style>
