import Vue from 'vue'
import Vuex from 'vuex'

import NotificationStore from '@/store/notification'
import SnackbarStore from '@/store/snackbar'
import UserStore from '@/store/user'

import ConfigurationApi from '@/api/Configuration'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    configuration: {}
  },

  getters: {
    companyRatio (state) {
      const { holdingReturnNumerator, holdingReturnDenominator } = state.configuration
      return holdingReturnNumerator / holdingReturnDenominator
    }
  },

  mutations: {
    setConfiguration (state, configuration) {
      state.configuration = configuration
    }
  },

  actions: {
    /**
     * getConfig
     *
     * @param {object} context
     * @returns {void}
     */
    async getConfig ({ commit }) {
      const res = await ConfigurationApi.get()
      res.ok && commit('setConfiguration', await res.json())
    },
  },

  modules: {
    notification: NotificationStore,
    user: UserStore,
    snackbar: SnackbarStore,
  },
})
