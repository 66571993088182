<template>
  <v-dialog
    :value="isVisible"
    :width="700"
    max-width="90%"
    @input="visibility => !visibility && close()"
  >
    <v-form ref="form" v-model="formIsValid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ company ? $t('companyData.edit') : $t('companyData.create') }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="companyConfig.shortName"
            :label="$t('companyData.shortName')"
            outlined
            hide-details
            class="mb-2"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="companyConfig.name"
            :label="$t('companyData.fullName')"
            outlined
            hide-details
            class="mb-2"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="companyConfig.emailDomain"
            :label="$t('companyData.mailDomain')"
            outlined
            hide-details
            class="mb-2"
          />
        </v-card-text>

        <v-card-actions>
          <v-btn depressed class="ml-auto" @click="close">
            <v-icon left>
              mdi-cancel
            </v-icon>
            {{ $t('common.close') }}
          </v-btn>

          <v-btn depressed class="ml-2" type="submit">
            <v-icon left color="primary">
              mdi-pencil
            </v-icon>
            {{ company ? $t('common.save') : $t('common.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
const defaultCompany = () => ({
  companyId: null,
  shortName: '',
  name: '',
  emailDomain: '',
})

export default {
  name: 'company-dialog',

  props: {
    // show the dialog
    isVisible: {
      type: Boolean,
      default: false,
    },

    // use an existing dataset
    company: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      companyConfig: defaultCompany(),
      formIsValid: false,
    }
  },

  computed: {
    requiredRules () {
      return [v => v !== undefined && v !== null && v !== '']
    },
  },

  watch: {
    company () {
      this.useCompany()
    }
  },

  mounted () {
    this.useCompany()
  },

  methods: {
    /**
     * Tells the parent component that the dialog can get closed, resets the
     * form-validation.
     *
     * @returns {void}
     */
    close () {
      this.$emit('close')
      this.$refs.form.resetValidation()
    },

    /**
     * Communicates the current company-configuration to the parent component
     * if the form is valid.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      if (this.formIsValid) {
        this.$emit('submit', this.companyConfig)
        this.companyConfig = defaultCompany()
        this.$refs.form.resetValidation()
      }
    },

    /**
     * Uses the company-data given as a prop or the default for new datasets.
     *
     * @returns {void}
     */
    useCompany () {
      this.companyConfig = this.company ? { ...this.company } : defaultCompany()
    }
  }
}
</script>
