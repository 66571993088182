<template>
  <div class="average-price-volumne-combi--wrap">
    <v-skeleton-loader
      v-if="isLoading"
      :height="340"
      type="image"
    />

    <v-card v-else-if="transactions.length">
      <v-card-title>
        {{ $t('averagePriceVolume.title') }}
        <v-spacer />
        <date-text-input
          :value="endDate | isoDate"
          :clearable="false"
          :label="$t('common.date')"
          dense
          input-class="pa-0 ma-0 font-weight-regular"
          @input="isoDate => endDate = new Date(isoDate)"
        />
      </v-card-title>
      <v-divider />
      <div>
        <v-sheet>
          <apex-chart
            width="100%"
            :height="350"
            :series="series"
            :options="priceVolumneChartOptions"
          />
        </v-sheet>

        <v-divider />
        <v-sheet color="d-flex align-center pa-2">
          <v-chip small label color="grey lighten-4">
            {{ startDate | readableDate }}
          </v-chip>
          <apex-chart
            :height="40"
            :options="brushOptions"
            :series="series"
            class="mx-4 flex"
          />
          <v-chip small label color="grey lighten-4">
            {{ endDate | readableDate }}
          </v-chip>
        </v-sheet>
      </div>
    </v-card>
  </div>
</template>

<script>
import { eachDayOfInterval, format, isSameDay, subYears } from 'date-fns'

import DateTextInput from '@/components/DateTextInput'
import colors from 'vuetify/lib/util/colors'

export default {
  name: 'average-price-volumne-combi',

  components: {
    DateTextInput,
  },

  props: {
    formulaPrices: {
      type: Array,
      default: () => ([])
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    transactions: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      chartColors: [
        colors.blue.accent3,
        colors.green.base,
        'rgba(0,0,0,0)',
        colors.indigo.darken4,
      ],
      endDate: new Date(),
    }
  },

  computed: {
    /**
     * Users can choose an enddate to use for the statistic. We don't want to
     * show every day before that (since too many points may affect the
     * performance), so we set a limit.
     *
     * @returns {Date}
     */
    startDate () {
      return subYears(this.endDate, 3)
    },

    /**
     * We want to show a value for each day within the desired date-range.
     *
     * @returns {array}
     */
    days () {
      return eachDayOfInterval({ start: this.startDate.getTime(), end: this.endDate.getTime() })
    },

    /**
     * Chart-configuration for displaying the average price, trading volume per
     * day.
     *
     * @returns {object}
     */
    priceVolumneChartOptions () {
      return {
        chart: {
          id: 'averagePrice',
          group: 'chartGroup',
          toolbar: {
            autoSelected: 'pan',
            show: false
          },
        },
        labels: this.days.map(day => day.toISOString()),
        stroke: { width: 2, curve: 'stepline' },
        colors: this.chartColors,
        dataLabels: { enabled: false },
        markers: { size: 0 },
        tooltip: {
          enabledOnSeries: [0, 1, 3],
          x: {
            format: 'DD.MM.yyyy'
          },
          y: {
            formatter: val => this.$options.filters.readablePrice(val, this.$i18n.locale),
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: (val, timestamp) => format(timestamp, 'dd.MM.yyyy'),
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: [{
          tickAmount: 6,
          seriesName: this.$t('averagePriceVolume.averagePrice'),
          labels: {
            formatter: val => this.formatPrice(val),
          },
          tooltip: {
            enabled: true,
          },
          crosshairs: {
            show: true,
          },
        }, {
          show: false,
          seriesName: this.$t('averagePriceVolume.averagePrice'),
          tickAmount: 6,
        }, {
          show: false,
          seriesName: this.$t('averagePriceVolume.averagePrice'),
        }, {
          tickAmount: 6,
          min: 0,
          opposite: true,
          labels: {
            formatter: val => this.getShortPrice(val),
          },
          tooltip: {
            enabled: true,
          },
          crosshairs: {
            show: true,
          },
        }],
      }
    },

    /**
     * Configuration for our simple summary-chart which acts as a control
     * for the chart with more details.
     *
     * @returns {object}
     */
    brushOptions () {
      return {
        chart: {
          id: 'lineControl',
          type: 'area',
          animations: { enabled: false },
          sparkline: { enabled: true },
          brush: { target: 'averagePrice', enabled: true },
          selection: {
            enabled: true,
            xaxis: {
              min: this.startDate.getTime(),
              max: this.endDate.getTime(),
            }
          },
        },
        colors: this.chartColors,
        stroke: { width: 2, curve: 'stepline' },
        fill: { opacity: 1, type: 'solid' },
        markers: { size: 0 },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: (val, timestamp) => format(timestamp, 'dd.MM.yyyy'),
          },
        },
        yaxis: [
          {
            show: false,
            seriesName: this.$t('averagePriceVolume.averagePrice'),
          },
          {
            show: false,
            seriesName: this.$t('averagePriceVolume.averagePrice'),
          },
          {
            show: false,
            seriesName: this.$t('averagePriceVolume.averagePrice'),
          },
          { show: false, opposite: true },
        ],
      }
    },

    /**
     * Calculates serveral datapoints per day of the currently visible date-range:
     *
     * - daily average price
     * - daily trading volume
     * - active formula-price
     * - average price per formula-price
     *
     * @returns {array}
     */
    dailyAverage () {
      const sortedFormulaPrices = JSON.parse(JSON.stringify(this.formulaPrices))
        .sort((a, b) => a.date.localeCompare(b.date))

      // transactions for the current start-date
      const transactionsInRange = this.transactions.filter(({ datePerformed, price }) =>
        new Date(datePerformed) > this.startDate && price > 0
      )

      // transactions, average price per formulaprice-step/change
      const averageByFormulaPrice = sortedFormulaPrices.map(({ date }, i) => {
        const from = new Date(date)
        const to = i < sortedFormulaPrices.length - 1 ? new Date(sortedFormulaPrices[i + 1].date) : new Date()
        const transactions = transactionsInRange.filter(({ datePerformed: d }) =>
          new Date(d) >= from && new Date(d) <= to
        )

        return {
          from,
          to,
          transactions,
          average: this.averageOfTransactions(transactions)
        }
      })

      this.$emit('calculated-averageprice', averageByFormulaPrice)

      return this.days.reduce((data, day, i) => {
        // transactions performed at the current date
        const transactions = transactionsInRange.filter(({ datePerformed }) => isSameDay(day, new Date(datePerformed)))

        // average price of the transactions of this day (or the last average if there aren't any transactions)
        const averagePrice = transactions.length
          ? this.averageOfTransactions(transactions)
          : i === 0 ? 0 : data[i - 1].y.averagePrice

        // active formulaprice at the current date
        const formulaPrice = sortedFormulaPrices
          .filter(entry => new Date(entry.date) < day)
          .at(-1)
          ?.formulaPrice || 0

        // average-price of transactions performed within the date-range of the formelprice active on this day
        const relatedAverageByFormulaPrice = averageByFormulaPrice.find(({ from, to }) => day >= from && day <= to)

        const entry = {
          x: day.getTime(),
          y: {
            averageByFormulaPrice: relatedAverageByFormulaPrice?.average || 0,
            averagePrice,
            formulaPrice,
            tradingVolume: transactions.reduce((sum, { price, amount }) => sum + price * amount, 0),
          },
        }

        return [...data, entry]
      }, [])
    },

    /**
     * Dataset for our combined chart containing the average price, formula
     * price and trading volume per day.
     *
     * @returns {array}
     */
    series () {
      const averagePricePerDay = {
        name: this.$t('averagePriceVolume.averagePrice'),
        type: 'line',
        data: this.dailyAverage.map(({ x, y }) => ({ x, y: y.averagePrice }))
      }

      const formulaPricePerDay = {
        name: this.$t('averagePriceVolume.formulaPrice'),
        type: 'line',
        data: this.dailyAverage.map(({ x, y }) => ({ x, y: y.formulaPrice }))
      }

      const tradingVolumePerDay = {
        name: this.$t('averagePriceVolume.tradingVolume'),
        type: 'area',
        data: this.dailyAverage.map(({ x, y }) => ({ x, y: y.tradingVolume }))
      }

      const averageByFormulaPrice = {
        name: this.$t('averagePriceVolume.formulaPriceAveragePrice'),
        type: 'line',
        data: this.dailyAverage.map(({ x, y }) => ({ x, y: y.averageByFormulaPrice }))
      }

      return [averagePricePerDay, formulaPricePerDay, averageByFormulaPrice, tradingVolumePerDay]
    },
  },

  methods: {
    /**
     * Average price of the given transactions (number of shares traded per
     * transaction influences the value).
     *
     * @param {array} transactions
     * @returns {number}
     */
    averageOfTransactions (transactions = []) {
      const { sum, shares } = transactions
        .filter(({ price }) => price > 0)
        .reduce(({ sum, shares }, { amount, price }) => (
          { sum: sum + amount * price, shares: shares + amount }
        ), { sum: 0, shares: 0 })

      return sum / (shares || 1)
    },

    /**
     * Formats the given amount of cents so the user sees a readable price.
     *
     * @param {number} cents
     * @returns {string}
     */
    formatPrice (cents) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: 'EUR',
        notation: 'compact',
        maximumFractionDigits: 0,
      }).format(cents / 100)
    },

    /**
     * Formats the given amount of cents as euros, thousands appear as 'k'.
     * Example: 200 000 € -> 200k €
     *
     * @param {number} cents
     * @returns {string}
     */
    getShortPrice (cents) {
      const euros = cents / 100
      return euros > 1000 ? `${(euros / 1000).toFixed(0)} TEUR` : `${euros.toFixed(0)} €`
    }
  },
}
</script>

<style lang="scss">
  .average-price-volumne-combi--wrap {
    .brush--wrap {
      .apexcharts-selection-rect {
        cursor: move;
        fill: #000;
        fill-opacity: 0.25;
        stroke: #000;
        stroke-opacity: 1;
      }
    }

    // We want to exclude the entry for the average price per formula-price-
    // change from the legend.
    //
    // The plugin doesn't allow us to remove specific legend-items. Another way
    // would be to manipulate the label and marker by index, which feels even
    // worse.
    .apexcharts-legend {
      .apexcharts-legend-series[rel="3"] {
        display: none;
      }
    }
  }
</style>
