<template>
  <div class="grouping-inputs--wrap">
    <v-text-field
      :value="(value.limit * 100).toFixed(0)"
      :disabled="!value.active"
      type="number"
      step="1"
      min="1"
      :error="value.limit < minimumLimit"
      outlined
      dense
      hide-details
      :label="$t('shareholders.groupUnderShare')"
      append-icon="mdi-percent"
      class="pa-0 mr-5"
      :style="{ flex: '0 1 100px' }"
      @input="val => update('limit', val / 100)"
    />
  </div>
</template>

<script>
import ShareholderFormatter from '@/services/shareholder-formatter'

/**
 * This component just handles the inputs for grouping, communicates values.
 * The handling must happen by views using this component.
 */
export default {
  name: 'grouping-inputs',

  props: {
    value: {
      type: Object,
      default: () => ({
        active: true, // should datasets get grouped?
        limit: 0.05, // group data under share (1 = 100%)
      })
    },
  },

  data () {
    return {
      minimumLimit: ShareholderFormatter.minimumLimit,
    }
  },

  methods: {
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}
</script>

<style lang="scss">
  .grouping-inputs--wrap {
    max-width: 150px;
  }
</style>
