<template>
  <div class="time-text-input--wrap">
    <v-menu
      v-model="pickerVisible"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="time"
          :label="label"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          outlined
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-sheet color="white">
        <v-time-picker
          v-if="pickerVisible"
          :value="time"
          full-width
          format="24hr"
          @input="setTime"
        />
        <v-divider />
        <div class="d-flex pa-2">
          <v-spacer />
          <v-btn depressed @click="pickerVisible = false">
            {{ $t('common.close') }}
          </v-btn>
        </div>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import { format, set } from 'date-fns'

export default {
  name: 'time-text-input',

  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: Date,
      default: new Date(),
    },
  },

  data () {
    return {
      pickerVisible: false,
    }
  },

  computed: {
    time () {
      return this.value ? format(this.value, 'HH:mm') : null
    },
  },

  methods: {
    setTime (time) {
      const [hours, minutes] = time.split(':')
      this.$emit('input', set(this.value, { hours, minutes }))
    }
  }
}
</script>
