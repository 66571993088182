<template>
  <div class="two-factor-creation--wrap">
    <v-card width="500" max-width="90%" class="mx-auto">
      <v-card-title>
        {{ $t('login.twoFactorCreation') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('login.pleaseEnableTwoFactorAuth') }}
      </v-card-subtitle>

      <v-divider />
      <v-card-text>
        {{ $t('login.twoFactorAuthInfo') }}
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="isLoading"
          depressed
          @click="enableTwoFactorAuth"
        >
          <v-icon left color="primary">
            mdi-send
          </v-icon>
          {{ $t('login.enableTwoFactorAuth') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <two-factor-dialog
      :is-visible="showConfirmDialog"
      :secret="secret"
      :qr-code="qrCode"
      @close="onClose"
      @confirmed="onConfirm"
    />
  </div>
</template>

<script>
import TwoFactorAuthApi from '@/api/TwoFactorAuth'
import TwoFactorDialog from '@/views/User/TwoFactorDialog'

export default {
  name: 'two-factor-creation',

  components: {
    TwoFactorDialog,
  },

  data () {
    return {
      isLoading: false,
      secret: '',
      showConfirmDialog: false,
      qrCode: '',
    }
  },

  methods: {
    /**
     * Starts the 2fa-activation for the current user. After that, we get a
     * secret and a qr-code to show to the user within a dialog where he has to
     * confirm it.
     *
     * @returns {void}
     */
    async enableTwoFactorAuth () {
      this.isLoading = true

      const res = await TwoFactorAuthApi.enable()

      if (res.ok) {
        const { secret, qrCodeDataUri } = await res.json()
        this.secret = secret
        this.qrCode = qrCodeDataUri
        this.showConfirmDialog = true
      } else {
        this.$store.commit('setSnackbar', { text: this.$t('common.errorOccured'), color: 'error' })
      }

      this.isLoading = false
    },

    onClose () {
      this.showConfirmDialog = false
      this.$emit('close')
    },

    /**
     * Resets the internal state.
     *
     * @returns {void}
     */
    onConfirm () {
      this.secret = ''
      this.qrCode = ''
    }
  },
}
</script>
