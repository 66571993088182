<template>
  <div class="document-overview--wrap">
    <data-table
      :columns="columns"
      :items="documents"
      :default-options="{ sortBy: ['date'], sortDesc: [true] }"
      :is-loading="isLoading"
    >
      <template #headerActions>
        <table-button
          icon="mdi-playlist-plus"
          dark
          :tooltip="$t('common.newEntry')"
          is-primary
          @click="showCreateDialog"
        />
      </template>

      <template #[`item.date`]="{ item }">
        {{ item.date | readableIsoDate }}
      </template>

      <template #[`item.companyId`]="{ item }">
        {{ getCompanyLabel(item.companyId) }}
      </template>

      <template #[`item.documentType`]="{ item }">
        {{ $t(`document.type.${item.documentType}`) }}
      </template>

      <template #[`item.displayAreas`]="{ item }">
        {{ formattedDisplayAreas(item.displayAreas) }}
      </template>

      <template #[`item.file`]="{ item }">
        <table-button
          icon="mdi-download"
          :tooltip="$t('common.download')"
          :href="getDownloadLink(item.documentId)"
          target="_blank"
        />
      </template>

      <template #[`item.language`]="{ item }">
        <v-img :src="getLanguageIconByKey(item.language)" max-width="20" height="15" />
      </template>

      <template #[`item.actions`]="{ item }">
        <table-button
          icon="mdi-pencil"
          icon-color="primary"
          :tooltip="$t('common.edit')"
          @click="showEditDialog(item)"
        />
      </template>
    </data-table>

    <document-dialog
      :is-visible="dialogVisible"
      :document="toEdit"
      @close="onClose"
      @request-reload="getDocuments"
    />
  </div>
</template>

<script>
import CompanyApi from '@/api/Company'
import DataTable from '@/components/DataTable'
import DocumentApi from '@/api/Documents'
import DocumentDialog from './Dialog'
import LanguageSwitch from '@/mixins/LanguageSwitch'
import TableButton from '@/components/TableButton'

export default {
  name: 'document-overview',

  components: {
    DataTable,
    DocumentDialog,
    TableButton,
  },

  mixins: [
    LanguageSwitch,
  ],

  data () {
    return {
      companies: [],
      dialogVisible: false,
      documents: [],
      downloadLink: null,
      isLoading: false,
      toEdit: null,
    }
  },

  computed: {
    columns () {
      return [
        { text: this.$t('common.date'), value: 'date' },
        { text: this.$t('common.company'), value: 'companyId', width: 130 },
        { text: this.$t('common.title'), value: 'title' },
        { text: this.$t('common.type'), value: 'documentType' },
        { text: this.$t('document.area'), value: 'displayAreas', sortable: false },
        { text: this.$t('document.language'), value: 'language', width: 120 },
        { text: this.$t('common.file'), value: 'file', sortable: false, align: 'right', width: 40 },
        { text: this.$t('common.actions'), value: 'actions', sortable: false, align: 'right', width: 40 },
      ]
    },
  },

  async mounted () {
    this.isLoading = true

    await this.getCompanies()
    await this.getDocuments()

    this.isLoading = false
  },

  methods: {
    /**
     * Loads available companies from the api.
     *
     * @returns {void}
     */
    async getCompanies () {
      const res = await CompanyApi.getAll()
      res.ok && (this.companies = await res.json())
    },

    /**
     * Loads all documents from the api.
     *
     * @returns {void}
     */
    async getDocuments () {
      const res = await DocumentApi.list()
      res.ok && (this.documents = await res.json())
    },

    /**
     * Finds the label of the company with the given id.
     *
     * @param {number|string}
     * @returns {string}
     */
    getCompanyLabel (companyId) {
      const company = this.companies.find(company => company.companyId === companyId)
      return company ? company.shortName : '-'
    },

    /**
     * Builds the url for downloading the document with the given id.
     *
     * @param {number} documentId
     */
    getDownloadLink (documentId) {
      return DocumentApi.getDownloadLink(documentId)
    },

    /**
     * Formats the given display-areas so the are translated, readable by the
     * user.
     *
     * @param {array} displayAreas
     * @returns {string}
     */
    formattedDisplayAreas (displayAreas) {
      return displayAreas
        .map(area => this.$t(`document.areas.${area}`))
        .sort()
        .join(', ')
    },

    onClose () {
      this.toEdit = null
      this.dialogVisible = false
    },

    async removeEntry () {
      const res = await DocumentApi.delete(this.toRemove.documentId)
      this.toRemove = null

      if (res.ok) {
        this.getDocuments()
        this.$store.commit('setSnackbar', { text: this.$t('common.deleted') })
      }
    },

    showCreateDialog () {
      this.toEdit = null
      this.dialogVisible = true
    },

    showEditDialog (documentEntry) {
      this.toEdit = documentEntry
      this.dialogVisible = true
    },
  },
}
</script>
